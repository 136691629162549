import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { NbDialogRef } from '@nebular/theme';
import { Prospect } from 'src/app/models/prospects';
import { CompanyService } from 'src/app/services/company/company.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-aditional-numbers',
  templateUrl: './add-aditional-numbers.component.html',
  styleUrls: ['./add-aditional-numbers.component.scss']
})
export class AddAditionalNumbersComponent implements OnInit {

  [x: string]: any;
  public numbersForm: FormGroup;
  public numPhone: number = 0;
  public additionalNumbers = [];
  public isEmpty: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public companyData: Prospect,
    public ref: MatDialogRef<AddAditionalNumbersComponent>,
    public formBuilder: FormBuilder,
    private companySerivce: CompanyService
  ) {
    this.numbersForm = new FormGroup({
      numbers: this.formBuilder.array([])
    })
  }

  get numbers(): FormArray {
    return this.numbersForm.get("numbers") as FormArray;
  }

  async ngOnInit() {
    this.additionalNumbers = this.companyData.additionalPrefix ?? []

    if (this.additionalNumbers.length == 0) {
      this.isEmpty = true
      this.numbers.push(
        this.formBuilder.group({
          phone: "",
          prefix: "",
        })
      )
      this.numPhone++
    }
    else {
      this.additionalNumbers.forEach(element => {
        this.numbers.push(
          this.formBuilder.group({
            phone: element.phone,
            prefix: element.prefix
          })
        )
        this.numPhone++
      });
    }

  }

  async getNumbersOfClient(companyId: string) {
    try {
      var response = await this.companySerivce.getAdditionalsNumbers(companyId);
      if (response.code == 200) {
        if (response.data != undefined) {
          return response.data
        }
        else {
          return []
        }
      }
      else {
        throw response.message
      }
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al obtener los números del cliente',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
      })
    }
  }

  addNumber() {
    if (this.numPhone < 5) {
      this.numbers.push(
        this.formBuilder.group({
          phone: "",
          prefix: "",
        })
      )
      this.isEmpty = false;
      this.numPhone++
    }
  }

  removeConcept(i: number) {
    this.numbers.removeAt(i);
    this.numPhone--
  }

  async submit() {
    try {
      var form = this.numbersForm.value
      var arrayOfPhones = []
      var arrayOfPrefix = []
      for (let i = 0; i < form.numbers.length; i++) {
        const element = form.numbers[i];
        if (element.phone == "" || element.phone == null) {
          await Swal.fire({
            title: 'Campos vacios',
            text: 'Favor de rellenar los campos faltantes',
            icon: 'error',
          })
          return
        }
        else {
          arrayOfPhones.push(element.phone)
          arrayOfPrefix.push(element)
        }
      }
      await this.companySerivce.addAdditionalNumbers(arrayOfPhones, this.companyData._id, arrayOfPrefix);
      await Swal.fire({
        title: 'Numeros actualizados con exito',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })
      this.ref.close();
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrio un error inesperado al guardar los números',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
      })
    }
  }

}
