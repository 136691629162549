import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Prospect } from 'src/app/models/prospects';
import { UserData } from 'src/app/models/user';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { UserService } from 'src/app/services/users/user.service';
import { ZoomService } from 'src/app/services/zoom/zoom.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generate-zoom',
  templateUrl: './generate-zoom.component.html',
  styleUrls: ['./generate-zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateZoomComponent implements OnInit {

  public zoomForm: FormGroup
  public agentList: UserData[] = []
  private clientList: Prospect[] = []
  public filteredOptions: Observable<any>
  private authCode = ''
  public loader = false;
  constructor(
    private router: Router,
    private formbuilder: FormBuilder,
    private prospectService: ProspectsService,
    private agentsService: UserService,
    private zoomService: ZoomService,
    private route: ActivatedRoute
  ) {
    this.zoomForm = this.formbuilder.group({
      client: ['', Validators.required],
      subject: ['', Validators.required],
      agents: [[], Validators.required],
      meetDate: ['', Validators.required],
      meetHour: ['', Validators.required],
      url: ['', Validators.required],
    })
  }

  async ngOnInit() {
    this.clientList = await this.getClients()
    this.agentList = await this.getAgents()
    this.filteredField()
    // this.route.queryParams.subscribe(async (params) => {
    //   if (params['code'] != undefined) {
    //     this.authCode = params['code']
    //   }
    //   else {
    //     this.closeDialog()
    //   }
    // })

  }

  async getClients() {
    try {
      const response = await this.prospectService.getActiveProspects()
      return response.data
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los clientes',
        icon: 'error',
        text: 'Si el problema persiste favor de comunicarlo con un administrador'
      })
    }
  }

  async getAgents() {
    try {
      const response = await this.agentsService.getActiveUsers()
      return response
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los clientes',
        icon: 'error',
        text: 'Si el problema persiste favor de comunicarlo con un administrador'
      })
    }
  }

  async submit() {
    try {
      if (this.zoomForm.invalid) {
        this.zoomForm.markAllAsTouched()
        Swal.fire({
          title: 'Favor de llenar los campos requeridos',
          icon: 'error',
        })
        return
      }
      this.loader = true
      const formValue = this.zoomForm.value
      var data = {
        clientId: formValue.client._id,
        agents: formValue.agents,
        meetDate: this.getCombineDateTime(formValue.meetDate, formValue.meetHour),
        url: formValue.url,
        subject: formValue.subject
      }
      const response = await this.zoomService.createZoom(data)
      if (response == 200) {
        this.loader = false
        await Swal.fire({
          icon: 'success',
          title: 'Conferencia creada con exito',
          timer: 2000,
          showConfirmButton: false
        })
        this.closeDialog()
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error inesperado al crear la conferencia',
        icon: 'error',
        text: 'Si el problema persiste favor de comunicarlo con un administrador'
      })
      this.closeDialog()
    }
  }

  filteredField() {
    this.filteredOptions = this.zoomForm.controls['client'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string) {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();
      return this.clientList.filter((client) => client.nombre.toLowerCase().includes(filterValue));
    }
  }

  closeDialog() {
    this.router.navigate(['clients'])
  }

  displayFn(client: Prospect) {
    return client.nombre
  }

  private getCombineDateTime(date: Date, time: any) {
    var day = new Date(new Date(date).setHours(0, 0, 0, 0))
    var hour = parseInt(time.hour);
    var min = parseInt(time.minute);
    var newDate = DateTime.fromISO(day.toISOString().split("T")[0])
      .set({ hour: hour })
      .set({ minute: min })
      .set({ second: 0 })
      .toJSDate();
    return newDate;
  }

}
