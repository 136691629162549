<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                <div class="header">
                    <div class="filter">
                        <input type="text" placeholder="Buscar archivo por nombre" nbInput type="text"
                            [(ngModel)]="term">
                        <!-- <button nbButton size="small" status="info"
                            (click)="findIntoArray(term)"><mat-icon>find_in_page</mat-icon>Buscar dentro de
                            docs.</button>
                        <button nbButton size="small" status="danger"
                            (click)="filterDocuments = [];term='' "><mat-icon>delete</mat-icon>Quitar filtros</button> -->
                    </div>
                    <button nbButton size="small" status="warning" (click)="addNewInfoCompany()">
                        <mat-icon>add_to_photos</mat-icon> Añadir info.
                    </button>
                </div>
            </nb-card-header>
        </nb-card>
    </div>
</div>

<div class="row" *ngIf="disclaimer">
    <div class="col-md-12">
        <nb-card>
            <nb-card-body class="text-center">
                <span style="text-transform: uppercase">Esta empresa aún no tiene información
                    para enviar, da
                    clic en "Añadir info." para añadir nueva información</span>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <ng-container>
        <div class="col-md-3" *ngFor="let quo of docs; let i = index">
            <nb-card>
                <nb-card-header >
                    <div class="row">
                        <div class="col-md-10">{{ quo.title }}</div>
                        <div class="col-md-2">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openImageCotizacion(quo.url)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                    <span>Ver / Descargar documento</span>
                                </button>
                                <button mat-menu-item (click)="deleteDoc(quo._id)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Eliminar documento</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </nb-card-header>
                <nb-card-body>
                    <div *ngIf="quo.extension == 'pdf'" class="text-center img-quo-div">
                        <img class="img-responsive"
                            src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/pdf.png?alt=media&token=289b2cbb-f248-42f0-a3ba-3c705de0e1f9" />
                    </div>
                    <div *ngIf="quo.extension == 'docx'" class="text-center img-quo-div">
                        <img class="img-responsive"
                            src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/doc.png?alt=media&token=efd97789-42d9-4ac9-aaf7-977144808075" />
                    </div>
                    <div *ngIf="quo.extension == 'xlsx'" class="text-center img-quo-div">
                        <img class="img-responsive"
                            src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/xls.png?alt=media&token=8e8c2b57-9368-4acf-9e61-aa85b6da7025" />
                    </div>
                    <div *ngIf="quo.extension == 'png' || quo.extension == 'jpg' || quo.extension == 'jpeg'"
                        class="text-center img-quo-div">
                        <img class="img-responsive" src="{{ quo.url }}" />
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="col-md-3 " *ngFor="let quo of filterDocuments" [title]="'Incluye -' + term + '- dentro del documento'">
            <nb-card class="documents-finds">
                <nb-card-header class="title-doc">
                    {{ quo.title }}
                </nb-card-header>
                <nb-card-body>
                    <div *ngIf="quo.extension == 'pdf'" class="text-center img-quo-div">
                        <img class="img-responsive"
                            src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/pdf.png?alt=media&token=289b2cbb-f248-42f0-a3ba-3c705de0e1f9" />
                    </div>
                    <div *ngIf="quo.extension == 'docx'" class="text-center img-quo-div">
                        <img class="img-responsive"
                            src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/doc.png?alt=media&token=efd97789-42d9-4ac9-aaf7-977144808075" />
                    </div>
                    <div *ngIf="quo.extension == 'xlsx'" class="text-center img-quo-div">
                        <img class="img-responsive"
                            src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/xls.png?alt=media&token=8e8c2b57-9368-4acf-9e61-aa85b6da7025" />
                    </div>
                    <div *ngIf="quo.extension == 'png'" class="text-center img-quo-div">
                        <img class="img-responsive" src="{{ quo.url }}" />
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
    </ng-container>
    <ng-container *ngIf="docs.length == 0">
        <div class="col-md-3">
            <p>No hay documentos por el momento</p>
        </div>
    </ng-container>
    
</div>