export const environment = {
  production: true,
  config: {
    apiKey: "AIzaSyCVWNnuFVke45CimYe9RH7H32gYNBCwZCA",
    authDomain: "miasistema.firebaseapp.com",
    databaseURL: "https://miasistema.firebaseio.com",
    projectId: "miasistema",
    storageBucket: "miasistema.appspot.com",
    messagingSenderId: "535313007371",
    appId: "1:535313007371:web:a977aec0771b594c26980e"
  },
  // URL_API: 'http://localhost:8080/api',
  URL_API: "https://miasistente.herokuapp.com/api"
};
