<div class="table-container">
    <table mat-table #table [dataSource]="data">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre comercial </th>
            <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef> Nombre del cliente </th>
            <td mat-cell *matCellDef="let row"> {{row.nombreCliente}} </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef> Fecha de creación </th>
            <td mat-cell *matCellDef="let row"> {{row.createdAt | date}} </td>
        </ng-container>
        <ng-container matColumnDef="agent">
            <th mat-header-cell *matHeaderCellDef> Ejecutivo asignado </th>
            <td mat-cell *matCellDef="let row"> {{row.ejecutivo}} </td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Numero telefonico </th>
            <td mat-cell *matCellDef="let row"> {{row.telefono}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Correo </th>
            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>
        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef> Plan a contratar </th>
            <td mat-cell *matCellDef="let row"> {{row.plan}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let row">
                <!-- <button mat-button (click)="openUpdateCompany(row)" matTooltip="Editar informacion del Prospecto"
                    class="warning-icon"><mat-icon class="info-icon">edit</mat-icon></button> -->
                <button mat-button (click)="hideCompany(row)" matTooltip="Revertir a Prospecto" class="info-icon"><mat-icon
                        class="warning-icon">visibility</mat-icon></button>
                <button mat-button (click)="deleteProspect(row)" matTooltip="Eliminar Prospecto"
                    class="danger-icon"><mat-icon class="danger-icon">delete</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayColumns"></tr>
    </table>
</div>