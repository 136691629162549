import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { DiaryComponent } from 'src/app/pages/diary/diary.component';
import { ImportContactsComponent } from 'src/app/pages/diary/import-contacts/import-contacts.component';
import { ContactService } from 'src/app/services/contact/contact.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { AddClientComponent } from '../add-client/add-client.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  @Output() refresh = new EventEmitter<string>();
  name_company: string;
  actionTitle: string;
  public term = ''
  company_clients: any = [];
  userType: string;

  constructor(
    public dialogService: NbDialogService,
    private contactService: ContactService,
  ) { }

  async ngOnInit() {
    this.userType = localStorage.getItem("currentUser");
    this.company_clients = await this.getClients(DiaryComponent.companyIdFocus);
  }

  async importClients() {
    this.dialogService.open(ImportContactsComponent).onClose.subscribe((data) => {
      this.ngOnInit()
    });
  }

  async getClients(companyID: string) {
    try {
      return await this.contactService.getContacsByCompany(companyID);
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperado',
        text: "Si el problema persiste, favor de reportar el error"
      })
      return []
    }
  }

  async generateExcel() {
    await Swal.fire({
      title: 'Generando archivo, por favor espere...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timer: 2000,
      timerProgressBar: true
    }).then(() => {
      let element = document.getElementById('tabla-clientes');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, 'Informacion de contactos');
      /* save to file */
      XLSX.writeFile(wb, "contactos_" + this.name_company + '.xlsx');
    })
  }

  async addContact() {
    this.dialogService.open(AddClientComponent, { context: { title: "Añadir contacto" } }).onClose.subscribe((data) => {
      this.ngOnInit()
    });
  }

  editContact(info) {
    this.dialogService.open(AddClientComponent, { context: { id: info, title: "Editar contacto" } }).onClose.subscribe((data) => {
      this.ngOnInit()
    });
  }

  async deleteUser(id: string) {
    var result = await Swal.fire({
      title: '¿Desea eliminar este contacto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    })
    if (result.isConfirmed) {
      try {
        await this.contactService.deleteContact(DiaryComponent.companyIdFocus, id);
        await Swal.fire({
          title: 'Eliminado',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1200
        }
        )
        this.ngOnInit()
      } catch (error) {
        await Swal.fire({
          title: "Ocurrió un error inesperado, intentalo de nuevo mas tarde",
          icon: "error",
          text: "Si el problema persiste, favor de reportar el error",
          showConfirmButton: true
        });
      }
    }
  }

}
