<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                Historial de notificaciones
            </nb-card-header>

            <nb-card-body>
                <nb-tabset>
                    <nb-tab tabTitle="Notificaciones de pendientes" tabIcon="calendar-outline" responsive>
                        <nb-list>
                            <nb-list-item *ngFor="let pending of pendingNotifications" style="display: block;">
                                <div class="pending-info">
                                    <div class="icon-notification">
                                        <mat-icon *ngIf="pending.title == 'Tarea atrasada'">warning</mat-icon>
                                        <mat-icon
                                            *ngIf="pending.title == 'Actualización de tarea' || pending.title == 'Modificación de tiempo en actividad'">edit</mat-icon>
                                        <mat-icon
                                            *ngIf="pending.title == 'Pendiente a punto de concluir'">timer</mat-icon>
                                    </div>
                                    <div class="content-notification">
                                        <strong>
                                            <h6>{{pending.title}}</h6>
                                        </strong>
                                        <p>{{pending.title != 'Pendiente a punto de concluir' ? "El usuario ":
                                            "Elcliente "}}<strong>{{pending.who}}</strong> {{pending.action}} </p>
                                    </div>
                                </div>
                                <div class="date">
                                    <small>{{pending.date | date:"dd/MMMM/yyyy"}} {{pending.date | date:"hh:mm a"}}
                                    </small>
                                </div>
                            </nb-list-item>
                        </nb-list>
                    </nb-tab>
                    <nb-tab tabTitle="Notificaciones de App" tabIcon="calendar-outline" responsive>
                        <ng2-smart-table [settings]="settingsAdmin" [source]="data_source" (create)="create(dialogadd)"
                            (edit)="resend($event, dialogadd)" (delete)="delete($event)">
                        </ng2-smart-table>
                    </nb-tab>
                </nb-tabset>
                <div>

                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<ng-template #dialogadd let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header class="text-center">Nueva notificación</nb-card-header>
        <nb-card-body class="text-center">
            <form [formGroup]="formgroup">
                <div class=" form-group row">
                    <div class="col-md-12">
                        <label for="">Título de la notificación</label>
                        <input type="text" nbInput fullWidth fieldSize="medium" formControlName="title"
                            placeholder="Título de la notificación">
                    </div>
                </div>
                <div class=" form-group row">
                    <div class="col-md-12">
                        <label for="">Mensaje de la notificación</label>
                        <textarea nbInput fullWidth placeholder="Mensaje" formControlName="message"></textarea>
                    </div>
                </div>
            </form>
        </nb-card-body>
        <nb-card-footer class="text-center">
            <button (click)="sendNotification();ref.close()" style="margin-right:8px" nbButton status="success">Enviar
                notificación</button>
            <button nbButton (click)="ref.close()">Cancelar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>