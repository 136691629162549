import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { CompanyFormComponent } from 'src/app/components/companies/company-form/edit.component';
import { CompanyService } from 'src/app/services/company/company.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {
  settingsUsers = {
    actions: {
      columnTitle: "Acciones",
      add: false,
      edit: true,
      delete: true,
      position: "right",
    },
    pager: {
      display: true,
      perPage: 20,
    },
    edit: {
      editButtonContent: "<span>Editar</span>",
    },
    delete: {
      deleteButtonContent: "<span>Eliminar</span>",
    },
    columns: {
      thumbnail: {
        title: "Cliente",
        type: "html",
      },
      nombre: {
        title: "Nombre",
      }
    },
    mode: "external",
    noDataMessage: "No hay registros por ahora",
  };

  @Input() data_source_2 = [];
  @Output('eventInit') fatherOnInit = new EventEmitter();

  constructor(
    private companyService: CompanyService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit() {
  }

  openUpdateCompany(data) {
    this.dialogService
      .open(CompanyFormComponent, { context: data })
      .onClose.subscribe(async (data) => {
        if (data != undefined) {
          await Swal.fire({
            title: "Guardando...",
            timer: 1500,
            timerProgressBar: true,
            showConfirmButton: false,
            willOpen(popup) {
              Swal.showLoading();
            },
          });
          try {
            await this.companyService.updateCompany(data.company, data.file);
            await Swal.fire({
              position: "center",
              icon: "success",
              title: "Datos actualizados",
              showConfirmButton: false,
              timer: 1000,
            });
            this.fatherOnInit.emit()

          } catch (error) {
            Swal.fire({
              title: "Ocurrió un error al actualizar el cliente",
              text: "Si el problema persiste, favor de reportar el error",
              icon: 'error'
            })
          }
        }
      });
  }

  async deleteCompany(id: string) {
    try {

    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

}
