import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { asBlob } from 'html-docx-js-typescript';
import { ClientProfitability } from 'src/app/models/client-profitablility';
import { Plan } from 'src/app/models/plan';
import { ActionsClient } from 'src/app/models/report-agent';
import { ContractsService } from 'src/app/services/contracts/contracts.service';
import { ExcelService } from 'src/app/services/excel/excel.service';
import { PlanService } from 'src/app/services/planService/plan.service';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

  @ViewChild("htmlDoc") html;
  public totalTime: string;
  public total_calls: number = 0;
  public total_task: number = 0;
  public total_workForDay: number = 0;
  public hours_calls: number = 0;
  public hours_work: number = 0;
  public hours_task: number = 0;
  public time_calls: string;
  public time_task: string;
  public reportID: any;
  public loader = true;
  public data_source: ClientProfitability;
  private planList: Plan[] = []
  private contractList = []
  public displayColumns = ["date", 'agent', 'client', 'completeData', 'totalCalls', 'timeCalls', 'callHours', "totalTask", "timeTask", "hourTask", "timeWork", "workHours", "daysWork", "plan", "price", "planTime", "hoursWork", "percent"]

  constructor(
    private excelService: ExcelService,
    private reportService: ReporteService,
    private activeLink: ActivatedRoute,
    private planService: PlanService,
    private contractService: ContractsService
  ) { }

  async ngOnInit() {
    var id = await this.activeLink.snapshot.params['id']
    this.planList = await this.getPlans()
    this.contractList = await this.getContracts()
    this.data_source = await this.getReportInfo(id)
    this.sumCallsTime(this.data_source.clients)
    this.loader = false
  }

  async getReportInfo(id: string) {
    try {
      var response = await this.reportService.getClientProfitabilityById(id);
      response.clients.map((client) => {
        var planData = this.planList.filter((plan) => plan.title == client.plan)
        if (planData) {
          client['planData'] = planData[0]
        }
        var contractData = this.contractList.filter((contract) => contract.clientId == client.companyId)
        if (contractData) {
          client['contractData'] = contractData[0]
          client['usage'] = this.calculateUsedDates(client.quoteTime, parseInt(planData[0].time))
        }
      })
      response.clients.sort((a: any, b: any) => a.usage > b.usage ? -1 : 1)
      return response
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error inesperado al consultar la información del reporte',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async getPlans() {
    try {
      const response = await this.planService.getPlans()
      return response.data
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un erro inesperado al consultar la información de los planes',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async getContracts() {
    try {
      const response = await this.contractService.get()
      return response.data
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un erro inesperado al consultar la información de los contratos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async generateWord() {
    const htmlString = this.html.nativeElement.innerHTML;
    const data = await asBlob(htmlString, {
      orientation: "portrait",
      margins: { top: 300, bottom: 300 },
    });
    var url = window.URL.createObjectURL(data as Blob);
    this.download(url);
  }

  calculateUsedDates(quouteTime: number, planTime: number) {
    try {
      var percentUsage = 0
      percentUsage = ((quouteTime / 3600) / planTime) * 100
      return percentUsage
    } catch (error) {
      console.log(error);
    }
  }

  calculateHours(hoursPlan: number, percentUsage: number) {
    var hoursWork = 0
    hoursWork = hoursPlan * (percentUsage / 100);
    return hoursWork;
  }

  download(url) {
    var link = document.createElement('a');
    link.target = "_blank";
    link.download = `reporte_de_renbtabilidad.docx`;
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  }

  sumCallsTime(clients: ActionsClient[]) {
    var total_llamadas = 0
    var total_llamadas_seconds = 0
    var total_tareas = 0
    var total_tareas_seconds = 0
    var total_trabajado = 0
    var total_workForDay = 0

    clients.forEach(element => {
      total_llamadas += element.total_Llamadas
      total_llamadas_seconds += element.seconds_llamadas
      total_tareas += element.total_tareas
      total_tareas_seconds += element.seconds_tareas
      total_trabajado += element.seconds_trabajo
      total_workForDay += element.seconds_trabajo
    });
    this.total_calls = total_llamadas;
    this.total_task = total_tareas
    this.time_calls = this.secondsToHms(total_llamadas_seconds)
    this.hours_calls = total_llamadas_seconds / 3600
    this.time_task = this.secondsToHms(total_tareas_seconds)
    this.hours_task = total_tareas_seconds / 3600
    this.totalTime = this.secondsToHms(total_trabajado)
    this.hours_work = total_trabajado / 3600
    this.total_workForDay = (total_workForDay / 3600) / 12
  }

  async excel() {
    const headers = [
      "Nombre del cliente",
      "Fecha de corte",
      "Ejecutivo asignado",
      "Antigüedad",
      "Total de llamadas",
      "Tiempo atentido en llamadas",
      "Total de horas en llamadas",
      "Total de tareas realizadas",
      "Tiempo atentido en tareas",
      "Total de horas en tareas",
      "Tiempo trabajado",
      "Total de horas trabajadas",
      "Horas trabajadas por dia",
      "Plan",
      "Monto",
      "Horas del plan",
      // "Valor pagado por Hora",
      // "Valor real pagado por Hora",
      "Horas trabajadas a la fecha",
      "% de Uso"
    ];
    var dataToPrint = []
    for await (const client of this.data_source.clients as any) {
      var executives = ""
      for await (const executive of client.agent) {
        if (client.agent.length == 1) {
          executives = executive
        }
        else {
          executives = executive + ", " + executives
        }
      }

      var obj = {
        "Nombre del cliente": client.nombre_empresa,
        "date": formatDate(new Date(client.contractData.nextRenovationDate), 'dd/MM/yyyy', 'en-US'),
        "Ejecutivo asignado": executives,
        "Feh": formatDate(new Date(client.companyDate), 'dd/MM/yyyy', 'en-US'),
        "Total de llamadas": client.total_Llamadas,
        "Tiempo atentido en llamadas": client.tiempo_llamadas,
        "Total de horas en llamadas": parseFloat((client.seconds_llamadas / 3600).toFixed(2)),
        "Total de tareas realizadas": client.total_tareas,
        "Tiempo atentido en tareas": client.tiempo_tareas,
        "Total de horas en tareas": parseFloat((client.seconds_tareas / 3600).toFixed(2)),
        "Tiempo trabajado": client.total_trabajo,
        "Total de horas trabajadas": parseFloat((client.seconds_trabajo / 3600).toFixed(2)),
        "Horas trabajadas por dia": parseFloat(((client.seconds_trabajo / 3600) / 12).toFixed(2)),
        "Plan": client.plan,
        "e": client.planData.precio,
        "a": parseInt(client.planData.time),
        // "b": parseFloat((client.planData.precio / client.planData.time).toFixed(2)),
        // "c": parseFloat((client.planData.precio / (client.seconds_trabajo / 3600)).toFixed(2)),
        "d": parseFloat((client.quoteTime / 3600).toFixed(2)),
        "f": client.usage.toFixed(2) + "%",
      }
      dataToPrint.push(obj)
    }
    const filename = this.data_source.report
    await this.excelService._createReport(dataToPrint, headers, filename, "RENTABILIDAD POR CLIENTE", ["-", this.total_calls, this.time_calls, this.hours_calls.toFixed(2), this.total_task, this.time_task, this.hours_task.toFixed(2), this.totalTime, this.hours_work.toFixed(2), this.total_workForDay, "-"], [5, 8, 10, 11], [15])
  }

  public secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
    return hDisplay + mDisplay + sDisplay || "0s";
  }

}
