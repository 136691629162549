<div>
    <nb-card>
        <nb-card-header>{{actionTitle}} </nb-card-header>
        <nb-card-body>
            <form [formGroup]="contactInfoForm">
                <div class="form-group row">
                    <div class="col-md-12">
                        <label style="font-size: 12px">NOMBRE: </label>
                        <input type="text" nbInput fullWidth fieldSize="medium" formControlName="name" />
                        <p *ngIf="(contactInfoForm.get('name')?.hasError('required') && contactInfoForm.get('name')?.touched) || (hasError && contactInfoForm.get('name')?.untouched)"
                            class="error">
                            *El campo Nombre es requerido
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label style="font-size: 12px">EMAIL: </label>
                        <input type="email" nbInput fullWidth fieldSize="medium" formControlName="email" />
                        <p *ngIf="(contactInfoForm.get('email')?.hasError('email') && contactInfoForm.get('email')?.touched) || (hasError && contactInfoForm.get('email')?.untouched)"
                            class="error">
                            *El campo email es requerido
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label style="font-size: 12px; text-transform: uppercase">Teléfono</label>
                        <input type="number" nbInput fullWidth fieldSize="medium" formControlName="phone" />
                        <p *ngIf="(contactInfoForm.get('phone')?.hasError('required') && contactInfoForm.get('phone')?.touched) || (hasError && contactInfoForm.get('phone')?.untouched)"
                            class="error">
                            *El campo Teléfono es requerido
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label style="font-size: 12px; text-transform: uppercase">Comentarios adicionales:</label>
                        <textarea formControlName="comments" nbInput fullWidth></textarea>
                    </div>
                </div>

                <div class="form-group row text-center">
                    <div class="col-md-6">
                        <button nbButton size="small" status="danger" (click)="ref.close()">
                            Cancelar
                        </button>
                    </div>
                    <div class="col-md-6">
                        <ng-container *ngIf="actionTitle == 'Añadir contacto'">
                            <button nbButton size="small" status="warning" (click)="newContact()">
                                <nb-icon icon="save-outline"></nb-icon>Añadir
                            </button>
                        </ng-container>
                        <ng-container *ngIf="actionTitle != 'Añadir contacto'">
                            <button nbButton size="small" status="warning" (click)="updateContact()">
                                <nb-icon icon="save-outline"></nb-icon>Guardar
                            </button>
                        </ng-container>

                    </div>
                </div>
            </form>
        </nb-card-body>
    </nb-card>
</div>