import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import { GenerateGeneralReportComponent } from '../generate-general-report/generate-general-report.component';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-general-reports',
  templateUrl: './general-reports.component.html',
  styleUrls: ['./general-reports.component.scss']
})
export class GeneralReportsComponent implements OnInit {

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: true,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Generar reporte</span>",
      // createButtonContent: '<i class="nb-checkmark"></i>',
      // cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<span>Ver detalles</span>',
    },
    delete: {
      deleteButtonContent: '<span>Eliminar</span>',
    },
    columns: {
      report: {
        title: 'Reporte',
      },
      clientName: {
        title: 'Cliente',
      },
      date: {
        title: 'Fecha de creación',
      },
    },
    mode: 'external',
    noDataMessage: 'No hay reportes por ahora'
  };
  public data_source = [];
  public loader = false

  constructor(
    private dialogService: MatDialog,
    private reports_service: ReporteService,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.getAllData();
  }

  async getAllData() {
    this.loader = true
    this.data_source = await this.getAllReports()
    this.loader = false
  }

  async getAllReports() {
    try {
      var reports = await this.reports_service.getGeneralClientReport();
      reports.map((element: any) => {
        element.date = formatDate(element.date._seconds * 1000, 'dd-MM-yyyy', 'es-MX');
      });
      return reports;
    } catch (error) {
      await Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error al consultar los reportes',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  openDetails(data) {
    this.router.navigate(['reporte-general/', data.data.id])

  }
  createNew() {
    this.dialogService.open(GenerateGeneralReportComponent, { height: '50vh' });
  }

  async delete(data) {
    var result = await Swal.fire({
      title: '¿Seguro que quiere eliminar este reporte?',
      text: "Esta acción no se puede revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    })
    if (result.value) {
      await this.reports_service.deleteReport(data.data.id)
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Datos eliminados',
        showConfirmButton: false,
        timer: 1000
      })
      await this.getAllData();
    }
  }

}
