import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { ReporteService } from "src/app/services/reportes/reporte.service";
import { MatDialogRef } from "@angular/material";
import { ProspectsService } from "src/app/services/prospects/prospects.service";
import { Prospect } from "src/app/models/prospects";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-generate-general-report",
  templateUrl: "./generate-general-report.component.html",
  styleUrls: ["./generate-general-report.component.scss"],
})
export class GenerateGeneralReportComponent implements OnInit {
  public addForm: FormGroup;
  public clients: Prospect[] = [];
  public minDate: Date;
  public loader = false;
  public filteredOptions;

  constructor(
    private companyService: ProspectsService,
    public ref: MatDialogRef<GenerateGeneralReportComponent>,
    private fb: FormBuilder,
    private router: Router,
    private reportService: ReporteService
  ) { }

  async ngOnInit() {
    this.addForm = this.fb.group({
      start_date: [""],
      end_date: [""],
      client: [""],
      hasCalls: [true],
      hasSchedule: [true],
    });
    this.clients = await this.getCompanies();
    this.filteredTariff()
  }

  filteredTariff() {
    this.filteredOptions = this.addForm.controls['client'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string) {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();
      return this.clients.filter((client) => client.nombre.toLowerCase().includes(filterValue));
    }
  }

  displayFn(client: Prospect) {
    return client.nombre
  }

  async getCompanies() {
    try {
      var companies = await this.companyService.getActiveProspects();
      return companies.data.sort((a, b) => a.nombre > b.nombre ? 1 : -1)
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al obtener los clientes',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  onSelectedStartDate(event) {
    var date = new Date(event);
    date.setDate(date.getDate() + 1);
    this.minDate = date;
  }


  validateForm() {
    var form = this.addForm.value;
    if (form.client == "" || form.start_date == "" || form.end_date == "") {
      return Swal.fire({
        position: "center",
        icon: "error",
        title: "Todos los campos son obligatorios",
        showConfirmButton: true,
      });
    }
    this.action(form);
  }

  async action(form: any) {
    try {
      this.loader = true;
      var companyID = form.client.uid;
      var obj = {
        initDate: form.start_date.getTime(),
        endDate: form.end_date.getTime(),
        callReport: form.hasCalls
      }
      var url = await this.reportService.createGeneralReport(companyID, obj)
      await Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se ha generado el reporte correctamente',
        showConfirmButton: false,
        timer: 1500
      });
      this.ref.close();
      this.addForm.reset();
      this.loader = false;
      this.router.navigate(['reporte-general/', url])
    } catch (error) {
      this.ref.close();
      this.addForm.reset();
      this.loader = false;
      if (error.status == 504) {
        return Swal.fire({
          icon: 'error',
          title: "Ocurrió un error externo al servidor",
          text: "No se pudo acceder al servicio http://134.122.14.131:9020, para consultar informacion de las llamadas, si el problema persiste favor de ponerse en contacto con el area de TI de MIA"
        })
      } else {
        return Swal.fire({
          position: "center",
          icon: 'error',
          title: "Ocurrió un error al crear los datos",
          text: "Intente de nuevo más tarde",
          showConfirmButton: true,
        });
      }
    }
  }
}
