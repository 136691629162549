import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-files',
  templateUrl: './add-files.component.html',
  styleUrls: ['./add-files.component.scss']
})
export class AddFilesComponent implements OnInit {

  [x: string]: any
  public addForm: FormGroup;
  public config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public componentRef: any,
    public ref: MatDialogRef<AddFilesComponent>,
    private storage: AngularFireStorage,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    let fileType = this.componentRef;
    this.addForm = this.formBuilder.group({
      title: ["", Validators.required],
      url: ["", Validators.required],
      extension: ["", Validators.required],
    });
    if (fileType.title != undefined) {
      this.addForm.controls.title.patchValue(fileType.title)
    }
  }

  async onUploadInfo(e) {
    try {
      var file_name = e[0].name;
      var base64url = e[1].files.file;
      var extension = file_name.split(".").pop();
      var name = new Date().getTime() + Math.random().toString(36).substring(2);
      var refUrl = "/prospects/additionalFiles/" + name + `.${extension}`;
      var format_type = base64url.split(";")
      var type_file = (format_type[0]).slice(5);

      const fileRef = this.storage.ref(refUrl);
      const task = this.storage.ref("/prospects/additionalFiles/").child(name + `.${extension}`).putString(base64url, "data_url", { contentType: type_file });

      task
        .snapshotChanges()
        .pipe(
          finalize(async () => {
            var downloadURL = await fileRef.getDownloadURL().toPromise();
            this.addForm.controls.url.setValue(downloadURL);
            this.addForm.controls.extension.setValue(extension);
          })
        )
        .subscribe();

    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error inesperdado al subir el archivo',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  submit() {
    var form = this.addForm.value;
    this.ref.close(form);
  }

}
