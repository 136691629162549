import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiaryComponent } from 'src/app/pages/diary/diary.component';
import { ContactService } from 'src/app/services/contact/contact.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  [x: string]: any;
  public contactInfoForm: FormGroup;
  public actionTitle: string;
  public contactInfo: any;
  public newPatientName: string;

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbModal,
    private contactService: ContactService,
    public ref: NbDialogRef<AddClientComponent>,
  ) {
    this.contactInfoForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      phone: ["", Validators.required],
      // birthday: [""],
      comments: [""]
    });
  }

  ngOnInit() {
    this.contactInfo = this.ref.componentRef.instance.id;
    this.actionTitle = this.ref.componentRef.instance.title;
    this.newPatientName = this.ref.componentRef.instance.patientName;
    // if (this.ref.componentRef.instance.birthday != undefined) {
    //   if (this.ref.componentRef.instance.birthday._seconds != undefined) {
    //     this.ref.componentRef.instance.birthday = new Date(this.ref.componentRef.instance.birthday._seconds * 1000)
    //   }
    // }
    if (this.actionTitle == "Editar contacto") {
      this.contactInfoForm.patchValue({
        name: this.contactInfo.nombre,
        email: this.contactInfo.email,
        phone: this.contactInfo.telefono,
        comments: this.contactInfo.comments,
        // birthday: this.ref.componentRef.instance.birthday || null
      })
    }
    if (this.actionTitle == "Añadir contacto" && this.newPatientName != null) {
      this.contactInfoForm.patchValue({
        name: this.newPatientName,
      })
    }
  }

  async newContact() {
    try {
      if(this.contactInfoForm.invalid){
        Swal.fire({
          icon:"warning",
          title:"Campos vacíos",
          text:"Asegurate que los campos nombre, email y teléfono no esten vacíos, para poder continuar con la creación del contacto",
          timer:5000
        })
        return
      }
      await this.contactService.addContact(this.contactInfoForm.value, DiaryComponent.companyIdFocus);
      await Swal.fire({
        title: 'Agregado',
        text: 'El contacto ha sido añadido.',
        icon: 'success',
        timer: 1000,
        showConfirmButton: false
      })
      this.ref.close(this.contactInfoForm.value);
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error inesperado, intentalo de nuevo mas tarde",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      });
    }
  }

  async updateContact() {
    try {
      if(this.contactInfoForm.invalid){
        Swal.fire({
          icon:"warning",
          title:"Campos vacíos",
          text:"Asegurate que los campos nombre, email y teléfono no esten vacíos, para poder continuar con la edición del contacto",
          // allowEnterKey:false,
          // allowEscapeKey:false,
          // allowOutsideClick:false,
          timer:5000
        })
        return
      }
      // return
      await this.contactService.updateContact(this.contactInfo.id, this.contactInfoForm.value);
      await Swal.fire({
        title: 'Actualizado',
        text: 'El contacto ha sido actualizado.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      })
      this.ref.close();
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al actualizar",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      });
    }
  }

}
