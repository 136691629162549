import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ServiceMinutesService } from 'src/app/services/service-minutes/service-minutes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class TaskFormComponent implements OnInit {

  public addTask: FormGroup
  public isEdit = false
  constructor(
    public formBuilder: FormBuilder,
    public ref: MatDialogRef<TaskFormComponent>,
    private taskService: ServiceMinutesService,
    @Inject(MAT_DIALOG_DATA) public dialogRef: any
  ) {
    this.addTask = this.formBuilder.group({
      title: ["", Validators.required],
      minutes: ["", Validators.required]
    })
  }

  ngOnInit() {
    if (this.dialogRef) {
      this.isEdit = true
      this.addTask.patchValue(this.dialogRef)
    }
  }

  async submit() {
    try {
      var form = this.addTask.value;
      if (this.isEdit) {
        form["_id"] = this.dialogRef._id
        await this.taskService.updateTask(form)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Tarea actualizada",
          showConfirmButton: false,
          timer: 1000,
        });
        this.ref.close(true)
      } else {
        var response = await this.taskService.addtask(form)
        if (response.code == 200) {
          await Swal.fire({
            title: "Tarea agregada con exito",
            text: "se creo una nueva tarea",
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.ref.close(true)
          this.ngOnInit()
        }
        else {
          throw response.message
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Ocurrió un error al crear la tarea",
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

}
