import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-view-prospect-info',
  templateUrl: './view-prospect-info.component.html',
  styleUrls: ['./view-prospect-info.component.scss']
})
export class ViewProspectInfoComponent implements OnInit {

  public clientInfo: any = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewProspectInfoComponent>,
  ) { }

  async ngOnInit() {
    this.clientInfo = this.data
    this.clientInfo.contratoDate = new Date(this.clientInfo.contratoDate)
  }

}
