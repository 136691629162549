<nb-card>
    <nb-card-header>
        Información del recado
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="recadoForm">
            <div class=" form-group row">
                <div class="col-md-12 text-center">
                    <label for=""><strong>Fecha de hoy: </strong> {{labelDateToday | date: 'fullDate'}}</label>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Asunto</label>
                    <input type="text" nbInput fullWidth fieldSize="medium" formControlName="subject"
                        placeholder="Asunto">
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Mensaje</label>
                    <textarea nbInput fullWidth fieldSize="medium" formControlName="body"
                        placeholder="Ingrese el mensaje"></textarea>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer style="text-align: center;">
        <button class="mr-5" nbButton status="danger" (click)="ref.close()">Cancelar</button>
        <button nbButton status="success" (click)="submit()" [disabled]="!recadoForm.valid">Guardar</button>
    </nb-card-footer>
</nb-card>