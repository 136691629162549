<table mat-table [dataSource]="dateLogs" class="mat-elevation-z8">
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Fecha de la cita</th>
        <td mat-cell *matCellDef="let element">{{element.startDate | date}} {{element.startDate | date :'hh:mm a'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef>Comentarios</th>
        <td mat-cell *matCellDef="let element">{{element.comments}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Estado de la cita</th>
        <td mat-cell *matCellDef="let element">{{ element.confirmated == true ? 'Confirmada' : element.statusDone}}
        </td>
    </ng-container>
    <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef>Asunto</th>
        <td mat-cell *matCellDef="let element">{{ element.statusDone == 'Cancelada' ? element.title : element.subject}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayColumns.length">
            No hay historial de citas hasta el momento
        </td>
    </tr>
    <!-- <tr mat-footer-row *matFooterRowDef="displayColumns"></tr> -->
</table>
<mat-paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
</mat-paginator>