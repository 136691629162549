import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { UserService } from 'src/app/services/users/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clients-contract',
  templateUrl: './clients-contract.component.html',
  styleUrls: ['./clients-contract.component.scss']
})
export class ClientsContractComponent implements OnInit {

  @Input() contract: any;
  @Output() newItemEvent = new EventEmitter<any>();
  public config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
  };
  public isAdmin = false;
  public fileForm: FormGroup
  constructor(
    private formbuilder: FormBuilder,
    private storage: AngularFireStorage,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.fileForm = this.formbuilder.group({
      url: [''],
      status: [''],
      extension: [''],
      nextRenovationDate: ['']
    })
  }

  async ngOnInit() {
    var result = await this.authService.currentuser().uid
    var userInfo = await this.userService.getUserById(result)
    if (userInfo.tipo == "administrador") {
      this.isAdmin = true
    }
  }
  ngOnChanges(): void {
    const contractDate = this.contract
    this.fileForm.controls.nextRenovationDate.patchValue(new Date(contractDate?.nextRenovationDate))
    this.fileForm.controls.status.patchValue(contractDate?.status)
  }

  changeDate(event: any) {
    this.fileForm.value.nextRenovationDate = new Date(event)
    this.newItemEvent.emit(
      this.fileForm.value
    )
  }

  changeStatus(event: any) {
    this.fileForm.value.status = event
    this.newItemEvent.emit(
      this.fileForm.value
    )
  }

  onUploadInfo(event: any) {
    try {
      var file_name = event[0].name;
      var base64url = event[1].files.file;
      var extension = file_name.split(".").pop();
      var name = new Date().getTime() + Math.random().toString(36).substring(2);
      var refUrl = "/prospects/additionalFiles/" + name + `.${extension}`;
      var format_type = base64url.split(";")
      var type_file = (format_type[0]).slice(5);

      const fileRef = this.storage.ref(refUrl);
      const task = this.storage.ref("/prospects/additionalFiles/").child(name + `.${extension}`).putString(base64url, "data_url", { contentType: type_file });

      task
        .snapshotChanges()
        .pipe(
          finalize(async () => {
            var downloadURL = await fileRef.getDownloadURL().toPromise();
            this.fileForm.controls.url.setValue(downloadURL);
            this.fileForm.controls.extension.setValue(extension);
            this.newItemEvent.emit(
              this.fileForm.value
            )
          })
        )
        .subscribe();

    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al subir el archivo',
        icon: 'error',
      })

    }
  }

}
