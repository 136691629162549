import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/authFirebase/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { FCMService } from '../../services/FCM/fcm.service';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  session: boolean;
  subscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private afAuth: AngularFireAuth,
    private auth: AuthService,
    private msg: FCMService,
    private userService: UserService
  ) {

    this.subscription = this.afAuth.authState.subscribe(session => {
      if (session) {
        this.session = true;
        this.router.navigate(['dashboard']);
      } else {
        this.session = false;

      }
    })

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async submit() {
    var mail = this.loginForm.value.email;
    var pass = this.loginForm.value.password;
    let exist = await this.userService.getUserByEmail(mail)
    if (!exist) {
      try {
        var res = await this.auth.loginUser(mail, pass)
        const token = await this.msg.getPermission();
        this.msg.saveToken(res.user, token, true)
        this.msg.receiveMessage();
        this.msg.currentMessage.subscribe((data: any) => {
          if (data != null) {
            let body = '<ul class="list-notifier">';
            body = body + `</ul>`;
            var title = `<h1>${data.notification.title} </h1> <p>${data.notification.body}</p>`;
            var msg = {
              title: title,
              type: 'info',
            };
            //   this.notify(msg);
          }
        });
        localStorage.setItem("isCompanyOnFocus", "false");
        localStorage.setItem("isCallOnFocus", "false");
        this.router.navigate(['dashboard'])
      } catch (error) {
        await Swal.fire('Ups...', 'Por favor verifique su usuario y contraseña e intente de nuevo', 'error');
      }
    } else {
      Swal.fire('Ups...', 'La cuenta que ingreso no tiene acceso a este sistema', 'error');
    }

  }

}
