import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import { Task } from '../../../models/task';
import { DiaryComponent } from '../diary.component';
import { ChangeTimeComponent } from 'src/app/components/change-time/change-time.component';
import { PendingsService } from 'src/app/services/pendings/pendings.service';
import { UserData } from 'src/app/models/user';
import { UserService } from 'src/app/services/users/user.service';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { logClient } from 'src/app/models/logs';
import { LogsService } from "src/app/services/logs/logs.service"
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { Prospect } from 'src/app/models/prospects';

@Component({
  selector: 'app-add-log',
  templateUrl: './add-log.component.html',
  styleUrls: ['./add-log.component.scss']
})
export class AddLogComponent implements OnInit {
  [x: string]: any;
  public addForm: FormGroup;
  public labelDateToday = new Date();
  public taskList: Task[] = []
  private tokenAdmins: string[] = []
  public user: UserData;
  private clientInfo: Prospect;
  public editForm = false;
  public agentNameEdit = ""

  constructor(
    public ref: MatDialogRef<AddLogComponent>,
    public formBuilder: FormBuilder,
    private dialogService: NbDialogService,
    private pendingsServices: PendingsService,
    private userService: UserService,
    private service: AuthService,
    private notificationService: NotificationsService,
    private logService: LogsService,
    private prospectService: ProspectsService,
    @Inject(MAT_DIALOG_DATA) public data: logClient,
  ) { }

  async ngOnInit() {
    this.addForm = this.formBuilder.group({
      date: [''],
      text: [""],
      cuota: [""],
      comments: [""],
      timeTask: [0],
    });
    this.addForm.controls.date.setValue(new Date());
    this.user = await this.getCurrentUser()
    this.tokenAdmins = await this.getAdminsTokens()
    this.clientInfo = await this.getProspectInfo(DiaryComponent.companyIdFocus)
    this.taskList = this.clientInfo.tareasRecurrentes
    if (this.data) {
      this.editForm = true
      this.agentNameEdit = this.data.text?.split(" por")[1]
      this.addForm.patchValue({
        date: this.data.date,
        text: this.data.text?.split(" por")[0],
        timeTask: this.data.timeTask,
        cuota: this.data.totalActions,
        comments: this.data.comments
      })
    }
  }

  async getProspectInfo(uid: string) {
    try {
      const response = await this.prospectService.getProspectByUid(uid)
      return response
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener las tareas del cliente',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async getCurrentUser() {
    try {
      let uid = this.service.currentuser();
      return await this.userService.getUserById(uid.uid);
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener los datos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  async getAdminsTokens() {
    try {
      var tokens: string[] = []
      const response = await this.userService.getAdmins()
      for await (const admin of response) {
        tokens = tokens.concat(admin.token)
      }
      return tokens
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error al obtener informacion de los administradores',
        icon: 'error',
        showConfirmButton: true
      })
    }
  }

  async submit() {
    var form = this.addForm.value;
    if (form.date == '' || form.text == '' || form.comments == '' || form.cuota == '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Todos los campos son obligatorios',
        showConfirmButton: true,
      })
    } else {
      if (this.editForm) {
        form["id"] = this.data.id;
        form.text = form.text + " por " + this.agentNameEdit
        await this.logService.editLog(this.clientInfo.uid, form)
        this.ref.close(true);
      } else {
        if (this.validateTime(form)) {
          this.dialogService.open(ChangeTimeComponent).onClose.subscribe(async (data) => {
            if (data != null) {
              await this.pendingsServices.createLog({ title: "Modificación de tiempo en actividad", who: this.user.email, action: "modifico el tiempo de la actividad " + form.text + " a " + form.timeTask + " min. | Razon: " + data.reason, pendingFor: "none" })
              await this.notificationService.sendReminder({
                tokens: this.tokenAdmins, notification: {
                  'title': "Modificación de tiempo en actividad",
                  'body': this.user.email + " modificó el tiempo de una actividad en la bitacora del cliente " + this.clientInfo.nombre,
                  'sound': "default",
                  'icon': 'https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/logo_circle.png?alt=media&token=c9ea2ac7-0db4-4913-a111-9290e4696bf7'
                }
              })
              this.ref.close(form);
            }
          })
        }
        else {
          this.ref.close(form);
        }
      }
    }
  }

  showTimeOfTask(event: string) {
    var selectTask = this.taskList.filter((task) => task.title == event)[0]
    this.addForm.controls["timeTask"].setValue(selectTask.minutes)
  }

  validateTime(data: any) {
    var task = this.taskList.filter((task) => task.title == data.text)[0]
    if (task.minutes != data.timeTask) {
      return true
    }
    else {
      return false
    }
  }

}
