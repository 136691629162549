<section>
    <div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
    <div class="container">
        <div class="card">
            <div class="row header-section">
                <div class="col-md-3 text-left">
                    <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262"
                        width="140">
                </div>
                <div class="col-md-9 text-right header-buttons">
                    <button mat-button class="danger" printSectionId="printsection" ngxPrint
                        [useExistingCss]="true"><mat-icon>picture_as_pdf</mat-icon> Imprimir en PDF</button>
                    <button mat-button class="success" (click)="excel()"><mat-icon> insert_drive_file</mat-icon>
                        Exportar en Excel</button>
                </div>
            </div>
            <ng-container *ngIf="!loader">
                <div class="text-center">
                    <h2>{{data_source.report}} </h2>
                </div>
                <div class="row justify-content-center" style="width: 100%;">
                    <div class="col-md-6 text-center">
                        <h3>Nombre del agente</h3>
                        <h6>{{data_source.agente}} </h6>
                    </div>
                    <div class="col-md-6 text-center">
                        <h4>Total de Horas trabajadas</h4>
                        <h6> {{totalTime}} </h6>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-md-11 nodata-card">
                        <table class="content-table">
                            <thead>
                                <tr>
                                    <td>Nombre del cliente</td>
                                    <td>Total de llamadas</td>
                                    <td>Tiempo atentido en llamadas</td>
                                    <td>Total de horas en llamadas</td>
                                    <td>Total de tareas realizadas</td>
                                    <td>Tiempo atentido en tareas</td>
                                    <td>Total de horas en tareas </td>
                                    <td>Tiempo trabajado</td>
                                    <td>Total de horas trabajadas</td>
                                    <td>Plan</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of data_source.clients">
                                    <td>{{item.nombre_empresa}} </td>
                                    <td>{{item.total_Llamadas}} </td>
                                    <td>{{item.tiempo_llamadas}} </td>
                                    <td>{{item.seconds_llamadas / 3600 | number:"1.2-2"}} </td>
                                    <td>{{item.total_tareas }} </td>
                                    <td>{{item.tiempo_tareas}} </td>
                                    <td>{{item.seconds_tareas / 3600 | number:"1.2-2"}} </td>
                                    <td>{{item.total_trabajo}} </td>
                                    <td>{{item.seconds_trabajo / 3600 | number:"1.2-2"}} </td>
                                    <td>{{item.plan}} </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{{total_calls}} </td>
                                    <td>{{time_calls}} </td>
                                    <td>{{hours_calls | number:"1.2-2" }} </td>
                                    <td>{{total_task}} </td>
                                    <td>{{time_task}} </td>
                                    <td>{{hours_task | number:"1.2-2"}}</td>
                                    <td>{{totalTime}} </td>
                                    <td>{{hours_work | number:"1.2-2"}}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<div class="row" id="printsection" hidden>
    <div class="col-md-12 col-center">
        <nb-card>
            <nb-card-header style="  border-bottom: 2px solid #e26b0a;">
                <div class="row">
                    <div class="col-md-5 text-left">
                        <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262"
                            width="140">
                    </div>
                    <div class="col-md-7 text-right">
                        <ul style="list-style: none; padding-left: 1px;">
                            <li style="color: #e26b0a;">www.miasistente.net
                            </li>
                            <li>ventas@miassitente.net
                            </li>
                            <li>800 288 02 68
                            </li>
                            <li>Cancún Quintana Roo México / Oficinas Centrales
                            </li>
                        </ul>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body style="margin-top: 1rem;" *ngIf="data_source">
                <div class="row justify-content-center">
                    <div class="col-md-8 text-center">
                        <h2>{{data_source.report}} </h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h3>Nombre del agente</h3>
                        <h6>{{data_source.agente}} </h6>
                    </div>
                    <div class="col-md-6 text-center">
                        <h4>Total de Horas trabajadas</h4>
                        <h6> {{totalTime}} </h6>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-md-11 nodata-card">
                        <table class="content-table-pdf">
                            <thead>
                                <tr>
                                    <td>Nombre del cliente</td>
                                    <td>Total de llamadas</td>
                                    <td>Total tiempo atentido en llamadas</td>
                                    <td>Total de tareas realizadas</td>
                                    <td>Total tiempo atentido en tareas</td>
                                    <td>Total de tiempo trabajado</td>
                                    <td>Plan</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of data_source.clients">
                                    <td>{{item.nombre_empresa}} </td>
                                    <td>{{item.total_Llamadas}} </td>
                                    <td>{{item.tiempo_llamadas || '0s'}} </td>
                                    <td>{{item.total_tareas }} </td>
                                    <td>{{item.tiempo_tareas || '0s'}} </td>
                                    <td>{{item.total_trabajo || '0s'}} </td>
                                    <td>{{item.plan}} </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td>{{total_calls}} </td>
                                    <td>{{time_calls}} </td>
                                    <td>{{total_task}} </td>
                                    <td>{{time_task}} </td>
                                    <td>{{totalTime}} </td>
                                    <td> </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>