import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'app-prospect-event-dialog',
  templateUrl: './prospect-event-dialog.component.html',
  styleUrls: ['./prospect-event-dialog.component.scss']
})
export class ProspectEventDialogComponent implements OnInit {

  public isProspect = true;
  constructor(
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public dataInject
  ) { }

  ngOnInit() {
    if (this.dataInject.prospectInfo.activeInDashboard) {
      this.isProspect = false
    }
  }

  copyZoomLink(link: string) {
    const textarea = document.createElement('textarea');
    textarea.value = link
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.shootToastAlert('success', 'Link copiado al portapapeles')
  }

  formattedHour(trainingHour: { hour: number, minute: number }) {
    const date = new Date()
    date.setHours(trainingHour.hour)
    date.setMinutes(trainingHour.minute)

    return this.datePipe.transform(date, 'h:mm a');
  }

  shootToastAlert(icon: SweetAlertIcon, title: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: icon,
      title: title
    })
  }
}
