import { Event } from "./../../services/events/model";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EventsService } from "src/app/services/events/events.service";
import { DiaryService } from "src/app/services/diary/diary.service";
import { Component, Inject, OnInit } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import Swal from "sweetalert2";
import { CompanyFocusService } from "src/app/services/companyOnFocus/company-focus.service";
import { ReScheduleComponent } from "../re-schedule/re-schedule.component";
import { Contact } from "src/app/models/contact";
import { AddClientComponent } from "../diary/add-client/add-client.component";
import { ContactService } from "src/app/services/contact/contact.service";
import { ColorPickerComponent } from "../color-picker/color-picker.component";

@Component({
  selector: "app-modal-event",
  templateUrl: "./modal-event.component.html",
  styleUrls: ["./modal-event.component.scss"],
})
export class ModalEventComponent implements OnInit {
  //FORM
  public eventForm: FormGroup;
  public eventData: Event;
  public cancelMessage: FormControl
  public reminderTimer: { reminderHour: number, tokens: [] };
  public dialogM: any;
  public patientsList = []
  public contactList: Contact[]
  public showOpenFieldName: boolean = false;
  public invalidFlag: string;
  public hasError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { event: any; clientId: string; reminderInfo: { reminderHour: string, tokens: [] }; },
    public dialogRef: MatDialogRef<ModalEventComponent>,
    public formBuilderService: FormBuilder,
    private eventService: EventsService,
    public diaryService: DiaryService,
    private dialogService: NbDialogService,
    public formBuilder: FormBuilder,
    private focusService: CompanyFocusService,
    public dialog: MatDialog,
    private contactService: ContactService
  ) { }

  async ngOnInit(): Promise<void> {
    this.cancelMessage = new FormControl(
      "",
      Validators.compose([Validators.required, Validators.maxLength(50)])
    );

    this.eventData = this.data.event.extendedProps;
    this.eventForm = this.formBuilderService.group({
      _id: ["", Validators.required],
      title: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      subject: ["", Validators.required],
      customer: [""],
      customerSelect: [""],
      comments: ["", Validators.required],
      duration: ["", Validators.required],
      status: ["", Validators.required],
      block: ["", Validators.required],
      clientId: ["", Validators.required],
      email: [""]
    });
    this.eventForm.patchValue(this.data.event.extendedProps);
    this.contactList = await this.getContactOfCompany()
  }

  async getContactOfCompany(): Promise<Contact[]> {
    try {
      return await this.contactService.getContacsByCompany(this.focusService.getCompanyOnFocus());
    } catch (error) {
      Swal.fire({
        title: "Lo sentimos",
        text: "Ha ocurrido un error al obtener los datos, intente más tarde",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }

  async getInfoForReminder(companyId: string) {
    try {
      var res = await this.focusService.getInfoForReminder(companyId)
      res.reminderHour = res.reminderHour == undefined ? 0 : parseInt(res.reminderHour)
      return res;
    } catch (error) {
      Swal.fire({
        title: "Ha ocurrido un error al obtener los datos, intente más tarde",
        text: "Si el problema persiste, favor de reportar el error",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }

  async onSelectOption(event: any) {
    if (event == "other") {
      this.showOpenFieldName = !this.showOpenFieldName
      this.eventForm.controls.customer.setValue('')
    }
    else {
      this.showOpenFieldName = false
      var patientSelect = this.contactList.filter((contact) => contact.nombre == event)[0]
      this.eventForm.controls.email.patchValue(patientSelect.email)
    }
  }

  validForm() {
    if (this.eventForm.controls['subject'].errors) {
      this.invalidFlag = 'invalid-subject';
      this.hasError = true
    }
    if (this.showOpenFieldName && this.eventForm.value.customer == "") {
      this.invalidFlag = 'invalid-customer';
      this.hasError = true
    }
    if (this.eventForm.controls['comments'].errors) {
      this.invalidFlag = 'invalid-comments';
      this.hasError = true
    }
    if (this.eventForm.invalid) {
      this.invalidFlag = 'invalid-all';
      return
    }
    this.saveContact()
  }

  async saveContact() {
    if (this.showOpenFieldName) {
      if (this.eventForm.value.customer == "" || this.eventForm.value.subject == '' || this.eventForm.value.comments == '') {
        return await Swal.fire({
          icon: "warning",
          title: "Campos vacíos",
          text: "Asegúrate que el campo Nombre del cliente, Asunto y/o Comentarios no estén vacíos",
        })
      }
      var result = await Swal.fire({
        title: 'Cliente no registrado',
        icon: 'info',
        text: '¿ Desea registrar este nuevo cliente ?. De esta forma sera mas facil agendar citas en un futuro',
        confirmButtonText: 'Si, registrar',
        cancelButtonText: 'No registrar',
        showCancelButton: true,
      })

      if (result.isConfirmed) {
        var ref = this.dialogService.open(AddClientComponent, { context: { title: "Añadir contacto", patientName: this.eventForm.value.customer } })
        ref.onClose.subscribe(async (data) => {
          if (data != undefined) {
            await this.save(data.name)
          }
        })
      }
      else {
        await this.save()
      }
    } else {
      if (!this.eventData.status) {
        this.eventForm.controls.customer.setValue(this.eventForm.value.customerSelect)
      }
      await this.save();
    }
  }

  public async save(newClientName?: string) {
    if (this.eventForm.value.customer == "" || this.eventForm.value.subject == '' || this.eventForm.value.comments == '') {
      return await Swal.fire({
        icon: "warning",
        title: "Campos vacíos",
        text: "Asegúrate que el campo Nombre del cliente, Asunto y/o Comentarios no estén vacíos",
      })
    }
    this.eventForm.removeControl('customerSelect');
    var dataForNotify = [];
    if (newClientName != undefined) {
      this.eventForm.value.customer = newClientName
    }
    var newStarDate = new Date(this.eventForm.value.startDate);
    var newEndDate = new Date(this.eventForm.value.endDate);
    dataForNotify.push({
      title: "Nueva cita con " + this.eventForm.value.customer,
      start: newStarDate.toISOString(),
      end: newEndDate.toISOString(),
      extendedProps: {
        comment: this.eventForm.value.comments,
        subject: this.eventForm.value.subject,
      },
    });
    this.reminderTimer = await this.getInfoForReminder(this.eventForm.value.clientId)
    if (this.reminderTimer.reminderHour != 0) {
      let hourAppointment = (newStarDate.getHours() * 60 + newStarDate.getMinutes()) - this.reminderTimer.reminderHour;
      let hourReminder = this.timeConvert(hourAppointment);
      let dateReminder = new Date(newStarDate.getFullYear(), newStarDate.getMonth(), newStarDate.getDate(), hourReminder.hour, hourReminder.minutes, 0);
      let unix_date = dateReminder.toISOString();
      let dataReminder = {
        title: `MIA Recordatorio - Cita con  ${this.eventForm.value.customer}`,
        body: `Tienes una cita a las ${newStarDate.getHours() > 9 ? newStarDate.getHours() : '0' + newStarDate.getHours()}:${newStarDate.getMinutes() == 0 ? '00' : newStarDate.getMinutes()}hrs.`,
        isodate_schedule: unix_date,
        companyId: this.eventForm.value.clientId,
        start: newStarDate.toISOString(),
        end: newEndDate.toISOString(),
        tokens: this.reminderTimer.tokens,
        infoAppointment: {
          comment: this.eventForm.value.comments,
          subject: this.eventForm.value.subject,
        },
      }
      this.diaryService.addReminderNoTification(dataReminder);
    }

    try {
      await this.eventService.createEvent(this.eventForm.value, this.data.clientId);
      dataForNotify.push({ companyID: this.eventForm.value.clientId });
      this.diaryService.notifyNewMeetting(dataForNotify);
      var log = {
        eventTime: newStarDate.getHours() + ":" + newStarDate.getMinutes(),
        client: this.eventForm.value.customer,
        comments: this.eventForm.value.comments,
        eventDate: newStarDate,
        created: new Date(),
      };
      await Swal.fire({
        title: "Cita agendada correctamente",
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      this.dialogRef.close();
      this.diaryService.saveCLientLog(log);
      this.dialog.closeAll()
    } catch (error) {
      Swal.fire({
        title: "Lo sentimos",
        text: "Ha ocurrido un error al agendar la cita, intente más tarde",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }

  public async block() {
    try {
      const swalResponse = await Swal.fire({
        title: "Estas seguro?",
        text: "No se podran agendar citas en este espacio",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si,bloquear",
        cancelButtonText: "Cancelar",
      });

      if (swalResponse.isConfirmed) {
        const response = await this.eventService.blockEvent(
          this.eventForm.value,
          this.data.clientId
        );
        this.dialogRef.close();
      }
    } catch (error) {
      Swal.fire({
        title: "Ocurrió un error inesperado al bloquear el espacio.",
        text: "Si el problema persiste, favor de reportar el error",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }

  public async deleteEvent() {
    try {
      const swalResponse = await Swal.fire({
        title: "Estas seguro?",
        text: "Se eliminara este espacio",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (swalResponse.isConfirmed) {
        await this.eventService.deleteEvent(this.eventForm.value, this.data.clientId)
        await Swal.fire({
          title: "Eliminado correctamente",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        this.dialogRef.close();
      }
    } catch (error) {
      Swal.fire({
        title: "Ocurrió un error inesperado al borrar el espacio.",
        text: "Si el problema persiste, favor de reportar el error",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }

  async openColorPicker() {
    var dialogResult = await this.dialog.open(ColorPickerComponent, { height: "80vh", width: "80vh" }).afterClosed().toPromise()
    if (dialogResult) {
      this.eventService.changeColor(dialogResult, this.eventData._id)
    }
  }

  async changeStatus(event: string, eventId: string) {
    var result = await Swal.fire({
      title: 'Se va a cambiar el estado de la cita.',
      icon: 'info',
      text: 'La cita pasara al estado: ' + event,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#e80c0c',
      confirmButtonText: 'Cambiar estado',
      confirmButtonColor: '#189c0b'

    })
    if (result.isConfirmed) {
      try {
        await this.eventService.changeStatus(event, eventId)
        await Swal.fire({
          title: "Estado actualizado con exito",
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        })
        this.dialogRef.close()
        this.ngOnInit()
      } catch (error) {
        Swal.fire({
          title: "Ocurrió un error inesperado al cambiar el estado.",
          text: "Si el problema persiste, favor de reportar el error",
          icon: "error",
          showCancelButton: false,
          showConfirmButton: true,
        });
      }
    }

  }

  public async openCancelEvent(dialog) {
    this.dialogM = this.dialogService.open(dialog, { context: 'Test', dialogClass: 'cancel-dialog', closeOnBackdropClick: false });
  }

  public async cancelEvent() {
    var dataForNotify = [];
    var newStarDate = new Date(this.eventForm.value.startDate);
    var newEndDate = new Date(this.eventForm.value.endDate);

    dataForNotify.push({
      title: "Cita cancelada con " + this.eventForm.value.customer,
      body: this.cancelMessage.value,
      start: newStarDate.toISOString(),
      end: newEndDate.toISOString(),
      extendedProps: {
        comment: this.eventForm.value.comments,
        subject: this.eventForm.value.subject,
        reason: this.cancelMessage.value
      },
    });

    try {
      const swalResponse = await Swal.fire({
        title: "¿Estas seguro?",
        text: "Se eliminaran los datos de esta cita",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, cancelar cita",
        cancelButtonText: "Cancelar",
      });

      if (swalResponse.isConfirmed) {
        this.dialogM.close();
        const response = await this.eventService.cancelEvent(
          this.eventForm.value,
          this.data.clientId
        );
        // dataForNotify.push({ companyID: 'ne7t1N4lfDOyngUDYm07YCKmv6u1' })
        dataForNotify.push({ companyID: this.eventForm.value.clientId });
        this.dialogRef.close();
        Swal.fire({
          title: "",
          text: "Cita cancelada correctamente",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: true,
        });
        this.dialogService
        this.diaryService.notifyCancelMeetting(dataForNotify);
      }
    } catch (error) {
      console.log("hubo un error", error);

      Swal.fire({
        title: "Lo sentimos",
        text: "Ha ocurrido un error al cancelar la cita, intente más tarde",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }

  async confirmEvent() {
    try {
      const swalResponse = await Swal.fire({
        title: "¿Estas seguro?",
        text: "Esta cita se confirmará",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, confirmar cita",
        cancelButtonText: "Cancelar",
      });

      if (swalResponse.isConfirmed) {
        await this.eventService.confirmEvent(
          this.eventForm.value._id,
          this.eventForm.value.clientId
        );
        await Swal.fire({
          title: "Cita confirmada correctamente",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });

        this.dialogRef.close();
      }
    } catch (error) {
      Swal.fire({
        title: "Lo sentimos",
        text: "Ha ocurrido un error al confirmar la cita, intente más tarde",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  public timeConvert(mins: number) {
    var hours = (mins / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return { hour: rhours, minutes: rminutes }
  }

  openReSchedule() {
    var company = this.focusService.getCompanyOnFocus();
    let dialogRef = this.dialog.open(ReScheduleComponent, {
      height: '70vh',
      data: { companyID: company, event: this.eventForm.value },
    });
    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        await this.eventForm.patchValue(data.event)
        await this.save();
        let log = {
          client: this.eventForm.value.customer,
          created: new Date(),
          comments: data.comment,
          title: data.event.title + " se ha reprogramado",
          type: "re-shedule",
          tokens: this.reminderTimer.tokens
        };
        await this.diaryService.saveCLientLog(log)
      }
    })
  }
}
