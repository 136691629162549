import { Component, Input, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { PendingsService } from '../../services/pendings/pendings.service';
import { AddPendingComponent } from '../add-pending/add-pending.component';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';
import { DiaryComponent } from '../../pages/diary/diary.component';
import { Pending } from '../../models/pending';
import { Task } from 'src/app/models/task';
import { ServiceMinutesService } from 'src/app/services/service-minutes/service-minutes.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { UserService } from 'src/app/services/users/user.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { UserData } from 'src/app/models/user';
import { ChangeTimeComponent } from '../change-time/change-time.component';
import { LogsService } from 'src/app/services/logs/logs.service';
import { Prospect } from 'src/app/models/prospects';

@Component({
    selector: 'app-pendings-list',
    templateUrl: './pendings-list.component.html',
    styleUrls: ['./pendings-list.component.scss']
})
export class PendingsListComponent implements OnInit {

    @Input() companyData: Prospect;
    @Input() pendingList: Pending[];
    @Output() refresh = new EventEmitter<string>();

    public pendings: any;
    private pendingsSub: Subscription;

    public itemId: string;
    public whoDid = '';
    public show: boolean = true;
    public modalRef: NbDialogRef<any>
    public hide: boolean = true;
    public taskList: Task[] = []
    public addForm: FormGroup;
    public user: UserData;
    constructor(
        private pendingsServices: PendingsService,
        private dialogService: NbDialogService,
        private taskService: ServiceMinutesService,
        public formBuilder: FormBuilder,
        private service: AuthService,
        private userService: UserService,
        private companyService: CompanyService,
        private logsServices: LogsService
    ) {
        this.addForm = this.formBuilder.group({
            whoDid: [''],
            action: [""],
            cuota: [""],
            timeTask: [0],
        });
    }

    async ngOnInit() {
        // await this.getData()
        this.pendings = this.pendingList
        console.log(this.pendingList);

        this.taskList = await this.getTasks();
        this.user = await this.getCurrentUser();
        this.addForm.controls['whoDid'].setValue(this.user.nombre)
    }

    async getData() {
        this.pendings = await this.getPendings();
    }

    ngOnDestroy() {
        if (this.pendingsSub) this.pendingsSub.unsubscribe();
    }

    async getPendings() {
        try {
            var response = await this.pendingsServices.getByUser(DiaryComponent.companyIdFocus);
            response.map((pending: any) => {
                pending["dateFormat"] = new Date(pending.date._seconds * 1000)
                pending.limitTime = new Date(pending.limitTime._seconds * 1000)
                if (pending.isVisibleInApp != false) {
                    pending["isVisibleInApp"] = true;
                }
            })
            return response
        }
        catch (error) {
            Swal.fire({
                title: "Lo sentimos",
                text: "Ocurrio un error al obtener los pendientes",
                icon: "error",
                showCancelButton: false,
                showConfirmButton: true,
            });
        }

    }

    addPending() {
        try {
            this.dialogService.open(AddPendingComponent).onClose.subscribe(async (data: Pending) => {
                if (data != undefined) {
                    if (data.repeat != null) {
                        switch (data.repeat) {
                            case "diaria":
                                var inicial = new Date(new Date(data.date).setHours(0, 0, 0, 0)).getTime();
                                var final = new Date(data.dateFinal).getTime();

                                var end = DateTime.fromMillis(final);
                                var ini = DateTime.fromMillis(inicial);
                                var diff = end.diff(ini, 'days')
                                for (let i = 0; i <= diff.toObject().days; i++) {
                                    let dateinicial = data.date;
                                    let suma = (ini.plus({ days: i })).toJSDate();
                                    console.log(data.time)
                                    data.date = suma;
                                    await this.pendingsServices.addPending(this.companyData.uid, this.companyData.nombre, data);
                                    data.date = dateinicial;
                                }

                                break
                            case "semanal":
                                var inicial = new Date(new Date(data.date).setHours(0, 0, 0, 0)).getTime();
                                var final = new Date(data.dateFinal).getTime();

                                var end = DateTime.fromMillis(final);
                                var ini = DateTime.fromMillis(inicial);
                                var diff = end.diff(ini, 'weeks');
                                for (let i = 0; i <= diff.toObject().weeks; i++) {
                                    let dateinicial = data.date;
                                    let suma = (ini.plus({ weeks: i })).toJSDate();
                                    data.date = suma;
                                    await this.pendingsServices.addPending(this.companyData.uid, this.companyData.nombre, data);
                                    data.date = dateinicial;
                                }
                                break
                            case "mensual":
                                var inicial = new Date(new Date(data.date).setHours(0, 0, 0, 0)).getTime();
                                var final = new Date(data.dateFinal).getTime();

                                var end = DateTime.fromMillis(final);
                                var ini = DateTime.fromMillis(inicial);
                                var diff = end.diff(ini, 'weeks')
                                for (let i = 0; i <= diff.toObject().months; i++) {
                                    let dateinicial = data.date;
                                    let suma = (ini.plus({ months: i })).toJSDate();
                                    data.date = suma;
                                    await this.pendingsServices.addPending(this.companyData.uid, this.companyData.nombre, data);
                                    data.date = dateinicial;
                                }
                                break
                        }
                        await Swal.fire("¡Agregado!", "Los pendientes se han creado", "success");
                    }
                    else {
                        await this.pendingsServices.addPending(this.companyData.uid, this.companyData.nombre, data);
                        await Swal.fire({
                            title: "¡Agregado!",
                            text: "El pendiente se ha creado",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                }
                this.ngOnInit();
            });

        } catch (error) {
            Swal.fire({
                title: "Lo sentimos",
                text: "Ocurrio un error al crear los pendientes",
                icon: "error",
                showCancelButton: false,
                showConfirmButton: true,
            });
        }

    }

    async hideInApp(data: Pending) {
        try {
            if (data.isVisibleInApp != false) {
                let result = await Swal.fire({
                    title: "¿Seguro que quiere ocultar esté pendiente?",
                    text: "Este pendiente no se mostrará en la aplicación del cliente",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sí, desactivar",
                    cancelButtonText: "Cancelar",
                })
                if (result.isConfirmed) {
                    await this.pendingsServices.hideApp(data.id, false);
                    await Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Pendiente desactivado",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                    this.show = false;
                    this.ngOnInit();
                }
            }
            else {
                let result = await Swal.fire({
                    title: "¿Seguro que quiere mostrar este pendiente?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, desactivar",
                    cancelButtonText: "Cancelar",
                })
                if (result.isConfirmed) {
                    await this.pendingsServices.hideApp(data.id, true)
                    await Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Pendiente activado",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                    this.ngOnInit();
                }
            }
        } catch (error) {
            Swal.fire({
                title: "Lo sentimos",
                text: "Ocurrio un error al ocultar el pendiente",
                icon: "error",
                showCancelButton: false,
                showConfirmButton: true,
            });
        }
    }

    openDialog(dialog: TemplateRef<any>, itemId, title) {
        this.itemId = itemId;
        this.modalRef = this.dialogService.open(dialog)
        this.modalRef.onClose.subscribe(async (data) => {
            if (data != undefined) {
                try {
                    if (this.validateTime(data)) {
                        this.dialogService.open(ChangeTimeComponent).onClose.subscribe(async (formData: any) => {
                            if (formData != null) {
                                await this.pendingsServices.updatePending(itemId, data.whoDid);
                                await this.pendingsServices.createLog({ title: "Modificación de tiempo en actividad", who: this.user.email, action: "modifico el tiempo de la actividad " + data.action + " a " + data.timeTask + " min. | Razon: " + formData.reason })
                                await this.addNewLogBitacora(data, title)
                                this.refresh.emit();
                                await Swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: "Pendiente realizado",
                                    showConfirmButton: false,
                                    timer: 1000,
                                });
                                this.addForm.controls['action'].setValue('')
                                this.addForm.controls['cuota'].setValue('')
                                this.addForm.controls['timeTask'].setValue(0)
                                this.getData();
                            }
                            else {
                                this.openDialog(dialog, itemId, "")
                                return
                            }
                        })
                    }
                    else {
                        await this.pendingsServices.updatePending(itemId, data.whoDid);
                        await this.addNewLogBitacora(data, title)
                        this.refresh.emit();
                        await Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Pendiente realizado",
                            showConfirmButton: false,
                            timer: 1000,
                        });
                        this.addForm.controls['action'].setValue('')
                        this.addForm.controls['cuota'].setValue('')
                        this.addForm.controls['timeTask'].setValue(0)
                        this.getData();
                    }

                } catch (error) {
                    Swal.fire({
                        title: 'Ocurrió un error inesperado',
                        text: "Si el problema persiste, favor de reportar el error",
                        icon: 'error'
                    })
                }
            } else {
                this.addForm.controls['action'].setValue('')
                this.addForm.controls['cuota'].setValue('')
                this.addForm.controls['timeTask'].setValue(0)
            }
        })
    }

    validateTime(data: any) {
        var task = this.taskList.filter((task) => task.title == data.action)[0]
        if (task.minutes != data.timeTask) {
            return true
        }
        else {
            return false
        }
    }

    async getTasks() {
        try {
            return await this.taskService.getTaskByCompany(DiaryComponent.companyIdFocus);
        } catch (error) {
            Swal.fire({
                title: 'Ocurrió un error al obtener las tareas del cliente',
                text: "Si el problema persiste, favor de reportar el error",
                icon: 'error'
            })
        }
    }

    async addNewLogBitacora(form, title) {
        try {
            const logInfo = {
                date: new Date(),
                text: form.action + " por " + this.user.nombre,
                comments: title,
                totalActions: form.cuota,
                idUser: this.user.uid,
                timeTask: form.timeTask
            }
            await this.logsServices.addManualLog(DiaryComponent.companyIdFocus, logInfo);
        } catch (error) {
            Swal.fire({
                title: 'Ocurrió un error inesperado',
                text: "Si el problema persiste, favor de reportar el error",
                icon: 'error'
            })
        }
    }

    submit() {
        const form = this.addForm.value;
        if (form.whoDid == '' || form.action == '' || form.cuota == '') {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Todos los campos son obligatorios',
                showConfirmButton: true,
            })
        } else {
            this.modalRef.close(form);
        }
    }

    async getCurrentUser() {
        try {
            let uid = this.service.currentuser();
            return await this.userService.getUserById(uid.uid);
        } catch (error) {
            Swal.fire({
                title: 'Ocurrió un error al obtener los datos',
                text: "Si el problema persiste, favor de reportar el error",
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            })
        }
    }

    showTimeOfTask(event: string) {
        var selectTask = this.taskList.filter((task) => task.title == event)[0]
        this.addForm.controls["timeTask"].setValue(selectTask.minutes)
    }

}
