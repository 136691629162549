<section>
    <div class="header-container">
        <h3>Reportes de rentabilidad</h3>
        <div class="button-wrap">
            <button mat-button (click)="createNew()"><mat-icon>add</mat-icon> Generar reporte</button>
        </div>
    </div>
    <div class="card">
        <ng2-smart-table [settings]="settings" [source]="data_source" (edit)="openDetails($event)"
            (create)="createNew()" (delete)="delete($event)">
        </ng2-smart-table>
    </div>
</section>