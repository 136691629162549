<div class="dialog-template">
    <div class="header-template">
        <h4>Números adicionales</h4>
        <button mat-button class="success" (click)="addNumber()"><mat-icon>add</mat-icon>Agregar
            números</button>
    </div>
    <div>
        <form [formGroup]="numbersForm">
            <div formArrayName="numbers">
                <table>
                    <thead>
                        <tr>
                            <td>No.</td>
                            <td>Número Telefonico</td>
                            <td>Prefijo</td>
                            <td>Acción</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of numbers.controls let i=index" [formGroupName]="i">
                            <td>{{i+1}} </td>
                            <td>
                                <input type="number" nbInput fieldSize="medium" placeholder="0123456789"
                                    formControlName="phone">
                            </td>
                            <td>
                                <input type="text" nbInput fullWidth fieldSize="medium" formControlName="prefix"
                                    placeholder="Prefijo">
                            </td>
                            <td>
                                <button nbButton outline status="danger" size="small"
                                    (click)="removeConcept(i)"><mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
    <div class="buttons-footer">
        <button class="mr-5" mat-button class="danger" (click)="ref.close()"><mat-icon>close</mat-icon>
            Cancelar</button>
        <button mat-button class="success" (click)="submit()"><mat-icon>save</mat-icon> Guardar</button>
    </div>
</div>