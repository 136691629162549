import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { CompanyService } from 'src/app/services/company/company.service';
import { NbDialogRef } from '@nebular/theme';
import { Router } from '@angular/router';
import { Company } from 'src/app/models/company';
import { CallsReportsService } from 'src/app/services/calls-reports/calls-reports.service';

@Component({
    selector: 'app-generate',
    templateUrl: './generate.component.html',
    styleUrls: ['./generate.component.scss']
})
export class GenerateReporteInternoComponent implements OnInit {
    public addForm: FormGroup;
    public minDate: Date;
    public clients: Company[] = [];
    constructor(
        private companyService: CompanyService,
        private afs: AngularFirestore,
        private router: Router,
        public ref: NbDialogRef<GenerateReporteInternoComponent>,
        public formBuilder: FormBuilder,
        private callService: CallsReportsService
    ) { }

    async ngOnInit() {
        this.addForm = this.formBuilder.group({
            start_date: ["", Validators.required],
            end_date: ["", Validators.required],
            client: ["", Validators.required],
            generalReport: [false, Validators.required],
        });
        this.clients = await this.getCompanies();
    }
    async getCompanies() {
        try {
            var companies = await this.companyService.getAllCompanies();
            return companies.filter((company) => company.activeInDashboard == true)
        } catch (error) {
            Swal.fire({
                title: 'Ocurrió un error al obtener los datos',
                text: "Si el problema persiste, favor de reportar el error",
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
            })
        }
    }

    onSelectedStartDate(event) {
        var date = new Date(event);
        date.setDate(date.getDate() + 1);
        this.minDate = date;
    }

    async getCompanyDataSelect(id: string): Promise<Company> {
        try {
            var companyInfo = this.clients.filter((companyData) => companyData.id == id)[0]
            return companyInfo
        } catch (error) {
            await Swal.fire({
                title: 'Ocurrió un error al consultar la informacion del cliente seleccionado',
                text: "Si el problema persiste, favor de reportar el error",
                icon: 'error',
            })
        }
    }

    validateForm() {
        var form = this.addForm.value;
        if (form.generalReport) {
            if (form.start_date == "" || form.end_date == "") {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Todos los campos son obligatorios',
                    showConfirmButton: true,
                })
            } else {
                this.action(form)
            }
        } else {
            if (form.start_date == "" || form.end_date == "" || form.client == "") {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Todos los campos son obligatorios',
                    showConfirmButton: true,
                })
            }
            else {
                this.action(form)
            }
        }
    }


    hideClient(e) {
        console.log(e);
        if (!e) {
            this.addForm.get('client').setValidators([Validators.required]);
            this.addForm.get('client').updateValueAndValidity();
        } else {
            this.addForm.get('client').clearValidators();
            this.addForm.get('client').updateValueAndValidity();
        }

    }

    async action(form) {
        try {
            await Swal.fire({
                title: 'Generando reporte...',
                timer: 3500,
                timerProgressBar: true,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading()
                }
            })
            var compayData = await this.getCompanyDataSelect(form.client)
            var companyPhone = compayData.telefono.toString();
            var phoneSearch = companyPhone.includes('52') ? companyPhone : `52${companyPhone}`;
            var finalPhoneSearch = '';
            if (form.generalReport) {
                finalPhoneSearch = '';
            } else {
                finalPhoneSearch = phoneSearch;
            }

            var time_start_date = form.start_date.getTime();
            var time_end_date = form.end_date.getTime();

            var format_start_date = formatDate(new Date(time_start_date), 'dd/MM/yyyy', 'en-US');
            var format_end_date = formatDate(new Date(time_end_date), 'dd/MM/yyyy', 'en-US');

            var day_start = formatDate(new Date(time_start_date), 'dd', 'en-US');
            var month_start = formatDate(new Date(time_start_date), 'MMMM', 'es-MX');
            var year_start = formatDate(new Date(time_start_date), 'yyy', 'en-US');
            var start_date_txt = day_start + ' de ' + month_start + " de " + year_start;

            var day_end = formatDate(new Date(time_end_date), 'dd', 'en-US');
            var month_end = formatDate(new Date(time_end_date), 'MMMM', 'es-MX');
            var year_end = formatDate(new Date(time_end_date), 'yyy', 'en-US');
            var end_date_txt = day_end + ' de ' + month_end + " de " + year_end;

            var res = await this.afs.collection('reportes-internos').add({
                report: 'Reporte del ' + start_date_txt + ' al ' + end_date_txt,
                date: new Date(),
                format_start_date: format_start_date,
                format_end_date: format_end_date,
                finalPhoneSearch: finalPhoneSearch,
                clientID: compayData.id ?? 'Reporte general',
                clientName: compayData.nombre ?? 'Reporte general'
            })
            // await this.callService.getAllCalls(finalPhoneSearch, time_start_date, time_end_date)
            await Swal.fire({
                icon: 'success',
                title: "Reporte creado con exito",
                showConfirmButton: false,
                timer: 1500
            })
            this.ref.close();
            this.router.navigate(['/reporte_interno_details'], { queryParams: { id: res.id } })

        } catch (error) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Lo sentimos, ocurrio un error al solicitar la información, itente de nuevo mas tarde',
                text: "Si el problema persiste, favor de reportar el error",
                showConfirmButton: true,
            })
        }
    }
}
