<section>
  <div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
  <div class="container">
    <div class="card">
      <div class="header-section">
        <div class="col-md-3 text-left">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262"
            width="140">
        </div>
        <div class="col-md-9 text-right header-buttons">
          <!-- <button nbButton status="info" (click)="generateWord()"><mat-icon>insert_drive_file</mat-icon> Exportar como Word</button> -->
          <button mat-button class="danger" printSectionId="printsection" ngxPrint
            [useExistingCss]="true"><mat-icon>picture_as_pdf</mat-icon> Imprimir en PDF</button>
          <button mat-button class="success" (click)="excel()"><mat-icon> insert_drive_file</mat-icon>
            Exportar en Excel</button>
        </div>
      </div>
      <ng-container *ngIf="!loader">
        <div class="row justify-content-center">
          <h2>{{data_source.report}} </h2>
        </div>

        <div class="table-container">
          <table mat-table #table [dataSource]="data_source.clients">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Fecha de corte </th>
              <td mat-cell *matCellDef="let row"> {{row.contractData.nextRenovationDate | date}} </td>
              <td mat-footer-cell *matFooterCellDef>Total</td>
            </ng-container>
            <ng-container matColumnDef="agent">
              <th mat-header-cell *matHeaderCellDef> Ejecutivo asignado </th>
              <td mat-cell *matCellDef="let row"> {{row.agent}} </td>
              <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>
            <ng-container matColumnDef="client">
              <th mat-header-cell *matHeaderCellDef> Nombre del cliente </th>
              <td mat-cell *matCellDef="let row"> {{row.nombre_empresa}} </td>
              <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>
            <ng-container matColumnDef="totalCalls">
              <th mat-header-cell *matHeaderCellDef> Total de llamadas </th>
              <td mat-cell *matCellDef="let row"> {{row.total_Llamadas}} </td>
              <td mat-footer-cell *matFooterCellDef>{{total_calls}}</td>
            </ng-container>
            <ng-container matColumnDef="timeCalls">
              <th mat-header-cell *matHeaderCellDef> Tiempo atentido en llamadas </th>
              <td mat-cell *matCellDef="let row"> {{row.tiempo_llamadas}} </td>
              <td mat-footer-cell *matFooterCellDef>{{time_calls}}</td>
            </ng-container>
            <ng-container matColumnDef="callHours">
              <th mat-header-cell *matHeaderCellDef> Total de horas en llamadas </th>
              <td mat-cell *matCellDef="let row"> {{row.seconds_llamadas / 3600 | number:"1.2-2"}} </td>
              <td mat-footer-cell *matFooterCellDef>{{hours_calls | number:"1.2-2" }}</td>
            </ng-container>
            <ng-container matColumnDef="totalTask">
              <th mat-header-cell *matHeaderCellDef> Total de tareas realizadas </th>
              <td mat-cell *matCellDef="let row"> {{row.total_tareas}} </td>
              <td mat-footer-cell *matFooterCellDef>{{total_task}} </td>
            </ng-container>
            <ng-container matColumnDef="timeTask">
              <th mat-header-cell *matHeaderCellDef> Tiempo atentido en tareas </th>
              <td mat-cell *matCellDef="let row"> {{row.tiempo_tareas}} </td>
              <td mat-footer-cell *matFooterCellDef>{{time_task}}</td>
            </ng-container>
            <ng-container matColumnDef="hourTask">
              <th mat-header-cell *matHeaderCellDef> Total de horas en tareas </th>
              <td mat-cell *matCellDef="let row"> {{row.seconds_tareas / 3600 | number:"1.2-2"}} </td>
              <td mat-footer-cell *matFooterCellDef>{{hours_task | number:"1.2-2"}}</td>
            </ng-container>
            <ng-container matColumnDef="timeWork">
              <th mat-header-cell *matHeaderCellDef> Tiempo trabajado </th>
              <td mat-cell *matCellDef="let row"> {{row.total_trabajo}} </td>
              <td mat-footer-cell *matFooterCellDef>{{totalTime}}</td>
            </ng-container>
            <ng-container matColumnDef="workHours">
              <th mat-header-cell *matHeaderCellDef> Total de horas trabajadas </th>
              <td mat-cell *matCellDef="let row"> {{row.seconds_trabajo / 3600 | number:"1.2-2"}} </td>
              <td mat-footer-cell *matFooterCellDef>{{hours_work | number:"1.2-2"}}</td>
            </ng-container>
            <ng-container matColumnDef="daysWork">
              <th mat-header-cell *matHeaderCellDef> Horas trabajadas por dia </th>
              <td mat-cell *matCellDef="let row"> {{(row.seconds_trabajo / 3600) / 12 | number:"1.2-2"}} </td>
              <td mat-footer-cell *matFooterCellDef>{{total_workForDay | number:"1.2-2"}}</td>
            </ng-container>
            <ng-container matColumnDef="plan">
              <th mat-header-cell *matHeaderCellDef> Plan </th>
              <td mat-cell *matCellDef="let row"> {{row.plan}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef> Monto </th>
              <td mat-cell *matCellDef="let row"> {{row.planData.precio | currency:'MXN'}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="planTime">
              <th mat-header-cell *matHeaderCellDef> Horas del plan </th>
              <td mat-cell *matCellDef="let row"> {{row.planData.time}} hrs</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef> Valor pagado por Hora </th>
              <td mat-cell *matCellDef="let row"> {{row.planData.precio / row.planData.time | currency:'MXN'}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="completeData">
              <th mat-header-cell *matHeaderCellDef> Fecha de antiguedad </th>
              <td mat-cell *matCellDef="let row"> {{row.companyDate | date}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="percent">
              <th mat-header-cell *matHeaderCellDef> % de Uso </th>
              <td mat-cell *matCellDef="let row"> {{row.usage | number:'1.2-2'}} %</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="hoursWork">
              <th mat-header-cell *matHeaderCellDef>Horas trabajadas a la fecha </th>
              <td mat-cell *matCellDef="let row"> {{row.quoteTime / 3600 | number:'1.2-2'}}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayColumns"></tr>
          </table>
        </div>
      </ng-container>

    </div>
  </div>
</section>

<div class="row" id="printsection" hidden>
  <div class="col-md-12 col-center">
    <nb-card>
      <nb-card-header style="  border-bottom: 2px solid #e26b0a;">
        <div class="row">
          <div class="col-md-5 text-left">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262"
              width="140">
          </div>
          <div class="col-md-7 text-right">
            <ul style="list-style: none; padding-left: 1px;">
              <li style="color: #e26b0a;">www.miasistente.net
              </li>
              <li>ventas&#64;miassitente.net
              </li>
              <li>800 288 02 68
              </li>
              <li>Cancún Quintana Roo México / Oficinas Centrales
              </li>
            </ul>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body style="margin-top: 1rem;" *ngIf="data_source">
        <div class="row justify-content-center">
          <div class="col-md-8 text-center">
            <h2>{{data_source.report}} </h2>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-11 nodata-card">
            <table class="content-table-pdf">
              <thead>
                <tr>
                  <td>Fecha de corte</td>
                  <td>Nombre del cliente</td>
                  <td>Total de llamadas</td>
                  <td>Total tiempo atentido en llamadas</td>
                  <td>Total de tareas realizadas</td>
                  <td>Total tiempo atentido en tareas</td>
                  <td>Total de tiempo trabajado</td>
                  <td>Plan</td>
                  <td>Monto</td>
                  <td>Horas del plan</td>
                  <td>Valor pagado por Hora</td>
                  <td>Valor real pagado por Hora</td>
                  <td>Horas trabajadas a la fechaa</td>
                  <td>% de Uso</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data_source.clients">
                  <td>{{item.contractData.nextRenovationDate | date}} </td>
                  <td>{{item.nombre_empresa}} </td>
                  <td>{{item.total_Llamadas}} </td>
                  <td>{{item.tiempo_llamadas || '0s'}} </td>
                  <td>{{item.total_tareas }} </td>
                  <td>{{item.tiempo_tareas || '0s'}} </td>
                  <td>{{item.total_trabajo || '0s'}} </td>
                  <td>{{item.plan}} </td>
                  <td>{{item.planData.precio | currency:'MXN'}}</td>
                  <td>{{item.planData.time}} hrs</td>
                  <td>{{item.planData.precio / item.planData.time | currency:'MXN'}}</td>
                  <td>{{item.planData.precio / (item.seconds_trabajo / 3600) |
                    currency:'MXN'}}</td>
                  <td>{{item.hoursWork | number:'1.2-2'}}</td>
                  <td>{{item.usage | number:'1.2-2'}} %</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td>{{total_calls}} </td>
                  <td>{{time_calls}} </td>
                  <td>{{total_task}} </td>
                  <td>{{time_task}} </td>
                  <td>{{totalTime}} </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>