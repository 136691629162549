import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AddPlanComponent } from '../plan-form/add-plan.component';
import { PlanService } from 'src/app/services/planService/plan.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-plans-table',
  templateUrl: './plans-table.component.html',
  styleUrls: ['./plans-table.component.scss']
})
export class PlansTableComponent implements OnInit {

  settingsPlans = {
    actions: {
      columnTitle: "Acciones",
      add: false,
      edit: true,
      delete: true,
      position: "right",
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Nuevo Plan</span>"
    },
    edit: {
      editButtonContent: "<span>Editar</span>",
    },
    delete: {
      deleteButtonContent: "<span>Eliminar</span>",
    },
    columns: {
      title: {
        title: "Plan",
      },
      precio: {
        title: "Precio",
        filter: false,
      },
      time: {
        title: "Horas",
      }
    },
    mode: "external",
    noDataMessage: "No hay registros por ahora",
  };

  @Input() data_source_3 = [];
  @Output('eventInit') fatherOnInit = new EventEmitter();
  constructor(
    private dialogService: MatDialog,
    private planService: PlanService
  ) { }

  ngOnInit() {
  }

  async createPlan() {
    var resultDialog = await this.dialogService.open(AddPlanComponent, { height: '50vh' }).beforeClosed().toPromise()
    if (resultDialog) {
      this.fatherOnInit.emit();
    }
  }

  async editPrice(data) {
    try {
      const resultDialog = await this.dialogService.open(AddPlanComponent, { height: "50vh", data: data })
        .beforeClosed().toPromise()
      if (resultDialog) {
        this.fatherOnInit.emit();
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async deletePlan(data: any) {
    try {
      var result = await Swal.fire({
        title: "¿Seguro que quiere eliminar este plan?",
        text: "Esta plan ya no podrá ser asignada a los clientes.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
      })
      if (result.isConfirmed) {
        await this.planService.deletePlan(data._id)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Plan eliminado",
          showConfirmButton: false,
          timer: 1000,
        });
        this.fatherOnInit.emit()
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }


}
