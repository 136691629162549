import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FileProspect, Prospect } from 'src/app/models/prospects';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-file-prospect',
  templateUrl: './add-file-prospect.component.html',
  styleUrls: ['./add-file-prospect.component.scss']
})
export class AddFileProspectComponent implements OnInit {
  public fileContract: FileProspect | string;
  public fileAgreement: FileProspect | string;
  public fileInvoice: FileProspect | string;
  public fileFiscal: FileProspect | string;
  public filePay: FileProspect | string;
  public showError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<AddFileProspectComponent>,
    private prospectService: ProspectsService
  ) { }

  async ngOnInit() {
    if (this.data.fileAgreement != undefined && this.data.fileAgreement != "") {
      this.fileAgreement = this.data.fileAgreement
    }
    if (this.data.fileContract != undefined && this.data.fileContract != "") {
      this.fileContract = this.data.fileContract
    }
    if (this.data.fileInvoice != undefined && this.data.fileInvoice != "") {
      this.fileInvoice = this.data.fileInvoice
    }
    if (this.data.fileFiscal != undefined && this.data.fileFiscal != "") {
      this.fileFiscal = this.data.fileFiscal
    }
    if (this.data.filePay != undefined && this.data.filePay != "") {
      this.filePay = this.data.filePay
    }

  }

  deleteFile(type: number) {
    switch (type) {
      case 1:
        this.fileContract = undefined;
        break;
      case 2:
        this.fileAgreement = undefined
        break;
      case 3:
        this.fileInvoice = undefined
        break;
      case 4:
        this.fileFiscal = undefined
        break;
      case 5:
        this.filePay = undefined
        break;
    }
  }

  async saveContract(event, type) {
    var file = event.target.files[0];
    var split = event.target.files[0].name.split(".");
    var extension = split[split.length - 1];
    const id = Math.random().toString(36).substring(2);
    const filePath = `prospects/contracts/${id}`;
    const fileRef = this.storage.ref(filePath);
    await this.storage.upload(filePath, file).snapshotChanges().toPromise();
    var downloadURL = await fileRef.getDownloadURL().toPromise();
    switch (type) {
      case 1:
        this.fileContract = { url: downloadURL, extension: extension };
        break;
      case 2:
        this.fileAgreement = { url: downloadURL, extension: extension };
        break;
      case 3:
        this.fileInvoice = { url: downloadURL, extension: extension };
        break;
      case 4:
        this.fileFiscal = { url: downloadURL, extension: extension };
        break;
      case 5:
        this.filePay = { url: downloadURL, extension: extension };
        break;
      default:
        break;
    }
  }

  async saveFiles() {
    try {
      if (this.fileContract == undefined &&
        this.fileAgreement == undefined &&
        this.fileInvoice == undefined &&
        this.fileFiscal == undefined &&
        this.filePay == undefined) {
        this.showError = true
        return Swal.fire({
          icon: 'error',
          title: 'Favor de agregar al menos un documento para actualizar la información',
          confirmButtonText: 'Entendido'
        })
      }
      var saveFields = {
        "fileContract": this.fileContract ?? "",
        "fileAgreement": this.fileAgreement ?? "",
        "fileInvoice": this.fileInvoice ?? "",
        "fileFiscal": this.fileFiscal ?? "",
        "filePay": this.filePay ?? ""
      }
      if (this.filePay) {
        saveFields["isPaid"] = "Si"
      }
      else {
        saveFields["isPaid"] = "No"
      }

      await this.prospectService.updateProspect(this.data._id, saveFields)
      await Swal.fire({
        title: 'Documentos agregados con exito!',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      })
      this.dialogRef.close({ change: true })
    } catch (error) {
      Swal.fire({
        title: 'Ocurrión un error al agregar los archivos al prospecto',
        text: "Si el problema persiste, favor de reportar el error",
        icon: error
      })
    }
  }

}
