import { Component, OnInit } from '@angular/core';
import { ReporteService } from "../../services/reportes/reporte.service";
import { NbDialogService } from '@nebular/theme';
import { GenerateReporteComponent } from "./generate/generate.component";
import { formatDate } from '@angular/common';
import { DetailsReportsComponent } from './details/details.component';
@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})
export class ReportesComponent implements OnInit {

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: true,
      edit: true,
      delete: false,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Generar reporte</span>",
      // createButtonContent: '<i class="nb-checkmark"></i>',
      // cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<span>Ver detalles</span>',
    },
    delete: {
      deleteButtonContent: '<span>Eliminar</span>',
    },
    columns: {
      report: {
        title: 'Reporte',
      },
      clientName: {
        title: 'Cliente',
      },
      date: {
        title: 'Fecha de creación',
      },
    },
    mode: 'external',
    noDataMessage: 'No hay reportes por ahora'
  };
  data_source = [];

  constructor(
    private reports_service: ReporteService,
    private dialogService: NbDialogService) {

    var reports$ = this.reports_service.getClientReport();
    reports$.subscribe(data => {
      data.forEach(element => {
        element.date = formatDate(element.date.toDate(), 'dd-MM-yyyy', 'es-MX');
      });
      this.data_source = data
    })
  }

  ngOnInit() {
  }

  openDetails(data) {
    this.dialogService.open(DetailsReportsComponent, { context: data });
  }
  createNew() {
    this.dialogService.open(GenerateReporteComponent);
  }
}
