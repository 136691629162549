import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  uploadFile$: Observable<any>;
  quoRef: AngularFirestoreCollection<any>;
  quo$: Observable<any>;
  uploadQuo$: Observable<any>;
  public URLconnect = environment.URL_API;

  constructor(
    private sanitizer: DomSanitizer,
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage,
    private http: HttpClient
  ) {

  }

  async downloadFile(nameRef: any) {
    this.http.get(nameRef.url, { responseType: 'blob' }).subscribe(val => {
      let url = URL.createObjectURL(val);
      var link = document.createElement('a');
      link.target = "_blank";
      link.download = `${nameRef.fileName}.${nameRef.extension}`;
      link.href = url;
      link.click();
      URL.revokeObjectURL(url);
    });
  }

  async uploadFile(data, file) {

    let extension = file.name.split('.').pop();
    extension = extension.toLowerCase();

    let name = new Date().getTime() + Math.random().toString(36).substring(2);

    let company = localStorage.getItem("companyOnFocus");
    let refUrl = company + '/' + name;
    let ref = this.afStorage.ref(refUrl)
    let task = ref.put(file)

    let sub = task.snapshotChanges().pipe(
      finalize(() => {
        this.uploadQuo$ = ref.getDownloadURL();
        this.uploadQuo$.subscribe(url => {
          // this.addNewQuo(data, company, url, extension)
        })
      })
    ).subscribe();
  }

  async getRecentImages(uid) {
    try {
      var response = await this.http.post<{ data: any, code: number, message: string }>(this.URLconnect + "/cotizacion/getAll", { companyId: uid }).toPromise()
      return response.data
    } catch (error) {
      console.log(error);
      throw error
    }
  }

  getAllImages(uid) {

    this.quoRef = this.afs.collection('empresas').doc(uid).collection('cotizaciones', ref => ref.orderBy('fecha'))
    this.quo$ = this.quoRef.valueChanges();

    return this.quo$
  }

  async addNewQuo(quo: any, uid: string) {
    try {
      var data = {
        titulo: quo.title,
        fecha: quo.date,
        hora: quo.time,
        extension: quo.extension,
        url: quo.file,
        tags: quo.tags
      }
      await this.http.post<any>(this.URLconnect + "/cotizacion/add", { data, companyId: uid }).toPromise()
      return
    } catch (error) {
      console.log(error);
      throw error
    }
  }

  async deleteCotizacion(data: any, companyId: string) {
    try {
      await this.http.delete<any>(this.URLconnect + "/cotizacion/delete/" + companyId + "/" + data.id).toPromise()
      await this.afStorage.storage.refFromURL(data.url).delete()
      return
    } catch (error) {
      console.log(error);
      throw error
    }
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
