<nb-card style="max-height: 95vh; margin-bottom: 0;">
    <nb-card-body>
        <form [formGroup]="addForm">
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Cliente</label>
                    <nb-select fullWidth placeholder="Selecciona una opción" formControlName="client">
                        <nb-option *ngFor="let c of clients" value="{{c.id}},{{c.nombre}}">
                            {{c.nombre.toUppercase() }}</nb-option>
                    </nb-select>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Acciones a mostrar</label>
                    <ul style="list-style-type: none; padding-left: 0;">
                        <li>
                            <nb-toggle formControlName="commentsFacebook">Comentarios respondidos en Facebook
                            </nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="campaignFacebook">Campañas de Facebook</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="postsFacebook">Post de Facebook</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="postsInsta">Post de Instagram</nb-toggle>
                        </li>

                        <li>
                            <nb-toggle formControlName="inboxFacebook">Inbox enviados con información por Facebook
                            </nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="commentsInsta">Comentarios respondidos por Instagram</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="infoMasiva">Envíos de información masiva</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="responseInsta">Mensajes respondidos por Instagram</nb-toggle>
                        </li>


                        <li>
                            <nb-toggle formControlName="confirmDates">Confirmaciones de citas</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="remiders">Envíos de recordatorios</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="congrats">Envíos de felicitaciones</nb-toggle>
                        </li>

                        <li>
                            <nb-toggle formControlName="infoLinkedIn">Envío de información por LinkedIn</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="whatsappMessages">Mensaje de WhatsApp</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="surveys">Envíos de encuesta</nb-toggle>
                        </li>

                        <li>
                            <nb-toggle formControlName="administrativeActivities">Actividades
                                administrativas</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="externalAgenda">Citas agenda externa</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="zoomConections">Conexiones por Zoom</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="emailFollowUps">Seguimientos correo electrónico</nb-toggle>
                        </li>
                        <li>
                            <nb-toggle formControlName="socialNetworkPost">Post en otra red social</nb-toggle>
                        </li>

                    </ul>

                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-6">
                    <label for="">Fecha inicial</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="startDate" formControlName="start_date">
                    <nb-datepicker #startDate (dateChange)="onSelectedStartDate($event)"></nb-datepicker>
                </div>
                <div class="col-md-6">
                    <label for="">Fecha final</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="endDate" formControlName="end_date">
                    <nb-datepicker #endDate [min]="minDate" (dateChange)="onSelectedEndDate($event)"></nb-datepicker>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer class="text-center">
        <button class="mr-5" nbButton status="danger" (click)="ref.close()">Cancelar</button>
        <button nbButton status="warning" (click)="validateForm()">Generar
            reporte</button>
    </nb-card-footer>
</nb-card>