<nb-card>
    <nb-card-header class="text-center">
        <span style="float: left"> RECADOS</span>
        <span style="float: right">
            <button nbButton size="small" status="warning" (click)="createRecado()">
                <nb-icon icon="plus-outline"></nb-icon>Nuevo recado
            </button>
        </span>
    </nb-card-header>

    <nb-card-body>
        <nb-accordion>
            <nb-accordion-item expanded>
                <nb-accordion-item-header>
                    Recados no leidos
                    <span style="font-size: 11px"><i> (Recados aún no leidos por el
                            cliente)</i></span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                    <!-- UNREAD MSG -->
                    <nb-card>
                        <nb-card-body>
                            <div *ngIf="(unreadMsg$ | async)?.length === 0" class="text-center">
                                <span>No hay recados por ahora</span>
                            </div>
                            <div class="content-msg">
                                <div class="row">
                                    <div class="col-md-4" *ngFor="let msg of unreadMsg$ | async">
                                        <nb-card accent="warning">
                                            <nb-card-header class="text-right">
                                                <span>{{ msg.fecha }} a las
                                                    {{ msg.hora }}</span>
                                            </nb-card-header>
                                            <nb-card-body>
                                                <ul class="msg-body">
                                                    <li>
                                                        <strong>Asunto: </strong>{{ msg.asunto }}
                                                    </li>
                                                    <br />
                                                    <li>{{ msg.mensaje }}</li>
                                                </ul>
                                            </nb-card-body>
                                        </nb-card>
                                    </div>
                                </div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                    <!-- UNREAD MSG -->
                </nb-accordion-item-body>
            </nb-accordion-item>

            <nb-accordion-item>
                <nb-accordion-item-header>
                    Recados leidos
                    <span style="font-size: 11px"><i> (Recados leidos por el cliente)</i></span>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                    <!-- READ MSG -->
                    <nb-card>
                        <nb-card-body>
                            <div *ngIf="(readMsg$ | async)?.length === 0" class="text-center">
                                <span>No hay recados por ahora</span>
                            </div>
                            <div class="content-msg">
                                <div class="row">
                                    <div class="col-md-4" *ngFor="let msg of readMsg$ | async">
                                        <nb-card accent="success">
                                            <nb-card-header class="text-right">
                                                <span>{{ msg.fecha }} a las
                                                    {{ msg.hora }}</span>
                                            </nb-card-header>
                                            <nb-card-body>
                                                <ul class="msg-body">
                                                    <li>
                                                        <strong>Asunto: </strong>{{ msg.asunto }}
                                                    </li>
                                                    <br />
                                                    <li>{{ msg.mensaje }}</li>
                                                </ul>
                                            </nb-card-body>
                                        </nb-card>
                                    </div>
                                </div>
                            </div>
                        </nb-card-body>
                    </nb-card>
                    <!-- READ MSG -->
                </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
    </nb-card-body>
</nb-card>