<section>
  <div class="header-container">
    <h3>
      <mat-icon (click)="close()" class="back-btn" matTooltip="Volver atras">arrow_back</mat-icon>
      {{isEdit ? 'Editar prospecto': 'Agregar nuevo prospecto'}}
    </h3>
    <div class="button-wrap">
      <button mat-button class="success-btn" (click)="submit()"><mat-icon>save</mat-icon> {{isEdit ? 'Actualizar':
        'Crear'}}</button>
    </div>
  </div>
  <form [formGroup]="addForm">
    <div class="section">
      <div class="header-section">
        <h4>Información de contacto</h4>
        <hr>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3 form-group">
          <label>Nombre comercial <span class="requiered">(*)</span></label>
          <input type="text" nbInput fullWidth fieldSize="medium" formControlName="nombre"
            placeholder="Nombre comercial">
          <p class="alert alert-danger" *ngIf="fieldRequired('nombre')">
            <span>El campo nombre comercial es requerido</span>
          </p>
          <div class="alert alert-danger" *ngIf="fieldPattern('nombre')">
            <span>No se aceptan caracteres y números</span>
          </div>
        </div>

        <div class="col-md-6 form-group">
          <label>Nombre del cliente <span class="requiered">(*)</span></label>
          <input type="text" nbInput fullWidth fieldSize="medium" formControlName="nombreCliente"
            placeholder="Nombre del cliente">
          <p class="alert alert-danger" *ngIf="fieldRequired('nombreCliente')">
            <span>El campo nombre del cliente es requerido</span>
          </p>
          <div class="alert alert-danger" *ngIf="fieldPattern('nombreCliente')">
            <span>No se aceptan caracteres y números</span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3">
          <label>Sexo</label>
          <nb-select fullWidth placeholder="Selecciona una opción" formControlName="gender">
            <nb-option value="male">Masculino</nb-option>
            <nb-option value="female">Femenino</nb-option>
          </nb-select>
          <!-- <div class="alert alert-danger" *ngIf="fieldRequired('gender')">
              <span>El campo sexo es requerido</span>
            </div> -->
        </div>

        <div class="col-md-3">
          <label>Edad</label>
          <input type="number" (keydown)="onKeyDown($event)" nbInput fullWidth fieldSize="medium" formControlName="age"
            placeholder="Edad">
          <!-- <div class="alert alert-danger" *ngIf="fieldRequired('age')">
              <span>El campo edad es requerido</span>
            </div> -->
          <div class="alert alert-danger" *ngIf="fieldMin('age')">
            <span>Ingrese un número mayor a 0</span>
          </div>
        </div>

        <div class="col-md-6">
          <label for="">Número de teléfono <span class="requiered">(*)</span></label>
          <input type="text" (input)="onlyNums($event)" nbInput fullWidth fieldSize="medium" formControlName="telefono"
            placeholder="Número de teléfono" maxlength="10">
          <div class="alert alert-danger" *ngIf="fieldRequired('telefono')">
            <span>El campo número de teléfono es requerido</span>
          </div>
          <div class="alert alert-danger" *ngIf="fieldMinLength('telefono')">
            <span>El formato Número de telefono es incorrecto </span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Correo electrónico</label>
          <input type="email" nbInput fullWidth fieldSize="medium" formControlName="email"
            placeholder="Correo electrónico">
          <!-- <div class="alert alert-danger" *ngIf="fieldRequired('email')">
              <span>El campo correo electrónico es requerido</span>
            </div> -->
          <div class="alert alert-danger" *ngIf="fieldPattern('email')">
            <span>Ingrese un correo valido</span>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header-section">
        <h4>Información comercial</h4>
        <hr>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="">Giro comercial</label>
          <nb-select fullWidth placeholder="Selecciona una opción" formControlName="giroComercial">
            <nb-option *ngFor="let item of commercialTurn" value="{{item.value}}">{{item.viewValue |
              titlecase}}
            </nb-option>
          </nb-select>
          <div class="alert alert-danger" *ngIf="errorInComercial">
            <span>Se requiere un campo para agregar un tipo</span>
          </div>
        </div>
        <div class="col-md-6 form-group">
          <label for="">Tipo</label>
          <div class="box-tags">
            <input type="text" nbInput fullWidth fieldSize="medium" [formControl]="tagField" placeholder="Escribe...">
            <button mat-button (click)="addTag()"><mat-icon>add</mat-icon></button>
          </div>
        </div>
      </div>
      <div class="tags-container">
        <span *ngFor="let tag of tags" (click)="removeTag(tag)"><mat-icon>close</mat-icon> {{tag}}</span>
        <p *ngIf="tags.length == 0">No se han agregado etiquetas</p>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Dirección comercial</label>
          <input type="text" nbInput fullWidth fieldSize="medium" formControlName="direccion" placeholder="Dirección">
        </div>

        <div class="col-md-6">
          <label for="">Dirección fiscal</label>
          <input type="text" nbInput fullWidth fieldSize="medium" formControlName="fiscalAddress"
            placeholder="Dirección fiscal">
        </div>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Ciudad <span class="requiered">(*)</span></label>
          <nb-select fullWidth placeholder="Selecciona una opción" formControlName="city">
            <nb-option *ngFor="let item of cities" value="{{item.value}}">{{item.viewValue | titlecase}}
            </nb-option>
          </nb-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('city')">
            <span>El campo ciudad es requerido</span>
          </div>
        </div>

        <div class="col-md-6">
          <label for="">Origen <span class="requiered">(*)</span></label>
          <nb-select fullWidth placeholder="Selecciona una opción" formControlName="origin">
            <nb-option *ngFor="let item of origin" value="{{item.value}}">{{item.viewValue | titlecase}}
            </nb-option>
          </nb-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('origin')">
            <span>El campo origen es requerido</span>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header-section">
        <h4>Información de capacitación </h4>
        <hr>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Vendedor</label>
          <input type="text" formControlName="vendor" nbInput fullWidth fieldSize="medium" readonly>
        </div>
        <div class="col-md-6">
          <label for="">Ejecutivos para la capacitación <span class="requiered">(*)</span></label>
          <nb-select multiple fullWidth placeholder="Selecciona una opción" formControlName="ejecutivo">
            <nb-option *ngFor="let item of executives" [value]="item.nombre">{{item.nombre | titlecase}}
            </nb-option>
          </nb-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('ejecutivo')">
            <span>El campo ejecutivo asignado es requerido</span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Fecha de capacitación <span class="requiered">(*)</span></label>
          <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha" [nbDatepicker]="fechacontrato"
            formControlName="trainingDate">
          <nb-datepicker #fechacontrato></nb-datepicker>
          <div class="alert alert-danger" *ngIf="fieldRequired('trainingDate')">
            <span>El campo fecha de capacitación es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="timeSelect">
            <label for="">Hora de capacitación <span class="requiered">(*)</span></label>
            <ngb-timepicker formControlName="trainingHour" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
          </div>
          <div class="alert alert-danger" *ngIf="fieldRequired('trainingHour')">
            <span>El campo hora de capacitación es requerido</span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Link de Zoom</label>
          <input type="text" formControlName="zoomLink" nbInput fullWidth fieldSize="medium"
            placeholder="https://us06web.zoom.us...">
        </div>
        <div class="col-md-6">
          <label for="">En que requiere ayuda</label>
          <input type="text" formControlName="needHelp" nbInput fullWidth fieldSize="medium"
            placeholder="Apoyo para ...">
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header-section">
        <h4>Información de contrato</h4>
        <hr>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Plan contratado</label>
          <nb-select fullWidth placeholder="Selecciona una opción" formControlName="plan">
            <nb-option *ngFor="let item of actualPlans" [value]="item.title">{{item.title | titlecase}}
            </nb-option>
          </nb-select>
        </div>

        <div class="col-md-6">
          <label for="">Campaña</label>
          <input type="text" nbInput fullWidth fieldSize="medium" formControlName="campaign" placeholder="Campaña">
        </div>
      </div>

      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Promoción aplicada</label>
          <nb-select fullWidth placeholder="Selecciona una opción" formControlName="promotion">
            <nb-option *ngFor="let item of promotion" value="{{item.value}}">{{item.viewValue | titlecase}}
            </nb-option>
          </nb-select>
        </div>

        <div class="col-md-6">
          <label for="">Requiere Factura</label>
          <nb-select fullWidth placeholder="Selecciona una opción" formControlName="invoice">
            <nb-option value="Si">Si</nb-option>
            <nb-option value="No">No</nb-option>
          </nb-select>
        </div>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Pagado</label>
          <nb-select fullWidth placeholder="Selecciona una opción" formControlName="isPaid">
            <nb-option value="Si">Si</nb-option>
            <nb-option value="No">No</nb-option>
          </nb-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('isPaid')">
            <span>El campo de Pagado es requerido</span>
          </div>
        </div>
      </div>
    </div>

  </form>
</section>