import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GeneralReportsService } from "src/app/services/general-reports/general-reports.service";
import { asBlob } from "html-docx-js-typescript";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';

@Component({
  selector: "app-general-report-page",
  templateUrl: "./general-report-page.component.html",
  styleUrls: ["./general-report-page.component.scss"],
})
export class GeneralReportPageComponent implements OnInit {
  public generalReport;
  public loader = true;
  public callsEntries = [];
  public callsSalientesMore = [];
  public callsSalientesLess = [];
  @ViewChild("htmlDoc") html;

  public globalMinutes = 0;
  public callEntriesMinutes = 0;
  public callSalientesMinutes = 0;
  public realTime = 0;
  public totalTimeCalls = ''
  public timeTotalOutgoinCallLess = '0 s.'
  public timeTotalOutgoinCallMore = '0 s.'

  constructor(
    private route: ActivatedRoute,
    private generalReportService: GeneralReportsService,
  ) { }

  async ngOnInit() {
    let timeCallEntries = 0;
    let timeCallSalientes = 0;
    this.globalMinutes = 0;
    let id = this.route.snapshot.params.id;
    this.generalReport = await this.generalReportService.getGeneralReportById(id);
    let llamadas_entrantes = JSON.parse(
      this.generalReport.call_report.call_answeredEntriesCalls
    );
    let llamadas_salientes = JSON.parse(
      this.generalReport.call_report.call_answeredSalientesCalls
    );
    Promise.all(
      llamadas_entrantes.map((element) => {
        timeCallEntries += element.duration;
        element.minutes = this.secondsToHms(element.duration);
        this.callsEntries.push(element);
      })
    );

    await Promise.all(
      llamadas_salientes.map((element) => {
        timeCallSalientes += element.duration;
        element.minutes = this.secondsToHms(element.duration);
        if (element.duration > 20) {
          this.callsSalientesMore.push(element);
        } else {
          this.callsSalientesLess.push(element);
        }
      })
    );
    var timeLessCalls = this.callsSalientesLess.map(call => call['duration']).reduce((total,value)=> total+value,0)
    this.timeTotalOutgoinCallLess = this.secondsToHms(timeLessCalls) ?? "0 s."
    var timeMoreCalls = this.callsSalientesMore.map(call => call['duration']).reduce((total,value)=> total+value,0)
    this.timeTotalOutgoinCallMore = this.secondsToHms(timeMoreCalls) ?? "0 s."

    this.generalReport.socialMediaEntries.map(async entry => {
      if (entry.quantity > 0) {
        entry['totalMinutes'] = (entry.minutes * entry.totalAll);
        entry['realTotalMinutes'] = (entry.minutes * entry.totalAll) + entry.extraTime;
        this.globalMinutes += (entry.minutes * entry.totalAll);
        this.realTime += (entry.minutes * entry.totalAll) + entry.extraTime
      }
    });
    if (this.generalReport.additionalCalls) {
      this.generalReport.additionalCalls.forEach(additionalNumber => {
        additionalNumber.calls.forEach(call => {
          call['minutes'] = this.secondsToHms(call.duration)
          timeCallEntries += call.duration
        })
      })
    }
    if (this.generalReport?.outGoingCallPrefix) {
      this.generalReport.outGoingCallPrefix.forEach(prefixData => {
        prefixData['callMore'] = []
        prefixData['callLess'] = []
        prefixData.calls.forEach((call) => {
          call['minutes'] = this.secondsToHms(call.duration)
          timeCallSalientes += call.duration
          if (call.duration > 20) {
            prefixData['callMore'].push(call)
          } else {
            prefixData['callLess'].push(call)
          }
        })
      });
    }
    this.generalReport.call_report.total_TimeEntriesCalls = this.secondsToHms(timeCallEntries) || "0 s.";
    this.generalReport.call_report.total_TimeSalientesCalls = this.secondsToHms(timeCallSalientes) || "0 s.";
    this.totalTimeCalls = this.secondsToHms(timeCallEntries + timeCallSalientes) || "0 s."
  }

  viewPDF() {
    window.open(this.generalReport.pdfUrl, "_blank")
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  async generateWord(element) {
    const htmlString = this.html.nativeElement.innerHTML;
    const data = await asBlob(htmlString, {
      orientation: "portrait",
      margins: { top: 300, bottom: 300 },
    })
    var url = window.URL.createObjectURL(data as Blob);
    this.download(url);
  }

  download(url) {
    var link = document.createElement('a');
    link.target = "_blank";
    link.download = `${this.generalReport?.report}_${this.generalReport?.clientName}.docx`;
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  }

  exportToExcel() {
    Swal.fire({
      position: 'center',
      title: 'Generando archivo, por favor espere...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timer: 15000
    }).then(() => {
      let element = document.getElementById('tabla-socios');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, 'Reporte de actividades');
      /* save to file */
      let date = new Date();
      XLSX.writeFile(wb, this.generalReport?.report + '.xlsx');
    })
  }
}
