<div class="row">
    <div class="col-md-10 col-center">
        <nb-card>
            <nb-card-header style="  border-bottom: 2px solid #e26b0a;">
                <div class="row">
                    <div class="col-md-5 text-left">
                        <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262" width="140">
                    </div>
                    <div class="col-md-7 text-right">
                        <button nbButton status="warning" printSectionId="printsection" ngxPrint [useExistingCss]="true">Imprimir</button>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>
                <ng-container *ngIf="loader; else hasdata">
                    <div class="row justify-content-center">
                        <div class="col-md-8 text-center">
                            <h2>Cargando llamadas..</h2>
                            <div class="loader loader--style1" title="0">
                                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                                <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                                  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                                  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
                                <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                                  C22.32,8.481,24.301,9.057,26.013,10.047z">
                                  <animateTransform attributeType="xml"
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 20 20"
                                    to="360 20 20"
                                    dur="0.5s"
                                    repeatCount="indefinite"/>
                                  </path>
                                </svg>
                            </div>

                        </div>
                    </div>
                </ng-container>
                <ng-template #hasdata>
                    <div class="row justify-content-center">
                        <div class="col-md-8 text-center">
                            <h2>Registro de llamadas</h2>
                            <h6>{{titleReport}}</h6>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-3 mb-3">
                        <div class="col-md-6 card-total-calls text-center">
                            <p>Llamadas realizadas {{total_calls}}</p>
                        </div>
                    </div>
                    <div class="row justify-content-center  mt-3 mb-3">
                        <div class="col-md-6 nodata-card">
                            <table style="width: 100%;">
                                <thead>
                                    <tr>
                                        <td>Fecha</td>
                                        <td>Destino</td>
                                        <td>Estado</td>
                                        <td>Duración</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of rest_api">
                                        <td>{{item.calldate}}</td>
                                        <td>{{item.callerId}}</td>
                                        <td *ngIf="item.state == 'ANSWERED'" style="color: green;">Contestada</td>
                                        <td *ngIf="item.state == 'NO ANSWER'" style="color: red;">No contestada</td>
                                        <td>{{item.duration}}s ({{item.minutes}})</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-template>

            </nb-card-body>
        </nb-card>
    </div>
</div>


<div class="row" id="printsection" hidden>
    <div class="col-md-12 col-center">
        <nb-card>
            <nb-card-header style="  border-bottom: 2px solid #e26b0a;">
                <div class="row">
                    <div class="col-md-5 text-left">
                        <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262" width="140">
                    </div>
                    <div class="col-md-7 text-right">
                        <ul style="list-style: none; padding-left: 1px;">
                            <li style="color: #e26b0a;">www.miasistente.net
                            </li>
                            <li>ventas@miassitente.net
                            </li>
                            <li>800 288 02 68
                            </li>
                            <li>Cancún Quintana Roo México / Oficinas Centrales
                            </li>
                        </ul>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body style="margin-top: 1rem;">
                <div class="row justify-content-center">
                    <div class="col-md-10 text-center">
                        <h2>Registro de llamadas</h2>
                        <h6>{{titleReport}}</h6>
                    </div>
                </div>
                <div class="row justify-content-center mt-3 mb-3">
                    <div class="col-md-8 card-total-calls text-center">
                        <p>Llamadas realizadas {{total_calls}}</p>
                    </div>
                </div>
                <div class="row justify-content-center  mt-3 mb-3">
                    <div class="col-md-10 nodata-card">
                        <table style="width: 100%;">
                            <thead>
                                <tr>
                                    <td>Fecha</td>
                                    <td>Destino</td>
                                    <td>Estado</td>
                                    <td>Duración</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of rest_api">
                                    <td style="padding: 15px;">{{item.calldate}}</td>
                                    <td style="padding: 15px;">{{item.callerId}}</td>
                                    <td *ngIf="item.state == 'ANSWERED'" style="color: green; padding: 15px;">Contestada
                                    </td>
                                    <td *ngIf="item.state == 'NO ANSWER'" style="color: red; padding: 15px;">No contestada
                                    </td>
                                    <td style="padding: 15px;">{{item.duration}}s ({{item.minutes}})</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>