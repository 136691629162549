<div class="row justify-content-center">
    <div class="col-md-3 sidebar-client" *ngIf="infoCompany">
        <div class="img-company">
            <div *ngIf="infoCompany.profileImg">
                <img class="imgPreview" src="{{infoCompany.profileImg}}" alt="logo image" />
            </div>
            <div *ngIf="!infoCompany.profileImg">
                <img class="imgPreview"
                    src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/p1VMa9AT99RuXJkR8jM8BObhuQp1%2Fdapper%20test1591215725226z0v4mupeahk.png?alt=media&token=4d3187d0-a7ac-4646-b17a-d6f649fe8b3b"
                    alt="logo image" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 text-center mt-2 mb-2">
                <h3>{{infoCompany.nombre}}</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10">
                <ul class="info-left">
                    <li class="title"><span>Cliente</span></li>
                    <li class="info"><span>{{infoCompany.encargado}}</span></li>
                    <li class="title"><span>Email</span></li>
                    <li class="info"><span style="text-transform: lowercase;">{{infoCompany.email}}</span></li>
                    <li class="title"><span>Giro Comercial</span></li>
                    <li class="info"><span>{{infoCompany.giroComercial}}</span></li>
                    <li class="title"><span>Número Asignado</span></li>
                    <li class="info"><span>{{infoCompany.telefono}}</span></li>
                    <li class="title"><span>Dirección</span></li>
                    <li class="info"><span style="text-transform: lowercase;">direccion_company</span></li>
                    <li class="title"><span>Prefijo</span></li>
                    <li class="info"><span>{{infoCompany.prefix}}</span></li>
                </ul>
            </div>
        </div>

    </div>
    <div class="col-md-8 container-chat">
        <div class="content-chat">
            <div id="scroll-chat" #scrollframe>
                <ng-container *ngFor="let item of currentMessages">
                    <p [ngClass]="{'msm-left': item.type == 1 , 'msm-right': item.type == 2  }" #item>
                        <span class="msm">
                            {{item.message}}
                        </span>
                    </p>

                </ng-container>
            </div>
            <form [formGroup]="chat" class="container-input" (ngSubmit)="SendMessage()">
                <input type="text" formControlName="sendMessage" class="input-chat" placeholder="Escribir mensaje...">
                <a class="btn btn-success btn-chat" (click)="SendMessage()"> <img src="assets/send.svg" alt=""> </a>
            </form>
        </div>
    </div>
</div>