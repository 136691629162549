import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import Swal from 'sweetalert2';
import { GenerateReportComponent } from './generate-report/generate-report.component';
import { UserService } from '../../services/users/user.service';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { ReportAgents } from '../../models/report-agent';
import { filter } from 'rxjs-compat/operator/filter';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-reporte-agentes',
  templateUrl: './reporte-agentes.component.html',
  styleUrls: ['./reporte-agentes.component.scss']
})
export class ReporteAgentesComponent implements OnInit {

  settingsAgents = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: false,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    edit: {
      editButtonContent: '<span>Ver detalles</span>',
    },
    add: {
      addButtonContent: "<span>Generar reporte</span>",
    },
    columns: {
      report: {
        title: 'Reporte',
        filter: false
      },
      date: {
        title: 'Fecha de creación',
        filter: false
      }
    },
    mode: 'external',
    noDataMessage: 'No hay reportes por ahora'
  };
  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: true,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Generar reporte</span>",
    },
    edit: {
      editButtonContent: '<span>Ver detalles</span>',
    },
    delete: {
      deleteButtonContent: '<span>Eliminar</span>',
    },
    columns: {
      report: {
        title: 'Reporte',
        filter: false
      },
      date: {
        title: 'Fecha de creación',
        filter: false
      },
      agente: {
        title: 'Agente',
        filter: false
      },
    },
    mode: 'external',
    noDataMessage: 'No hay reportes por ahora'
  };
  data_source = [];
  data_filter = [];
  public agente = []
  public userInfo: any;
  public currentUser: string;
  public session: boolean;
  public loader: boolean = true;

  constructor(
    private router: Router,
    private dialogService: MatDialog,
    private reportService: ReporteService,
    private userService: UserService,
    private service: AuthService
  ) {
    this.currentUser = localStorage.getItem("currentUser")
  }
  async ngOnInit() {
    await this.getAllData()
    this.loader = false;
  }

  async getAllData() {
    this.userInfo = await this.getCurrentUser()
    this.data_source = await this.getReports(this.currentUser, this.userInfo.nombre)
  }

  async getReports(userType: string, userName?: string): Promise<ReportAgents[]> {
    try {
      var reports = await this.reportService.getAgentsReport();
      if (reports.code == 200) {
        reports.data.map((element: any) => {
          element.date = formatDate(element.date._seconds * 1000, 'dd-MM-yyyy', 'es-MX');
        });
      }
      if (userType == "administrador") {
        return reports.data
      }
      else {
        return reports.data.filter((report) => report.agente == userName)
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener los reportes',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  async getCurrentUser() {
    try {
      let uid = this.service.currentuser();
      return await this.userService.getUserById(uid.uid);
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener los datos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  async openDetails(data: ReportAgents) {
    var reportID = data.id;
    this.router.navigate(['/reporte-agentes-details/' + reportID])
  }

  createNew() {
    this.dialogService.open(GenerateReportComponent, { height: '60vh' });
  }

  async delete(reportId: string) {
    var result = await Swal.fire({
      title: '¿Seguro que desea eliminar el reporte?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    })
    if (result.isConfirmed) {
      try {
        await this.reportService.deleteAgentReport(reportId);
        Swal.fire({
          title: 'Eliminado!',
          text: 'El reporte ha sido borrado.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        })
        this.getAllData();
      } catch (error) {
        Swal.fire({
          title: 'Ocurrió un error al obtener los datos',
          text: "Si el problema persiste, favor de reportar el error",
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar'
        })
      }
    }
  }

}
