<div>
    <h3>Formulario de Presentación y Capacitación</h3>
</div>
<div>
    <form [formGroup]="trainingForm">
        <div class="form-group row">
            <div class="col-md-6" [ngClass]="invalidFlag">
                <label for="">Se da el speech de Confidencialidad</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="speach">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('speach')" class="error">*El campo speech es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Se le pide permiso para grabar</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="recording">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('recording')" class="error">*El campo Permiso para grabar es requerido</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6" [ngClass]="invalidFlag">
                <label for="">Cliente indica que herramientas usa</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="tools">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('tools')" class="error">*El campo es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Cliente detalla las tareas que requiere</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="tasksDo">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('tasksDo')" class="error">*El campo es requerido</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="">Solicitud de Datos Fiscales</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="fiscalData">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('fiscalData')" class="error">*El campo es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Se le menciona que se dara revision sobre su servicio</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="serviceReview">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('serviceReview')" class="error">*El campo es requerido</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="">Se le menciona acerca del uso de la APP</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="appUse">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('appUse')" class="error">*El campo es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Se le menciona acerca de los reportes mensuales</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="monthlyReports">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('monthlyReports')" class="error">*El campo es requerido</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="">Envio de convenio de confidencialidad</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="agreementFile">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('agreementFile')" class="error">*El campo es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Envio de contrato </label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="contractFile">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('contractFile')" class="error">*El campo es requerido</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="">Cliente usa crm propio</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="hasCRM">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('hasCRM')" class="error">*El campo es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Cliente usa calendario propio</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="hasSchedule">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('hasSchedule')" class="error">*El campo es requerido</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="">Solicita Numero asignado</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="requestNumber">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('requestNumber')" class="error">*El campo es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Solicita Redes Sociales</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="requestSocialMedia">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('requestSocialMedia')" class="error">*El campo es requerido</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="">Solicita Activación Facturador</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="requestInvoice">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('requestInvoice')" class="error">*El campo es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Envia Fiel</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="sendFiel">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('sendFiel')" class="error">*El campo es requerido</span>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="">Envia Contraseña</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="sendPassword">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('sendPassword')" class="error">*El campo es requerido</span>
            </div>

            <div class="col-md-6">
                <label for="">Cliente requiere ser Administrador de Plataformas</label>
                <nb-select fullWidth placeholder="Selecciona una opción" formControlName="adminAccess">
                    <nb-option value="Si">Si</nb-option>
                    <nb-option value="No">No</nb-option>
                </nb-select>
                <span *ngIf="fieldRequired('adminAccess')" class="error">*El campo es requerido</span>
            </div>
        </div>
        <div class="btn-section">
            <button nbButton (click)="dialogRef.close()" status="danger"><mat-icon>close</mat-icon> Cerrar</button>
            <button nbButton (click)="validateForm()" status="success"><mat-icon>save</mat-icon> Guardar</button>
        </div>
    </form>
</div>