<div class="dialog-template">
  <div class="chooser-color">
    <div class="preview-colors">
      <!-- <ng-container *ngFor="let item of reverRange">
        <div style="width: 10px;height: 10px;" [style]="'background-color: rgb('+item+','+item+','+item+')'"
          (click)="onSelectPreview('rgb('+item+','+item+','+item+')')"></div>
      </ng-container>
      <ng-container *ngFor="let item of redRange;let i = index">
        <div style="width: 10px;height: 10px;" [style]="'background-color: rgb('+i+',0,0)'"
          (click)="onSelectPreview('rgb('+i+',0,0)')"></div>
      </ng-container> -->
      <ng-container *ngFor="let item of redRange;let i = index">
        <div style="width: 10px;height: 10px;" [style]="'background-color: rgb(255,'+i+',0)'"
          (click)="onSelectPreview('rgb(255,'+i+',0)')"></div>
      </ng-container>
      <ng-container *ngFor="let item of reverRange">
        <div style="width: 10px;height: 10px;" [style]="'background-color: rgb('+item+',255,0)'"
          (click)="onSelectPreview('rgb('+item+',255,0)')"></div>
      </ng-container>
      <ng-container *ngFor="let item of redRange;let i = index">
        <div style="width: 10px;height: 10px;" [style]="'background-color: rgb(0,255,'+i+')'"
          (click)="onSelectPreview('rgb(0,255,'+i+')')"></div>
      </ng-container>
      <ng-container *ngFor="let item of reverRange">
        <div style="width: 10px;height: 10px;" [style]="'background-color: rgb(0,'+item+',255)'"
          (click)="onSelectPreview('rgb(0,'+item+',255)')"></div>
      </ng-container>
      <ng-container *ngFor="let item of redRange;let i = index">
        <div style="width: 10px;height: 10px;" [style]="'background-color: rgb('+i+',0,255)'"
          (click)="onSelectPreview('rgb('+i+',0,255)')"></div>
      </ng-container>
      <ng-container *ngFor="let item of reverRange">
        <div style="width: 10px;height: 10px;" [style]="'background-color: rgb(255,0,'+item+')'"
          (click)="onSelectPreview('rgb(255,0,'+item+')')"></div>
      </ng-container>
    </div>
  </div>
  <div class="chooser-color" style="margin: 20px 0px;">
    <div [style]="'background-color:'+getColor()"
      style="width: 200px;height: 200px;display: flex;align-items: center;justify-content: center;border: 1px solid black;">
    </div>
    <div>
      <div class="color-field">
        <div class="color-name">
          <p>#</p>
        </div>
        <div class="form-group">
          <input type="text" matInput [formControl]="colorHex" (input)="changeHex($event)">
        </div>
      </div>
      <div class="color-field">
        <div class="color-name">
          <p>R</p>
          <!-- <div
            style="width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;background-color: red;">
          </div> -->
        </div>
        <button mat-button (click)="addPointColor(1)"
          [disabled]="this.colorR.value == 255"><mat-icon>add</mat-icon></button>
        <div class="form-group">
          <input type="text" matInput [formControl]="colorR" (input)="onlyNums($event)">
        </div>
        <button mat-button (click)="removePointColor(1)"
          [disabled]="this.colorR.value == 0"><mat-icon>remove</mat-icon></button>
      </div>
      <div class="color-field">
        <div class="color-name">
          <p>G</p>
          <!-- <div
            style="width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;background-color: green;">
          </div> -->
        </div>
        <button mat-button (click)="addPointColor(2)"
          [disabled]="this.colorG.value == 255"><mat-icon>add</mat-icon></button>
        <div class="form-group">
          <input type="text" matInput [formControl]="colorG">
        </div>
        <button mat-button (click)="removePointColor(2)"
          [disabled]="this.colorG.value == 0"><mat-icon>remove</mat-icon></button>
      </div>
      <div class="color-field">
        <div class="color-name">
          <p>B</p>
          <!-- <div
            style="width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;background-color: blue;">
          </div> -->
        </div>
        <button mat-button (click)="addPointColor(3)"
          [disabled]="this.colorB.value == 255"><mat-icon>add</mat-icon></button>
        <div class="form-group">
          <input type="text" matInput [formControl]="colorB">
        </div>
        <button mat-button (click)="removePointColor(3)"
          [disabled]="this.colorB.value == 0"><mat-icon>remove</mat-icon></button>
      </div>
    </div>
  </div>
  <div class="buttons-footer">
    <button mat-button (click)="close()" class="danger"><mat-icon>close</mat-icon> Cerrar</button>
    <button mat-button (click)="submitColor()" class="success"><mat-icon>save</mat-icon> Aceptar</button>
  </div>
</div>