<nb-card>
    <nb-card-header class="text-center">
        llamadas perdidas de <br> {{clientName}}
    </nb-card-header>
    <nb-card-body>
        <div class="row" *ngIf="calls.length == 0">
            <div class="col-md-10">
                Este cliente no tiene llamadas perdidas
            </div>
        </div>
        <div class="row missing-calls" *ngIf="calls.length > 0">
            <div class="col-md-12">
                <ng-container *ngFor="let item of calls">
                    <p><strong>Fecha y hora:</strong> {{item.value.information.calldate}}</p>
                    <p><strong>Telefono:</strong> {{item.value.information.origen}}</p>
                    <p><strong>Duración:</strong> {{item.value.information.duration}}s ({{item.value.information.minutes}})</p>
                    <p><strong>Horario: </strong>{{item.value.information.horario}}</p>
                    <p><strong>Motivo: </strong>
                        <span  *ngIf="item.value.information.evento == 'EXITWITHTIMEOUT'">La llamada permaneció<br>en espera el tiempo máximo</span>
                        <span  *ngIf="item.value.information.evento == 'EXITWITHKEY'">Durante la espera en fila el<br>cliente solicito que le<br>devolvieran la llamada</span>
                        <span  *ngIf="item.value.information.evento == 'ABANDON'"> El cliente colgó la<br>llamada durante la espera</span>
                    </p>
                    <hr>
                </ng-container>

            </div>
        </div>

    </nb-card-body>
    <nb-card-footer style="text-align: center;">
        <button nbButton status="danger" (click)="ref.close()">Cerrar</button>
    </nb-card-footer>
</nb-card>