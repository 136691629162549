<nb-card>
    <nb-card-header>
        <h3>Generar Reporte interno</h3>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="addForm">
            <div class=" form-group row">
                <div class="col-md-12 text-right">
                    <nb-toggle status="primary" formControlName="generalReport"
                        (checkedChange)="hideClient($event)">Generar reporte general</nb-toggle>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12" *ngIf="!addForm.value.generalReport">
                    <label for="">Cliente</label>
                    <nb-select fullWidth placeholder="Selecciona una opción" formControlName="client">
                        <nb-option *ngFor="let c of clients " [value]="c.id">
                            {{c.nombre}}</nb-option>
                    </nb-select>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-6">
                    <label for="">Fecha inicial</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="startDate" formControlName="start_date">
                    <nb-datepicker #startDate (dateChange)="onSelectedStartDate($event)"></nb-datepicker>
                </div>
                <div class="col-md-6">
                    <label for="">Fecha final</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="endDate" formControlName="end_date">
                    <nb-datepicker #endDate [min]="minDate"></nb-datepicker>
                </div>
            </div>

        </form>
    </nb-card-body>
    <nb-card-footer class="text-center">
        <button nbButton status="success" (click)="validateForm()"><mat-icon>save</mat-icon> Generar reporte</button>
    </nb-card-footer>
</nb-card>