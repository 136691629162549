<div class="dialog-template">
    <h3>Eliminar espacios</h3>
    <form [formGroup]="eventForm">
        <div class="form-group">
            <mat-slide-toggle formControlName="onlyOneDate" (change)="checkedChange($event)">Solo una
                fecha</mat-slide-toggle>
        </div>
        <div class="form-group row">
            <div [ngClass]="eventForm.controls['onlyOneDate'].value ? 'col-md-12': 'col-md-6'">
                <label for="">Fecha inicial</label>
                <input matInput [matDatepicker]="picker" formControlName="startDate" (click)="picker.open()"
                    placeholder="dd/mm/yyyy" (dateChange)="onSelectedStartDate($event.value)">
                <mat-datepicker #picker></mat-datepicker>
            </div>
            <div class="col-md-6" *ngIf="!eventForm.value.onlyOneDate">
                <label for="">Fecha final</label>
                <input matInput [matDatepicker]="end_date" formControlName="endDate" (click)="end_date.open()"
                    placeholder="dd/mm/yyyy" [min]="minDate">
                <mat-datepicker #end_date></mat-datepicker>
            </div>
        </div>

        <div class=" form-group row">
            <div class="col-md-6">
                <label for="">Hora inicial</label>
                <ngb-timepicker formControlName="startTime" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
            </div>
            <div class="col-md-6">
                <label for="">Hora final</label>
                <ngb-timepicker formControlName="endTime" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
            </div>
        </div>
    </form>
    <div class="buttons-footer">
        <button mat-button class="danger" (click)="cancel()">
            <mat-icon>close</mat-icon> Cerrar
        </button>
        <button mat-button class="success" [disabled]="!eventForm.value" (click)="delete()">
            <mat-icon>save</mat-icon> Eliminar
        </button>
    </div>
</div>