<div class="actions-button">
    <div class="left">
        <button mat-button matTooltip="Confirmar cita" class="info" (click)="confirmEvent()"
            *ngIf="eventData.status && !eventData.confirmated">
            <mat-icon>event_available</mat-icon> <span>Confirmar cita</span>
        </button>
        <button mat-button class="success select-status" [matMenuTriggerFor]="menu"
            *ngIf="eventData.status && !eventData.confirmated">
            <span>{{eventData.statusDone}}</span> <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <ng-container *ngIf="eventData.status">
            <button mat-button matTooltip="Reprogramar cita" class="warning" (click)="openReSchedule();">
                <mat-icon>refresh</mat-icon> <span>Reprogramar cita</span>
            </button>
            <button mat-button matTooltip="Cancelar cita" class="danger" (click)="openCancelEvent(dialog);">
                <mat-icon>event_busy</mat-icon> <span>Cancelar cita</span>
            </button>
        </ng-container>
    </div>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="changeStatus('Pendiente de Pago',eventData._id)">
            <span>Pendiente de Pago</span>
        </button>
        <button mat-menu-item (click)="changeStatus('Pagado',eventData._id)">
            <span>Pagado</span>
        </button>
        <button mat-menu-item (click)="changeStatus('Pendiente de Facturación',eventData._id)">
            <span>Pendiente de Facturación</span>
        </button>
        <button mat-menu-item (click)="changeStatus('Pendiente Confirmación',eventData._id)">
            <span>Pendiente Confirmación</span>
        </button>
    </mat-menu>
    <div class="right">
        <button mat-Button matTooltip="Cancelar cita" class="basic" (click)="block()" *ngIf="!eventData.status">
            <mat-icon>lock</mat-icon> Bloquear espacio
        </button>
        <button mat-Button matTooltip="Eliminar espacio" class="danger" (click)="deleteEvent()">
            <mat-icon>delete</mat-icon> Eliminar espacio
        </button>
        <button mat-Button matTooltip="Eliminar espacio" class="info" (click)="openColorPicker()"
            *ngIf="eventData.status">
            <mat-icon>edit</mat-icon> Cambiar colores
        </button>
    </div>
</div>
<div class="dialog-template" style="justify-content: center;">
    <h3>{{ eventData.title }} {{ eventData.startDate | date: "short" }} - {{ eventData.endDate | date:
        "shortTime" }}</h3>
    <form [formGroup]="eventForm">
        <div class="formContainer">
            <h4 class="text-center" *ngIf="eventData.confirmated" style="color: green; font-size: 19px;">
                <mat-icon>check_circle_outline</mat-icon> Cita confirmada
            </h4>
            <div class="form-group" [ngClass]="invalidFlag">
                <label>Asunto</label>
                <input matInput placeholder="Ejemplo: Cita de negocios..." formControlName="subject" id="subject" />
                <p *ngIf="(eventForm.get('subject')?.hasError('required') && eventForm.get('subject')?.touched) || (hasError && eventForm.get('subject')?.untouched)"
                    class="error">
                    *El campo Asunto es requerido
                </p>
            </div>
            <div class="form-group" [ngClass]="invalidFlag">
                <label>Nombre del cliente</label>
                <mat-select fullWidth placeholder="Selecciona una opción" formControlName="customerSelect"
                    (selectionChange)="onSelectOption($event.value)" *ngIf="!eventData.status" id="customerSelect">
                    <mat-option value="other">Otro </mat-option>
                    <mat-option *ngFor="let operador of contactList" [value]="operador.nombre">
                        {{operador.nombre}}
                    </mat-option>
                </mat-select>
                <input matInput fullWidth placeholder="Ejemplo: Sara Martinez" formControlName="customer"
                    [readonly]="eventData.status" *ngIf="showOpenFieldName || eventData.status" id="customer" />
                <p *ngIf="(eventForm.get('customerSelect')?.hasError('required') && eventForm.get('customerSelect')?.touched) || (hasError && eventForm.get('customerSelect')?.untouched) ||(showOpenFieldName && eventForm.get('customer')?.untouched) "
                    class="error">
                    *El campo Nombre del cliente es requerido
                </p>
            </div>
            <div class="form-group">
                <label>Correo del cliente</label>
                <input type="email" formControlName="email" id="email" matInput fullWidth
                    placeholder="correo@email.com">
            </div>
            <div class="form-group" [ngClass]="invalidFlag">
                <label>Comentarios</label>
                <textarea matInput fullWidth placeholder="Informacion adicional" rows="5" style="resize: none;"
                    formControlName="comments" id="comments"></textarea>
                <p *ngIf="(eventForm.get('comments')?.hasError('required') && eventForm.get('comments')?.touched) || (hasError && eventForm.get('comments')?.untouched)"
                    class="error">
                    *El campo Comentarios es requerido
                </p>
            </div>
        </div>
    </form>
    <div class="buttons-footer">
        <button mat-button class="danger" (click)="cancel()">
            <mat-icon>close</mat-icon> Cancelar
        </button>
        <button mat-button class="success" (click)="validForm()">
            <mat-icon>save</mat-icon> {{eventData.status ? 'Actualizar' : 'Guardar' }}
        </button>
    </div>
</div>
<ng-template #dialog let-data let-ref="dialogRef">
    <div class="dialog-template card">
        <h3>Cancelar cita</h3>
        <div>
            <div class="form-group">
                <label>Favor de añadir el motivo de cancelación</label>
                <textarea matInput [formControl]="cancelMessage" rows="4"></textarea>
                <span *ngIf="cancelMessage.value.length <= 50">{{cancelMessage.value.length}}/50</span>
                <span style="color: red;" *ngIf="cancelMessage.value.length > 50">{{(50 -
                    cancelMessage.value.length)}}/50</span>
            </div>
        </div>
        <div class="buttons-footer">
            <button mat-button class="danger"
                (click)="ref.close();cancelMessage.setValue('')"><mat-icon>close</mat-icon>
                Cancelar</button>
            <button mat-button class="success" (click)="cancelEvent()"
                [disabled]="!cancelMessage.valid"><mat-icon>save</mat-icon> Continuar</button>
        </div>
    </div>
</ng-template>
