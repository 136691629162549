<section>
  <div class="header-container">
    <h3>Clientes {{tabSelect == 1 ? 'activos': 'inactivos'}} </h3>
    <div class="button-wrap">
      <button mat-button (click)="createNew()"
        *ngIf="tabSelect == 1 && user == 'administrador'"><mat-icon>add</mat-icon> Nuevo
        cliente</button>
      <button mat-button (click)="createMeet()" *ngIf="user == 'administrador'"><mat-icon>video_call</mat-icon> Generar Zoom</button>
      <button mat-button [ngClass]="tabSelect == 1 ? 'success': 'danger'" (click)="generateExcel()"
        *ngIf="user == 'administrador'"><mat-icon>description</mat-icon> Generar reporte gnral.</button>
    </div>
  </div>
  <div class="card">
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="changeTab()">
      <mat-tab label="Activos">
        <div *ngIf="user == 'administrador'">
          <ng2-smart-table [settings]="settingsAdmin" [source]="data_source" (edit)="openUpdateCompany($event.data)"
            (delete)="hideCompany($event.data)">
          </ng2-smart-table>
        </div>
        <div *ngIf="user != 'administrador'">
          <ng2-smart-table [settings]="settingsOp" [source]="data_source" (edit)="openUpdateCompany($event.data)">
          </ng2-smart-table>
        </div>
      </mat-tab>
      <mat-tab label="Inactivos">
        <div class="table-container">
          <mat-form-field appearance="outline">
            <mat-label>Buscar por nombre...</mat-label>
            <input type="text" matInput (keyup)="applyFilter($event)" placeholder="Ej. MIA ghost" #input>
          </mat-form-field>
          <table mat-table [dataSource]="dataClients" class="mat-elevation-z8">
            <ng-container matColumnDef="icon">
              <th mat-header-cell *matHeaderCellDef>Cliente </th>
              <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.thumbnail"></p>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Nombre</th>
              <td mat-cell *matCellDef="let element">{{element.nombre}} </td>
            </ng-container>
            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef>Encargado</th>
              <td mat-cell *matCellDef="let element">{{element.encargado}} </td>
            </ng-container>
            <ng-container matColumnDef="agent">
              <th mat-header-cell *matHeaderCellDef>Operador designado</th>
              <td mat-cell *matCellDef="let element">{{element.ejecutivo[0]}} </td>
            </ng-container>
            <ng-container matColumnDef="plan">
              <th mat-header-cell *matHeaderCellDef>Plan</th>
              <td mat-cell *matCellDef="let element">{{element.plan}} </td>
            </ng-container>
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef>Telefono</th>
              <td mat-cell *matCellDef="let element">{{element.telefono}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Correo</th>
              <td mat-cell *matCellDef="let element">{{element.email}} </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Acciones</th>
              <td mat-cell *matCellDef="let element">
                <!-- <button mat-fab color="warn" matTooltip="Borrar cliente" (click)="deleteCompany(element.id)"><mat-icon>delete</mat-icon></button> -->
                <button mat-fab color="primary" matTooltip="Activar cliente"
                  (click)="showClient(element)"><mat-icon>remove_red_eye</mat-icon></button>
                <!-- <button mat-fab color="basic" matTooltip="Ver cliente" (click)="openUpdateCompany(element)"><mat-icon>edit</mat-icon></button> -->
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="displayColumns"></tr> -->
          </table>
          <mat-paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
          </mat-paginator>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
<!-- <nb-card>
    <nb-card-header class="header-info">
    </nb-card-header>
    <nb-card-body>
        <nb-tabset (changeTab)="changeTab()">
            <nb-tab tabTitle="Activos">

            </nb-tab>
            <nb-tab tabTitle="Inactivos">

            </nb-tab>
        </nb-tabset>
    </nb-card-body>
</nb-card> -->
