import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { DiaryComponent } from 'src/app/pages/diary/diary.component';
import { ImportContactsComponent } from 'src/app/pages/diary/import-contacts/import-contacts.component';
import { ContactService } from 'src/app/services/contact/contact.service';
import Swal from 'sweetalert2';
import { AddClientComponent } from '../add-client/add-client.component';
import * as XLSX from 'xlsx';
import { AddMeetComponent } from '../add-meet/add-meet.component';
import { Contact } from 'src/app/models/contact';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit {

  @Output() refresh = new EventEmitter<string>();
  name_company: string;
  actionTitle: string;
  public term = ''
  public company_clients: any[];
  userType: string;
  public displayColumns = ["name", "email", "phone", "comments", "biling", "actions"]
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output('catchPaginator') emitPaginator = new EventEmitter<MatPaginator>();

  public isSelectPatient: boolean = false
  public contactInfoSelect: Contact
  public patientForm: FormGroup

  constructor(
    public dialogService: NbDialogService,
    private contactService: ContactService,
    private formbuilder: FormBuilder,
    public storage: AngularFireStorage,
  ) {
    this.patientForm = this.formbuilder.group({
      nombre: ["", Validators.required],
      lastName: ["", Validators.required],
      birthday: ["", Validators.required],
      gender: ["", Validators.required],
      typeOfPatient: ["", Validators.required],
      file: [""],
    })
  }

  async ngOnInit() {
    this.userType = localStorage.getItem("currentUser");
    this.company_clients = await this.getClients(DiaryComponent.companyIdFocus);
    // this.company_clients.paginator = this.paginator
  }

  ngAfterViewInit() {
    this.emitPaginator.emit(this.paginator)
  }

  async importClients() {
    this.dialogService.open(ImportContactsComponent).onClose.subscribe((data) => {
      this.ngOnInit()
    });
  }

  async getClients(companyID: string) {
    try {
      return await this.contactService.getContacsByCompany(companyID);
    } catch (error) {
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperado',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async generateExcel() {
    await Swal.fire({
      title: 'Generando archivo, por favor espere...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timer: 2000,
      timerProgressBar: true
    }).then(() => {
      let element = document.getElementById('tabla-clientes');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, 'Informacion de contactos');
      /* save to file */
      XLSX.writeFile(wb, "contactos_" + this.name_company + '.xlsx');
    })
  }

  async addContact() {
    this.dialogService.open(AddClientComponent, { context: { title: "Añadir contacto" } }).onClose.subscribe((data) => {
      this.ngOnInit()
    });
  }

  editContact(info) {
    this.dialogService.open(AddClientComponent, { context: { id: info, title: "Editar contacto" } }).onClose.subscribe((data) => {
      this.ngOnInit()
    });
  }

  async viewClient(info: any) {
    try {
      this.isSelectPatient = true
      if (info.birthday != undefined) {
        if (info.birthday._seconds != undefined) {
          info.birthday = new Date(info.birthday._seconds * 1000)
        }
      }
      this.contactInfoSelect = info;
      this.patientForm.patchValue(info)
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error inesperado, intentalo de nuevo mas tarde",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      });
    }
  }

  async deleteUser(id: string) {
    var result = await Swal.fire({
      title: '¿Desea eliminar este contacto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    })
    if (result.isConfirmed) {
      try {
        await this.contactService.deleteContact(DiaryComponent.companyIdFocus, id);
        await Swal.fire({
          title: 'Eliminado',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1200
        }
        )
        this.ngOnInit()
      } catch (error) {
        await Swal.fire({
          title: "Ocurrió un error inesperado, intentalo de nuevo mas tarde",
          icon: "error",
          text: "Si el problema persiste, favor de reportar el error",
          showConfirmButton: true
        });
      }
    }
  }

  async createMeet(info: any) {
    this.dialogService.open(AddMeetComponent, { context: { info } })
  }

  backPage() {
    this.isSelectPatient = false
    this.patientForm.reset()
  }

  async deleteFileFromArrayForm(index: number) {
    await Swal.fire({
      title: '¿Desea eliminar este documento de la lista de archivos adjuntos?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí, eliminar',
    })
  }

  saveFile(img) {
    return new Promise(async (resolve, reject) => {
      Swal.fire("Cargando imagen");
      Swal.showLoading();
      let file = img.target.files[0];
      const id = Math.random().toString(36).substring(2);
      const filePath = `companies/${this.contactInfoSelect.id}/${id}`;
      const fileRef = this.storage.ref(filePath);
      await this.storage.upload(filePath, file).snapshotChanges().toPromise();
      var downloadURL = await fileRef.getDownloadURL().toPromise();
      // var  = downloadURL;
      this.patientForm.controls.file.setValue(downloadURL);
      resolve(downloadURL);
      Swal.close();
    });
  }

  async updatePatient() {
    try {
      var form = this.patientForm.value
      await this.contactService.updatePatient(DiaryComponent.companyIdFocus, this.contactInfoSelect.id, form)
      await Swal.fire({
        title: 'Paciente actualizado',
        icon: 'success',
        showCancelButton: false,
        showConfirmButton: false,
        timer: 1200
      })
      this.ngOnInit()
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error inesperado, intentalo de nuevo mas tarde",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      });
    }
  }

}
