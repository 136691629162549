<div class="dialog-template">
  <div class="header-template">
    <h3>{{isEdit ? 'Editar': 'Nuevo'}} ejecutivo</h3>
  </div>
  <form [formGroup]="addForm">
    <div class=" form-group row">
      <div class="col-md-6">
        <label for="">Nombre</label>
        <input type="text" matInput fullWidth fieldSize="medium" formControlName="nombre" placeholder="Nombre">
      </div>
      <div class="col-md-6">
        <label for="">Tipo de usuario</label>
        <mat-select placeholder="Selecciona una opción" formControlName="tipo">
          <mat-option value="administrador">Administrador</mat-option>
          <mat-option value="operador">Operador</mat-option>
          <mat-option value="marketing">Vendedor</mat-option>
        </mat-select>
      </div>
    </div>
    <div class=" form-group row">
      <div class="col-md-6">
        <label for="">Correo electrónico</label>
        <input type="email" matInput fullWidth fieldSize="medium" formControlName="email"
          placeholder="Correo electrónico">
        <div *ngIf="addForm.get('email').invalid && (addForm.get('email').dirty || addForm.get('email').touched)"
          class="alert alert-danger">
          <div *ngIf="addForm.get('email').errors.email">
            Ingrese un correo electrónico valido
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label for="">Contraseña (min 6 caracteres)</label>
        <input type="text" matInput fullWidth fieldSize="medium" formControlName="password" placeholder="Contraseña">
        <div
          *ngIf="addForm.get('password').invalid && (addForm.get('password').dirty || addForm.get('password').touched)"
          class="alert alert-danger">
          <div *ngIf="addForm.get('password').errors.minlength">
            La contraseña debe tener al menos 6 caracteres
          </div>
        </div>
      </div>
    </div>
    <div class=" form-group row">
      <div class="col-md-6">
        <label for="">Extensión</label>
        <input type="number" matInput fullWidth fieldSize="medium" formControlName="extension"
          placeholder="Número de extensión">
      </div>
      <div class="col-md-6" *ngIf="isEdit">
        <label for="">Estado</label>
        <mat-select fullWidth placeholder="Selecciona una opción" formControlName="isActive">
          <mat-option [value]="true">Activo</mat-option>
          <mat-option [value]="false">Inactivo</mat-option>
        </mat-select>
      </div>
    </div>
  </form>
  <div class="buttons-footer">
    <button mat-button class="danger" (click)="ref.close()"><mat-icon>close</mat-icon> Cancelar</button>
    <button mat-button class="success" (click)="submit()"><mat-icon>save</mat-icon> Guardar</button>
  </div>
</div>
