<div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
<div class="row">
    <mat-tab-group mat-align-tabs="start">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">people</mat-icon>
                Clientes
            </ng-template>
            <app-clients-table [data_source]="data_source" [dataClients]="dataClients" [user]="userType"
                (eventInit)="ngOnInit()" (catchPaginator)="getPaginator($event)">
            </app-clients-table>
        </mat-tab>
        <mat-tab *ngIf="userType == 'administrador'">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">assignment</mat-icon>
                Tareas de clientes
            </ng-template>
            <app-tasks-table [data_source_4]="data_source_4" (eventInit)="ngOnInit()"></app-tasks-table>
        </mat-tab>
        <mat-tab *ngIf="userType == 'administrador'">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">loyalty</mat-icon>
                Planes
            </ng-template>
            <app-plans-table [data_source_3]="data_source_3" (eventInit)="ngOnInit()"></app-plans-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">list</mat-icon>
                Historial de Citas Registradas
            </ng-template>
            <app-dates-logs></app-dates-logs>
        </mat-tab>
    </mat-tab-group>
</div>