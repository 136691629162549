<nb-layout>
  <nb-layout-header fixed i *ngIf="session == true">
    <div class="desktop-header" style="margin: 0 auto;">
      <div class="row col-center">
        <div class="col-md-1">
          <a class="logo" href="#" routerLink="/dashboard">
            <img src="../assets/logo_circle.png" alt="Logo" width="50">
          </a>
        </div>
        <div class="col-md-9">
          <nb-actions size="medium" *ngIf="user?.tipo == 'administrador'">
            <nb-action>
              <button nbButton [routerLink]="['/dashboard']" class="submenu">Dashboard</button>
            </nb-action>
            <nb-action class="menu-reports">
              <button class="selector" mat-button mat-button [matMenuTriggerFor]="community">
                CLIENTES <mat-icon> keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #community="matMenu">
                <button mat-menu-item [routerLink]="['/clients']">Lista de Clientes</button>
                <button mat-menu-item [routerLink]="['/drive']">Gestor de archivos</button>
                <button mat-menu-item [routerLink]="['/analytics-clients']">Metricas de
                  Clientes</button>
                <button mat-menu-item [routerLink]="['/analytics']">Metricas de Prospectos</button>
              </mat-menu>
            </nb-action>
            <nb-action>
              <button nbButton [routerLink]="['/users']" class="submenu">Ejecutivos</button>
            </nb-action>
            <nb-action>
              <button nbButton [routerLink]="['/notificaciones']" class="submenu">Notificaciones</button>
            </nb-action>
            <nb-action class="menu-reports">
              <button class="selector" mat-button mat-button [matMenuTriggerFor]="report">
                REPORTES <mat-icon> keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #report="matMenu">
                <button mat-menu-item [routerLink]="['/reportes-generales']">Reporte general</button>
                <button mat-menu-item [routerLink]="['/reportes-llamadas']">Llamadas pérdidas</button>
                <button mat-menu-item [routerLink]="['/report-profibility']">Reporte rentabilidad de
                  clientes</button>
                <!-- <button mat-menu-item [routerLink]="['/reporte_interno']">Reporte interno</button> -->
                <button mat-menu-item [routerLink]="['/reporte-rentabilidad']">Reporte de
                  Rentabilidad</button>
                <button mat-menu-item [routerLink]="['/reporte-agentes']">Reporte de Agentes</button>
              </mat-menu>
            </nb-action>
          </nb-actions>

          <nb-actions size="medium" *ngIf="user?.tipo == 'operador'">
            <nb-action>
              <button nbButton [routerLink]="['/dashboard']" class="submenu">Dashboard</button>
            </nb-action>
            <nb-action>
              <button nbButton [routerLink]="['/clients']" class="submenu">Clientes</button>
            </nb-action>
            <nb-action>
              <button nbButton [routerLink]="['/reportes-llamadas']" class="submenu">Llamadas
                pérdidas</button>
            </nb-action>
            <nb-action>
              <button nbButton [routerLink]="['/reporte-agentes']" class="submenu">Reportes</button>
            </nb-action>
          </nb-actions>

          <nb-actions size="medium" *ngIf="user?.tipo == 'marketing'">
            <nb-action>
              <button nbButton [routerLink]="['/dashboard']" class="submenu">Dashboard</button>
            </nb-action>
            <nb-action>
              <button nbButton [routerLink]="['/analytics']" class="submenu">Metricas</button>
            </nb-action>
            <nb-action>
              <button nbButton [routerLink]="['/drive']" class="submenu">Gestor de archivos</button>
            </nb-action>
          </nb-actions>
        </div>
        <div class="col-md-2">
          <div class="user-action" [matMenuTriggerFor]="menu">
            <img [src]="user?.profileImg ? user?.profileImg : defaultImg" width="45" height="45">
            <span>{{user?.nombre + ' - '+ user?.extension}} </span>
          </div>
          <mat-menu #menu="matMenu">
            <ng-container *ngIf="user?.tipo == 'administrador'">
              <button mat-menu-item [routerLink]="['/marketing']">
                <span><mat-icon>assessment</mat-icon>
                  <span>Panel de ventas </span></span>
              </button>
            </ng-container>
            <button mat-menu-item *ngFor="let item of adminMenu" [routerLink]="['/'+item.data.id]">
              <span><mat-icon>{{item.icon}}</mat-icon>
                <span>{{item.title}} </span></span>
            </button>
            <button mat-menu-item (click)="logOut()">
              <span><mat-icon>exit_to_app</mat-icon>
                <span>Salir </span></span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="movil-header">
      <div class="left">
        <a class="logo" href="#" routerLink="/dashboard">
          <img src="../assets/logo_circle.png" alt="Logo" width="50">
        </a>
      </div>
      <div class="right">
        <button nbButton ghost [nbContextMenu]="menuMovilAdmin" nbContextMenuTrigger="click" style="float: right;"
          *ngIf="user?.tipo == 'administrador'">
          <nb-icon icon="menu-outline"></nb-icon>
        </button>
        <button nbButton ghost [nbContextMenu]="menuMovil" nbContextMenuTrigger="click" style="float: right;"
          *ngIf="user?.tipo != 'administrador'">
          <nb-icon icon="menu-outline"></nb-icon>
        </button>
      </div>
    </div>
  </nb-layout-header>
  <nb-layout-column *ngIf="router.url != '/incomming-call'">
    <router-outlet></router-outlet>
    <div class="whats-icon" (click)="toggle()"
      *ngIf="session && user?.tipo != 'marketing' && router.url != '/incomming-call'">
      <ng-container *ngIf="newMissingCall">
        <mat-icon class="bell-icon-new">notifications_active</mat-icon>
      </ng-container>
      <ng-container *ngIf="!newMissingCall">
        <mat-icon class="bell-icon">notifications</mat-icon>
      </ng-container>
      <p>Llamadas perdidas</p>
    </div>

    <div class="whats-icon-call" (click)="closeModal()" *ngIf="modalCall">
      <ng-container>
        <mat-icon class="bell-icon">phone_callback</mat-icon>
      </ng-container>
      <p>LLamada Entrante</p>
    </div>
  </nb-layout-column>

</nb-layout>
<div [ngClass]="{'close-wp': wpClose}" class="whats-menu">
  <div class="menu-title">
    <div class="close-button" (click)="toggle()">
      <mat-icon>close</mat-icon>
    </div>
    <p>Clientes con llamadas perdidas</p>
  </div>
  <div class="whats-list">
    <ng-container *ngIf="loader;">
      <div class="row justify-content-center">
        <div class="col-md-8 text-center">
          <div class="loader loader--style1" title="0">
            <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40"
              enable-background="new 0 0 40 40" xml:space="preserve">
              <path opacity="0.2" fill="#000"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                          s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                          c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
              <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                          C22.32,8.481,24.301,9.057,26.013,10.047z">
                <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20"
                  to="360 20 20" dur="0.5s" repeatCount="indefinite" />
              </path>
            </svg>
          </div>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!loader">
      <ng-container *ngFor="let client of missingCalls">
        <div class="whats-card">
          <div class="card-info">
            <h3>{{client.client}}</h3>
            <p class="info-rol">Llamadas perdidas: {{client.calls}} </p>
          </div>
          <div class="card-icon" [routerLink]="['/reportes-llamadas']" (click)="toggle()">
            <mat-icon>phone_enabled</mat-icon> Atender
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="missingCalls.length == 0">
        <div class="no-calls">
          <p>Sin llamadas perdidas</p>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div [ngClass]="{'close-wp': !closeModalCall}" class="whats-menu-call">
  <div class="whats-list-call" *ngFor=" let call of currrentIncomingCall">
    <div class="row header-info">
      <div class="col-md-7">
        <h3>{{callerCompany?.nombre}}</h3>
        <p>Etiqueta: <strong>{{callerCompany.tag}}</strong> </p>
      </div>
      <div class="col-md-4">
        <div class="text-center img-client-div">
          <img [src]="callerCompany?.profileImg" class="img-responsive">
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="col-md-12 caller-container-active" *ngIf="clientLoader == false && callerIdentified == true">
        <h6>Cliente identificado</h6>
        <p>Nombre: <strong>{{currentCustomerCaller.nombre}}</strong></p>
        <p>Número: <strong>{{currentCustomerCaller.telefono}}</strong></p>
        <p>Comentarios: <strong>{{currentCustomerCaller.comments}}</strong></p>
      </div>
      <div class="col-md-12 caller-container-active" *ngIf="clientLoader == false && callerIdentified == false">
        <h6>¡Nuevo cliente!</h6>
        <p>Número: <strong>{{currentCustomerCaller.telefono}}</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button nbButton status="danger" shape="round" (click)="cancelCall(call)" class="mr-5">
          <nb-icon icon="phone-off-outline" style="height: 2.2rem; width: 2.2rem;"></nb-icon>
        </button>
        <button nbButton status="success" shape="round" (click)="takePhoneCall(call)">
          <nb-icon icon="phone-call-outline" style="height: 2.2rem; width: 2.2rem;"></nb-icon>
        </button>
      </div>
    </div>
  </div>

</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
