import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ReporteRentabilidadModalComponent } from './reporte-rentabilidad-modal/reporte-rentabilidad-modal.component';
import { ReporteService } from '../../services/reportes/reporte.service';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-reporte-rentabilidad',
  templateUrl: './reporte-rentabilidad.component.html',
  styleUrls: ['./reporte-rentabilidad.component.scss']
})
export class ReporteRentabilidadComponent implements OnInit {

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: true,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Generar reporte</span>",
    },
    edit: {
      editButtonContent: '<span>Ver detalles</span>',
    },
    delete: {
      deleteButtonContent: '<span>Eliminar</span>',
    },
    columns: {
      report: {
        title: 'Reporte',
        filter: false,
      },
      date: {
        title: 'Fecha de creación',
        filter: false
      },
    },
    mode: 'external',
    noDataMessage: 'No hay reportes por ahora'
  };
  data_source = [];

  constructor(
    private router: Router,
    private dialogService: MatDialog,
    private reportService: ReporteService
  ) { }

  async ngOnInit() {
    var reports = await this.reportService.getProfitabilityReport();
    reports.map(data => {
      data.date = formatDate(data.dateTime, 'dd-MM-yyyy', 'es-MX');
    })
    this.data_source = reports
  }

  openDetails(data) {
    var reportID = data.data._id;
    this.router.navigate(['/reporte-rentabilidad-details/' + reportID])
  }
  createNew() {
    this.dialogService.open(ReporteRentabilidadModalComponent, { height: "50vh" });
  }

  async delete(data) {
    var reportID = data.data._id;
    var result = await Swal.fire({
      title: '¿Seguro que desea eliminar el reporte?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    })
    if (result.isConfirmed) {
      try {
        this.reportService.deleteProfitabilityReport(reportID);
        await Swal.fire({
          title: 'Eliminado!',
          text: 'El reporte ha sido borrado.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        }
        )
        this.ngOnInit()
      } catch (error) {
        await Swal.fire({
          title: 'Ocurrrió un error inesperado',
          icon: 'error',
          text: "Si el problema persiste, favor de reportar el error",
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar'
        })
      }
    }
  }

}
