import { Component, OnInit } from "@angular/core";
import { CompanyService } from "../../services/company/company.service";
import { ContractsService } from "../../services/contracts/contracts.service";
import Swal from "sweetalert2";
import { PlanService } from 'src/app/services/planService/plan.service';
import { ServiceMinutesService } from "src/app/services/service-minutes/service-minutes.service";
import { Router } from "@angular/router";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { Plan } from "src/app/models/plan";
import { ProspectsService } from "src/app/services/prospects/prospects.service";

@Component({
  selector: "app-companies",
  templateUrl: "./companies.component.html",
  styleUrls: ["./companies.component.scss"],
})
export class CompaniesComponent implements OnInit {

  public data_source = [];
  public data_source_3 = [];
  public data_source_4 = [];
  public inactiveClients = [];
  public dataClients: MatTableDataSource<any>;
  public displayColumns: string[] = ['icon', 'name', 'owner', 'agent', 'phone', 'email', 'plan', 'actions'];
  public paginator: MatPaginator;
  public loader = false;
  public userType: string;
  public actualPlans: Plan[] = [];
  private contractClients = []
  private prospectList = []

  constructor(
    private companyService: CompanyService,
    private planService: PlanService,
    private taskService: ServiceMinutesService,
    private router: Router,
    private contractServices: ContractsService,
    private prospectService: ProspectsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  async ngOnInit() {
    this.loader = true;
    this.actualPlans = await this.getPlans()
    this.prospectList = await this.getProspectInfo()
    this.userType = localStorage.getItem("currentUser")
    this.contractClients = await this.getContracts()
    var response = await this.getAllCompanies();
    this.data_source = response.filter((companyInfo) => companyInfo.activeInDashboard == true)
    this.inactiveClients = response.filter((companyInfo) => companyInfo.activeInDashboard != true)
    this.dataClients = new MatTableDataSource(this.inactiveClients);
    this.data_source_3 = this.actualPlans;
    this.data_source_4 = await this.getTask()
    setTimeout(() => {
      this.dataClients.paginator = this.paginator;
    }, 500);
    this.loader = false
  }

  async getPlans() {
    try {
      var response = await this.planService.getPlans();
      response.data.map(async (data: any) => {
        data.precio = "$ " + data.precio
      })
      return response.data
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al obtener los planes",
        icon: 'error'
      })
    }
  }

  async getContracts() {
    try {
      var response = await this.contractServices.get()
      return response.data
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al obtener los planes",
        text: "En caso de persistir el error, favor de comunicarlo",
        icon: 'error'
      })
    }
  }

  async getProspectInfo() {
    try {
      var response = await this.prospectService.getAllCompleteProspects()
      return response.data
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al obtener los planes",
        text: "En caso de persistir el error, favor de comunicarlo",
        icon: 'error'
      })
    }
  }

  async getAllCompanies() {
    try {
      var companies = await this.prospectService.getAllCompleteProspects()
      companies.data.map(async (data: any) => {
        if (data.profileImg) {
          data.thumbnail =
            "<img src='" + data.profileImg + "' class='imgTable'>";
        } else {
          data.thumbnail =
            "<img src='https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/logo-default.png?alt=media&token=31c837f0-867c-431f-8778-4b45ba65f926' class='imgTable'>";
        }
        var userPlan = this.actualPlans.filter(Planes => Planes.title == data.plan)[0];
        if (userPlan != undefined) {
          if (data.planPrice != undefined && data.planPrice != 0) {
            data.plan = userPlan.title + " ($ " + data.planPrice + ")"
          } else {
            data.plan = userPlan.title + " (" + userPlan.precio + ")"
          }
        }
        var contract = this.contractClients.filter((contract) => contract.clientId == data.uid)[0]
        if (contract) {
          data['status'] = contract.status == 1 ? "Pagado" : contract.status == 2 ? "Pendiente por pagar" : "Pago atrasado"
        } else {
          console.log(data.nombre);
        }
      })
      return companies.data.sort((a, b) => a.nombre > b.nombre ? 1 : -1)
    } catch (error) {
      this.loader = true;
      Swal.fire({
        title: "Ocurrió un error al obtener los clientes",
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  getPaginator(element: MatPaginator) {
    this.paginator = element;
  }

  async getTask() {
    try {
      var response = await this.taskService.getTask();
      response.map(taskInfo => {
        if (taskInfo.minutes == 1) {
          taskInfo["time"] = taskInfo.minutes + " minuto"
        }
        else {
          taskInfo["time"] = taskInfo.minutes + " minutos"
        }
      })
      return response
    } catch (error) {
      Swal.fire({
        title: "Ocurrió un error al obtener las tareas",
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }
}
