<div class="card-count">
    <nb-card [ngClass]="{'active-graphs': selectType == 1}" (click)="changeFilter(1)">
        <nb-card-body>
            <div class="card-content">
                <div>
                    <h4>Nuevos prospectos</h4>
                    <h4><strong>Total: </strong> {{countProspect}} </h4>
                </div>
                <mat-icon class="success-icon">fiber_new</mat-icon>
            </div>
        </nb-card-body>
    </nb-card>
    <nb-card [ngClass]="{'active-graphs': selectType == 2}" (click)="changeFilter(2)">
        <nb-card-body>
            <div class="card-content">
                <div>
                    <h4>Prospectos completados</h4>
                    <h4><strong>Total: </strong> {{countComplete}} </h4>
                </div>
                <mat-icon class="success-icon">check_circle</mat-icon>
            </div>
        </nb-card-body>
    </nb-card>
    <nb-card [ngClass]="{'active-graphs': selectType == 3}" (click)="changeFilter(3)">
        <nb-card-body>
            <div class="card-content">
                <div>
                    <h4>Prospectos inactivos</h4>
                    <h4><strong>Total: </strong> {{countCancel}} </h4>
                </div>
                <mat-icon class="success-icon">cancel_presentation</mat-icon>
            </div>
        </nb-card-body>
    </nb-card>
</div>
<div class="header-graph">
    <button mat-button (click)="downloadExcel()"><mat-icon>insert_chart_outlined</mat-icon> Importar a Excel</button>
</div>
<div class="row" id="firstGraph">
    <div class="col-md-4">
        <nb-card>
            <nb-card-header>
                <h5>Ejecutivos</h5>
            </nb-card-header>
            <div *ngIf="showData">
                <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                    [legend]="barChartLegend" [chartType]="barChartType" [plugins]="pluginBar">
                </canvas>
            </div>
        </nb-card>
    </div>
    <div class="col-md-4">
        <nb-card>
            <nb-card-header>
                <h5>Giro comercial </h5>
            </nb-card-header>
            <div *ngIf="showData">
                <canvas baseChart [datasets]="comercialGraphData" [labels]="barComercialLabels"
                    [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
                </canvas>
            </div>
        </nb-card>
    </div>
    <div class="col-md-4">
        <nb-card>
            <nb-card-header>
                <h5>Ciudad</h5>
            </nb-card-header>
            <div *ngIf="showData">
                <canvas baseChart [datasets]="stateGraphData" [labels]="barStateLabels" [options]="barChartOptions"
                    [legend]="barChartLegend" [chartType]="barChartType">
                </canvas>
            </div>
        </nb-card>
    </div>
</div>

<div class="row" id="secondGraphs">
    <div class="col-md-4">
        <nb-card>
            <nb-card-header>
                <h5>Origen </h5>
            </nb-card-header>
            <div *ngIf="showData">
                <canvas baseChart [datasets]="originGraphData" [labels]="barOriginLabels" [options]="barChartOptions"
                    [legend]="barChartLegend" [chartType]="barChartType">
                </canvas>
            </div>
        </nb-card>
    </div>
    <div class="col-md-4">
        <nb-card>
            <nb-card-header>
                <h5>Plan </h5>
            </nb-card-header>
            <div *ngIf="showData">
                <canvas baseChart [datasets]="planGraphData" [labels]="planLabels" [options]="barChartOptions"
                    [legend]="barChartLegend" [chartType]="barChartType">
                </canvas>
            </div>
        </nb-card>
    </div>
    <div class="col-md-4">
        <nb-card>
            <nb-card-header>
                <h5>Promoción</h5>
            </nb-card-header>
            <div *ngIf="showData">
                <canvas baseChart [datasets]="promotionGraphData" [labels]="barPromotionLabels"
                    [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
                </canvas>
            </div>
        </nb-card>
    </div>
</div>