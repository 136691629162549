import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  private commonURI = environment.URL_API + "/zoom/"
  constructor(
    private http: HttpClient
  ) { }

  async createZoom(formValue: any) {
    try {
      const response = await this.http.post<any>(this.commonURI, { formValue }).toPromise()
      return response.code
    } catch (error) {
      throw error
    }
  }

  async findMeetOfUser(id: string) {
    try {
      const response = await this.http.get<any>(this.commonURI + "user/" + id).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async update(id: string, data: any) {
    try {
      const response = await this.http.put<any>(this.commonURI + id, { formValue: data }).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }
}
