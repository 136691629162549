<div class="dialog-template" *ngIf="!loader">
    <div class="header-template">
        <h4>Generar reporte</h4>
    </div>
    <form [formGroup]="addForm">
        <div class=" form-group row">
            <div class="col-md-12">
                <label for="">Cliente</label>
                <input type="text" placeholder="Seleccione una opción o escriba un nombre" matInput
                    formControlName="client" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.nombre.toUpperCase()}}
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
        <div class=" form-group row">
            <div class="col-md-6">
                <mat-slide-toggle formControlName="hasCalls">Con reporte de llamadas</mat-slide-toggle>
            </div>
            <div class="col-md-6">
                <mat-slide-toggle formControlName="hasSchedule">Con reporte de citas</mat-slide-toggle>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <label for="">Fecha inicial</label>
                <input matInput placeholder="Seleccione una fecha" [matDatepicker]="startDate"
                    formControlName="start_date" (click)="startDate.open()"
                    (dateChange)="onSelectedStartDate($event.value)" placeholder="dd/mm/yyyy">
                <mat-datepicker #startDate></mat-datepicker>
            </div>
            <div class="col-md-6">
                <label for="">Fecha final</label>
                <input matInput placeholder="Seleccione una fecha" [matDatepicker]="endDate" formControlName="end_date"
                    (click)="endDate.open()" placeholder="dd/mm/yyyy" [min]="minDate">
                <mat-datepicker #endDate></mat-datepicker>
            </div>
        </div>
    </form>
    <div class="buttons-footer">
        <button class="mr-5" mat-button class="danger" (click)="ref.close()"><mat-icon>close</mat-icon>
            Cancelar</button>
        <button mat-button class="success" (click)="validateForm()" [disabled]="loader"><mat-icon>save</mat-icon>
            Crear</button>
    </div>
</div>
<div class="dialog-template" *ngIf="loader" style="justify-content: center;">
    <div>
        <h3>Cargando reporte...</h3>
        <p>Por favor espere, esto tomara algo de tiempo. No cierre la ventana.</p>
    </div>
    <div class="loader loader--style1" title="0">
        <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40"
            xml:space="preserve">
            <path opacity="0.2" fill="#000"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
              s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
              c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
            <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
              C22.32,8.481,24.301,9.057,26.013,10.047z">
                <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20"
                    to="360 20 20" dur="0.5s" repeatCount="indefinite" />
            </path>
        </svg>
    </div>
</div>