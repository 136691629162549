<div>
    <h3>Editar columnas a mostrar en la tabla</h3>
    <div>
        <form [formGroup]="rowViewForm">
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Columna</th>
                            <th>Visibilidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="form-field" *ngFor="let row of displayColumns">
                            <td>
                                <mat-label>{{row.diplayLabel}} </mat-label>
                            </td>
                            <td>
                                <mat-slide-toggle
                                    [formControlName]="row.controlName">{{rowViewForm.controls[row.controlName].value ?
                                    'Visible': 'Oculto' }}
                                </mat-slide-toggle>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="actions-btn">
                <button mat-button (click)="dialogRef.close()" style="background: red;"><mat-icon>close</mat-icon>
                    Cerrar</button>
                <button mat-button (click)="submit()" style="background: green;"><mat-icon>save</mat-icon>
                    Guardar</button>
            </div>
        </form>
    </div>
</div>