import { Event } from './model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventsService {

  private URI = `${environment.URL_API}`;
  public eventsList: Event[] = []
  constructor(private httpService: HttpClient) { }

  async setEventsOfClients(events: Event[]) {
    this.eventsList = events
  }

  async getEventsOfClients() {
    return this.eventsList
  }

  async getEvents(clientId: string): Promise<Event[]> {
    try {
      const response = await this.httpService
        .get<{ code: number; data: Array<any>; message: string; size: number; }>(this.URI + `/calendar/event/meeting/${clientId}`).toPromise();
      return response.data;

    } catch (error) {
      throw error;
    }
  }

  async getEventsToday(): Promise<Event[]> {
    try {
      const response = await this.httpService
        .get<{ code: number; data: Array<Event>; message: string; size: number; }>(this.URI + `/calendar/event/get-today`).toPromise();
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createEvent(events: any, clietId: string) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string }>(this.URI + '/calendar/event/meeting', { events: events, clientId: clietId })
        .toPromise();

      return true;
    } catch (error) {
      throw error;
    }
  }

  async createEventMeet(events: any) {
    try {
      await this.httpService.post<{ code: number; message: string }>(this.URI + '/calendar/event/create-meet', { eventData: events }).toPromise();
      return true;
    } catch (error) {
      throw error;
    }
  }

  async blockEvent(events: Event, clientId: string) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string }>(this.URI + '/calendar/event/block', { eventId: events._id, clientId: clientId })
        .toPromise();

      return true;
    } catch (error) {
      throw error;
    }
  }

  async deleteEvent(events: Event, clientId: string) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string }>(this.URI + '/calendar/event/delete', { eventId: events._id, clientId: clientId })
        .toPromise();
      console.log(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  async unblockEvent(events: Event, clientId: string) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string }>(this.URI + '/calendar/event/unblock', { eventId: events._id, clientId: clientId })
        .toPromise();

      return true;
    } catch (error) {
      throw error;
    }
  }

  async blockEventByRange(clientId: string, startDate: string, endDate: string) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string }>(this.URI + '/calendar/event/block/range', { clientId: clientId, startDate: startDate, endDate: endDate })
        .toPromise();
      return true;
    } catch (error) {
      throw error;
    }
  }

  async cancelEvent(events: Event, clientId: string) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string }>(this.URI + '/calendar/event/cancel', { eventId: events, clientId: clientId })
        .toPromise();

      return true;
    } catch (error) {
      throw error;
    }
  }

  async addSpaces(events: any) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string, data: any }>(this.URI + '/calendar/event', events)
        .toPromise();
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSpaces(events: any) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string }>(this.URI + '/calendar/event/delete/range', events)
        .toPromise();
      return true;
    } catch (error) {
      throw error;
    }
  }

  async confirmEvent(eventId: string, clientId: string) {
    try {
      const response = await this.httpService
        .post<{ code: number; message: string }>(this.URI + '/calendar/event/confirm', { clientId: clientId, eventId: eventId })
        .toPromise();
      return true;
    } catch (error) {
      throw error;
    }
  }

  async changeStatus(status: string, eventId: string) {
    try {
      await this.httpService.put<{ code: number, message: string }>(this.URI + "/calendar/event/change-status", { status, eventId }).toPromise()
      return true
    } catch (error) {
      throw error
    }
  }

  async changeColor(status: any, eventId: string) {
    try {
      await this.httpService.put<{ code: number, message: string }>(this.URI + `/calendar/event/${eventId}/change-color`, { data: status }).toPromise()
      return true
    } catch (error) {
      throw error
    }
  }
}
