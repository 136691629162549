import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/users/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserData } from 'src/app/models/user';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditUserComponent implements OnInit {

  public addForm: FormGroup;
  public isEdit = false;
  constructor(
    public ref: MatDialogRef<EditUserComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public dataRef: UserData
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      tipo: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      email: ['', [Validators.required, Validators.email]],
      extension: [''],
      isActive: [""]
    });
    if (this.dataRef) {
      this.addForm.patchValue(this.dataRef)
      this.isEdit = true
    }
  }

  async submit() {
    try {
      if (this.addForm.invalid) {
        this.addForm.markAllAsTouched()
        Swal.fire({
          title: 'Favor de llenar los campos requeridos',
          icon: 'error'
        })
        return
      }
      await Swal.fire({
        title: 'Guardando...',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading()
        }
      })
      const formValue = this.addForm.value
      if (this.isEdit) {
        await this.userService.updateUser(this.dataRef._id, formValue)
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Datos actualizados',
          showConfirmButton: false,
          timer: 1000
        })
      } else {
        formValue.nombre = formValue.nombre.trim()
        await this.userService.addNewUser(formValue)
        await Swal.fire({
          icon: 'success',
          title: 'Datos guardados',
          showConfirmButton: false,
          timer: 1000
        })
      }
      this.ref.close(true);
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error inesperado al actualizar el usuario',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

}
