import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { AddFilesComponent } from 'src/app/components/marketing/add-files/add-files.component';
import { Prospect } from 'src/app/models/prospects';
import { QuotationService } from 'src/app/services/cotizaciones/quotation.service';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-drive-view',
  templateUrl: './drive-view.component.html',
  styleUrls: ['./drive-view.component.scss']
})
export class DriveViewComponent implements OnInit {

  public companyData: Prospect
  public prospectFile: any[]
  public loader: boolean = false
  constructor(
    private prosepctService: ProspectsService,
    private activeLink: ActivatedRoute,
    private dialog: MatDialog,
    private fileService: QuotationService
  ) { }

  async ngOnInit() {
    this.loader = true
    this.getInitData()
    this.loader = false
  }

  async getInitData() {
    let prospectId = await this.activeLink.snapshot.params['id']
    this.companyData = await this.getProspectInfo(prospectId);
    this.prospectFile = await this.getFilesOfProspect(this.companyData)
  }

  async getProspectInfo(prospectId: string) {
    try {
      var response = await this.prosepctService.getProspectById(prospectId)
      if (response.fileAgreement == undefined) {
        response.fileAgreement = { url: "", extension: "" }
      }
      if (response.fileContract == undefined) {
        response.fileContract = { url: "", extension: "" }
      }
      if (response.fileFiscal == undefined) {
        response.fileFiscal = { url: "", extension: "" }
      }
      if (response.fileInvoice == undefined) {
        response.fileInvoice = { url: "", extension: "" }
      }
      if (response.filePay == undefined) {
        response.filePay = { url: "", extension: "" }
      }
      return response
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al consultar la información del prospecto',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getFilesOfProspect(companyData: Prospect) {
    try {
      var data = [
        { fileName: "Contrato", url: companyData.fileContract.url, extension: companyData.fileContract.extension },
        { fileName: "Cedula fiscal de pago", url: companyData.fileFiscal.url || "", extension: companyData.fileFiscal.extension || "" },
        { fileName: "Factura", url: companyData.fileInvoice.url, extension: companyData.fileInvoice.extension },
        { fileName: "Convenio de confidencialidad", url: companyData.fileAgreement.url, extension: companyData.fileAgreement.extension },
        { fileName: "Comprobante de pago", url: companyData.filePay.url || "", extension: companyData.filePay.extension || "" },
      ]
      if (companyData.driveFiles != undefined) {
        for await (const fileUrl of companyData.driveFiles) {
          data.push({ fileName: fileUrl.title, url: fileUrl.url, extension: fileUrl.extension })
        }
      }
      return data
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al consultar los documentos del prospecto',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async deleteFile(fileInfo: any) {
    var fileType = ""
    switch (fileInfo.fileName) {
      case "Contrato":
        fileType = "fileContract"
        break;
      case "Cedula fiscal de pago":
        fileType = "fileFiscal"
        break;
      case "Factura":
        fileType = "fileInvoice"
        break;
      case "Convenio de confidencialidad":
        fileType = "fileAgreement"
        break;
      case "Comprobante de pago":
        fileType = "filePay"
        break;
      default:
        fileType = fileInfo.fileName
        break;
    }
    try {
      var result = await Swal.fire({
        title: "¿Desea eliminar este elemento?",
        text: "Esta acción no se puede revertir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
      })
      if (result.isConfirmed) {
        await this.prosepctService.deleteFileOfProspect(this.companyData._id, fileInfo.url, fileType)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Información eliminada",
          showConfirmButton: false,
          timer: 1000,
        });
        this.getInitData()
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al descargar el documento',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async download(file: any) {
    try {
      await this.fileService.downloadFile(file)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al eliminar el documento',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async viewFile(file: string) {
    try {
      window.open(file)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error al abrir el documento',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async addFiles(fileType?: string) {
    try {
      var result = await this.dialog.open(AddFilesComponent, { data: { title: fileType }, height: '60vh' }).beforeClosed().toPromise()
      if (result != undefined) {
        switch (result.title) {
          case "Contrato":
            result.title = "fileContract"
            await this.prosepctService.updateFileInProspect(this.companyData._id, result)
            break;
          case "Cedula fiscal de pago":
            result.title = "fileFiscal"
            await this.prosepctService.updateFileInProspect(this.companyData._id, result)
            break;
          case "Factura":
            result.title = "fileInvoice"
            await this.prosepctService.updateFileInProspect(this.companyData._id, result)
            break;
          case "Convenio de confidencialidad":
            result.title = "fileAgreement"
            await this.prosepctService.updateFileInProspect(this.companyData._id, result)
            break;
          case "Comprobante de pago":
            result.title = "filePay"
            await this.prosepctService.updateFileInProspect(this.companyData._id, result)
            break;
          default:
            await this.prosepctService.addFileInProspect(this.companyData._id, result)
            break;
        }
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Información guardada",
          showConfirmButton: false,
          timer: 1000,
        });
        this.getInitData()
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error en algun lado.',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

}
