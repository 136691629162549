import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dates-logs',
  templateUrl: './dates-logs.component.html',
  styleUrls: ['./dates-logs.component.scss']
})
export class DatesLogsComponent implements OnInit {

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    columns: {
      client: {
        title: 'Cliente',
      },
      eventDate: {
        title: 'Cita',
      },
      created: {
        title: 'Fecha de creacion',
      },
      comments: {
        title: 'Comentarios',
      },
    },
    mode: 'external',
    noDataMessage: 'No hay registros por ahora'
  };
  public data_source = [];
  public loader: boolean = false

  constructor(
    private companyService: CompanyService
  ) { }

  async ngOnInit() {
    this.loader = true
    this.data_source = await this.getDateLogs()
    this.loader = false
  }

  async getDateLogs() {
    try {
      var response = await this.companyService.getLogsDates();
      response.map((logData) => {
        if (logData.eventDate != undefined) {
          logData.eventDate = formatDate(logData.eventDate.seconds * 1000, 'dd-MM-yyyy hh:mm a', 'es-MX')
        }
        logData.created = formatDate(logData.created.seconds * 1000, 'dd-MM-yyyy hh:mm a', 'es-MX')
      })
      return response
    } catch (error) {
      this.loader = false
      Swal.fire({
        title: 'Ocurrió un problema al consular las citas',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

}
