<section id="general-report">
    <div class="container">
        <div class="card">
            <div class="header-buttons">
                <button mat-button class="info"
                    (click)="generateWord('print-pdf-report')"><mat-icon>insert_drive_file</mat-icon>
                    Exportar como Word</button>
                <button mat-button class="danger" (click)="viewPDF()"><mat-icon>picture_as_pdf</mat-icon> Exportar
                    como PDF </button>
                <button class="r-btn" mat-button class="success" (click)="exportToExcel()"><mat-icon>
                        insert_drive_file</mat-icon> Descargar Excel</button>
            </div>
            <div class="header-section">
                <div class="col-md-3">
                    <img src="assets/logo_circle.png" style="max-width: 200px;" alt="">
                </div>
                <div class="col-md-9 info-links">
                    <a class="link" href="www.miasistente.net">www.miasistente.net</a>
                    <a href="mailto:ventas@miasistente.com">ventas&#64;miasistente.com</a>
                    <a href="tel:8002880268">8002880268</a>
                    <span>Cancún Quintana Roo México / Oficinas Centrales</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 report">
                    {{generalReport?.report}}
                </div>
                <div class="col-md-12 company">
                    {{generalReport?.clientName}}
                </div>

                <!-- hidden -->
                <table class="table table-striped" id="tabla-socios">
                    <thead>
                        <tr>
                            <th scope="col" colspan="3">Tareas</th>
                            <th scope="col" colspan="1">Cantidad</th>
                            <th scope="col" colspan="1">Minutos de actividad</th>
                            <th scope="col" colspan="1">Tiempo estimado</th>
                            <th>Tiempo final realizado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of generalReport?.socialMediaEntries">
                            <tr *ngIf="item.quantity > 0">
                                <td colspan="3">{{item.title}}</td>
                                <td colspan="1">{{item.totalAll}}</td>
                                <td colspan="1">{{item.minutes}}</td>
                                <td colspan="1">{{item.totalMinutes}}</td>
                                <td>{{item.realTotalMinutes}} </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td colspan="5">Total de minutos</td>
                            <td colspan="1">{{globalMinutes}}</td>
                            <td>{{realTime}} </td>
                        </tr>
                        <tr class="row-blue">
                            <td colspan="5"><strong>Horas laboradas</strong></td>
                            <td colspan="1"><strong>{{(globalMinutes/60) | number:"1.2-2"}}</strong></td>
                            <td colspan="1"><strong>{{(realTime/60) | number:"1.2-2"}}</strong></td>
                        </tr>
                        <tr class="row-blue space"></tr>
                        <tr class="row-blue">
                            <td colspan="3">Total de llamadas recibidas</td>
                            <td colspan="1">{{generalReport?.call_report.total_answeredEntriesCalls}}</td>
                            <td colspan="1"></td>
                            <td colspan="1">{{ generalReport?.call_report.total_TimeEntriesCalls }}</td>
                            <td></td>
                        </tr>
                        <tr class="row-blue">
                            <td colspan="7">
                                <mat-expansion-panel [expanded]="false" class="row-blue">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div class="header-call-expand">
                                                <h4>Total de llamadas realizadas</h4>
                                                <p>{{generalReport?.call_report.total_answeredSalientesCalls}}</p>
                                                <p></p>
                                                <p>{{ generalReport?.call_report.total_TimeSalientesCalls }}</p>
                                                <p></p>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="callInfoRegister">
                                        <p>Llamadas con tiempo menor a 20"</p>
                                        <p>{{callsSalientesLess.length}} </p>
                                        <p></p>
                                        <p>{{timeTotalOutgoinCallLess}} </p>
                                    </div>
                                    <div class="callInfoRegister">
                                        <p>Llamadas con tiempo mayor a 20"</p>
                                        <p>{{callsSalientesMore.length}} </p>
                                        <p></p>
                                        <p>{{timeTotalOutgoinCallMore}} </p>
                                    </div>
                                </mat-expansion-panel>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3"><strong>Total llamadas</strong></td>
                            <td><strong>{{generalReport?.call_report.total_answeredEntriesCalls +
                                    generalReport?.call_report.total_answeredSalientesCalls}} </strong></td>
                            <td></td>
                            <td><strong>{{ totalTimeCalls}}</strong> </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <mat-expansion-panel class="logs" [expanded]="true">
                    <mat-expansion-panel-header>
                        <h3>Registro de actividades realizadas</h3>
                    </mat-expansion-panel-header>
                    <table style="width: 100%;">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Acción</th>
                                <th>Total de acciones</th>
                                <th>Comentarios</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of generalReport?.activities">
                                <td>{{item.date.toDate() | date: 'fullDate'}}</td>
                                <td>{{item.text}}</td>
                                <td style="text-align: center;">{{item.totalActions}}</td>
                                <td>{{item.comments}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-expansion-panel>
                <div class="col-md-12">
                    <br>
                </div>
                <div class="col-md-6" *ngIf="generalReport?.hasCalls">
                    <mat-expansion-panel class="calls-entries" [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h3>Registro de llamadas entrantes</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <table>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Destino</th>
                                    <th>Duración</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let call of callsEntries">
                                    <td>{{call.calldate}}</td>
                                    <td>{{call.callerId}}</td>
                                    <td>{{call.duration}}s ({{call.minutes}})</td>
                                </tr>
                                <ng-container *ngIf="callsEntries.length == 0">
                                    <tr>
                                        <td colspan="3" style="text-align: center;">Sin registro de llamadas</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </mat-expansion-panel>
                    <ng-container
                        *ngIf="generalReport.additionalCalls != null || generalReport.additionalCalls != undefined">
                        <ng-container *ngFor="let additionalNumber of generalReport.additionalCalls">
                            <mat-expansion-panel class="calls-entries">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h3>Registro de llamadas entrantes( {{additionalNumber.number}})</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Destino</th>
                                            <th>Duración</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let call of additionalNumber.calls">
                                            <td>{{call.calldate}}</td>
                                            <td>{{call.did}}</td>
                                            <td>{{call.duration}}s ({{call.minutes}})</td>
                                        </tr>
                                        <ng-container *ngIf="additionalNumber.calls.length == 0">
                                            <tr>
                                                <td colspan="3" style="text-align: center;">Sin registro de llamadas
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </mat-expansion-panel>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-md-6 calls" *ngIf="generalReport?.hasCalls">
                    <mat-expansion-panel class="calls-out" [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h3>Registro de llamadas salientes</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-expansion-panel class="header" [expanded]="true">
                            <mat-expansion-panel-header>
                                <p>Llamadas con tiempo menor a 20"</p>
                            </mat-expansion-panel-header>
                            <table style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Destino</th>
                                        <th>Duración</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let call of callsSalientesLess">
                                        <td>{{call.calldate}}</td>
                                        <td>{{call.destino.slice(4)}}</td>
                                        <td>{{call.duration}}s ({{call.minutes}})</td>
                                        <td>{{call.state == "ANSWERED" ? 'Respondida' : 'Perdida'}} </td>
                                    </tr>
                                    <ng-container *ngIf="callsSalientesLess.length == 0">
                                        <tr>
                                            <td colspan="4" style="text-align: center;">Sin registro de llamadas</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </mat-expansion-panel>
                        <mat-expansion-panel class="header">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p>Llamadas con tiempo mayor a 20"</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <table style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Destino</th>
                                        <th>Duración</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let call of callsSalientesMore">
                                        <td>{{call.calldate}}</td>
                                        <td>{{call.destino.slice(4)}}</td>
                                        <td>{{call.duration}}s ({{call.minutes}})</td>
                                        <td>{{call.state == "ANSWERED" ? 'Respondida' : 'Perdida'}} </td>
                                    </tr>
                                    <ng-container *ngIf="callsSalientesMore.length == 0">
                                        <tr>
                                            <td colspan="4" style="text-align: center;">Sin registro de llamadas</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </mat-expansion-panel>
                    </mat-expansion-panel>
                    <ng-container *ngIf="generalReport?.outGoingCallPrefix">
                        <ng-container *ngFor="let prefixData of generalReport?.outGoingCallPrefix">
                            <mat-expansion-panel class="calls-out" [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h3>Registro de llamadas salientes( {{prefixData.prefix}} )</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-expansion-panel class="header" [expanded]="true">
                                    <mat-expansion-panel-header>
                                        <p>Llamadas con tiempo menor a 20"</p>
                                    </mat-expansion-panel-header>
                                    <table style="width: 100%;">
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Destino</th>
                                                <th>Duración</th>
                                                <th>Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let call of prefixData.callLess">
                                                <td>{{call.calldate}}</td>
                                                <td>{{call.destino.slice(4)}}</td>
                                                <td>{{call.duration}}s ({{call.minutes}})</td>
                                                <td>{{call.state == "ANSWERED" ? 'Respondida' : 'Perdida'}} </td>
                                            </tr>
                                            <ng-container *ngIf="prefixData.callLess.length == 0">
                                                <tr>
                                                    <td colspan="4" style="text-align: center;">Sin registro de llamadas
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </mat-expansion-panel>
                                <mat-expansion-panel class="header">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <p>Llamadas con tiempo mayor a 20"</p>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <table style="width: 100%;">
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Destino</th>
                                                <th>Duración</th>
                                                <th>Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let call of prefixData.callMore">
                                                <td>{{call.calldate}}</td>
                                                <td>{{call.destino.slice(4)}}</td>
                                                <td>{{call.duration}}s ({{call.minutes}})</td>
                                                <td>{{call.state == "ANSWERED" ? 'Respondida' : 'Perdida'}} </td>
                                            </tr>
                                            <ng-container *ngIf="prefixData.callMore.length == 0">
                                                <tr>
                                                    <td colspan="4" style="text-align: center;">Sin registro de llamadas
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </mat-expansion-panel>
                            </mat-expansion-panel>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="print-pdf-report" style="padding-top: 50px; background-color: white; font-family: Arial;display: none;"
    #htmlDoc>
    <meta charset="utf-8">
    <div style=" width: 100%;margin-right: auto;margin-left: auto;max-width: 1000px;">
        <div style="border-bottom: 3px solid #e96d18; margin-bottom: 30px;">
            <table>
                <tbody style="width: 1000px;">
                    <tr>
                        <td style="width: 500px;">

                            <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/miaLogoOfficial.png?alt=media&token=e45eede6-4721-4570-b242-bfabbeda7262"
                                style="max-width: 200px;" alt="">
                        </td>
                        <td style="text-align: end; width: 500px;">
                            <a style=" text-decoration: none; color:#e96d18;text-align: end;" class="link"
                                href="www.miasistente.net">www.miasistente.net</a> <br>

                            <a style=" text-decoration: none; color: #939292;text-align: end;"
                                href="mailto:ventas@miasistente.com">ventas&#64;miasistente.com</a> <br>
                            <a style="text-decoration: none; color: #939292;text-align: end;"
                                href="tel:8002880268">8002880268</a> <br>
                            <span style="text-decoration: none; color: #939292;text-align: end;">Cancún Quintana Roo
                                México / Oficinas
                                Centrales</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            style="width: 1000px; background-color: #eab386; padding: 10px 0; font-weight: 700; color: black; font-size: 20px; text-align: center;">
            {{generalReport?.report}}
        </div>
        <div
            style="width: 1000px; background-color: #b6dde7; padding: 10px 0; font-weight: 700; color: black; font-size: 20px; text-align: center;margin-bottom: 30px;">
            {{generalReport?.clientName}}
        </div>

        <table class="table table-striped" id="tabla-socios" style="background-color: #c5d9f1;">
            <thead>
                <tr>
                    <th style="width: 1000px; background-color: #c5d9f1;" scope="col" colspan="3">Tareas</th>
                    <th style="width: 100px; background-color: #c5d9f1;" scope="col" colspan="1">Cantidad</th>
                    <th style="width: 100px; background-color: #c5d9f1;" scope="col" colspan="1">Minutos de actividad
                    </th>
                    <th style="width: 100px; background-color: #c5d9f1;" scope="col" colspan="1">Tiempo estimado</th>
                    <th style="width: 100px; background-color: #c5d9f1;" scope="col" colspan="1">Tiempo final realizado
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of generalReport?.socialMediaEntries">
                    <tr *ngIf="item.quantity > 0">
                        <td style="border: none; width: 1000px; background-color: #fcd5b4;" colspan="3">{{item.title}}
                        </td>
                        <td style="border: none; width: 100px; background-color: #fcd5b4;" colspan="1">{{item.totalAll}}
                        </td>
                        <td style="border: none; width: 100px; background-color: #fcd5b4;" colspan="1">{{item.minutes}}
                        </td>
                        <td style="border: none; width: 100px; background-color: #fcd5b4;" colspan="1">
                            {{item.totalMinutes}}</td>
                        <td style="border: none; width: 100px; background-color: #fcd5b4;" colspan="1">
                            {{item.realTotalMinutes}}</td>
                    </tr>
                </ng-container>
                <tr>
                    <td style="border: none; width: 1300px; background-color: #fcd5b4;" colspan="5">Total de minutos
                    </td>
                    <td style="border: none; width: 100px; background-color: #fcd5b4;" colspan="1">{{globalMinutes}}
                    </td>
                    <td style="border: none; width: 100px; background-color: #fcd5b4;" colspan="1">{{realTime}}
                    </td>
                </tr>
                <tr class="row-blue">
                    <td style="border: none; width: 1300px; background-color: #c5d9f1;" colspan="5">Horas laboradas</td>
                    <td style="border: none; width: 100px; background-color: #c5d9f1;" colspan="1">{{(globalMinutes/60)
                        |
                        number:"1.2-2"}}</td>
                    <td style="border: none; width: 100px; background-color: #c5d9f1;" colspan="1">{{(realTime/60)
                        |
                        number:"1.2-2"}}</td>
                </tr>
                <tr class="row-blue space"></tr>
                <tr class="row-blue">
                    <td style="border: none; width: 1300px; background-color: #c5d9f1;" colspan="3">Total de llamadas
                        recibidas</td>
                    <td style="border: none; width: 100px; background-color: #c5d9f1;" colspan="1">
                        {{generalReport?.call_report.total_answeredEntriesCalls}}</td>
                    <td colspan="1"></td>
                    <td colspan="1">{{ generalReport?.call_report.total_TimeEntriesCalls }}</td>
                </tr>
                <tr class="row-blue">
                    <td style="border: none; width: 1300px; background-color: #c5d9f1;" colspan="3">Total de llamadas
                        realizadas</td>
                    <td style="border: none; width: 100px; background-color: #c5d9f1;" colspan="1">
                        {{generalReport?.call_report.total_answeredSalientesCalls}}</td>
                    <td colspan="1"></td>
                    <td colspan="1">{{ generalReport?.call_report.total_TimeSalientesCalls }}</td>
                </tr>
                <tr>
                    <td colspan="3">Total llamadas</td>
                    <td>{{generalReport?.call_report.total_answeredEntriesCalls +
                        generalReport?.call_report.total_answeredSalientesCalls}} </td>
                    <td></td>
                    <td>{{ totalTimeCalls}} </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <h2>Registro de actividades realizadas</h2>
        <div class="row logs">
            <table style="width: 100%; background-color: #F0A787;
            border: 2px solid black;
            padding: 5px;">
                <thead style="background-color: #fff;">
                    <tr>
                        <th style="min-width: 250px;">Fecha</th>
                        <th>Acción</th>
                        <th>Total de acciones</th>
                        <th>Comentarios</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of generalReport?.activities">
                        <td>{{item.date.toDate() | date: 'fullDate'}}</td>
                        <td>{{item.text}}</td>
                        <td style="text-align: center;">{{item.totalActions}}</td>
                        <td>{{item.comments}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr>
        <div *ngIf="generalReport?.hasCalls">
            <div class="row">
                <div class="col-md-12">
                    <h2>Llamadas entrantes</h2>
                    <table style="width: 100%;background-color: #4FA94D;
                    border: 2px solid black;
                    padding: 5px;">
                        <thead style="background-color: #fff;">
                            <tr>
                                <th style="padding: 5px 10px;">Fecha</th>
                                <th style="padding: 5px 10px;">Destino</th>
                                <th style="padding: 5px 10px;">Duración</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let call of callsEntries">
                                <td>{{call.calldate}}</td>
                                <td>{{call.callerId}}</td>
                                <td>{{call.duration}}s ({{call.minutes}})</td>
                            </tr>
                            <ng-container *ngIf="callsEntries.length == 0">
                                <tr>
                                    <td colspan="3" style="text-align: center;">Sin registro de llamadas</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <ng-container
                    *ngIf="generalReport.additionalCalls != null || generalReport.additionalCalls != undefined">
                    <div class="col-md-12">
                        <ng-container *ngFor="let additionalNumber of generalReport.additionalCalls">
                            <h2>Llamadas entrantes ( {{additionalNumber.number}}) </h2>
                            <table style="width: 100%;background-color: #4FA94D;
                            border: 2px solid black;
                            padding: 5px;">
                                <thead style="background-color: #fff;">
                                    <tr>
                                        <th style="padding: 5px 10px;">Fecha</th>
                                        <th style="padding: 5px 10px;">Destino</th>
                                        <th style="padding: 5px 10px;">Duración</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let call of additionalNumber.calls">
                                        <td>{{call.calldate}}</td>
                                        <td>{{call.did}}</td>
                                        <td>{{call.duration}}s ({{call.minutes}})</td>
                                    </tr>
                                    <ng-container *ngIf="additionalNumber.calls.length == 0">
                                        <tr>
                                            <td colspan="3" style="text-align: center;">Sin registro de llamadas
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </ng-container>
                    </div>
                </ng-container>
                <hr>
                <div class="col-md-12">
                    <h2>Registro de llamadas salientes</h2>
                    <h4 style="background-color: #cfcfcf;
                            border: 1px solid black;">Llamadas con tiempo menor a 20"</h4>
                    <table style="width: 100%;background-color: #56A4A2;
                    border: 2px solid black;">
                        <thead style="background-color: #fff;">
                            <tr>
                                <th style="padding: 5px 10px;">Fecha</th>
                                <th style="padding: 5px 10px;">Destino</th>
                                <th style="padding: 5px 10px;">Duración</th>
                                <th style="padding: 5px 10px;">Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let call of callsSalientesLess">
                                <td>{{call.calldate}}</td>
                                <td>{{call.destino.slice(4)}}</td>
                                <td>{{call.duration}}s ({{call.minutes}})</td>
                                <td>{{call.state == "ANSWERED" ? 'Respondida' : 'Perdida'}} </td>
                            </tr>
                            <ng-container *ngIf="callsSalientesLess.length == 0">
                                <tr>
                                    <td colspan="3" style="text-align: center;">Sin registro de llamadas</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <br>
                    <h4 style="background-color: #cfcfcf;
                            border: 1px solid black;">Llamadas con tiempo menor a 20"</h4>
                    <table style="width: 100%;background-color: #56A4A2;
                    border: 2px solid black;">
                        <thead style="background-color: #fff;">
                            <tr>
                                <th style="padding: 5px 10px;">Fecha</th>
                                <th style="padding: 5px 10px;">Destino</th>
                                <th style="padding: 5px 10px;">Duración</th>
                                <th style="padding: 5px 10px;">Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let call of callsSalientesMore">
                                <td>{{call.calldate}}</td>
                                <td>{{call.destino.slice(4)}}</td>
                                <td>{{call.duration}}s ({{call.minutes}})</td>
                                <td>{{call.state == "ANSWERED" ? 'Respondida' : 'Perdida'}} </td>
                            </tr>
                            <ng-container *ngIf="callsSalientesMore.length == 0">
                                <tr>
                                    <td colspan="3" style="text-align: center;">Sin registro de llamadas</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <ng-container *ngIf="generalReport?.outGoingCallPrefix">
                    <ng-container *ngFor="let prefixData of generalReport?.outGoingCallPrefix">
                        <div class="col-md-12">
                            <h2>Registro de llamadas salientes( {{prefixData.prefix}} )</h2>
                            <h4 style="background-color: #cfcfcf;
                            border: 1px solid black;">Llamadas con tiempo menor a 20"</h4>
                            <table style="width: 100%;background-color: #56A4A2;
                            border: 2px solid black;">
                                <thead style="background-color: #fff;">
                                    <tr>
                                        <th style="padding: 5px 10px;">Fecha</th>
                                        <th style="padding: 5px 10px;">Destino</th>
                                        <th style="padding: 5px 10px;">Duración</th>
                                        <th style="padding: 5px 10px;">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let call of prefixData.callLess">
                                        <td>{{call.calldate}}</td>
                                        <td>{{call.destino.slice(4)}}</td>
                                        <td>{{call.duration}}s ({{call.minutes}})</td>
                                        <td>{{call.state == "ANSWERED" ? 'Respondida' : 'Perdida'}} </td>
                                    </tr>
                                    <ng-container *ngIf="prefixData.callLess.length == 0">
                                        <tr>
                                            <td colspan="3" style="text-align: center;">Sin registro de llamadas
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <br>
                            <h4 style="background-color: #cfcfcf;
                            border: 1px solid black;">Llamadas con tiempo mayor a 20"</h4>
                            <table style="width: 100%;background-color: #56A4A2;
                            border: 2px solid black;">
                                <thead style="background-color: #fff;">
                                    <tr>
                                        <th style="padding: 5px 10px;">Fecha</th>
                                        <th style="padding: 5px 10px;">Destino</th>
                                        <th style="padding: 5px 10px;">Duración</th>
                                        <th style="padding: 5px 10px;">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let call of prefixData.callMore">
                                        <td>{{call.calldate}}</td>
                                        <td>{{call.destino.slice(4)}}</td>
                                        <td>{{call.duration}}s ({{call.minutes}})</td>
                                        <td>{{call.state == "ANSWERED" ? 'Respondida' : 'Perdida'}} </td>
                                    </tr>
                                    <ng-container *ngIf="prefixData.callMore.length == 0">
                                        <tr>
                                            <td colspan="3" style="text-align: center;">Sin registro de llamadas
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                </ng-container>
            </div>
        </div>
    </div>
</section>