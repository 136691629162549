import { formatDate } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import Swal from 'sweetalert2';
import { PendingsService } from 'src/app/services/pendings/pendings.service';
import { Notification } from '../../models/notifications';
import { UserService } from 'src/app/services/users/user.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    public settingsAdmin = {
        actions: {
            columnTitle: 'Acciones',
            add: true,
            edit: true,
            delete: true,
            position: 'right'
        },
        pager: {
            display: true,
            perPage: 20,
        },
        add: {
            addButtonContent: "<span>Nueva notificación</span>",
        },
        edit: {
            editButtonContent: '<span>Reenviar</span>',
        },
        delete: {
            deleteButtonContent: '<span>Eliminar</span>',
        },
        columns: {
            date: {
                title: 'Enviada el',
            },
            title: {
                title: 'Título',
            },
            message: {
                title: 'Mensaje',
            },
            status: {
                title: 'Estatus',
            },
        },
        mode: 'external',
        noDataMessage: 'No hay notificaciones por ahora'
    };

    public data_source = [];
    public formgroup: FormGroup;
    public titlecard: string = '';
    public edit: boolean = false;
    public docToUpdate: string = '';
    public pendingNotifications: Notification[] = []

    constructor(
        private service: NotificationsService,
        public formBuilder: FormBuilder,
        private dialogService: NbDialogService,
        private afs: AngularFirestore,
        private pendingService: PendingsService,
        private userService: UserService
    ) {
        var data$ = this.service.getAll();
        data$.subscribe(res => {
            this.data_source = res;
        })
        var logs = []
        this.pendingService.getLogs().subscribe(async (notifications) => {
            var userList = await this.userService.getActiveUsers()
            notifications.map(async (notificationInfo: any) => {
                var userInfo = userList.filter(user => user.uid == notificationInfo.who)
                if (notificationInfo.title != "Pendiente a punto de concluir" && userInfo.length != 0) {
                    notificationInfo.who = userInfo[0].nombre
                }
                notificationInfo.date = notificationInfo.date.toDate().toISOString()

                if (notificationInfo.title == "Tarea atrasada" || notificationInfo.title == "Actualización de tarea" || notificationInfo.title == "Pendiente a punto de concluir" || notificationInfo.title == "Modificación de tiempo en actividad") {
                    logs.push(notificationInfo)
                }
            })
            this.pendingNotifications = logs
            logs = []
        })
    }

    ngOnInit() {
        this.formgroup = this.formBuilder.group({
            title: ["", Validators.required],
            message: ["", Validators.required],
            status: ["Enviada"],
            date: [""],
            schedule: [""],
            tokens: [""],
        });
    }

    create(dialog: TemplateRef<any>) {
        this.titlecard = 'Nueva notificación';
        this.dialogService.open(dialog, { context: '' });
    }

    async delete(item: any) {
        var result = await Swal.fire({
            title: '¿Seguro que quiere eliminar esta notificación?',
            text: "Esta acción no se puede revertir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar'
        })
        if (result.value) {
            await this.service.delete(item.data.id)
            await Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Datos eliminados',
                showConfirmButton: false,
                timer: 1000
            })
        }
    }

    resend(item: any, dialog: TemplateRef<any>) {
        this.titlecard = 'Reenviar notificación';
        this.edit = true;
        this.docToUpdate = item.data.id;
        this.formgroup.patchValue({
            title: item.data.title,
            message: item.data.message,
        })
        this.dialogService.open(dialog, { context: '' });
    }

    sendNotification() {
        var form = this.formgroup.value;
        var today = new Date()
        form.date = formatDate(today, 'dd/MM/yyyy', 'en-US') + ' a las ' + formatDate(today, 'hh:mm a', 'en-US');
        form.schedule = today.toISOString();

        if (this.edit)
            this.afs.collection('general-notifications').doc(this.docToUpdate).delete();

        this.service.getCompanyIds().then(async companies => {
            let finalTokens = [];
            await Promise.all(companies.map(async element => {
                let tokens = await this.service.getCompanyTokens(element);
                tokens.forEach(element => {
                    finalTokens.push(element)
                });
            }))

            form.tokens = finalTokens;
            this.service.create(form).then(() => {
                Swal.fire({
                    title: 'Notificación creada correctamente',
                    text: 'Se enviará su notificación a todos los clientes en los próximos minutos',
                    icon: 'success'
                })

                this.formgroup.reset();
            })
        })

    }

}
