<section>
  <div class="header-container">
    <h3>Ejecutivos</h3>
    <div class="button-wrap">
      <button mat-button (click)="createNew()"><mat-icon>add</mat-icon> Nuevo ejecutivo</button>
    </div>
  </div>
  <div class="card">
    <ng2-smart-table [settings]="settings" [source]="data_source" (edit)="openUpdateUser($event)"
      (delete)="deleteUser($event)">
    </ng2-smart-table>
  </div>
</section>
