<nb-card>
    <nb-card-header>
        <h3>Importar nuevos usuarios</h3>
    </nb-card-header>
    <nb-card-body>
        <div class="form-group" *ngIf="!loader">
            <input id="file-upload" type="file" name="fileUpload" accept=".xls,.xlsx,.csv" (change)="onUploadInfo($event)" />
            <label for="file-upload" id="file-drag">
                <div id="start" class="upload">
                    <i class="fa fa-download" aria-hidden="true"></i>
                    <div>Selecciona un archivo .xls o .xlsx</div>
                    <span id="file-upload-btn" class="btn btn-primary">Seleccionar Archivo</span>
                </div>
            </label>
        </div>
        <nb-card [nbSpinner]="true" nbSpinnerStatus="basic" *ngIf="loader">
            <nb-card-body>
              Cargando archivo...
            </nb-card-body>
          </nb-card>
    </nb-card-body>
</nb-card>