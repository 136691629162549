<div class="card">
  <div class="buttons-schedule">
    <button mat-button (click)="renderCalendar()" class="warning"><mat-icon>refresh</mat-icon> Actualizar
      agenda</button>
    <button mat-button (click)="addNewSpaces()" class="success"><mat-icon>add</mat-icon> Agregar espacio</button>
    <button mat-button (click)="deleteSpaces()" class="danger"><mat-icon>close</mat-icon> Elminar espacios</button>
  </div>
  <full-calendar #calendar id="calendar" [allDaySlot]="false" [minTime]="minTime" [slotDuration]="slotDuration"
    [nowIndicator]="true" [maxTime]="maxTime" [slotLabelFormat]="slotLabelFormat" defaultView="timeGridWeek"
    [customButtons]="options.customButtons" [header]="headerConfig" [buttonText]="buttonConfig"
    [plugins]="calendarPlugins" [weekends]="true" [locale]="locale" [events]="events" [eventLimit]="true"
    [views]="views" [selectable]="true" (select)="selectEvents($event)" (eventClick)="openEvent($event)"
    (dateClick)="handledateClick($event)">
  </full-calendar>
</div>
