import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { PlanService } from 'src/app/services/planService/plan.service';
import Swal from 'sweetalert2';
import { UserData } from 'src/app/models/user';
import { ProspectsService } from 'src/app/services/prospects/prospects.service'
import { formatDate } from '@angular/common';
import { UserService } from 'src/app/services/users/user.service';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { DateTime } from 'luxon';
import { EventsService } from 'src/app/services/events/events.service';
import { Prospect } from 'src/app/models/prospects';

@Component({
  selector: 'app-add-prospects',
  templateUrl: './add-prospects.component.html',
  styleUrls: ['./add-prospects.component.scss']
})
export class AddProspectsComponent implements OnInit {

  public addForm: FormGroup;
  config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    acceptedFiles: 'image/*, .pdf',
  };
  public tags: string[] = []
  public errorInComercial = false
  public users: UserData[] = []
  public actualPlans: any[] = []
  public executives: UserData[] = []
  public commercialTurn: any[] = [
    { value: "Profesional", viewValue: "Profesional" },
    { value: "Comercial", viewValue: "Comercial" },
    { value: "Medico", viewValue: "Medico" },
  ]
  public promotion: any[] = [
    { value: "N/A", viewValue: "N/A" },
    { value: "Otra", viewValue: "Otra" },
  ]
  public sex: any[] = [
    { value: "Masculino", viewValue: "Masculino" },
    { value: "Femenino", viewValue: "Femenino" },
  ]
  public origin: any[] = [
    { value: "Facebook", viewValue: "Facebook" },
    { value: "Google", viewValue: "Google" },
    { value: "BNI", viewValue: "BNI" },
    { value: "Recomendado", viewValue: "Recomendado" },
    { value: "Otro", viewValue: "Otro" },
  ]
  public cities: any[] = [
    { value: "Aguascalientes", viewValue: "Aguascalientes" },
    { value: "Baja California", viewValue: "Baja California" },
    { value: "Baja California Sur", viewValue: "Baja California Sur" },
    { value: "Campeche", viewValue: "Campeche" },
    { value: "Coahuila", viewValue: "Coahuila" },
    { value: "Chiapas", viewValue: "Chiapas" },
    { value: "Colima", viewValue: "Colima" },
    { value: "Chihuahua", viewValue: "Chihuahua" },
    { value: "Durango", viewValue: "Durango" },
    { value: "Estado de México", viewValue: "Estado de México" },
    { value: "Guanajuato", viewValue: "Guanajuato" },
    { value: "Guerrero", viewValue: "Guerrero" },
    { value: "Hidalgo", viewValue: "Hidalgo" },
    { value: "Jalisco", viewValue: "Jalisco" },
    { value: "Ciudad de México", viewValue: "Ciudad de México" },
    { value: "Michoacán", viewValue: "Michoacán" },
    { value: "Morelos", viewValue: "Morelos" },
    { value: "Nayarit", viewValue: "Nayarit" },
    { value: "Nuevo León", viewValue: "Nuevo León" },
    { value: "Oaxaca", viewValue: "Oaxaca" },
    { value: "Puebla", viewValue: "Puebla" },
    { value: "Querétaro", viewValue: "Querétaro" },
    { value: "Quintana Roo", viewValue: "Quintana Roo" },
    { value: "San Luis Potosí", viewValue: "San Luis Potosí" },
    { value: "Sinaloa", viewValue: "Sinaloa" },
    { value: "Sonora", viewValue: "Sonora" },
    { value: "Tabasco", viewValue: "Tabasco" },
    { value: "Tamaulipas", viewValue: "Tamaulipas" },
    { value: "Tlaxcala", viewValue: "Tlaxcala" },
    { value: "Veracruz", viewValue: "Veracruz" },
    { value: "Yucatán", viewValue: "Yucatán" },
    { value: "Zacatecas", viewValue: "Zacatecas" },
  ]

  urlDefaultImg =
    "https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/logo-default.png?alt=media&token=31c837f0-867c-431f-8778-4b45ba65f926";
  showOther: boolean = false;
  public showError: boolean = false;
  public userInfo: UserData
  public tagField = new FormControl('')
  public isEdit = false
  public prospectData!: Prospect;

  constructor(
    private formBuilder: FormBuilder,
    private prospectService: ProspectsService,
    private router: Router,
    private planService: PlanService,
    private agentService: UserService,
    private authService: AuthService,
    private eventService: EventsService,
    private activeRoute: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.addForm = this.formBuilder.group({
      nombre: ["", [Validators.required]],
      nombreCliente: ["", [Validators.required]],
      gender: ["", [Validators.required,]],
      age: ["", [Validators.required, Validators.min(1)]],
      telefono: ["", [Validators.required, Validators.minLength(10)]],
      email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      giroComercial: ["", [Validators.required]],
      direccion: ["", [Validators.required]],
      fiscalAddress: ["", [Validators.required]],
      city: ["", [Validators.required]],
      origin: ["", [Validators.required]],
      ejecutivo: [[], Validators.required],
      vendor: ["", Validators.required],
      trainingDate: ["", Validators.required],
      trainingHour: ["", Validators.required],
      zoomLink: ["", Validators.required],
      needHelp: ["", Validators.required],
      plan: ["", [Validators.required]],
      campaign: ["", [Validators.required]],
      promotion: ["", [Validators.required]],
      invoice: ["", [Validators.required]],
      isPaid: ["", Validators.required]
    });
    this.actualPlans = await this.getPlans()
    this.executives = await this.getAgents()
    this.userInfo = await this.getActiveUser()
    let param = this.activeRoute.snapshot.params['id'] ?? ''
    if (param != '') {
      this.prospectData = await this.getProspectInfo(param);
      this.addForm.patchValue(this.prospectData)
      this.isEdit = true
      this.tags = this.prospectData?.comercialTags ?? []
    }
  }

  async getProspectInfo(prospectId: string) {
    try {
      var response = await this.prospectService.getProspectById(prospectId);
      return response
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar la información del prospecto',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async getPlans() {
    try {
      var response = await this.planService.getPlans();
      return response.data
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los planes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getAgents() {
    try {
      var response = await this.agentService.getActiveUsers()
      var executives = response.filter((user) => user.tipo == "operador")
      return executives
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los ejecutivos',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getActiveUser() {
    try {
      let currentUserId = this.authService.currentuser().uid;
      const currentUser = await this.agentService.getUserById(currentUserId);
      this.addForm.controls['vendor'].setValue(currentUser.nombre)
      return currentUser
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los datos del usuario',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async submit() {
    var form = this.addForm.value;
    if (
      form.nombre == "" ||
      form.nombreCliente == "" ||
      form.telefono == "" ||
      form.origin == "" ||
      // form.email == "" ||
      form.trainingDate == "" ||
      form.trainingHour == "" ||
      form.ejecutivo == "" ||
      form.city == ""
      // form.gender == "" ||
      // form.age == "" ||
      // form.giroComercial == "" ||
      // form.direccion == "" ||
      // form.fiscalAddress == "" ||
      // form.vendor == "" ||
      // form.zoomLink == "" ||
      // form.needHelp == "" ||
      // form.plan == "" ||
      // form.campaign == "" ||
      // form.promotion == "" ||
      // form.invoice == ""
    ) {
      this.showError = true
      return await Swal.fire({
        title: "Revise  y llene los campos obligatorios",
        icon: "error",
      });
    }
    Swal.fire({
      title: "Guardando",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    try {
      form.comercialTags = this.tags
      if (this.isEdit) {
        await this.prospectService.updateProspect(this.prospectData._id, form)
        await Swal.fire({
          title: "Prospecto actualizado",
          icon: "success",
          timer: 1500,
          showConfirmButton: false
        });
        if (this.userInfo.tipo == "administrador") {
          this.router.navigate(['marketing'])
        }
        else {
          this.router.navigate(['dashboard'])
        }
      } else {
        var response = await this.prospectService.createProspects(form)
        await this.createEventMeet(form, response.insertedId)
        Swal.close();
        await Swal.fire({
          title: "Nueva empresa creada!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false
        });
        this.close();
      }


    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al crear la empresa",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async createEventMeet(form: any, prospectId: string) {
    try {
      var endHour = { hour: parseInt(form.trainingHour.hour + 1), minute: 0 }
      var startDateTime = this.getCombineDateTime(new Date(form.trainingDate), form.trainingHour);
      var endDateTimer = this.getCombineDateTime(new Date(form.trainingDate), endHour);

      for await (const agent of form.ejecutivo) {
        var userInfo = this.executives.filter((userData) => userData.nombre == agent)[0]

        var buildInfo = {
          startDate: new Date(startDateTime),
          endDate: new Date(endDateTimer),
          duration: 60,
          clientId: userInfo.uid,
          comments: "",
          subject: "Capacitación",
          customer: form.nombreCliente,
          prospectId: prospectId,
          title: "Zoom para capacitacion con" + form.nombreCliente,
          status: false,
          block: false,
        }
        await this.eventService.createEventMeet(buildInfo);
      }
      var buildMeet = {
        startDate: new Date(startDateTime),
        endDate: new Date(endDateTimer),
        duration: 60,
        clientId: this.userInfo.uid,
        comments: "",
        subject: "Capacitación",
        customer: form.nombreCliente,
        prospectId: prospectId,
        title: "Zoom para capacitacion con" + form.nombreCliente,
        status: false,
        block: false,
      }
      await this.eventService.createEventMeet(buildMeet);
    } catch (error) {
      await Swal.fire({
        title: "Ocurrió un error al crear el evento",
        icon: "error",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  getCombineDateTime(date: Date, time: any) {
    var day = new Date(new Date(date).setHours(0, 0, 0, 0))
    var hour = parseInt(time.hour);
    var min = parseInt(time.minute);
    var newDate = DateTime.fromISO(day.toISOString().split("T")[0])
      .set({ hour: hour })
      .set({ minute: min })
      .set({ second: 0 })
      .toJSDate();
    return newDate;
  }

  addTag() {
    const typeOf = this.addForm.value.giroComercial
    if (typeOf == '') {
      this.errorInComercial = true
      return
    }
    if (this.tagField.value == "") {
      return
    }
    this.tags.push(this.tagField.value)
    this.tagField.setValue('')
  }

  removeTag(tag: string) {
    var indexTag = this.tags.findIndex((element) => element == tag)
    this.tags.splice(indexTag, 1)
  }

  close() {
    if (this.userInfo.tipo == 'administrador') {
      this.router.navigate(['marketing'])
    }
    else {
      this.router.navigate(['dashboard'])
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'e' || event.key === '+' || event.key === '-' || event.key === '.') {
      event.preventDefault();
    }
  }

  onlyNums(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/\D/g, '')
  }

  // Validaciones
  fieldRequired(field: string) {
    return this.addForm.get(field)?.hasError('required') && this.addForm.get(field)?.touched || (this.showError && this.addForm.get(field)?.untouched)
  }
  fieldMin(field: string) {
    return this.addForm.controls[field].hasError('min') && this.addForm.controls[field].touched
  }
  fieldMinLength(field: string) {
    return this.addForm.controls[field].hasError('minlength') && this.addForm.controls[field].touched
  }
  fieldPattern(field: string) {
    return this.addForm.controls[field].hasError('pattern') && this.addForm.controls[field].touched
  }

}
