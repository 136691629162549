<div class="dialog-template">
    <h3> Reprogramar cita</h3>
    <form [formGroup]="eventForm">
        <div class="form-group">
            <label for="">Fecha</label>
            <input matInput [matDatepicker]="picker" formControlName="startDate" (click)="picker.open()"
                placeholder="dd/mm/yyyy" [min]="minDate" (dateChange)="onSelectedDate($event.value)">
            <mat-datepicker #picker></mat-datepicker>
        </div>
        <div class="form-group">
            <label for="">Espacio</label>
            <mat-select placeholder="Selecciona un espacio libre" formControlName="space">
                <mat-option *ngFor="let event of filterEvents"
                    [value]="event.extendedProps">{{event.title}}</mat-option>
                <mat-option [value]="" disabled *ngIf="filterEvents.length == 0">No hay espacios
                    disponibles</mat-option>
            </mat-select>
        </div>
        <div class="form-group">
            <label for="">Comentario</label>
            <textarea matInput [(ngModel)]="comment" [ngModelOptions]="{standalone: true}" rows="7"
                [disabled]="eventForm.value.space == ''" style="resize:none"></textarea>
        </div>
    </form>
    <div class="buttons-footer">
        <button mat-button class="danger" (click)="cancel()">
            <mat-icon>close</mat-icon> Cancelar
        </button>
        <button mat-button class="success" [disabled]="!eventForm.value || !(comment != '')"
            (click)="confirmReSchedule()">
            <mat-icon>save</mat-icon> Guardar
        </button>
    </div>
</div>