import { Component, OnInit } from '@angular/core';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { Prospect } from 'src/app/models/prospects';
import Swal from 'sweetalert2';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { PlanService } from 'src/app/services/planService/plan.service';
import { Plan } from 'src/app/models/plan';
import { UserService } from 'src/app/services/users/user.service';
import { UserData } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/company';
import { Task } from 'src/app/models/task';
import { ServiceMinutesService } from 'src/app/services/service-minutes/service-minutes.service';
import { PendingsService } from 'src/app/services/pendings/pendings.service';
import { Pending } from 'src/app/models/pending';
import { EventsService } from 'src/app/services/events/events.service';
import { Event } from 'src/app/services/events/model';
import { CallsReportsService } from 'src/app/services/calls-reports/calls-reports.service';

@Component({
  selector: 'app-analytics-clients',
  templateUrl: './analytics-clients.component.html',
  styleUrls: ['./analytics-clients.component.scss']
})
export class AnalyticsClientsComponent implements OnInit {

  public countClients = 0
  public countInactiveClients = 0
  public selectType = 1;
  public showData = false;
  public clientList: Prospect[] = []
  public clientActive: Prospect[] = []
  public clientInactive: Prospect[] = []
  public agentsList: UserData[]
  public communTask: Task[] = []
  public pendingList: Pending[]
  public displayColumns = ["pending", "client", "time"]
  public eventsColumns = ["subject", "client", "time"]
  public eventsList: Event[] = []
  public newClients = []
  public todayCalls = []
  public loader = true;
  //Config of chars
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public pluginBar = {
    legend: {
      display: true,
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
    },
  }
  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;
  //Data for graphs
  public planGraphData: ChartDataSets[] = []
  public plans: Plan[];
  public planLabels = []

  public taskGraphData: ChartDataSets[] = []
  public taskLabels = []

  public barChartData: ChartDataSets[] = [];
  public agentGraphData = []
  public barChartLabels: string[] = []

  constructor(
    private companyService: CompanyService,
    private planService: PlanService,
    private userService: UserService,
    private taskService: ServiceMinutesService,
    private pendingService: PendingsService,
    private eventsService: EventsService,
    private prospectInfo: ProspectsService,
    private callService: CallsReportsService
  ) { }

  async ngOnInit() {
    await this.getInitialData()
    await this.buildGraphs()
    this.loader = false;
  }

  async buildGraphs() {
    this.buildPlanGraph()
    this.buildAgentGraph()
    this.buildTasksGraph()
  }

  async getInitialData() {
    this.plans = await this.getPlans()
    this.agentsList = await this.getAgents();
    this.clientList = await this.getAllCompanies()
    this.clientActive = await this.getAciveClients()
    this.clientInactive = await this.getInactiveClients()
    this.communTask = await this.getTasks()
    this.countClients = this.clientActive.length
    this.countInactiveClients = this.clientInactive.length
    this.pendingList = await this.getPendingsToday()
    this.eventsList = await this.getEventsToday();
    this.newClients = await this.getNewProspects()
    this.todayCalls = await this.getCallsToday()
  }

  async getAciveClients() {
    const result = this.clientList.filter((client) => client.activeInDashboard == true)
    return result
  }

  async getInactiveClients() {
    const result = this.clientList.filter((client) => client.activeInDashboard == false)
    return result
  }

  async getPendingsToday() {
    try {
      const response = await this.pendingService.getPendingsToday()
      response.data.map((pending) => {
        pending.limitTime = new Date(new Date(pending.limitTime._seconds * 1000))
      })
      var onlyToday = response.data.filter((pending) => pending.limitTime >= new Date().setHours(0, 0, 0, 0) && pending.limitTime <= new Date(new Date().setDate(new Date().getDate() + 1)))
      return onlyToday
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los pendientes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getEventsToday() {
    try {
      const response = await this.eventsService.getEventsToday();
      response.map((event) => {
        event.clientId = this.clientList.filter((client) => client.uid == event.clientId)[0].nombre
      })
      return response
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar las citas',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getNewProspects(): Promise<Prospect[]> {
    try {
      const response = await this.prospectInfo.getNewsProspects()
      return response
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar las tareas',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getCallsToday(): Promise<any[]> {
    try {
      var callData = []
      const startDate = new Date().setHours(0, 0, 0, 0)
      const endDate = new Date().setDate(new Date().getDate() + 1)
      const response = await this.callService.getAllCalls(' ', startDate.toString(), endDate.toString())

      var format = new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
      const outGoing = await this.callService.getCallSalientes(' ', format.format(startDate), format.format(endDate))
      for await (const call of response) {
        if (call.extension.length == 4) {
          callData.push({
            "number": call.callerId,
            "state": call.state == "ANSWERED" ? "Respondida" : "Perdida",
            "type": "incoming",
            "duration": call.duration + "s"
          })
        }
      }
      for await (const call of outGoing) {
        callData.push({
          "number": call.callerId,
          "state": call.state == "ANSWERED" ? "Respondida" : "Perdida",
          "type": "outgoing",
          "duration": call.duration + " s"
        })
      }

      return callData
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar las tareas',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getTasks(): Promise<Task[]> {
    try {
      const response = await this.taskService.getTask()
      return response
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar las tareas',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getAgents() {
    try {
      const response = await this.userService.getActiveUsers()
      return response
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los planes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getPlans() {
    try {
      const response = await this.planService.getPlans();
      return response.data
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los planes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getAllCompanies() {
    try {
      const response = await this.prospectInfo.getAllCompleteProspects()
      return response.data
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al los clientes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  buildPlanGraph() {
    var data = []
    this.plans.forEach(plan => {
      this.planLabels.push(plan.title)
      data.push(0)
    })

    this.clientActive.forEach((prospectInfo => {
      var index = this.planLabels.findIndex((agent) => agent == prospectInfo.plan)
      data[index]++;
    }))

    this.planGraphData.push({
      label: "Clientes",
      data: data,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
      ],
      borderColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)',
        'rgba(0, 131, 28,0.5 )',
        'rgba(192, 57, 43,0.5 )',
        'rgba(185, 119, 14,0.5 )',
        'rgba(163, 228, 215,0.5 )',
        'rgba(72, 168, 72,0.5 )',
        'rgba(218, 112, 214,0.5 )',
        'rgba(32, 64, 128,0.5 )',
        'rgba(255, 165, 0,0.5 )',
        'rgba(184, 41, 95,0.5 )',
        'rgba(87, 109, 99,0.5 )',
        'rgba(145, 35, 47,0.5 )',
        'rgba(70, 130, 180,0.5 )',
        'rgba(220, 20, 60,0.5 )',
        'rgba(143, 188, 139,0.5 )',
        'rgba(123, 104, 238,0.5 )',
        'rgba(210, 105, 30,0.5 )',
        'rgba(119, 136, 153,0.5 )',
        'rgba(95, 158, 160,0.5 )',
        'rgba(106, 90, 205,0.5 )',
        'rgba(25, 25, 112,0.5 )',
        'rgba(72, 61, 139,0.5 )',
        'rgba(100, 149, 237,0.5 )',
        'rgba(205, 133, 63,0.5 )',
        'rgba(0, 191, 255,0.5 )',
      ]
    })
    this.showData = true;
  }

  async buildAgentGraph() {
    for await (const agentInfo of this.agentsList) {
      this.barChartLabels.push(agentInfo.nombre);
      this.agentGraphData.push(0)
    }
    this.clientActive.forEach((prospectInfo => {
      var index = this.barChartLabels.findIndex((agent) => prospectInfo.ejecutivo.includes(agent))
      this.agentGraphData[index]++;
    }))

    this.barChartData.push({
      label: "Clientes",
      data: this.agentGraphData,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
      ],
      borderColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)',
        'rgba(0, 131, 28,0.5 )',
        'rgba(192, 57, 43,0.5 )',
        'rgba(185, 119, 14,0.5 )',
        'rgba(163, 228, 215,0.5 )',
        'rgba(72, 168, 72,0.5 )',
        'rgba(218, 112, 214,0.5 )',
        'rgba(32, 64, 128,0.5 )',
        'rgba(255, 165, 0,0.5 )',
        'rgba(184, 41, 95,0.5 )',
        'rgba(87, 109, 99,0.5 )',
        'rgba(145, 35, 47,0.5 )',
        'rgba(70, 130, 180,0.5 )',
        'rgba(220, 20, 60,0.5 )',
        'rgba(143, 188, 139,0.5 )',
        'rgba(123, 104, 238,0.5 )',
        'rgba(210, 105, 30,0.5 )',
        'rgba(119, 136, 153,0.5 )',
        'rgba(95, 158, 160,0.5 )',
        'rgba(106, 90, 205,0.5 )',
        'rgba(25, 25, 112,0.5 )',
        'rgba(72, 61, 139,0.5 )',
        'rgba(100, 149, 237,0.5 )',
        'rgba(205, 133, 63,0.5 )',
        'rgba(0, 191, 255,0.5 )',
      ]
    })
    this.showData = true;
  }

  buildTasksGraph() {
    var data = []
    this.communTask.forEach(task => {
      this.taskLabels.push(task.title)
      data.push(0)
    })

    this.clientActive.forEach((prospectInfo => {
      var index = this.taskLabels.findIndex((task) => prospectInfo.tareasRecurrentes.filter((taskAssing) => taskAssing.title == task)[0])
      data[index]++;
    }))
    this.taskLabels[0] = "Comentarios de facebook"

    this.taskGraphData.push({
      label: "Clientes",
      data: data,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
      ],
      borderColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)',
        'rgba(0, 131, 28,0.5 )',
        'rgba(192, 57, 43,0.5 )',
        'rgba(185, 119, 14,0.5 )',
        'rgba(163, 228, 215,0.5 )',
        'rgba(72, 168, 72,0.5 )',
        'rgba(218, 112, 214,0.5 )',
        'rgba(32, 64, 128,0.5 )',
        'rgba(255, 165, 0,0.5 )',
        'rgba(184, 41, 95,0.5 )',
        'rgba(87, 109, 99,0.5 )',
        'rgba(145, 35, 47,0.5 )',
        'rgba(70, 130, 180,0.5 )',
        'rgba(220, 20, 60,0.5 )',
        'rgba(143, 188, 139,0.5 )',
        'rgba(123, 104, 238,0.5 )',
        'rgba(210, 105, 30,0.5 )',
        'rgba(119, 136, 153,0.5 )',
        'rgba(95, 158, 160,0.5 )',
        'rgba(106, 90, 205,0.5 )',
        'rgba(25, 25, 112,0.5 )',
        'rgba(72, 61, 139,0.5 )',
        'rgba(100, 149, 237,0.5 )',
        'rgba(205, 133, 63,0.5 )',
        'rgba(0, 191, 255,0.5 )',
      ]
    })
    this.showData = true;
  }

  async changeFilter(type: number) {
    this.taskGraphData = []
    this.taskLabels = []
    this.agentGraphData = []
    this.barChartLabels = []
    this.planLabels = []
    this.planGraphData = []
    this.barChartData = []
    switch (type) {
      case 1:
        this.clientActive = await this.getAciveClients()
        this.selectType = 1
        this.buildGraphs()
        break;
      case 2:
        this.clientActive = await this.getInactiveClients()
        this.selectType = 2
        this.buildGraphs()
        break;
      case 3:
        this.clientActive = await this.getAllCompanies()
        this.selectType = 3
        this.buildGraphs()
        break;
      default:
        break;
    }
  }

}
