import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/users/user.service';
import Swal from 'sweetalert2';
import { PendingsService } from 'src/app/services/pendings/pendings.service';
import { formatDate } from '@angular/common';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { PendingUser } from '../../models/pending-user';

@Component({
  selector: 'app-edit-pending-users',
  templateUrl: './edit-pending-users.component.html',
  styleUrls: ['./edit-pending-users.component.scss']
})
export class EditPendingUsersComponent implements OnInit {
  [x: string]: any;
  public editForm:FormGroup
  public userList = []
  public typePending:string;
  public currentUser: string
  public userId:string
  constructor(
    public ref:NbDialogRef<EditPendingUsersComponent>,
    private formbuilder : FormBuilder,
    private userService: UserService,
    private pendingService: PendingsService,
    private authSerivce: AuthService
  ) { }

  async ngOnInit() {
    this.currentUser = localStorage.getItem("currentUser");
    this.userId = this.authSerivce.currentuser().uid
    var pending:PendingUser = this.ref.componentRef.instance.pending;
    if (this.currentUser == 'administrador') {
      pending.date = new Date(pending.limitTime)
    }
    
    this.typePending = pending.userId;
    this.editForm = this.formbuilder.group({
      title:[pending.title],
      date:[pending.date],
      userId:[pending.userId],
      comments:[pending.comments],
      time:[pending.time],
      id:[pending._id]
    })
    await this.getUsers()
  }

  async getUsers(){
    try {
      this.userList = await this.userService.getActiveUsers();

    } catch (error) {
      Swal.fire({
        title:'Ocurrió un error al modificar el pendiente',
        icon:'error',
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      })
    }
  }

  async updatePending(){
    try {
      var form = this.editForm.value;
      let formattedDate = formatDate(form.date, 'yyyy-MM-dd', 'en-US');
      var hour = form.time.hour
      var minutes = form.time.minute;
      form.limitTime = new Date(`${formattedDate}`);
      form.limitTime.setDate(form.limitTime.getDate() + 1);
      form.limitTime.setHours(hour,minutes,0)
      await this.pendingService.updatePendingUser(form);
      // if (this.currentUser == 'administrador') {
      // }
      // else{
      //   console.log("no");
      //   await this.pendingService.addPending(this.userId,"asesores",form)
      // }
      await Swal.fire({
        title:'Pendiente actualizado',
        icon:'success',
        text: 'El pendiente ha sido modificado con exito',
        showConfirmButton: false,
        timer: 1000
      })
      this.ref.close()
    } catch (error) {
      Swal.fire({
        title:'Ocurrió un error al modificar el pendiente',
        icon:'error',
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true
      })
    }
  }

}
