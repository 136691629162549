import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Prospect } from 'src/app/models/prospects';
import { CompanyService } from 'src/app/services/company/company.service';
import { ExcelService } from 'src/app/services/excel/excel.service';
import Swal from 'sweetalert2';
import { GenerateZoomComponent } from '../../generate-zoom/generate-zoom.component';

@Component({
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss']
})
export class ClientsTableComponent implements OnInit {
  settingsAdmin = {
    actions: {
      columnTitle: "Acciones",
      add: false,
      edit: true,
      delete: true,
      position: "right",
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Nuevo cliente</span>",
    },
    edit: {
      editButtonContent: "<span>Editar</span>",
    },
    delete: {
      deleteButtonContent: "<span>Ocultar</span>",
    },
    update: {
      updateButtonContent: "<span>Actua</span>",
    },
    columns: {
      thumbnail: {
        title: "Cliente",
        type: "html",
        filter: false
      },
      nombre: {
        title: "Nombre",
      },
      nombreCliente: {
        title: "Encargado",
      },
      ejecutivo: {
        title: "Operador designado",
      },
      plan: {
        title: "Plan"
      },
      status: {
        title: "Contrato"
      }
    },
    mode: "external",
    noDataMessage: "No hay registros por ahora",
  };

  settingsOp = {
    actions: {
      columnTitle: "Acciones",
      add: false,
      edit: true,
      delete: false,
      position: "right",
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Nuevo cliente</span>",
    },
    edit: {
      editButtonContent: "<span>Editar</span>",
    },
    delete: {
      deleteButtonContent: "<span>Eliminar</span>",
    },
    columns: {
      thumbnail: {
        title: "Cliente",
        type: "html",
      },
      nombre: {
        title: "Nombre",
      },
      nombreCliente: {
        title: "Encargado",
      },
      ejecutivo: {
        title: "Operador designado",
      },
      plan: {
        title: "Plan"
      },
      status: {
        title: "Contrato"
      }
    },
    mode: "external",
    noDataMessage: "No hay registros por ahora",
  };

  @Output('eventInit') fatherOnInit = new EventEmitter();
  @Input() data_source = [];
  @Input() dataClients: MatTableDataSource<any>;
  @Input() user: any;

  public displayColumns: string[] = ['icon', 'name', 'owner', 'agent', 'phone', 'email', 'plan', 'actions'];
  public tabSelect = 1;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output('catchPaginator') emitPaginator = new EventEmitter<MatPaginator>();

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private excelService: ExcelService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.emitPaginator.emit(this.paginator)
  }

  createNew() {
    this.router.navigate(['clients/new'])
  }

  openUpdateCompany(data) {
    this.router.navigate(['clients/edit/' + data._id])
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataClients.filter = filterValue.trim().toLowerCase();
    if (this.dataClients.paginator) {
      this.dataClients.paginator.firstPage();
    }
  }

  changeTab() {
    if (this.tabSelect == 1) {
      this.tabSelect = 2
    }
    else {
      this.tabSelect = 1
    }
  }

  createMeet() {
    // window.open("https://zoom.us/oauth/authorize?response_type=code&client_id=tHUdz3MQTOuzjTSQMtiiag&redirect_uri=https://miasistema.web.app/create-zoom","_self")
    // this.dialog.open(GenerateZoomComponent)
    this.router.navigate(['create-zoom'])
  }

  async generateExcel() {
    try {
      const day = new Date().getDate();
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();
      var fileName = `Reporte general de clientes_${day}/${month}/${year}`
      this.tabSelect == 1 ? fileName = fileName : fileName = fileName + "_Inactivos";
      const headers = [
        "Nombre comercial",
        "Nombre del cliente",
        "Correo electrónico",
        "Numero de teléfono",
        "Giro comercial",
        "Dirección del establecimiento",
        "Servicios adicionales",
        "Ejecutivo asignado",
        "Referenacias",
        "Plan contratado",
        "Datos de facturacion",
        "Fecha de inicio de contrato",
        "Profesión u ocupación",
        "Prefijo",
        "Horario de atencion",
        "Origen",
        "Campaña",
        "Edad",
        "Sexo",
        "Ciudad",
        "Ayuda en"
      ]
      var dataToPrint = []
      var auxData = []
      this.tabSelect == 1 ? auxData = this.data_source : auxData = this.dataClients.data;
      for await (const company of auxData) {
        var executives = ""
        for await (const executive of company.ejecutivo) {
          if (company.ejecutivo.length == 1) {
            executives = executive
          }
          else {
            executives = executive + ", " + executives
          }
        }
        var obj = {
          "Nombre comercial": company.nombre,
          "Nombre del cliente": company.encargado,
          "Correo": company.email.toLowerCase(),
          "Numero de teléfono": company.telefono,
          "Giro comercial": company.giroComercial,
          "Dirección del establecimiento": company.direccion,
          "Servicios adicionales": company.serviciosAdicionales,
          "Ejecutivo asignado": executives,
          "Referenacias": company.referencias,
          "Plan contratado": company.plan,
          "Datos de facturacion": company.datosFacturacion,
          "Fecha de inicio de contrato": new Date(company.contratoDate),
          "Profesión u ocupación": company.profession,
          // "Link de acceso a drive": company.drive,
          "Prefijo": company.prefix,
          "Horario de atencion": company.startHourWork.hour + " - " + company.endHourWork.hour,
          "origin": company.origin ?? "",
          "campaign": company.campaign ?? "",
          "age": company.age ?? "",
          "gender": company.gender ?? "",
          "city": company.city ?? "",
          "help": company.needHelp ?? ""
        }
        dataToPrint.push(obj)
      }
      await Swal.fire({
        position: 'center',
        title: 'Generando archivo, por favor espere...',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timer: 2000,
      })
      await this.excelService._createReport(dataToPrint, headers, fileName, this.tabSelect == 2 ? "Clientes MIA" : "Clientes Inactivos MIA")
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async hideCompany(data) {
    try {
      var result = await Swal.fire({
        title: "¿Seguro que quiere desactivar este cliente?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, desactivar",
        cancelButtonText: "Cancelar",
      })
      if (result.isConfirmed) {
        await this.companyService.changeStatus(data.uid, false)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Cliente desactivado",
          showConfirmButton: false,
          timer: 1000,
        });
        this.fatherOnInit.emit()
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async showClient(compnyInfo: Prospect) {
    var result = await Swal.fire({
      title: "¿Seguro que quiere activar este cliente?",
      text: "El cliente aparecera nuevamente en el panel",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, activar",
      cancelButtonText: "Cancelar",
    })
    if (result.isConfirmed) {
      try {
        await this.companyService.changeStatus(compnyInfo.uid, true)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Cliente activado",
          showConfirmButton: false,
          timer: 1000,
        });
        this.fatherOnInit.emit()
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Ocurrió un error inesperado",
          text: "Si el problema persiste, favor de reportar el error"
        });
      }
    }
  }
}
