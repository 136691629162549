import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CompanyFocusService } from '../companyOnFocus/company-focus.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DiaryService {

  idCompany: string;
  diaryRef: AngularFirestoreCollection<any>;
  diaryDoc: AngularFirestoreDocument<any>;
  diary$: Observable<any>;
  sub: Subscription;

  public URIconnect = environment.URL_API;

  objToMap = (obj => {
    const mp = new Map;
    Object.keys(obj).forEach(k => { mp.set(k, obj[k]) });
    return mp;
  });
  mapToObj = (aMap => {
    const obj = {};
    aMap.forEach((v, k) => { obj[k] = v });
    return obj;
  });

  constructor(
    public http: HttpClient,
    private companyFocus: CompanyFocusService,
    private frb: AngularFireFunctions,
    private afs: AngularFirestore) {
  }

  openSpace(date, time) {
    let openDay = {
      [time]: {
        espacio: true,
        fecha: date,
        hora: time
      }
    }

    let closeDay = {
      [time]: {
        espacio: false,
        fecha: date,
        hora: time
      }
    }

    try {
      this.diaryDoc = this.afs.collection('empresas').doc(this.idCompany).collection('calendario').doc(date);
      this.diary$ = this.diaryDoc.valueChanges()
      let sub = this.diary$.subscribe(data => {
        if (!data) {
          this.afs.collection('empresas').doc(this.idCompany).collection('calendario').doc(date).set(openDay)

        } else {

          if (data[time]) {
            if (data[time].espacio === true) {
              this.afs.collection('empresas').doc(this.idCompany).collection('calendario').doc(date).update(closeDay)
            } else {
              this.afs.collection('empresas').doc(this.idCompany).collection('calendario').doc(date).update(openDay)
            }

          } else {
            this.afs.collection('empresas').doc(this.idCompany).collection('calendario').doc(date).update(openDay)
          }

        }
        this.sub.unsubscribe();
      })
    } catch (err) {
    }

  }

  getSchedule(company) {
    // var headers = { headers: new HttpHeaders() };
    return new Promise<any>((resolve, reject) => {

      var body = { userUid: company };
      this.http.post<any>('https://api-backendmia.herokuapp.com/searchUser', body).subscribe({

        next: data => resolve(data),
        error: error => reject('error')

      })
    });
  }

  notifyNewMeetting(dataForNotify) {

    return new Promise<any>((resolve, reject) => {
      this.idCompany = this.companyFocus.getCompanyOnFocus();
      var message = this.frb.functions.httpsCallable('newMeetinglast');
      message({ text: dataForNotify }).then((result) => {
        var sanitizaMessage = result;
        resolve('done');
      })
    })

  }

  addReminderNoTification(data: any) {
    this.afs.collection('reminderNotifications').add(data);
  }

  notifyCancelMeetting(dataForNotify) {

    return new Promise<any>((resolve, reject) => {
      this.idCompany = this.companyFocus.getCompanyOnFocus();
      var message = this.frb.functions.httpsCallable('cancelMeeting');
      message({ text: dataForNotify }).then((result) => {
        var sanitizaMessage = result;
        resolve('done');
      })
    })

  }

  saveCLientLog(info) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('dates-logs').add(info).then(() => {
        resolve(true);
      }).catch((err) => {
        reject(err);
      })
    })
  }

  async getDatesOfPatient(name: string) {
    try {
      var response = await this.afs.collection("dates-logs", (ref) => ref.where("client", '==', name)).get().toPromise()
      let data = response.docs.map((dates) => {
        let doc = dates.data()
        let id = dates.id
        return { id, ...doc }
      })
      return data
    } catch (error) {
      throw error
    }
  }

  deleteMeeting(idMeet) {
    this.idCompany = this.companyFocus.getCompanyOnFocus();
    this.afs.collection("empresas").doc(this.idCompany).collection("calendario").doc(idMeet).update({
      status: false,
      comments: "",
      name: "Espacio disponible"
    })
  }

  async sendMailInfo(data: any) {
    try {
      var response = await this.http.post<any>(this.URIconnect + "/sendInfo", { data: data }).toPromise();
      return response
    } catch (error) {
      throw error
    }
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error")
  }

  encodeRFC5987ValueChars(str) {
    return encodeURIComponent(str).
      // Note that although RFC3986 reserves "!", RFC5987 does not,
      // so we do not need to escape it
      replace(/['()]/g, escape). // i.e., %27 %28 %29
      replace(/\*/g, '%2A').
      // The following are not required for percent-encoding per RFC5987, 
      //  so we can allow for a little better readability over the wire: |`^
      replace(/%(?:7C|60|5E)/g, unescape);
  }

  addSpacesToMongo(obj, user) {
    return new Promise<any>((resolve, reject) => {
      var body = { userUid: user, spaces: obj };
      this.http.post<any>('https://api-backendmia.herokuapp.com/addSpace', body).subscribe({
        next: data => resolve(data),
        error: error => reject('error')

      })
    })
  }

  getCurrentCalendarByUser(user) {
    return new Promise<any>((resolve, reject) => {
      var body = { userUid: user };
      this.http.post<any>('https://api-backendmia.herokuapp.com/searchUser', body).subscribe({
        next: data => resolve(data),
        error: error => reject('error')

      })
    })
  }

}
