import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import { NbDialogService } from '@nebular/theme';
import { GenerateReporteInternoComponent } from './generate/generate.component';
import { database } from 'firebase';

@Component({
  selector: 'app-reporte-interno',
  templateUrl: './reporte-interno.component.html',
  styleUrls: ['./reporte-interno.component.scss']
})
export class ReporteInternoComponent implements OnInit {

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: true,
      edit: true,
      delete: false,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Generar reporte</span>",
      // createButtonContent: '<i class="nb-checkmark"></i>',
      // cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<span>Ver detalles</span>',
    },
    delete: {
      deleteButtonContent: '<span>Eliminar</span>',
    },
    columns: {
      report: {
        title: 'Reporte',
      },
      clientName: {
        title: 'Cliente',
      },
      date: {
        title: 'Fecha de creación',
      },
    },
    mode: 'external',
    noDataMessage: 'No hay reportes por ahora'
  };
  data_source = [];

  constructor(
    private reports_service: ReporteService,
    private router: Router,
    private dialogService: NbDialogService) {

    var reports$ = this.reports_service.getInterReport();
    reports$.subscribe(data => {
      data.forEach(element => {
        element.date = formatDate(element.date.toDate(), 'dd-MM-yyyy', 'es-MX');
      });
      this.data_source = data
    })
  }

  ngOnInit() {
  }

  openDetails(data) {
    var reportID = data.data.id;
    this.router.navigate(['/reporte_interno_details'], { queryParams: { id: reportID } })
  }
  createNew() {
    this.dialogService.open(GenerateReporteInternoComponent);
  }
}
