import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Plan } from "../../models/plan"

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  public URI = environment.URL_API
  constructor(
    private http: HttpClient
  ) { }


  async getPlans() {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Plan[] }>(this.URI + "/plans/get").toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async updatePrice(id: string, planData: Plan) {
    try {
      var response = await this.http.put<{ code: number, message: string }>(this.URI + "/plans/" + id, { data: planData }).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }
  async addPlan(plan: any) {
    try {
      var form = {
        title: plan.title,
        precio: parseInt(plan.precio),
        time: plan.time
      }
      await this.http.post<{ code: number, message: string }>(this.URI + "/plans/add", { data: form }).toPromise()
      return
    }
    catch (error) {
      throw error
    }
  }

  async deletePlan(planId: string) {
    try {
      await this.http.delete<{ code: number, message: string }>(this.URI + "/plans/delete/" + planId).toPromise()
      return
    } catch (error) {
      throw error
    }
  }
}
