import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FileProspect } from 'src/app/models/prospects';

@Component({
  selector: 'app-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss']
})
export class ContractFormComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<any>();
  public contractFile: FileProspect;
  public fileAgreement: FileProspect;
  public fileInvoice: FileProspect;
  public fileFiscal: FileProspect;
  public filePay: FileProspect;
  public showError: boolean = false
  public loadFile = false;

  constructor(
    private storage: AngularFireStorage
  ) { }

  ngOnInit() {
  }

  async saveContract(event, type) {
    this.loadFile = true
    var file = event.target.files[0];
    var split = event.target.files[0].name.split(".");
    var extension = split[split.length - 1];
    const id = Math.random().toString(36).substring(2);
    const filePath = `prospects/contracts/${id}`;
    const fileRef = this.storage.ref(filePath);
    await this.storage.upload(filePath, file).snapshotChanges().toPromise();
    var downloadURL = await fileRef.getDownloadURL().toPromise();
    switch (type) {
      case 1:
        this.contractFile = { url: downloadURL, extension: extension };
        this.newItemEvent.emit({ "type": 1, ...this.contractFile })
        this.loadFile = false
        break;
      case 2:
        this.fileAgreement = { url: downloadURL, extension: extension };
        this.newItemEvent.emit({ "type": 2, ... this.fileAgreement })
        this.loadFile = false
        break;
      case 3:
        this.fileInvoice = { url: downloadURL, extension: extension };
        this.newItemEvent.emit({ "type": 3, ... this.fileInvoice })
        this.loadFile = false
        break;
      case 4:
        this.fileFiscal = { url: downloadURL, extension: extension };
        this.newItemEvent.emit({ "type": 4, ... this.fileFiscal })
        this.loadFile = false
        break;
      case 5:
        this.filePay = { url: downloadURL, extension: extension };
        this.newItemEvent.emit({ "type": 5, ... this.filePay })
        this.loadFile = false
        break;
      default:
        break;
    }
  }

  deleteFile(type: number) {
    switch (type) {
      case 1:
        this.contractFile = undefined;
        break;
      case 2:
        this.fileAgreement = undefined
        break;
      case 3:
        this.fileInvoice = undefined
        break;
      case 4:
        this.fileFiscal = undefined
        break;
      case 5:
        this.filePay = undefined
        break;
    }
  }

}
