import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiaryService } from 'src/app/services/diary/diary.service';
import { EventsService } from 'src/app/services/events/events.service';
import Swal from 'sweetalert2';
import { DateTime } from "luxon";

@Component({
  selector: 'app-delete-spaces',
  templateUrl: './delete-spaces.component.html',
  styleUrls: ['./delete-spaces.component.scss']
})
export class DeleteSpacesComponent implements OnInit {


  public eventForm: FormGroup;
  public minDate: Date;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { companyID: any },
    public dialogRef: MatDialogRef<DeleteSpacesComponent>,
    public formBuilderService: FormBuilder,
    private eventService: EventsService,
    public diaryService: DiaryService) { }

  ngOnInit() {
    this.eventForm = this.formBuilderService.group({
      onlyOneDate: [false,],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      subject: ["", Validators.required],
      startTime: [{ hour: 9, minute: 0 }, Validators.required],
      endTime: [{ hour: 10, minute: 0 }, Validators.required],
      clientId: [this.data.companyID, Validators.required]
    })


  }

  checkedChange(e) {
    this.eventForm.controls.endDate.setValue('');
    if (e) {
      this.eventForm.get('endDate').setValidators([Validators.required]);
      this.eventForm.get('endDate').updateValueAndValidity();
    } else {
      this.eventForm.get('endDate').clearValidators();
      this.eventForm.get('endDate').updateValueAndValidity();
    }
  }

  onSelectedStartDate(event) {
    var date = new Date(event);
    date.setDate(date.getDate() + 1);
    this.minDate = date;
  }

  getCombineDateTime(date, time) {

    var hour = parseInt(time.hour);
    var min = parseInt(time.minute);
    var newDate = DateTime.fromISO(date.toISOString().split("T")[0])
      .set({ hour: hour })
      .set({ minute: min })
      .set({ second: 0 })
      .toJSDate();
    return newDate;
  }


  async delete() {
    var form = this.eventForm.value;

    if (form.startTime.hour >= form.endTime.hour) {
      return Swal.fire({
        title: 'La hora fin debe ser mayor a la hora inicio',
        icon: 'warning',
      })
    }
    var startDateTime = this.getCombineDateTime(form.startDate, form.startTime);
    var endDateTime

    if (form.endDate == '') {
      endDateTime = this.getCombineDateTime(form.startDate, form.endTime);
    } else {
      endDateTime = this.getCombineDateTime(form.endDate, form.endTime);
    }

    var buildInfo = {
      startDate: startDateTime.toString(),
      endDate: endDateTime.toString(),
      clientId: form.clientId,
    };
    await Swal.fire({
      title: 'Eliminando espacios...',
      html: '',
      timer: 7000,
      timerProgressBar: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })

    try {
      await this.eventService.deleteSpaces(buildInfo);
      this.dialogRef.close();
      Swal.fire({
        title: 'Espacios eliminados correctamente',
        text: "",
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (error) {
      Swal.fire({
        title: 'Lo sentimos',
        text: "Ha ocurrido un error al eliminar espacios",
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
      })
      this.eventForm.reset();
    }

  }

  public cancel() {
    this.dialogRef.close();
  }

}
