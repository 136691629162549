<nb-card>
    <ng-container *ngIf="!isSelectPatient">
        <nb-card-header class="text-center actions-btn">
            <span style="float: left;">
                <input nbInput type="text" [(ngModel)]="term" placeholder="Buscar paciente...">
            </span>
            <span style="float: right">
                <ng-container *ngIf="userType == 'administrador'">
                    <button nbButton status="warning" (click)="importClients()">
                        <mat-icon>cloud_upload</mat-icon> Importar pacientes
                    </button>
                    <button nbButton status="success" (click)="generateExcel()">
                        <mat-icon>cloud_download</mat-icon> Exportar pacientes
                    </button>
                </ng-container>
                <button nbButton status="info" (click)="addContact()">
                    <mat-icon>library_add</mat-icon> Nuevo paciente
                </button>
            </span>
        </nb-card-header>
        <div class="table-container">
            <table mat-table [dataSource]="company_clients | patients:term" class="mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Nombre</th>
                    <td mat-cell *matCellDef="let element">{{element.nombre}} {{element.lastName}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Correo</th>
                    <td mat-cell *matCellDef="let element">{{element.email}} </td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Telefono</th>
                    <td mat-cell *matCellDef="let element">{{element.telefono}} </td>
                </ng-container>
                <ng-container matColumnDef="comments">
                    <th mat-header-cell *matHeaderCellDef>Comentarios</th>
                    <td mat-cell *matCellDef="let element">{{element.comments}} </td>
                </ng-container>
                <ng-container matColumnDef="biling">
                    <th mat-header-cell *matHeaderCellDef>Requiere Factura</th>
                    <td mat-cell *matCellDef="let element"><a [href]="element.file" target="_blank" *ngIf="element.file != null">Ver factura</a> </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Acciones</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="btn-actions">
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="createMeet(element)">
                                    <mat-icon>date_range</mat-icon>
                                    <span>Generar cita</span>
                                </button>
                                <button mat-menu-item (click)="viewClient(element)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                    <span>Ver información detallada</span>
                                </button>
                                <button mat-menu-item (click)="editContact(element)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Editar información de contacto</span>
                                </button>
                                <button mat-menu-item (click)="deleteUser(element.id)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Eliminar</span>
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                <!-- <tr mat-footer-row *matFooterRowDef="displayColumns"></tr> -->
            </table>
            <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
            </mat-paginator>
        </div>
    </ng-container>
    <ng-container *ngIf="isSelectPatient">
        <nb-card-header>
            <div class="header-card">
                <button (click)="backPage()" nbTooltip="Regresar" class="back-btn"><mat-icon>arrow_back_ios</mat-icon>
                </button>
                <h3>{{contactInfoSelect.nombre}}</h3>
            </div>
        </nb-card-header>
        <nb-card-body class="body-card">
            <nb-tabset>
                <nb-tab tabTitle="Datos del paciente" tabIcon="person-outline" active>
                    <form [formGroup]="patientForm">
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label style="font-size: 16px">Nombre: </label>
                                <input type="text" nbInput fullWidth fieldSize="medium" formControlName="nombre" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label style="font-size: 16px">Apellido: </label>
                                <input type="text" nbInput fullWidth fieldSize="medium" formControlName="lastName" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label style="font-size: 16px">Fecha de nacimiento: </label>
                                <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                                    formControlName="birthday" [nbDatepicker]="start_date">
                                <nb-datepicker #start_date></nb-datepicker>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label style="font-size: 16px">Genero: </label>
                                <nb-select fullWidth placeholder="Genero" formControlName="gender">
                                    <nb-option value="male">Masculino</nb-option>
                                    <nb-option value="female">Femenino</nb-option>
                                </nb-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label style="font-size: 16px">Tipo de paciente: </label>
                                <input type="text" nbInput fullWidth fieldSize="medium"
                                    formControlName="typeOfPatient" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label style="font-size: 16px">Factura: </label>
                                <ng-container *ngIf="contactInfoSelect.file != null">
                                    <a [href]="contactInfoSelect.file"><mat-icon>attach_file</mat-icon> Archivo
                                        adjunto</a>
                                </ng-container>
                                <div class="wrap-documents">
                                    <input id="file-upload" #fileInput class="upload" type="file" accept=".pdf"
                                        (change)="saveFile($event)">
                                </div>
                            </div>
                            <div class="input-container">
                            </div>
                        </div>
                    </form>
                    <div class="btn-footer">
                        <button nbButton size="medium" shape="round" status="success" (click)="updatePatient()">Guardar
                            cambios</button>
                    </div>
                </nb-tab>
                <nb-tab tabTitle="Historial de citas" tabIcon="list-outline">
                    <div class="table-container">
                        <app-patient-dates-table [patientInfo]="contactInfoSelect"></app-patient-dates-table>
                    </div>
                </nb-tab>
                <nb-tab tabTitle="Expediente" tabIcon="file-outline">
                    <app-patients-expedient [patientInfo]="contactInfoSelect"></app-patients-expedient>
                </nb-tab>

            </nb-tabset>
        </nb-card-body>
    </ng-container>

</nb-card>