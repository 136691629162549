import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'patients'
})
export class PatientsPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultPosts = [];
    for(const post of value){
      var filter = post.nombre.toUpperCase()
      if(filter.indexOf(arg.toUpperCase()) > -1){
         resultPosts.push(post);
      };
    };
    return resultPosts;
  }

}
