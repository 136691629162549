import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  
  private URI = environment.URL_API

  constructor(
    public http: HttpClient,
    private afs: AngularFirestore,
    public storage: AngularFireStorage
  ) { }

  async sendMessage(message) {
    try {
      const res = await this.http.post<any>(this.URI + '/chat/message', message).toPromise();
      return res
    } catch (error) {
      throw error
    }
  }

  async newMessageNotification(notification) {
    try {
      await this.afs.collection('chat-notifications').add(notification);
      return
    } catch (error) {
      return false
    }
  }

}
