<section>
  <div class="loadspinner" *ngIf="loader == true">Cargando&#8230;</div>
  <div class="header-container">
    <h3><mat-icon (click)="closeDialog()" matTooltip="Volver a clientes">keyboard_arrow_left</mat-icon> Nueva conferencia
    </h3>
    <div class="button-wrap">
      <button mat-button class="success" (click)="submit()"><mat-icon>save</mat-icon> Guardar</button>
    </div>
  </div>
  <ng-container *ngIf="!loader">
    <div class="card">
      <form [formGroup]="zoomForm">
        <div class="row-flex">
          <div class="form-group">
            <label for="">Cliente</label>
            <input type="text" placeholder="Seleccione una opción o escriba un nombre" matInput formControlName="client"
              [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.nombre.toUpperCase()}}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="form-group">
            <label for="">Asunto</label>
            <input type="text" formControlName="subject">
          </div>
        </div>

        <div class="row-flex">
          <div class="form-group">
            <label for="">Agentes</label>
            <mat-select placeholder="Seleccione los participantes" formControlName="agents" multiple>
              <mat-option *ngFor="let item of agentList" [value]="item.uid">
                {{item.nombre}}
              </mat-option>
            </mat-select>
          </div>
          <div class="form-group">
            <label for="">Fecha de conferencia</label>
            <input matInput [matDatepicker]="picker" formControlName="meetDate" (click)="picker.open()"
              placeholder="dd/mm/yyyy">
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="row-flex">
          <div class="form-group">
            <label for="">Hora de la conferencia </label>
            <ngb-timepicker formControlName="meetHour" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
          </div>
          <div class="form-group">
            <label for="">Enlace de la conferecia</label>
            <input type="text" formControlName="url">
          </div>
        </div>
      </form>
    </div>
  </ng-container>

</section>
