<nb-card>
    <nb-card-header>
        Usuarios
    </nb-card-header>

    <nb-card-body>
        <div>
            <ng2-smart-table [settings]="settingsUsers" [source]="data_source_2"
                (edit)="openUpdateCompany($event)" (delete)="deleteCompany($event)">
            </ng2-smart-table>
        </div>
    </nb-card-body>
</nb-card>