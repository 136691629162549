import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import { ReportProfitability } from '../../../models/report-profitability';
import { ExcelService } from 'src/app/services/excel/excel.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reporte-rentabilidad-details',
  templateUrl: './reporte-rentabilidad-details.component.html',
  styleUrls: ['./reporte-rentabilidad-details.component.scss']
})
export class ReporteRentabilidadDetailsComponent implements OnInit {

  public totalTime: string;
  public total_clients: number = 0;
  public total_facturado: number = 0;
  public total_equivalente: number = 0;
  public hours_work: number = 0
  public loader = true;
  public data_source!: ReportProfitability;

  constructor(
    private reportService: ReporteService,
    private route: ActivatedRoute,
    private router: Router,
    private excelService: ExcelService
  ) { }

  async ngOnInit() {
    var reportID = this.route.snapshot.paramMap.get('id') ?? '';
    if (reportID != '') {
      this.data_source = await this.getReport(reportID)
      this.loader = false
      this.sumasTotales();
    } else {
      this.router.navigate(['/reporte-rentabilidad'])
    }
  }

  async getReport(reportID: string) {
    try {
      const response = await this.reportService.getReportProfitabilityById(reportID)
      response.agents.map((data) => {
        data['equivalentes'] = data.total_invoiced / 2490
      })
      return response
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar el reporte',
        icon: 'error',
        text: 'Si el problema persiste favor de comunicarlo con un administrador'
      })
    }
  }

  sumasTotales() {
    var total_trabajado = 0
    var total_clientes = 0
    var total_factura = 0
    var total_equivalente = 0
    this.data_source.agents.forEach((element: any) => {
      total_trabajado += element.seconds_work
      total_clientes += element.numbers_clients
      total_factura += element.total_invoiced
      total_equivalente += element.equivalentes
    });
    this.totalTime = this.secondsToHms(total_trabajado)
    this.hours_work = total_trabajado / 3600
    this.total_clients = total_clientes
    this.total_facturado = total_factura
    this.total_equivalente = total_equivalente
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  async excel() {
    try {
      const headers = [
        "Nombre del asistente",
        "Tiempo trabajado",
        "Total horas trabajadas",
        "No. Clientes asignados",
        "Clientes equivalentes Plata",
        "Total facturado"];
      var dataToPrint = []
      for await (const client of this.data_source.agents) {
        var obj = {
          "Nombre del cliente": client.name,
          "Ejecutivo asignado": client.timeWork,
          "Total de llamadas": (client.seconds_work / 3600),
          "Tiempo atentido en llamadas": client.numbers_clients,
          "Total de horas en llamadas": client.equivalentes,
          "Total de tareas realizadas": client.total_invoiced,
        }
        dataToPrint.push(obj)
      }
      await this.excelService._createReport(dataToPrint, headers, this.data_source.report, "Reporte de rentabilidad", [this.totalTime, this.hours_work.toFixed(2), this.total_clients, this.total_equivalente.toFixed(2), this.formatCurrency(this.total_facturado)], [3, 5], [6])
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al generar el excel',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        position: 'center',
      })
    }
  }

  formatCurrency(price: any): String {
    var lng = price.toLocaleString('en-US', { style: 'currency', currency: 'MXN' });
    return lng.slice(2)
  }

}
