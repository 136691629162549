import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ServiceMinutesService } from 'src/app/services/service-minutes/service-minutes.service';
import { Task } from '../../models/task';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-edit-timetask',
  templateUrl: './edit-timetask.component.html',
  styleUrls: ['./edit-timetask.component.scss']
})
export class EditTimetaskComponent implements OnInit {

  [x: string]: any
  public editTime!: FormGroup
  public taskList: Task[] = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public componentRef: any,
    public ref: MatDialogRef<EditTimetaskComponent>,
    public formBuilder: FormBuilder,
    private taskService: ServiceMinutesService,
  ) {
    this.editTime = new FormGroup({
      tasks: this.formBuilder.array([])
    })
  }

  get tasks(): FormArray {
    return this.editTime.get('tasks') as FormArray
  }

  async ngOnInit() {
    this.taskList = await this.taskService.getTask()
    const form = this.componentRef
    if (form.editTasks != undefined) {
      for await (const element of form.editTasks) {
        var findTask = this.taskList.filter(task => task.title == element.title)
        this.tasks.push(
          this.formBuilder.group({
            title: findTask[0].title,
            minutes: element.minutes
          })
        )
      }
    }
    else {
      for await (const element of form.tareasRecurrentes) {
        var findTask = this.taskList.filter(task => task._id == element)
        this.tasks.push(
          this.formBuilder.group({
            title: findTask[0].title,
            minutes: findTask[0].minutes
          })
        )
      }
    }
  }

  async submit() {
    var form = this.editTime.value
    this.ref.close({ form: form.tasks })
  }

}
