<nb-card>
    <nb-card-header class="text-center actions-btn">
        <span style="float: left;">
            <input nbInput type="text" [(ngModel)]="term"
                placeholder="Buscar numero...">
        </span>
        <span style="float: right">
            <ng-container *ngIf="userType == 'administrador'">
                <button nbButton status="warning" (click)="importClients()">
                    <mat-icon>cloud_upload</mat-icon> Importar clientes
                </button>
                <button nbButton status="success" (click)="generateExcel()">
                    <mat-icon>cloud_download</mat-icon> Exportar clientes
                </button>
            </ng-container>
            <button nbButton status="info" (click)="addContact()">
                <mat-icon>library_add</mat-icon> Nuevo cliente
            </button>
        </span>
    </nb-card-header>
    <div class="row card-clients-row">
        <ng-container *ngFor="let item of company_clients | filter:term">
            <div class="col-md-4">
                <nb-card>
                    <nb-card-header class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4 btns-users">
                            <button (click)="editContact(item)"
                                class="edit-btn">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button (click)="deleteUser(item.id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <div>
                            <p><strong>Nombre: </strong> {{item.nombre == "" ? 'Sin
                                definir' :
                                item.nombre}}</p>
                            <p><strong>Email: </strong> {{item.email == "" ? 'Sin
                                definir' :
                                item.email}}</p>
                            <p><strong>Telefono: </strong> {{item.telefono}}</p>
                            <p><strong>Comentarios: </strong> {{item.comments}}</p>
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
        </ng-container>
    </div>
</nb-card>