// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
    apiKey: "AIzaSyCVWNnuFVke45CimYe9RH7H32gYNBCwZCA",
    authDomain: "miasistema.firebaseapp.com",
    databaseURL: "https://miasistema.firebaseio.com",
    projectId: "miasistema",
    storageBucket: "miasistema.appspot.com",
    messagingSenderId: "535313007371",
    appId: "1:535313007371:web:a977aec0771b594c26980e"
  },
  // URL_API: 'http://localhost:8080/api',
  URL_API: "https://miasistente.herokuapp.com/api"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
