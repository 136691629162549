import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NbDialogRef } from '@nebular/theme';
import { DateTime } from 'luxon';
import { DiaryComponent } from 'src/app/pages/diary/diary.component';
import { EventsService } from 'src/app/services/events/events.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-meet',
  templateUrl: './add-meet.component.html',
  styleUrls: ['./add-meet.component.scss']
})
export class AddMeetComponent implements OnInit {
  [x: string]: any
  public eventForm: FormGroup
  public patientInfo: any;
  constructor(
    private formbuilder: FormBuilder,
    public ref: NbDialogRef<AddMeetComponent>,
    private eventService: EventsService
  ) { }

  ngOnInit() {
    this.eventForm = this.formbuilder.group({
      date: ["", Validators.required],
      time: [{ hour: 9, minute: 0 }, Validators.required],
      subject: ["", Validators.required],
      comments: ["",Validators.required]
    })
    this.patientInfo = this.ref.componentRef.instance.info;
    var date = new Date();
    date.setDate(date.getDate());
    this.minDate = date;
  }

  async save() {
    try {
      var form = this.eventForm.value
      var space = {
        startDate: form.date.toString(),
        endDate: form.date.toString(),
        startTime: this.getCombineDateTime(form.date, form.time).toString(),
        endTime: this.getCombineDateTime(form.date, { hour: form.time.hour + 1, minute: form.time.minute }).toString(),
        interval: 60,
        clientId: DiaryComponent.companyIdFocus
      };
      var response = await this.eventService.addSpaces(space);
      var dataToSave = {
        comments: form.comments,
        subject: form.subject,
        customer: this.patientInfo.nombre,
        _id: response[0]._id
      }
      await this.eventService.createEvent(dataToSave, DiaryComponent.companyIdFocus);
      await Swal.fire({
        title: 'Cita creada con exito !',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      })
      this.ref.close()
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error inesperdado al crear la cita',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  getCombineDateTime(date: Date, time) {
    var day = new Date(new Date(date).setHours(0, 0, 0, 0))
    var hour = parseInt(time.hour);
    var min = parseInt(time.minute);
    var newDate = DateTime.fromISO(day.toISOString().split("T")[0])
      .set({ hour: hour })
      .set({ minute: min })
      .set({ second: 0 })
      .toJSDate();
    return newDate;
  }

}
