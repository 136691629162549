import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { asBlob } from 'html-docx-js-typescript';
import Swal from 'sweetalert2';
import { saveAs } from "file-saver";
import { AddInfoComponent } from 'src/app/pages/diary/add-info/add-info.component';
import { ContactService } from 'src/app/services/contact/contact.service';
import { Expedient } from 'src/app/models/expedient';
import { Contact } from 'src/app/models/contact';

@Component({
  selector: 'app-patients-expedient',
  templateUrl: './patients-expedient.component.html',
  styleUrls: ['./patients-expedient.component.scss']
})
export class PatientsExpedientComponent implements OnInit {

  @Input() patientInfo: Contact
  public disclaimer: boolean;
  public docs: Expedient[] = [];
  public name_company: any;
  public term = '';
  public filterDocuments = []
  @ViewChild("htmlDoc") html;

  constructor(
    private dialogService: NbDialogService,
    private contactService: ContactService
  ) { }

  async ngOnInit() {
    this.docs = await this.getExpedientOfPatient(this.patientInfo.id)
  }

  async getExpedientOfPatient(patientId: string) {
    try {
      return this.contactService.getExpedientOfPatient(patientId)
    } catch (error) {
      await Swal.fire({
        icon: "error",
        title: "Ocurrió un error al consultar el expediente del paciente",
        text: "Si el problema persiste, favor de reportar el error",
        confirmButtonText: "Aceptar"
      });
    }
  }

  async generateWord() {
    const htmlString = this.html.nativeElement.innerHTML;
    const data = await asBlob(htmlString, {
      orientation: "portrait",
      margins: { top: 300, bottom: 300 },
    });
    saveAs(data, `clientes_de_${this.name_company}.docx`);
  }

  addNewInfoCompany() {
    this.dialogService.open(AddInfoComponent).onClose.subscribe(async (data) => {
      if (data != undefined) {
        try {
          var expedient = {
            extension: data.extension,
            patientId: this.patientInfo.id,
            url: data.file,
            title: data.title
          }
          await this.contactService.addExpedient(expedient);
          await Swal.fire({
            position: "center",
            icon: "success",
            title: "Información guardada",
            showConfirmButton: false,
            timer: 1000,
          });
          this.ngOnInit();
        } catch (error) {
          await Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocurrió un error al añadir el documento",
            text: "Si el problema persiste, favor de reportar el error",
            showConfirmButton: true,
          });
        }
      }
    });
  }

  openImageCotizacion(url) {
    window.open(url);
  }

  async deleteDoc(expedientId:string) {
    var result = await Swal.fire({
      title: "¿Desea eliminar este elemento?",
      text: "Esta acción no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    })
    if (result.isConfirmed) {
      try {
        await this.contactService.deleteExpedient(expedientId)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Datos eliminados",
          showConfirmButton: false,
          timer: 1000,
        });
        this.ngOnInit();
      } catch (error) {
        await Swal.fire({
          position: "center",
          icon: "error",
          title: "Ocurrió un error al borrar el documento",
          text: "Si el problema persiste, favor de reportar el error",
          showConfirmButton: true,
        });
      }
    }
  }

  async findIntoArray(word: string) {
    try {
      var completeSearch = [];
      for await (const documents of this.docs) {
        if (documents.tags != undefined) {
          var expresion = new RegExp(`${word}.*`, "i")
          var filterDocs = documents.tags.filter(keyword => expresion.test(keyword))
          if (filterDocs.length >= 1) {
            completeSearch.push(documents)
          }
        }
      }
      this.filterDocuments = completeSearch

      if (this.filterDocuments.length == 0) {
        await Swal.fire({
          position: "center",
          icon: "error",
          title: "No se encontraron archivos",
          text: "Intente usar otras palabras, o verifique que están bien escritas",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      await Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error al buscar un documento",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true,
      });
    }
  }

}
