<section>
    <div class="img-box">
        <img src="assets/login.jpg" alt="" />
    </div>

    <div class="content-box">
        <div class="logo-box">
            <img src="assets/logo_circle.png" alt="" />
        </div>

        <div class="form-box">
            <h2>Login</h2>

            <form action="" autocomplete="off" [formGroup]="loginForm">
                <div class="input-box">
                    <span>Email</span>
                    <input type="email" name="" nbButton placeholder="Correo electrónico" formControlName="email" />
                </div>

                <div class="input-box">
                    <span>Password</span>
                    <input type="password" nbButton placeholder="Contraseña" formControlName="password" />
                </div>
                <div class="input-box">
                    <button mat-flat-button (click)="submit()" type="submit" [disabled]="loginForm.invalid">Iniciar
                        Sesión</button>
                </div>
            </form>
            <!-- <p class="error-msg" *ngIf="showError">{{loginError.message}}</p>
            <div *ngIf="loading" class="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div> -->
        </div>
    </div>
</section>