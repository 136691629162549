import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { CompanyFocusService } from '../companyOnFocus/company-focus.service';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ReportAgents } from '../../models/report-agent';
import { environment } from '../../../environments/environment';
import { ReportProfitability } from '../../models/report-profitability';
import { ReportClient } from '../../models/report-client';
import { ClientProfitability } from '../../models/client-profitablility';

@Injectable({
    providedIn: 'root'
})
export class ReporteService {

    public urlConnect = environment.URL_API

    constructor(
        public afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        public companyFocus: CompanyFocusService,
        private http: HttpClient
    ) { }

    async createGeneralReport(compnayId: string, formValue: any) {
        try {
            var response = await this.http.post<{ code: number; message: string; data: ReportClient[] }>(this.urlConnect + "/reports/client-report-panel/" + compnayId, formValue).toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }

    getClientReport() {

        var ref: AngularFirestoreCollection = this.afs.collection('client-reports', ref => ref.orderBy('date', 'desc'));
        var reports$: Observable<any> = ref.snapshotChanges().map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data() as any;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
        });

        return reports$;
    }

    async getGeneralClientReport() {
        try {
            var response = await this.http.get<{ code: number; message: string; data: ReportClient[] }>(this.urlConnect + "/reports/clients/get-all").toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }

    getInterReport() {

        var ref: AngularFirestoreCollection = this.afs.collection('reportes-internos', ref => ref.orderBy('date', 'desc'));
        var reports$: Observable<any> = ref.snapshotChanges().map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data() as any;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
        });
        return reports$;
    }

    async addProfitabilityReport(data: ReportProfitability) {
        try {
            var response = await this.http.post<{ code: number, message: string, data: any }>(this.urlConnect + "/reports/profitability/add", { report: data }).toPromise()
            return response
        } catch (error) {
            throw error
        }
    }

    async getProfitabilityReport() {
        try {
            var response = await this.http.get<{ code: number, message: string, data: ReportProfitability[] }>(this.urlConnect + "/reports/profitability/get-all").toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getAgentsReport() {
        try {
            var response = await this.http.get<{ code: number, message: string, data: ReportAgents[] }>(this.urlConnect + "/reports/agents/get-all").toPromise()
            return response
        } catch (error) {
            throw error
        }
    }

    async getReportById(id: string) {
        try {
            var response = await this.http.get<{ code: number, message: string, data: ReportAgents }>(this.urlConnect + "/reports/agents/get/" + id).toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }

    async addAgentReport(data: ReportAgents) {
        try {
            var response = await this.http.post<{ code: number, message: string, data: ReportAgents }>(this.urlConnect + "/reports/agents/add", { report: data }).toPromise()
            return response
        } catch (error) {
            throw error
        }
    }

    async getReportProfitabilityById(id: string) {
        try {
            var response = await this.http.get<{ code: number, message: string, data: ReportProfitability }>(this.urlConnect + "/reports/profitability/get/" + id).toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }

    async deleteReport(id: string) {
        try {
            await this.afs.collection('client-general-reports').doc(id).delete();
            return true;
        } catch (error) {
            throw error
        }
    }

    async deleteProfitabilityReport(id: string) {
        try {
            await this.http.delete<{ code: number, message: string }>(this.urlConnect + "/reports/profitability/delete/" + id).toPromise()
            return true
        } catch (error) {
            throw error
        }
    }

    async deleteClientReport(id: string) {
        try {
            await this.http.delete<{ code: number, message: string }>(this.urlConnect + "/report/profitability-client/delete/" + id).toPromise()
            return true
        } catch (error) {
            throw error
        }
    }

    async deleteAgentReport(id: string) {
        try {
            await this.http.delete<{ code: number, message: string }>(this.urlConnect + "/reports/agents/delete/" + id).toPromise()
            return true;
        } catch (error) {
            throw error
        }
    }

    async getProfibilityClientReport() {
        try {
            var response = await this.http.get<{ code: number, message: string, data: ClientProfitability[] }>(this.urlConnect + "/report/profitability-client/get-all").toPromise()
            return response
        } catch (error) {
            throw error
        }
    }

    async addClientReport(data: ClientProfitability) {
        try {
            var response = await this.http.post<{ code: number, message: string, data: any }>(this.urlConnect + "/report/profitability-client/add", { report: data }).toPromise()
            return response
        } catch (error) {
            throw error
        }
    }

    async getClientProfitabilityById(id: string) {
        try {
            var response = await this.http.get<{ code: number, message: string, data: ClientProfitability }>(this.urlConnect + "/report/profitability-client/" + id).toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getReportData(uid: string, initDate: number, endDate: number) {
        try {
            var response = await this.http.get<{ code: number, message: string, data: any[] }>(this.urlConnect + "/reports/agents/get-info/" + uid + "/" + initDate + "/" + endDate).toPromise()
            return response.data
        } catch (error) {
            throw error
        }
    }
}
