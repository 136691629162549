import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-recado',
  templateUrl: './add-recado.component.html',
  styleUrls: ['./add-recado.component.scss']
})
export class AddRecadoComponent implements OnInit {


  recadoForm: FormGroup;
  labelDateToday = new Date();
  constructor(
    public ref: NbDialogRef<AddRecadoComponent>,
    public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.recadoForm = this.formBuilder.group({
      subject: ["", Validators.required],
      body: ["", Validators.required],
      date: [""],
      timestamp: [""],
      time: [""]
    });
  }

  submit() {
    var date = formatDate(new Date(), "dd/MM/yyyy", "es-MX");
    var time = formatDate(new Date(), "hh:mm a", "es-MX");
    this.recadoForm.controls.date.setValue(date);
    this.recadoForm.controls.time.setValue(time);
    this.recadoForm.controls.timestamp.setValue(new Date());
    var form = this.recadoForm.value;
    this.ref.close(form)
  }

}
