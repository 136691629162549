import { Component } from '@angular/core';
import { PendingsService } from '../../services/pendings/pendings.service'
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Notification } from '../../models/notifications';
import { UserService } from 'src/app/services/users/user.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./pendings-list.component.scss'],
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastsContainer {
  public toast_pendings = [];
  public currentPendingsUser = []
  public show = false;
  public TimeNow = new Date().toISOString();
  public currentUser: string
  public pendingForUsers = []
  public userType: string
  public today = new Date()
  public notificationList = []
  public clientsNotifications = []
  public showNotification = false;
  public userLists = [];
  constructor(
    public pendingService: PendingsService,
    private route: Router,
    private afAuth: AngularFireAuth,
    private userService: UserService
  ) {
    this.userType = localStorage.getItem("currentUser")
  }

  async ngOnInit() {
    var session = this.afAuth.authState.pipe(first());
    if (session == null) return;
    session.subscribe(userInfo => this.currentUser = userInfo?.uid)

    this.notificationsListener();
    this.userLists = await this.userService.getActiveUsers()
  }

  async notificationsListener() {
    var pendingsLogs$ = this.pendingService.getLogs();
    pendingsLogs$.subscribe(async notifications => {

      var arrayNotifications = []
      var userPendings = []
      var pendingInTime = []
      var clientsList = []
      this.notificationList = []
      notifications.map(notification => {
        var userInfo = this.userLists.filter(user => user.id == notification.who)
        if (userInfo.length != 0) {
          notification["pendingFor"] = userInfo[0].nombre
        }
        if (!notification.isRead) {
          if (this.currentUser == notification.who) {
            // Validacion por si la tarea se esta ejecutando en la hora
            if (notification.title != "Tarea atrasada" && notification.title != "Actualización de tarea" && notification.title != "Pendiente a punto de concluir") {
              pendingInTime.push(notification)
            }
            else {
              //Por si el operador tiene una tarea atrasada
              userPendings.push(notification)
            }
          }
          if (notification.title == "Tarea atrasada" && this.userType != 'administrador') {
            //Validacion para mostrar las pendientes de los clientes a punto de concluir
            clientsList.push(notification)
          }
          if (notification.title == "Tarea atrasada" || notification.title == "Actualización de tarea" || notification.title == "Pendiente a punto de concluir") {
            arrayNotifications.push(notification)
          }
        }
      })
      this.clientsNotifications = clientsList
      this.pendingForUsers = userPendings
      this.currentPendingsUser = pendingInTime
      this.notificationList = arrayNotifications

      setTimeout(async () => {
        for await (const notification of this.notificationList) {
          await this.pendingService.closeNotification(notification.id)
        }
        for await (const notification of this.currentPendingsUser) {
          await this.pendingService.closeNotification(notification.id)
        }
        for await (const notification of this.pendingForUsers) {
          await this.pendingService.closeNotification(notification.id)
        }
        for await (const notification of this.clientsNotifications) {
          await this.pendingService.closeNotification(notification.id)
        }
        this.notificationList = []
        this.pendingForUsers = []
        this.currentPendingsUser = []
        this.clientsNotifications = []
      }, 10000);
    })
  }

  async close(toast) {
    try {
      await this.pendingService.removePendingForUser(toast);
    } catch (error) {
      console.log(error);
    }
  }

  async closeNotification(item: Notification) {
    try {
      this.showNotification = true
      await this.pendingService.closeNotification(item.id)
    } catch (error) {
      Swal.fire({
        title: 'Oucrrió un error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
    this.showNotification = false
  }

  async closeToast() {
    this.show = false
  }
  async goPending() {
    this.show = false
    this.route.navigate(["/pendings"])
  }
}
