<div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
<section>
    <div class="header-container" *ngIf="currentUser == 'administrador' ">
        <h3>Reportes de Agentes</h3>
        <div class="button-wrap">
            <button mat-button (click)="createNew()"><mat-icon>add</mat-icon> Generar reporte</button>
        </div>
    </div>
    <div class="header-container" *ngIf="currentUser != 'administrador' ">
        <h3>Reportes de {{userInfo.nombre}}</h3>
        <div class="button-wrap">
            <button mat-button (click)="createNew()"><mat-icon>add</mat-icon> Generar reporte</button>
        </div>
    </div>
    <div class="card" *ngIf="currentUser == 'administrador' ">
        <ng2-smart-table [settings]="settings" [source]="data_source" (edit)="openDetails($event.data)"
            (delete)="delete($event.data.id)">
        </ng2-smart-table>
    </div>
    <div class="card" *ngIf="currentUser != 'administrador' ">
        <ng2-smart-table [settings]="settingsAgents" [source]="data_source" (edit)="openDetails($event.data)"
            (delete)="delete($event)" (create)="createNew()">
        </ng2-smart-table>
    </div>
</section>