import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'app-missing-calls',
    templateUrl: './missing-calls.component.html',
    styleUrls: ['./missing-calls.component.scss']
})
export class MissingCallsComponent implements OnInit {

    [x: string]: any;
    public clientName: string;
    public calls: Array<any> = [];
    constructor(
        public ref: NbDialogRef<MissingCallsComponent>,
    ) { }

    ngOnInit() {
        this.calls = this.ref.componentRef.instance.calls;
        this.clientName = this.ref.componentRef.instance.clientName;
    }

}
