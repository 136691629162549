import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Observable } from 'rxjs';
import { PendingsService } from 'src/app/services/pendings/pendings.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-add-pending',
    templateUrl: './add-pending.component.html',
    styleUrls: ['./add-pending.component.scss']
})
export class AddPendingComponent implements OnInit {

    [x: string]: any;
    addForm: FormGroup;
    labelDateToday = new Date();
    entryOptions$: Observable<any>;
    hide = false;
    minDate: Date;

    constructor(
        public ref: NbDialogRef<AddPendingComponent>,
        public formBuilder: FormBuilder,
        public toastService: PendingsService
    ) { }

    change(event: boolean) {
        this.hide = event;
    }

    onSelectedStartDate(event) {
        var date = new Date(event);
        date.setDate(date.getDate() + 1);
        this.minDate = date;
    }

    ngOnInit() {
        this.addForm = this.formBuilder.group({
            date: [''],
            limitTime: [''],
            time: [''],
            title: [""],
            comments: [""],
            new: [true],
            status: ['pending'],
            checked: [false],
            repeat: [null],
            dateFinal: [''],
        });
        this.addForm.controls.time.setValue({ hour: this.labelDateToday.getHours()+1, minute: 0, second: 0 });
    }

    async submit() {
        var isChecked = this.addForm.value.checked;
        this.addForm.removeControl('checked')
        var form = this.addForm.value;
        let formattedDate = formatDate(form.date, 'yyyy-MM-dd', 'en-US');
        form.limitTime = new Date(`${formattedDate}`);
        form.limitTime.setDate(form.limitTime.getDate() + 1);
        form.limitTime.setHours(form.time.hour, form.time.minute, 0)
        if (form.date == '' || form.title == '' || form.comments == '') {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Todos los campos son obligatorios',
                showConfirmButton: true,
            })
        } else {
            if (isChecked == true) {
                let repaet = form.repeat;
                switch (repaet) {
                    case "diaria":
                        {
                            if (form.dateFinal <= form.date) {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'warning',
                                    title: 'La Fecha final debe ser mayor a la inicial',
                                    showConfirmButton: true,
                                })
                                break
                            }
                            else {
                                this.ref.close(form);
                                break
                            }
                        }
                    case "semanal":
                        {
                            var finalReal = new Date(form.date).setDate(new Date(form.date).getDate() + 6);
                            var final = new Date(form.dateFinal).setDate(new Date(form.dateFinal).getDate());
                            if (final <= finalReal) {
                                console.log("fallo")
                                Swal.fire({
                                    position: 'center',
                                    icon: 'warning',
                                    title: 'La Fecha final debe ser mayor a la inicial por al menos 7 días',
                                    showConfirmButton: true,
                                })
                                break
                            }
                            else {
                                this.ref.close(form);
                                break
                            }
                        }
                    case "mensual":
                        {
                            var finalReal = new Date(form.date).setMonth(new Date(form.date).getMonth() + 1);
                            var final = new Date(form.dateFinal).setMonth(new Date(form.dateFinal).getMonth());
                            if (final < finalReal) {
                                console.log("fallo")
                                Swal.fire({
                                    position: 'center',
                                    icon: 'warning',
                                    title: 'La Fecha final debe ser mayor a la inicial por al menos 1 mes',
                                    showConfirmButton: true,
                                })
                                break
                            }
                            else {
                                this.ref.close(form);
                                break
                            }
                        }
                }
            }
            else {
                this.ref.close(form);
            }
        }
    }

}
