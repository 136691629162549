import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { formatDate } from '@angular/common';
import { DiaryService } from "../../../services/diary/diary.service";
import Swal from 'sweetalert2';
import { CompanyFocusService } from '../../../services/companyOnFocus/company-focus.service';

@Component({
  selector: 'app-cita-info',
  templateUrl: './cita-info.component.html',
  styleUrls: ['./cita-info.component.scss']
})
export class CitaInfoComponent implements OnInit {

  [x: string]: any;
  data = [];
  objToMap = (obj => {
    const mp = new Map;
    Object.keys(obj).forEach(k => { mp.set(k, obj[k]) });
    return mp;
  });
  mapToObj = (aMap => {
    const obj = {};
    aMap.forEach((v, k) => { obj[k] = v });
    return obj;
  });
  constructor(
    private focusService: CompanyFocusService,
    public diaryService: DiaryService,
    public ref: NbDialogRef<CitaInfoComponent>) { }

  ngOnInit() {
    this.data.push(this.ref.componentRef.instance[0]);

  }

  cancelarCita() {
    Swal.fire({
      title: '¿Seguro que quiere cancelar esta cita?',
      text: "Esta acción no se puede revertir",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cancelar',
      cancelButtonText: 'Cerrar',
    }).then((result) => {
      if (result.value) {
        var dataForNotify = [];
        var companyID = this.focusService.getCompanyOnFocus();
        var dateSpace = formatDate(this.data[0].start, 'yyyy-MM-dd', 'en-US');
        var timeSpace = formatDate(this.data[0].start, 'HH:mm', 'en-US');
        this.diaryService.getCurrentCalendarByUser(companyID).then(res => {
          var map = this.objToMap(res.usuario.spaces);
          for (let e of map.entries()) {
            var key = e[0];
            if (key == dateSpace) {
              var horas = e[1];
              for (let h in horas) {
                if (h == timeSpace) {
                  horas[h].extendedProps.available = false;
                  horas[h].title = 'Espacio disponible';
                  horas[h].color = '#00b887';
                  dataForNotify.push(horas[h]);
                }
              }
            }
          }
          var newObj = this.mapToObj(map);
          this.diaryService.addSpacesToMongo(newObj, companyID).then(res => {
            if (res.codigo == 200) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Cita cancelada correctamente',
                showConfirmButton: false,
                timer: 1300
              }).then(() => {
                dataForNotify.push({ companyID: companyID })
                this.diaryService.notifyCancelMeetting(dataForNotify);
              })
              this.ref.close();
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Uupss... ha ocurrido un error, intente de nuevo máa tarde',
                showConfirmButton: false,
                timer: 1300
              })
              this.ref.close();
            }
          })
        });

      }
    });
  }

}
