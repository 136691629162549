import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { AddPendingsUsersComponent } from 'src/app/components/add-pendings-users/add-pendings-users.component';
import { PendingsService } from 'src/app/services/pendings/pendings.service';
import { UserService } from 'src/app/services/users/user.service';
import Swal from 'sweetalert2';
import { EditPendingUsersComponent } from '../../components/edit-pending-users/edit-pending-users.component';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { formatDate } from '@angular/common';
import { PendingUser } from 'src/app/models/pending-user';
import { DateTime } from 'luxon';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-pendings-users',
  templateUrl: './pendings-users.component.html',
  styleUrls: ['./pendings-users.component.scss']
})
export class PendingsUsersComponent implements OnInit {

  public pendings: MatTableDataSource<any>;
  public userList = [];
  public userType: string
  public currentUser: any;
  public displayColumns = ["title", "comments", "limitDate", "pendingFor", "status", "action"]
  public displayUserColumns = ["title", "comments", "limitDate", "status", "action"]
  private tokenAdmins = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dialogService: NbDialogService,
    private pendingService: PendingsService,
    private userService: UserService,
    private authService: AuthService,
    private _liveAnnouncer: LiveAnnouncer,
    private notificationService: NotificationsService
  ) { }

  async ngOnInit() {
    this.userType = localStorage.getItem("currentUser");
    await this.getData()
  }

  async getData() {
    this.currentUser = this.authService.currentuser();
    await this.getUserName()
    var pendingsList = await this.getPendings()
    this.pendings = new MatTableDataSource(pendingsList)
    this.pendings.paginator = this.paginator
    this.pendings.sort = this.sort
    this.tokenAdmins = await this.getAdminsTokens()
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  ngOnChanges(): void {
    this.getPendingsForUser()
  }
  async getUserName() {
    this.userList = await this.userService.getActiveUsers();
  }

  async addPending() {
    this.dialogService.open(AddPendingsUsersComponent).onClose.subscribe(res =>
      this.getData()
    );
  }

  async deletePending(id: string) {
    try {
      let result = await Swal.fire({
        title: 'Desea eliminar este pendiente',
        icon: 'warning',
        text: 'El pendiente sera eliminado de la lista',
        confirmButtonText: "Sí, Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar"
      })
      if (result.isConfirmed) {
        try {
          await this.pendingService.removePendingForUser(id);
          await Swal.fire({
            title: 'Pendiente eliminado',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false
          })
          this.ngOnInit();
        } catch (error) {
          await Swal.fire({
            title: 'Ocurrió un error al eliminar el pendiente',
            icon: 'error',
            showConfirmButton: true
          })
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error inesperado',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showConfirmButton: true
      })
    }
  }

  async getPendings() {
    try {
      if (this.userType == "administrador") {
        var response = await this.pendingService.getForAdmins()
        response.data.map(async (pending: PendingUser) => {
          for await (const user of this.userList) {
            if (user.id == pending.userId) {
              pending["pendingFor"] = user.nombre
            }
            if (pending.userId == "Todos") {
              pending["pendingFor"] = "Todos"
            }
          }
          var stringDate = formatDate(pending.limitTime, 'dd', 'es-MX') + " de " + formatDate(pending.limitTime, 'MMMM', 'es-MX')
            + " del " + formatDate(pending.limitTime, 'yyyy', 'es-MX') + " a las " + formatDate(pending.limitTime, 'hh:mm a', 'es-MX')
          pending["dateFormat"] = stringDate
        })
        return response.data;
      } else {
        return await this.getPendingsForUser();
      }
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error al obtener los pendientes',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showConfirmButton: true
      })
      this.getData();
    }
  }

  async getAdminsTokens() {
    try {
      var tokens: string[] = []
      const response = await this.userService.getAdmins()
      for await (const admin of response) {
        tokens = tokens.concat(admin.token)
      }
      return tokens
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error al obtener informacion de los administradores',
        icon: 'error',
        showConfirmButton: true
      })
    }
  }

  async getPendingsForUser() {
    try {
      var today = new Date().setHours(0, 0, 0, 0)
      var tomorrow = DateTime.fromMillis(today).plus({ days: 1 })
      var pendings: PendingUser[] = []
      var usersDone = []
      var pendingList = await this.pendingService.getForUser(this.currentUser.uid)

      pendingList.map(async (pending) => {
        var date = new Date(pending.date)
        var limitDate = new Date(pending.limitTime)
        pending.date = date
        pending.limitTime = limitDate
        if (limitDate.getTime() <= tomorrow.toMillis() && limitDate.getTime() >= today) {
          pendings.push(pending as PendingUser)
        }
      })
      pendings.forEach((pending) => {
        if (pending.userId == "Todos") {
          var pendingInfo = {
            id: pending._id,
            whoDid: pending.whoDid
          }
          usersDone.push(pendingInfo)
        }
      })
      pendings.map(async (pendingsDo) => {
        for await (const listWhoDid of usersDone) {
          if (listWhoDid.whoDid != undefined) {
            listWhoDid.whoDid.forEach(userList => {
              if (pendingsDo._id == listWhoDid.id) {
                if (userList.userId === this.currentUser.uid && userList.isDone === true) {
                  pendingsDo.isDone = true
                }
              }
            })
          }
        }
      })

      return pendings
    } catch (error) {
      throw error
    }
  }

  updatePending(data) {
    this.dialogService.open(EditPendingUsersComponent, { context: { pending: data } }).onClose.subscribe(async res => {
      if (this.userType != "administrador") {
        await this.pendingService.createLog({ who: this.currentUser.email, title: "Actualización de tarea", action: "modificó una actividad", pendingFor: "none" })
        await this.notificationService.sendReminder({
          tokens: this.tokenAdmins, notification: {
            'title': "Actualización de tarea",
            'body': this.currentUser.email + " modificó una actividad",
            'sound': "default",
            'icon': 'https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/logo_circle.png?alt=media&token=c9ea2ac7-0db4-4913-a111-9290e4696bf7'
          }
        })
      }
      this.getData()
    }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.pendings.filter = filterValue.trim().toLowerCase();
    if (this.pendings.paginator) {
      this.pendings.paginator.firstPage();
    }
  }

  async pendingFinished(data: PendingUser) {
    try {
      if (!data.isDone) {
        data.whoDid = this.currentUser.uid;
        if (data.userId == "Todos") {
          try {
            data.whoDid = this.currentUser.uid;
            await this.pendingService.checkPending(data)
          } catch (error) {
            throw error
          }
        }
        else {
          try {
            await this.pendingService.checkPending(data);
          } catch (error) {
            throw error
          }
        }
        // await this.pendingService.checkPending(data)
        await Swal.fire({
          title: 'Pendiente realizado',
          icon: 'success',
          timer: 1000,
          showConfirmButton: false
        })
        this.getData();
      }
      else {
        if (data.userId == "Todos") {
          try {
            data.whoDid = this.currentUser.uid;
            await this.pendingService.cancelPendingByUser(data)
          } catch (error) {
            throw error
          }
        }
        else {
          try {
            await this.pendingService.cancelPending(data._id);
          } catch (error) {
            throw error
          }
        }
        await Swal.fire({
          title: 'Pendiente no realizado',
          icon: 'warning',
          timer: 1000,
          showConfirmButton: false
        })
        this.getData();
      }

    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error al actualizar el pendiente',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showConfirmButton: true
      })
    }

  }
}
