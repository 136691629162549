import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/users/user.service';
import Swal from 'sweetalert2';
import { EditUserComponent } from './user-form/edit.component';
import { AuthService } from '../../services/authFirebase/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: true,
      position: 'right'
    },
    pager: {
      display: true,
      perPage: 20,
    },
    edit: {
      editButtonContent: '<span>Editar</span>',
    },
    delete: {
      deleteButtonContent: '<span>Eliminar</span>',
    },
    columns: {
      thumbnail: {
        title: 'Ejecutivo',
        type: 'html',
        filter: false
      },
      nombre: {
        title: 'Nombre',
      },
      email: {
        title: 'Email',
      },
      tipo: {
        title: 'Tipo',
      },
      isActive: {
        title: 'Estado',
        valuePrepareFunction: (value) => {
          return value ? "Activo" : "Inactivo";
        },
      },
    },
    mode: 'external',
    noDataMessage: 'No hay registros por ahora'
  };
  data_source = [];
  current_user: any;
  constructor(
    private service: AuthService,
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private dialogService: MatDialog
  ) { }

  async ngOnInit() {
    this.data_source = await this.getAllAgents();
    this.afAuth.authState.subscribe(session => {
      if (session) {
        let uid = this.service.currentuser();
        this.current_user = uid.uid;
      }
    })
  }

  async getAllAgents() {
    try {
      var response = await this.userService.getAllUsers();
      response.data.map((element: any) => {
        element.thumbnail = element.profileImg ? "<img src='" + element.profileImg + "' class='imgTable'>" : "<img src='https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/profileDefault.png?alt=media&token=11dfec0b-5b69-448d-801b-6adefa90690f' class='imgTable'>";
      });
      return response.data
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los usuarios',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async createNew() {
    var resultDialog = await this.dialogService.open(EditUserComponent, { height: '60vh' }).afterClosed().toPromise();
    if (resultDialog) {
      this.ngOnInit()
    }
  }

  async openUpdateUser(data) {
    if (data.data.id == this.current_user) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No puedes editar el usuario actual',
        showConfirmButton: true,
      })
    } else {
      var resultDialog = await this.dialogService.open(EditUserComponent, { data: data.data, height: '60vh' }).beforeClosed().toPromise()
      if (resultDialog) {
        this.ngOnInit()
      }
    }
  }

  async deleteUser(data) {
    if (data.data.id == this.current_user) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'No puedes eliminar el usuario actual',
        showConfirmButton: true,
      })
    }
    else {
      var result = await Swal.fire({
        title: '¿Seguro que quiere eliminar este ejecutivo?',
        text: "Esta acción no se puede revertir",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar'
      })
      if (result.isConfirmed) {
        try {
          this.userService.deleteUser(data.data.id)
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Datos eliminados',
            showConfirmButton: false,
            timer: 1000
          })
          this.ngOnInit()
        } catch (error) {
          Swal.fire({
            title: 'Ocurrió un error inesperado al eliminar el usuario',
            icon: 'error',
            text: "Si el problema persiste, favor de reportar el error"
          })
        }
      }
    }
  }

}
