import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { CallsReportsService } from 'src/app/services/calls-reports/calls-reports.service';
@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class ReporteInternoDetailsComponent implements OnInit, OnDestroy {
    reportID: any;
    companyID: any;
    rest_api = [];
    total_calls: number = 0;
    titleReport: string;

    calls: any;
    loader = true;
    constructor(
        private route: ActivatedRoute,
        public afs: AngularFirestore,
        private callService: CallsReportsService
    ) {
        this.reportID = this.route.snapshot.queryParams['id'];
        this.calls = this.route.snapshot.queryParams['calls'];
        if (this.calls == undefined) {
            this.afs.collection('reportes-internos').doc(this.reportID).ref.get().then(doc => {
                this.callService.getAllCalls(doc.data().finalPhoneSearch, doc.data().format_start_date, doc.data().format_end_date).then(data => {
                    this.titleReport = doc.data().report;
                    let calls = JSON.parse(data.success)
                    this.total_calls = calls.length;
                    calls.forEach(element => {
                        element.minutes = this.secondsToHms(element.duration)
                        this.rest_api.push(element);
                    });
                    this.loader = false;

                })
            })
        } else {
            this.afs.collection('reportes-internos').doc(this.reportID).ref.get().then(doc => {
                this.titleReport = doc.data().report;
                let calls = JSON.parse(this.calls)
                this.total_calls = calls.length;
                calls.forEach(element => {
                    element.minutes = this.secondsToHms(element.duration)
                    this.rest_api.push(element);
                });
                this.loader = false;

            });
        }
    }

    secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr., " : " hrs. ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    ngOnInit() {
        this.loader = true;
    }

    ngOnDestroy() {
    }

    submit() {

    }
}
