import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class GeneralReportsService {

    constructor(
        private afs: AngularFirestore
    ) { }


    async saveGeneralReport(data) {
        try {
            let res = await this.afs.collection('client-general-reports').add(data);
            return res;
        } catch (error) {
            throw error
        }
    }


    async getGeneralReportById(uid) {
        try {
            let res = await this.afs.collection('client-general-reports').doc(uid).get().toPromise();
            let generalReport = res.data()
            generalReport.id = res.id
            return generalReport;
        } catch (error) {
            console.log(error);
            throw error;
        }

    }

}
