<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                Reportes de llamadas
            </nb-card-header>
            <!-- <nb-card-body>
                <mat-form-field>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Escribe para buscar" #input>
                </mat-form-field>
                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="calldate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                        <td mat-cell *matCellDef="let row"> {{row.value.information.calldate}} </td>
                    </ng-container>
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Destino - Cliente</th>
                        <td mat-cell *matCellDef="let row"> {{row.value.information.did}} </td>
                    </ng-container>
                    <ng-container matColumnDef="caller">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Quién marco - Usuario</th>
                        <td mat-cell *matCellDef="let row"> {{row.value.information.origen}} </td>
                    </ng-container>
                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Duración </th>
                        <td mat-cell *matCellDef="let row"> {{row.value.information.duration}}s ({{row.value.information.minutes}}) </td>
                    </ng-container>
                    <ng-container matColumnDef="horario">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Horario </th>
                        <td mat-cell *matCellDef="let row"> {{row.value.information.horario}}</td>
                    </ng-container>
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
                        <td mat-cell *matCellDef="let row" style="padding: 5px 0;">
                            <span style="color: red;">
                                <strong  *ngIf="row.value.information.evento == 'EXITWITHTIMEOUT'">La llamada permaneció<br>en espera el tiempo máximo</strong>
                                <strong  *ngIf="row.value.information.evento == 'EXITWITHKEY'">Durante la espera en fila el<br>cliente solicito que le<br>devolvieran la llamada</strong>
                                <strong  *ngIf="row.value.information.evento == 'ABANDON'"> El cliente colgó la<br>llamada durante la espera</strong>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-raised-button (click)="changeStatusTrue(row); row.status = !row.status" [disabled]="row.status" style="margin-right: 20px; color: white;" [ngStyle]="{'background': row.status ? 'green' : '#ff8100'}"> {{row.status ? 'Atendida'
                                :
                                'Atender'}}
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No hay datos que mostrar </td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[20, 30, 50, 100]"></mat-paginator>
            </nb-card-body> -->
            <nb-card-body>
                <ng2-smart-table [settings]="settings" [source]="data_source" (edit)="changeStatusTrue($event)">
                </ng2-smart-table>
            </nb-card-body>
        </nb-card>
    </div>
</div>