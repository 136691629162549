import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Prospect } from 'src/app/models/prospects';
import { ViewProspectInfoComponent } from '../view-prospect-info/view-prospect-info.component';
import { EditRowsTableComponent } from '../edit-rows-table/edit-rows-table.component';
import { AddAditionalInfoComponent } from '../add-aditional-info/add-aditional-info.component';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { Task } from 'src/app/models/task';
import Swal from 'sweetalert2';
import { ServiceMinutesService } from 'src/app/services/service-minutes/service-minutes.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-complete-prospect-table',
  templateUrl: './complete-prospect-table.component.html',
  styleUrls: ['./complete-prospect-table.component.scss']
})
export class CompleteProspectTableComponent implements OnInit {

  @Input() data;
  @Output('eventInit') fatherOnInit = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output('catchPaginator') emitPaginator = new EventEmitter<MatPaginator>();
  public displayColumns: string[] = ['name', 'companyName', 'phone', 'email', 'plan', 'createdDate', 'agent', 'actions', 'options'];
  public data_source: MatTableDataSource<any>
  public taskList: Task[];
  public clientList: Company[] = []
  constructor(
    private dialogService: MatDialog,
    private prospectService: ProspectsService,
    private taskService: ServiceMinutesService,
    private companyService: CompanyService,
  ) { }

  async ngOnInit() {
    var columnsOptions = JSON.parse(localStorage.getItem("completeTColumns"))
    if (columnsOptions) {
      this.displayColumns = []
      for await (const newRow of columnsOptions) {
        if (newRow.value) {
          this.displayColumns.push(newRow.controlName)
        }
      }
      this.displayColumns.push("actions")
      this.displayColumns.push("options")
    }
    await this.initGetData()
  }

  async initGetData() {
    this.taskList = await this.getTask()
    this.clientList = await this.getAllCompanies()
    var completeProspects = await this.getAllCompleteProspects();
    this.data_source = new MatTableDataSource(completeProspects)
    this.data_source.paginator = this.paginator
  }

  ngAfterViewInit() {
    this.emitPaginator.emit(this.paginator)
  }

  async getAllCompanies(): Promise<Company[]> {
    try {
      var response = await this.companyService.getAllCompanies()
      return response
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al consultar los datos",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data_source.filter = filterValue.trim().toLowerCase();
    if (this.data_source.paginator) {
      this.data_source.paginator.firstPage();
    }
  }

  async getAllCompleteProspects() {
    try {
      var response = await this.prospectService.getAllCompleteProspects();
      var dataWhitoutInterface = []
      await Promise.all(response.data.map(async (prospect) => {
        var findTask: Task[] = [];
        prospect.tareasRecurrentes.forEach((assingTask: any) => {
          if (typeof assingTask == "string") {
            assingTask = this.taskList.filter((taskInfo) => taskInfo._id == assingTask)[0]
          }
          else {
            assingTask = this.taskList.filter((taskInfo) => taskInfo._id == assingTask._id || taskInfo.title == assingTask.title)[0]
          }
          findTask.push(assingTask)
        })
        prospect.tareasRecurrentes = findTask
        // var completeInfo = this.clientList.filter((comanyData) => comanyData.id == prospect.uid)[0]
        // prospect = { ...prospect, ...completeInfo } as any
        dataWhitoutInterface.push(prospect)
      }))

      return dataWhitoutInterface
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al consultar los datos",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async getTask() {
    try {
      var response = await this.taskService.getTask()
      return response
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar información de las tareas',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async getClientInfo(prospectUid: string) {
    try {
      var response = await this.companyService.getCompanyByUserUid(prospectUid)
      delete response.contratoDate
      return response
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar información de los clientes',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  showDetails(prospetInfo: Prospect) {
    this.dialogService.open(ViewProspectInfoComponent, { data: prospetInfo, width: "850px", height: 'auto' })
  }

  addInfo(prospetInfo: Prospect) {
    this.dialogService.open(AddAditionalInfoComponent, { data: prospetInfo, width: "850px", height: 'auto' }).beforeClosed().subscribe((response) => {
      if (response) {
        this.initGetData()
      }
    })
  }

  editRows() {
    this.dialogService.open(EditRowsTableComponent, { data: this.displayColumns, width: "550px", height: 'auto' }).beforeClosed().subscribe((response) => {
      if (response) {
        this.ngOnInit()
      }
    })
  }
}
