import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { asBlob } from 'html-docx-js-typescript';
import { ReporteService } from 'src/app/services/reportes/reporte.service';
import { ReportAgents } from '../../../models/report-agent';
import { ExcelService } from '../../../services/excel/excel.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reporte-agentes-details',
  templateUrl: './reporte-agentes-details.component.html',
  styleUrls: ['./reporte-agentes-details.component.scss']
})
export class ReporteAgentesDetailsComponent implements OnInit {
  @ViewChild("htmlDoc") html;
  public totalTime: string;
  public total_calls: number = 0;
  public total_task: number = 0;
  public hours_calls: number = 0;
  public hours_work: number = 0;
  public hours_task: number = 0;
  public time_calls: string;
  public time_task: string;
  public loader = true;
  public data_source: ReportAgents;

  constructor(
    private reportService: ReporteService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private router: Router
  ) { }

  async ngOnInit() {
    var reportID = this.route.snapshot.paramMap.get('id') ?? '';
    if (reportID != '') {
      this.data_source = await this.getReportInfo(reportID);
      this.sumCallsTime();
      this.loader = false
    } else {
      this.router.navigate(['/reporte-agentes'])
    }
  }

  async getReportInfo(id: string) {
    try {
      const report = await this.reportService.getReportById(id)
      report.clients.map(actions => {
        actions.tiempo_llamadas = actions.tiempo_llamadas || "0s"
      })
      report.clients.sort((a, b) => a.seconds_trabajo > b.seconds_trabajo ? -1 : 1)
      return report
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar la información del reporte',
        icon: 'error',
      })
    }
  }

  async generateWord() {
    const htmlString = this.html.nativeElement.innerHTML;
    const data = await asBlob(htmlString, {
      orientation: "portrait",
      margins: { top: 300, bottom: 300 },
    });
    var url = window.URL.createObjectURL(data as Blob);
    this.download(url);
  }
  download(url) {
    var link = document.createElement('a');
    link.target = "_blank";
    link.download = `reporte_de_agente.docx`;
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  }

  sumCallsTime() {
    var total_llamadas = 0
    var total_llamadas_seconds = 0
    var total_tareas = 0
    var total_tareas_seconds = 0
    var total_trabajado = 0

    this.data_source.clients.forEach(element => {
      total_llamadas += element.total_Llamadas
      total_llamadas_seconds += element.seconds_llamadas
      total_tareas += element.total_tareas
      total_tareas_seconds += element.seconds_tareas
      total_trabajado += element.seconds_trabajo
    });
    this.total_calls = total_llamadas;
    this.total_task = total_tareas
    this.time_calls = this.secondsToHms(total_llamadas_seconds)
    this.hours_calls = total_llamadas_seconds / 3600
    this.time_task = this.secondsToHms(total_tareas_seconds)
    this.hours_task = total_tareas_seconds / 3600
    this.totalTime = this.secondsToHms(total_trabajado)
    this.hours_work = total_trabajado / 3600
  }

  async excel() {
    const headers = [
      'Nombre del cliente', //Columna A
      'Total de llamadas', //Columna B
      "Tiempo atentido en llamadas",
      "Total de horas en llamadas",
      "Total de tareas realizadas",
      "Tiempo atentido en tareas",
      "Total de horas en tareas",
      "Tiempo trabajado",
      "Total de horas trabajadas",
      "Plan"
    ]
    var dataToPrint = []
    for await (const element of this.data_source.clients) {
      var obj = {
        "name": element.nombre_empresa,
        "time1": element.total_Llamadas,
        "timeDo": element.tiempo_llamadas,
        "secondDo": (element.seconds_llamadas / 3600),
        "time2": element.total_tareas,
        "timeDo2": element.tiempo_tareas,
        "secondsDo2": (element.seconds_tareas / 3600),
        "time3": element.total_trabajo,
        "timeDo3": (element.seconds_trabajo / 3600),
        "planc": element.plan
      }
      dataToPrint.push(obj)
    }
    await this.excelService._createReport(dataToPrint, headers, this.data_source.report, this.data_source.agente, [this.total_calls, this.time_calls, this.hours_calls.toFixed(2), this.total_task, this.time_task, this.hours_task.toFixed(2), this.totalTime, this.hours_work.toFixed(2)], [4, 7, 9])
  }

  public secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
    return hDisplay + mDisplay + sDisplay || "0s";
  }

}
