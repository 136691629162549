import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../../services/contact/contact.service';
import { CompanyFocusService } from '../../../services/companyOnFocus/company-focus.service';
import { NbDialogRef } from '@nebular/theme';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DiaryService } from 'src/app/services/diary/diary.service';
import Swal from 'sweetalert2';
import { CompanyService } from 'src/app/services/company/company.service';
import { UserService } from 'src/app/services/users/user.service';
import { AuthService } from 'src/app/services/authFirebase/auth.service';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {

  [x: string]: any;
  addForm: FormGroup;
  public contact$: any
  public isLoading:boolean = false

  constructor(
    public ref: NbDialogRef<SendMailComponent>,
    private contact_service: ContactService,
    public formBuilder: FormBuilder,
    private focusService: CompanyFocusService,
    private diaryService: DiaryService,
    private companyService: CompanyService,
    private userService: UserService,
    private service: AuthService
  ) { }

  ngOnInit() {

    this.addForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      file: [""],
      company: [""],
    });

    this.addForm.controls.file.setValue(this.ref.componentRef.instance.url);
    this.addForm.controls.company.setValue(this.ref.componentRef.instance.company);
    this.getContact().then(res => {
      this.emailContant = res;
      this.addForm.controls.email.setValue(res);
    }).catch(e => {
      this.addForm.controls.email.setValue('');
    });
  }

  getContact() {
    var number = parseInt(this.focusService.getContact().phone);
    return new Promise<any>(async (resolve, reject) => {
      this.contact$ = await this.contact_service.getContact(number, this.focusService.getCompanyOnFocus());
      if (this.contact$ != null) {
        resolve(this.contact$.email);
      } else {
        reject("no-data");
      };
    })
  }

  async submit() {
    try {
      this.isLoading = true;
      var form = this.addForm.value;
      let uid = this.service.currentuser().uid;
      var asistentData = await this.userService.getUserById(uid);
      await this.diaryService.sendMailInfo(form)
      await this.companyService.createLog(form.company, asistentData)
      this.ref.close();
      this.isLoading = false
      await Swal.fire({
        position: "center",
        icon: "success",
        title: "Correo enviado correctamente",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      throw error
    }
  }
}
