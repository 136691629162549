import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { DateTime } from 'luxon';
import { PendingsService } from 'src/app/services/pendings/pendings.service';
import { UserService } from 'src/app/services/users/user.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/authFirebase/auth.service';
import { PendingUser } from '../../models/pending-user';

@Component({
  selector: 'app-add-pendings-users',
  templateUrl: './add-pendings-users.component.html',
  styleUrls: ['./add-pendings-users.component.scss']
})
export class AddPendingsUsersComponent implements OnInit {

  [x: string]: any;
  public addForm: FormGroup;
  labelDateToday = new Date();
  hide = false;
  minDate: Date;
  public dateFinal: number;
  public dateSelect: number;
  public users = [];
  public showRepeat: string;
  public labelRepeat: string
  public currentUser: string;
  public userType: string
  public isLoading = false

  constructor(
    public ref: NbDialogRef<AddPendingsUsersComponent>,
    private formBuilder: FormBuilder,
    private pendingService: PendingsService,
    private userService: UserService,
    private authService: AuthService
  ) {

  }

  change(event: boolean) {
    this.hide = event;
  }
  showLimit(event: string) {
    this.showRepeat = event
    switch (event) {
      case "semanal":
        this.labelRepeat = "Semanas"
        break;
      case "mensual":
        this.labelRepeat = "Meses"
        break;
      case "anual":
        this.labelRepeat = "Años"
        break;
      default:
        break;
    }
  }

  onSelectedStartDate(event) {
    var date = new Date(event);
    date.setDate(date.getDate() + 1);
    this.minDate = date;
  }

  async ngOnInit() {
    this.addForm = this.formBuilder.group({
      title: ["", Validators.required],
      userId: ["", Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      limitTime: [''],
      checked: [false],
      dateFinal: [''],
      repeat: [null],
      comments: ['', Validators.required],
      loops: [1]
    });
    await this.getUsers();
    this.addForm.controls.time.setValue({ hour: this.labelDateToday.getHours() + 1, minute: 0, second: 0 });
    this.currentUser = this.authService.currentuser().uid;
    this.userType = localStorage.getItem("currentUser")
  }

  async getUsers() {
    try {
      var userList = await this.userService.getActiveUsers()
      userList.forEach(element => {
        if (element.tipo != "administrador") {
          this.users.push(element);
        }
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: true,
      })
    }
  }

  async submit() {
    this.isLoading = true
    var isChecked = this.addForm.value.checked
    var loops = this.addForm.value.loops;
    this.addForm.removeControl("checked")
    this.addForm.removeControl("loops")
    var form = this.addForm.value;
    if (this.userType != "administrador") {
      form.userId = this.currentUser
    }
    if (form.date == '' || form.title == '' || form.comments == '' || form.userId == '') {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Todos los campos son obligatorios',
        showConfirmButton: true,
      })
    } else {
      if (isChecked == true) {
        let repeat = form.repeat;
        switch (repeat) {
          case "diaria":
            var dateFinal = new Date(new Date(form.date).setHours(0, 0, 0, 0)).setDate(new Date(form.date).getDate());
            var dateSelect = new Date(new Date(form.dateFinal).setHours(0, 0, 0, 0)).setDate(new Date(form.dateFinal).getDate());
            this.addPending(repeat, dateSelect, dateFinal);
            break
          case "semanal":
            var dateSelect = new Date(new Date(form.date).setHours(0, 0, 0, 0)).setDate(new Date(form.date).getDate());
            this.addPending(repeat, loops, dateSelect);
            break
          case "mensual":
            var dateSelect = new Date(new Date(form.date).setHours(0, 0, 0, 0)).setMonth(new Date(form.date).getMonth());
            this.addPending(repeat, loops, dateSelect);
            break
          case "anual":
            var dateSelect = new Date(new Date(form.date).setHours(0, 0, 0, 0)).setFullYear(new Date(form.date).getFullYear());
            this.addPending(repeat, loops, dateSelect);
            break
        }
      }
      else {
        var dateSelect = new Date(new Date(form.date).setHours(0, 0, 0, 0)).setDate(new Date(form.date).getDate());
        this.addPending("noRepeat", dateSelect, 0);
      }
    }
  }

  async addPending(repeat: string, dateFinal: number, dateSelect: number) {
    try {
      this.addForm.removeControl("dateFinal")
      var form: PendingUser = this.addForm.value
      if (this.userType != "administrador") {
        form.userId = this.currentUser
      }
      let formattedDate = formatDate(form.date, 'yyyy-MM-dd', 'en-US');
      form.limitTime = new Date(`${formattedDate}`);
      form.limitTime.setDate(form.limitTime.getDate() + 1);
      form.limitTime.setHours(form.time.hour, form.time.minute, 0, 0)
      var endDate = DateTime.fromMillis(dateFinal);
      var initDate = DateTime.fromMillis(dateSelect);
      switch (repeat) {
        case "diaria":
          var diff = endDate.diff(initDate, 'days')
          for (let i = 0; i <= diff.toObject().days; i++) {
            let suma = (initDate.plus({ days: i })).toJSDate();
            suma.setHours(form.time.hour, form.time.minute, 0, 0)
            this.addForm.value.limitTime = suma;
            await this.pendingService.addPendingForUser(form);
          }
          break;
        case "semanal":
          for (let i = 0; i <= dateFinal; i++) {
            let suma = (initDate.plus({ weeks: i })).toJSDate();
            suma.setHours(form.time.hour, form.time.minute, 0, 0)
            this.addForm.value.limitTime = suma;
            await this.pendingService.addPendingForUser(form);
          }
          break;
        case "mensual":
          for (let i = 0; i <= dateFinal; i++) {
            let suma = (initDate.plus({ months: i })).toJSDate();
            suma.setHours(form.time.hour, form.time.minute, 0, 0)
            this.addForm.value.limitTime = suma;
            await this.pendingService.addPendingForUser(form);
          }
          break;
        case "anual":
          for (let i = 0; i <= dateFinal; i++) {
            let suma = (initDate.plus({ years: i })).toJSDate();
            suma.setHours(form.time.hour, form.time.minute, 0, 0)
            this.addForm.value.limitTime = suma;
            await this.pendingService.addPendingForUser(form);
          }
          break;
        case "noRepeat":
          await this.pendingService.addPendingForUser(form);
        default:
          break;
      }
      await Swal.fire("¡Agregado!", "El pendiente se ha creado", "success");
      this.ref.close();
      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Ocurrió un error al crear el pendiente',
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true,
      })
    }
  }

}
