<section>
  <div class="loadspinner" *ngIf="loader == true">Cargando&#8230;</div>
  <div class="header-container">
    <h3>{{user?.nombre}} </h3>
  </div>

  <div class="card">
    <div class="history-user"></div>
    <form [formGroup]="userForm">
      <div class="profile-container">
        <div class="image-container">
          <img [src]="user?.profileImg ? user?.profileImg : defaultImg" alt="" width="300" height="300">
          <input hidden type="file" #imgFileInput multiple placeholder="Upload file" accept=".png,.jpg,.jpeg"
            name="file" (change)="saveFile($event)" required />
          <button mat-button class="btn-photo" (click)="imgFileInput.click()"><mat-icon>camera_alt</mat-icon> Cambiar
            foto</button>
        </div>
        <div class="info-data">
          <div class="form-group">
            <label for="">Correo</label>
            <input type="email" formControlName="email">
          </div>
          <div class="form-group">
            <label for="">Contraseña</label>
            <input type="password" formControlName="password">
          </div>
          <div class="form-group">
            <label for="">Extension</label>
            <input type="number" formControlName="extension">
          </div>

          <button mat-button class="edit-btn" (click)="updateData()"><mat-icon>edit</mat-icon> Editar
            información</button>
        </div>
      </div>
    </form>
  </div>
</section>
