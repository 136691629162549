<nb-card>
    <nb-card-header>
        <h3>Crear cita a {{patientInfo.nombre}} </h3>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="eventForm" class="formContainer">
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Asunto</label>
                    <input type="text" nbInput formControlName="subject" placeholder="Motivo de la cita" fullWidth>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Fecha</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="start_date" formControlName="date">
                    <nb-datepicker #start_date [min]="minDate"></nb-datepicker>
                </div>
            </div>
            <div class=" form-group row">
                <label for="">Hora de la cita</label>
                <div class="col-md-6">
                    <ngb-timepicker formControlName="time" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Comentarios </label>
                    <textarea rows="5" formControlName="comments" nbInput fullWidth placeholder="Informacion adicional"></textarea>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="form-group row text-center">
            <div class="col-md-6">
                <button nbButton size="small" status="danger" (click)="ref.close()" class="ml-3">
                    <nb-icon icon="close-outline"></nb-icon>Cancelar
                </button>
            </div>
            <div class="col-md-6">
                <button nbButton size="small" status="success" (click)="save()">
                    <nb-icon icon="save-outline"></nb-icon>Guardar
                </button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>