import { Injectable, TemplateRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { Pending } from '../../models/pending';
import { PendingUser } from '../../models/pending-user';
import { Notification } from 'src/app/models/notifications';

@Injectable({
    providedIn: 'root'
})
export class PendingsService {

    public URLconnect = environment.URL_API;

    constructor(
        private afs: AngularFirestore,
        private http: HttpClient
    ) { }

    public async getByUser(companyId: string) {
        try {
            var response = await this.http.post<{ code: number; message: string, data: Pending[] }>(this.URLconnect + "/pendings/getAll-byId", { id: companyId }).toPromise()
            return response.data
        }
        catch (error) {
            console.log(error);
            throw error
        }
    }

    public async getForAdmins() {
        try {
            var response = await this.http.get<{ data: PendingUser[] }>(this.URLconnect + "/pendings/getAdmin").toPromise()
            return response
        }
        catch (error) {
            console.log(error);
            throw error
        }
    }

    public async getForUser(id: string) {
        try {
            var response = await this.http.get<{ data: PendingUser[] }>(this.URLconnect + "/pendings/get-for-user/" + id).toPromise()
            return response.data
        }
        catch (error) {
            console.log(error);
            throw error
        }
    }

    public async addPending(companyId: string, companyName: string, form: any) {
        try {
            form.clientId = companyId;
            form.companyName = companyName;
            await this.http.post<any>(this.URLconnect + "/pendings/add", { data: form }).toPromise();
            return
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    public async addPendingForUser(form: PendingUser) {
        try {
            await this.http.post<any>(this.URLconnect + "/pendings-user/add", { data: form }).toPromise();
            return
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async getPendings() {
        try {
            var response = await this.http.get<{ data: Pending[], message: string, code: number }>(this.URLconnect + "/pendings/getAll").toPromise()
            return response
        } catch (error) {
            throw error
        }
    }

    async removePendingForUser(item: string) {
        try {
            var response = await this.http.delete<{ code: number, message: string }>(this.URLconnect + "/pendings-user/delete/" + item).toPromise()
            return response.code
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async createLog(log: any) {
        try {
            await this.http.post<{ code: number, message: string, data: any }>(this.URLconnect + "/pendings/create-log", { data: log }).toPromise()
            return
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    public getLogs() {
        try {
            var response = this.afs.collection("notifications", ref => ref.orderBy("date", "desc").limit(30))
            return response.snapshotChanges().map((doc) => {
                return doc.map(action => {
                    let data = action.payload.doc.data() as any
                    let id = action.payload.doc.id
                    return { id, ...data } as Notification
                })
            })
        } catch (error) {
            throw error
        }
    }

    public getConcludedLogs$() {
        try {
            var response = this.afs.collection("notifications", ref => ref.where("title", '==', "Tarea atrasada").orderBy("date", "desc").limit(50))
            return response.snapshotChanges().map((doc) => {
                return doc.map(action => {
                    let data = action.payload.doc.data() as any
                    let id = action.payload.doc.id
                    return { id, ...data } as Notification
                })
            })
        } catch (error) {
            throw error
        }
    }

    public getInTimeLogs$() {
        try {
            var response = this.afs.collection("notifications", ref => ref.where("title", '==', "Pendiente a punto de concluir").orderBy("date", "desc").limit(50))
            return response.snapshotChanges().map((doc) => {
                return doc.map(action => {
                    let data = action.payload.doc.data() as any
                    let id = action.payload.doc.id
                    return { id, ...data } as Notification
                })
            })
        } catch (error) {
            throw error
        }
    }

    async closeNotification(id: string) {
        try {
            await this.http.put<{ code: number, message: string, data: any }>(this.URLconnect + "/pendings/close-log", { id: id }).toPromise()
            return
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async updatePending(itemId: string, whoDid: string) {
        try {
            var data = {
                id: itemId,
                whoDid: whoDid
            }
            var response = await this.http.put<any>(this.URLconnect + "/pendings/update", { data: data }).toPromise()
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async cancelPending(itemId: string) {
        try {
            var response = await this.http.put<any>(this.URLconnect + "/pendings/cancel", { id: itemId }).toPromise()
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async cancelPendingByUser(pending: PendingUser) {
        try {
            var response = await this.http.put<any>(this.URLconnect + "/pendings/cancel-by-user", { data: pending }).toPromise()
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async checkPending(data: PendingUser) {
        try {
            var response = await this.http.put<any>(this.URLconnect + "/pendings/finished-by-user", { data: data }).toPromise()
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async updatePendingUser(data: PendingUser) {
        try {
            var response = await this.http.put<any>(this.URLconnect + "/pendings-user/update", { data: data }).toPromise()
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    async hideApp(id: string, status: boolean) {
        try {
            var response = await this.http.put<any>(this.URLconnect + "/pendings/hideInApp", { id, status }).toPromise()
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    public getPendingsLate(userId: string) {
        try {
            var response = this.http.get<{ code: number, message: string, data: any }>(this.URLconnect + "/pendings/get-late/" + userId).toPromise()
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }

    public getPendingsToday() {
        try {
            var response = this.http.get<{ code: number, message: string, data: any }>(this.URLconnect + "/pendings/get-today").toPromise()
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }
}
