import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EventInput } from '@fullcalendar/core';
import { DateTime } from 'luxon';
import { AddSpacesComponent } from 'src/app/components/add-spaces/add-spaces.component';
import { DeleteSpacesComponent } from 'src/app/components/delete-spaces/delete-spaces.component';
import { CompanyFocusService } from 'src/app/services/companyOnFocus/company-focus.service';
import { EventsService } from 'src/app/services/events/events.service';
import { Event } from "src/app/services/events/model";
import Swal from 'sweetalert2';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { ModalEventComponent } from 'src/app/components/modal-event/modal-event.component';
import { FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from "@fullcalendar/core/locales/es";
import { ProspectEventDialogComponent } from 'src/app/components/prospect-event-dialog/prospect-event-dialog.component';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { ActivatedRoute } from '@angular/router';
import { ColorPickerComponent } from 'src/app/components/color-picker/color-picker.component';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, OnChanges {
  @Input() listOfEvents: Event[] = [];
  public currentRoute: string = '';
  calendarComponent: FullCalendarComponent;
  events: Array<any>;
  private startDate: string;
  private endDate: string;
  calendarWeekends = true;
  eventLimit = true;

  public headerConfig = {
    left: 'prev,next',
    center: 'title',
    right: 'block dayGridMonth,timeGridWeek'
  }
  public buttonConfig = {
    month: 'Mes',
    week: 'Semana',
  }
  calendarPlugins = [
    dayGridPlugin,
    timeGrigPlugin,
    interactionPlugin,
    listPlugin,
  ];
  public locale = esLocale;
  slotDuration = "00:30:00";
  minTime = "07:00:00";
  maxTime = "24:00:00";
  slotLabelFormat = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  views = {
    dayGrid: {
      eventLimit: 5,
    },
  };

  calendarEvents: EventInput[];
  options: any = "";
  @Output('eventInit') fatherOnInit = new EventEmitter();

  constructor(
    private focusService: CompanyFocusService,
    private eventService: EventsService,
    private prospectService: ProspectsService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.listOfEvents.length > 0) {
      await this.renderCalendar()
    }
  }

  async ngOnInit() {
    this.route.url.subscribe(urlSegments => {
      const segments = urlSegments.map(segment => segment.path);
      const currentRoute = '/' + segments.join('/'); // La ruta actual como cadena
      this.currentRoute = currentRoute
    });

    this.options = {
      customButtons: {
        block: {
          class: "btn-block",
          text: "Bloquear espacios",
          click: this.blockSpaces.bind(this),
        },
      },
    };

    this.renderCalendar();
  }

  handledateClick(event) {

  }

  async renderCalendar() {
    try {
      // * If you do not receive any events the calendar does not render correctly, this fixes the problem
      if (this.listOfEvents.length == 0) {
        setTimeout(function () {
          window.dispatchEvent(new Event('resize'))
        }, 1)
      }

      await this.eventService.setEventsOfClients(this.listOfEvents);
      this.events = this.listOfEvents.map((event: any) => {
        var starDatenew = DateTime.fromISO(event.startDate);
        var endDatenew = DateTime.fromISO(event.endDate);

        return {
          title: event.title,
          start: starDatenew.toJSDate(),
          end: endDatenew.toJSDate(),
          extendedProps: event,
          backgroundColor: this.getColor(event),
          borderColor: this.getColor(event),
        };
      });

    } catch (error) {
      console.log('error', error);

      Swal.fire(
        'Error al renderizar los eventos del calendario',
        'Hubo un problema con el servidor, intentelo mas tarde',
        'error'
      )
    }
  }

  private getColor(event: Event) {
    if (event.customColors && event.statusDone == 'Pendiente Confirmación' && !event.confirmated) {
      return "#" + event.customColors
    }
    if (event.confirmated && "confirmated" in event) {
      return "#33FFA2";
    }
    if (!event.confirmated && !event.block && event.status) {
      switch (event.statusDone) {
        case "Pendiente de Confirmación":
          return "#FFFF00"
        case "Pagado":
          return "#90EA36"
        case "Pendiente de Pago":
          return "#FFC000"
        case "Pendiente de Facturación":
          return "#7030A0"
        case "Cancelada":
          return "red"
        default:
          return "#0C9BE8";
        // break;
      }
    }
    if (!event.confirmated && !event.block && !event.status) {
      switch (event.statusDone) {
        case "Pendiente Confirmación":
          return "#FFFF00"
        case "Pagado":
          return "#90EA36"
        case "Pendiente de Pago":
          return "#FFC000"
        case "Pendiente de Facturación":
          return "#7030A0"
        case "Cancelada":
          return "red"
        default:
          return "green";
        // break;
      }
    }
    if (event.status) {
      return "#0C9BE8";
    }

    if (event.block) {
      return "gray";
    }

    return "green";
  }

  public selectEvents(select: any) {
    this.startDate = select.startStr;
    this.endDate = select.endStr;
  }

  addNewSpaces() {
    var company = this.focusService.getCompanyOnFocus();
    let dialogRef = this.dialog.open(AddSpacesComponent, {
      height: '70vh',
      width: '550px',
      data: { companyID: company, initDay: this.startDate, endDay: this.endDate },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.fatherOnInit.emit()
      this.startDate = undefined
      this.endDate = undefined
    });
  }

  deleteSpaces() {
    var company = this.focusService.getCompanyOnFocus();
    let dialogRef = this.dialog.open(DeleteSpacesComponent, {
      width: "550px",
      height: '60vh',
      data: { companyID: company },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.fatherOnInit.emit()
    });
  }

  async openEvent(event: any) {
    let companyId = this.focusService.getCompanyOnFocus();
    if (event.event._def.extendedProps.block) {

      const swalResponse = await Swal.fire({
        title: "Desbloquear espacio",
        text: "¿Esta seguro de desbloquear este espacio?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, desbloquear",
        cancelButtonText: "Cancelar",
      });

      if (swalResponse.isConfirmed) {
        const response = await this.eventService.unblockEvent(
          event.event._def.extendedProps,
          companyId
        );

        this.fatherOnInit.emit()
        return Swal.fire({
          title: "Espacio desbloqueado",
          icon: "success",
          timer: 1500,
          showConfirmButton: false
        });
      } else {
        return;
      }
    }

    if (event.event._def.extendedProps.prospectId) {
      var meetData = event.event._def.extendedProps
      const prospectInfo = await this.getProspectInfo(event.event._def.extendedProps.prospectId)
      prospectInfo.trainingDate = meetData.startDate
      prospectInfo.trainingHour = this.getHourObjet(meetData.startDate)
      prospectInfo.trainingInfo = meetData.subject
      prospectInfo.zoomLink = meetData.comments
      let dialogRef = this.dialog.open(ProspectEventDialogComponent, {
        width: '600px',
        maxHeight: '750px',
        data: {
          prospectInfo
        },
      });

      dialogRef.afterClosed().subscribe(() => {
        this.fatherOnInit.emit()
      });
    } else {
      this.focusService.getInfoForReminder(companyId).then((res) => {
        let dialogRef = this.dialog.open(ModalEventComponent, {
          width: "700px",
          height: "85vh",
          data: {
            event: event.event._def,
            clientId: companyId,
            reminderInfo: res,
          },
        });

        dialogRef.afterClosed().subscribe(() => {
          this.fatherOnInit.emit()
        });

        return;
      });
    }
  }

  openColorPicker() {
    this.dialog.open(ColorPickerComponent, { height: "90vh", width: "80vh" })
  }

  private getHourObjet(date: string) {
    var dateHour = new Date(date).getHours()
    var dateMinute = new Date(date).getMinutes()
    return { hour: dateHour, minute: dateMinute }
  }

  async getProspectInfo(prospectId: string) {
    try {
      const response = await this.prospectService.getProspectById(prospectId);
      return response
    } catch (error) {
      console.log('error', error);
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperado',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  public async blockSpaces() {
    var companyId = this.focusService.getCompanyOnFocus();
    try {
      if (!this.startDate && !this.endDate) {
        return Swal.fire({
          text: "Selecciona un espacio primero",
          icon: "warning",
        });
      }

      const swalResponse = await Swal.fire({
        title: "Estas seguro?",
        text: "No se podran agendar citas en este espacio",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si,bloquear",
        cancelButtonText: "Cancelar",
      });

      if (swalResponse.isConfirmed) {
        await this.eventService.blockEventByRange(companyId, this.startDate, this.endDate);
        Swal.fire({
          text: "Espacios bloqueados",
          icon: "success",
        });
        this.startDate = null;
        this.endDate = null;
        this.fatherOnInit.emit()
      }
      else {
        this.startDate = undefined
        this.endDate = undefined
      }
    } catch (error) {
      if (error.error != undefined) {
        Swal.fire({
          text: "No existen eventos en el rango seleccionado",
          icon: "error",
        });
      }
      else {
        Swal.fire({
          text: "Ha ocurrido un error, intentalo de nuevo",
          icon: "error",
        });
      }
      this.startDate = '';
      this.endDate = ''
    }
  }

}
