import { Component, OnInit } from "@angular/core";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireStorage } from "@angular/fire/storage";
import { NbDialogRef } from "@nebular/theme";
import { formatDate } from "@angular/common";
import { finalize } from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: "app-add-info",
  templateUrl: "./add-info.component.html",
  styleUrls: ["./add-info.component.scss"],
})
export class AddInfoComponent implements OnInit {
  addForm: FormGroup;
  config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
  };
  constructor(
    public ref: NbDialogRef<AddInfoComponent>,
    private storage: AngularFireStorage,
    public formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      title: ["", Validators.required],
      file: ["", Validators.required],
      extension: ["", Validators.required],
      date: [""],
      time: [""],
    });
  }

  onUploadInfo(e) {
    try {
      var file_name = e[0].name;
      var base64url = e[1].files.file;
      var extension = file_name.split(".").pop();
      extension = extension.toLowerCase();
      this.addForm.controls.extension.setValue(extension);
      var name = new Date().getTime() + Math.random().toString(36).substring(2);
      var company = localStorage.getItem("companyOnFocus");
      var refUrl = "/" + company + "/" + name + `.${extension}`;
      var format_type = base64url.split(";")
      var type_file = (format_type[0]).slice(5);

      const fileRef = this.storage.ref(refUrl);
      const task = this.storage.ref("/" + company + "/").child(name + `.${extension}`).putString(base64url, "data_url", { contentType: type_file });

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            var downloadURL = fileRef.getDownloadURL();
            downloadURL.subscribe((data) => {
              this.addForm.controls.file.setValue(data);
            });
          })
        )
        .subscribe();
    } catch (error) {
      return Swal.fire({
        title: 'Ocurrió un error al subir el documento',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })

    }
  }

  submit() {
    var date = formatDate(new Date(), "dd/MM/yyyy", "es-MX");
    var time = formatDate(new Date(), "hh:mm a", "es-MX");
    this.addForm.controls.date.setValue(date);
    this.addForm.controls.time.setValue(time);
    var form = this.addForm.value;
    this.ref.close(form);
  }
}
