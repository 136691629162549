import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { CompanyFocusService } from 'src/app/services/companyOnFocus/company-focus.service';
import { EventsService } from 'src/app/services/events/events.service';
import { Event } from 'src/app/services/events/model';
import Swal from "sweetalert2";

@Component({
  selector: 'app-user-schedule',
  templateUrl: './user-schedule.component.html',
  styleUrls: ['./user-schedule.component.scss']
})
export class UserScheduleComponent implements OnInit {

  public clientId: string = '';
  public events: Event[] = [];

  constructor(
    private authService: AuthService,
    private eventService: EventsService,
    private focusService: CompanyFocusService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.setCurrentUserId()
    await this.getEvents();
  }

  async setCurrentUserId(): Promise<void> {
    try {
      const currentUser = await this.authService.currentuser();
      this.clientId = currentUser.uid
      this.focusService.setFocusCompany(this.clientId)
    } catch (error) {
      console.log('error');
      await Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperado',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  async getEvents(): Promise<void> {
    try {
     const events: Event[] = await this.eventService.getEvents(this.clientId)
     this.events = events
     console.log('los eventos', this.events);
     
    } catch (error) {
     console.log('error');
     await Swal.fire({
       icon: 'error',
       title: 'Ocurrió un error inesperado',
       text: "Si el problema persiste, favor de reportar el error"
     })
    } 
   }

}
