<nb-card>
    <nb-card-header>
        <h3>Editar pendiente</h3>
    </nb-card-header>
    <nb-card-body>
        <div class="">
            <form [formGroup]="editForm">
                <div class=" form-group row">
                    <div class="col-md-12">
                        <label for="">Título</label>
                        <input type="text" nbInput formControlName="title" fullWidth>
                    </div>
                </div>
                <div class=" form-group row">
                    <div class="col-md-12">
                        <label for="">Fecha</label>
                        <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                            [nbDatepicker]="datePending" formControlName="date">
                        <nb-datepicker #datePending></nb-datepicker>
                    </div>
                </div>
                <div class=" form-group row" *ngIf="currentUser == 'administrador'">
                    <div class="col-md-12">
                        <label for="">Pendiente para:</label>
                        <nb-select formControlName="userId" fullWidth>
                            <nb-option value="Todos">Todos</nb-option>
                            <nb-option *ngFor="let user of userList" [value]="user.id">{{user.nombre}} </nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class=" form-group row">
                    <div class="col-md-12">
                        <label for="">Comentarios</label>
                        <textarea formControlName="comments" rows="3" nbInput fullWidth=""></textarea>
                    </div>
                </div>
                <div class=" form-group row">
                    <div class="col-md-5">
                        <label for="">Hora límite</label>
                    </div>
                    <div class="col-md-6">
                        <ngb-timepicker formControlName="time" [hourStep]="1" [minuteStep]="15">
                        </ngb-timepicker>
                    </div>
                </div>
            </form>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="row">
            <div class="col-md-6">
                <button nbButton status="danger" (click)="ref.close()" size="medium"><mat-icon>highlight_off</mat-icon> Cancelar</button>
            </div>
            <div class="col-md-6">
                <button nbButton status="success" size="medium" (click)="updatePending()"><mat-icon>save</mat-icon> Guardar</button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>