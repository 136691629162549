import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { CompanyFocusService } from '../companyOnFocus/company-focus.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  companyId: any;
  messagesRef: AngularFirestoreCollection<any>;
  message$: Observable<any>;


  constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore, public companyFocus: CompanyFocusService) {
  }

  addNewMessage(message) {
    return new Promise<any>((resolve, reject) => {
      this.companyId = this.companyFocus.getCompanyOnFocus()
      this.afs.collection('empresas').doc(this.companyId).collection('recados').add({
        asunto: message.subject,
        fecha: message.date,
        timestamp: message.timestamp,
        hora: message.time,
        mensaje: message.body,
        estado: false
      }).then(function (messageRef) {
        messageRef.update({
          id: messageRef.id
        });
        resolve('done');
      }).catch(() => {
        reject('error')
      })
    })


  }

  getUnreadMessages() {
    this.companyId = this.companyFocus.getCompanyOnFocus()
    this.messagesRef = this.afs.collection('empresas').doc(this.companyId).collection('recados', ref => ref.where('estado', '==', false).orderBy('fecha'));
    this.message$ = this.messagesRef.valueChanges();

    return this.message$;
  }

  getreadMessages() {
    this.companyId = this.companyFocus.getCompanyOnFocus()
    this.messagesRef = this.afs.collection('empresas').doc(this.companyId).collection('recados', ref => ref.where('estado', '==', true).orderBy('fecha'));
    this.message$ = this.messagesRef.valueChanges();

    return this.message$;
  }
}
