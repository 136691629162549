<nb-card>
    <nb-card-header>
        <h3>Modificación de tiempo</h3>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="contextForm">
            <div class="form-group row">
                <div class="col-md-12">
                    <label for="">Justifique la razón del por que se modificó el tiempo base de la actividad</label>
                    <textarea formControlName="reason" nbInput fullWidth fieldSize="medium" rows="4" [ngClass]="invalidFlag"
                        style="resize: none;"></textarea>
                    <p *ngIf="(contextForm.get('reason')?.hasError('required') && contextForm.get('reason')?.touched) || (hasError && contextForm.get('reason')?.untouched)"
                        class="error">
                        *El campo razón es requerido
                    </p>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="btn-actions">
            <button nbButton status="success" size="small" (click)="validForm()"><mat-icon>save</mat-icon>
                Aceptar</button>
            <button nbButton status="danger" size="small" (click)="ref.close()"><mat-icon>close</mat-icon>
                Cancelar</button>
        </div>
    </nb-card-footer>
</nb-card>