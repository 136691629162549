import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { CompanyFocusService } from 'src/app/services/companyOnFocus/company-focus.service';
import { DiaryService } from 'src/app/services/diary/diary.service';
import { EventsService } from 'src/app/services/events/events.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-re-schedule',
  templateUrl: './re-schedule.component.html',
  styleUrls: ['./re-schedule.component.scss']
})
export class ReScheduleComponent implements OnInit {

  public eventForm: FormGroup;
  public minDate: Date;
  public events2;
  public filterEvents = [];
  public comment: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: { companyID: any, event: any },
    public dialogRef: MatDialogRef<ReScheduleComponent>,
    public formBuilderService: FormBuilder,
    private eventService: EventsService,
    public diaryService: DiaryService,
    private focusService: CompanyFocusService,
  ) { }

  ngOnInit() {
    this.setMinDate(new Date());
    this.renderCalendar()
    this.eventForm = this.formBuilderService.group({
      startDate: ["", Validators.required],
      subject: ["", Validators.required],
      space: ["", Validators.required],
      clientId: [this.data.companyID, Validators.required],
      comments: ["", Validators.required],

      _id: ["", Validators.required],
      title: ["", Validators.required],
      endDate: ["", Validators.required],
      customer: ["", Validators.required],
      duration: ["", Validators.required],
      status: ["", Validators.required],
      block: ["", Validators.required],
    })
    this.eventForm.patchValue(this.data.event);
    this.eventForm.controls["startDate"].setValue("");
  }

  setMinDate(event) {
    var date = new Date(event);
    date.setDate(date.getDate() - 1);
    this.minDate = date;
  }

  onSelectedDate(event) {
    this.eventForm.controls["space"].setValue("");
    let now = new Date();
    let events = Array.from(this.events2);
    let next = new Date(new Date(event).setDate(new Date(event).getDate() + 1));
    let dateTime = DateTime.fromJSDate(new Date(event));

    var date = dateTime.set({ hour: now.getHours(), minute: now.getMinutes(), second: now.getSeconds() }).toJSDate()
    if (dateTime.diff(DateTime.fromJSDate(now).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), ["days"]).days > 0) {
      date = new Date(event);
    }

    events = events.filter((e: any) => new Date(e.start) > date);
    events = events.filter((e: any) => new Date(e.start) < next);
    events = events.filter((e: any) => e.extendedProps.status == false);
    this.filterEvents = events;
  }

  public cancel() {
    this.dialogRef.close();
  }

  async renderCalendar() {
    var companyID = this.focusService.getCompanyOnFocus();
    try {
      const events2 = await this.eventService.getEvents(companyID);
      this.events2 = events2.reverse().map((event: any) => {
        var starDatenew = DateTime.fromISO(event.startDate);
        var endDatenew = DateTime.fromISO(event.endDate);

        return {
          title: starDatenew.hour + ":" + (starDatenew.minute).toString().padStart(2, "0") + " - " + endDatenew.hour + ":" + (endDatenew.minute).toString().padStart(2, "0") + " Espacio libre",//event.title,
          start: starDatenew.toJSDate(),
          end: endDatenew.toJSDate(),
          extendedProps: event
        };
      });
    } catch (error) { }
  }

  async confirmReSchedule() {
    try {
      const swalResponse = await Swal.fire({
        title: "¿Estas seguro?",
        text: "Se reprogramará la cita seleccionada",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, reprogramar cita",
        cancelButtonText: "Cancelar",
      });

      if (swalResponse.isConfirmed) {
        await this.eventService.cancelEvent(this.eventForm.value, this.data.companyID);
        this.eventForm.patchValue({
          _id: this.eventForm.value.space?._id,
          status: this.eventForm.value.space?.status,
          startDate: this.eventForm.value.space?.startDate,
          duration: this.eventForm.value.space?.duration,
          endDate: this.eventForm.value.space?.endDate,
          confirmated: this.eventForm.value.space?.confirmated,
        })
        this.dialogRef.close({
          event: this.eventForm.value,
          comment: this.comment
        })
      }
    } catch (error) {
      console.log("hubo un error", error);

      Swal.fire({
        title: "Lo sentimos",
        text: "Ha ocurrido un error al cancelar la cita, intente más tarde",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }
}