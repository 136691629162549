<div class="dialog-template">
    <div class="header-dialog">
        <h3>Tareas Asignadas</h3>
    </div>
    <div class="time-list">
        <form [formGroup]="editTime">
            <div formArrayName="tasks">
                <div *ngFor="let task of tasks.controls;let i=index" [formGroupName]="i">
                    <div class="form-group row">
                        <div class="col-md-11">
                            <label for="">{{tasks.controls[i].value.title}} </label>
                            <input type="number" nbInput fullWidth fieldSize="medium" title="Tiempo en minutos"
                                formControlName="minutes">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="buttons-footer">
        <button mat-button class="danger" (click)="ref.close()"><mat-icon>close</mat-icon>
            Cancelar</button>
        <button mat-button class="success" (click)="submit()" [disabled]="!editTime.valid"><mat-icon>save</mat-icon>
            Guardar</button>
    </div>
</div>