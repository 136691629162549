import { Component, Input, OnInit } from '@angular/core';
import { Contact } from 'src/app/models/contact';
import { DiaryService } from 'src/app/services/diary/diary.service';
import { EventsService } from 'src/app/services/events/events.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-patient-dates-table',
  templateUrl: './patient-dates-table.component.html',
  styleUrls: ['./patient-dates-table.component.scss']
})
export class PatientDatesTableComponent implements OnInit {

  @Input() patientInfo: Contact
  public displayColumns = ["subject", "comments", "date", "status"]
  public dateLogs = []

  constructor(
    private dateService: DiaryService,
    private eventService: EventsService
  ) { }

  async ngOnInit() {
    this.dateLogs = await this.getDatesOfPatient(this.patientInfo.nombre);
  }

  async getDatesOfPatient(name: string): Promise<any> {
    try {
      var response = await this.eventService.getEventsOfClients()
      var datesOfPatient = response.filter((eventInfo) => eventInfo.customer == name)
      return datesOfPatient
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrió un error inesperdado al consultar el historial',
        text: "Si el problema persiste, favor de reportar el error"
      })
    }
  }

  formatTime(time: string) {
    var hour = time.split(":")[0]
    var minutes = time.split(":")[1]

    if (hour.length == 1) {
      hour = "0" + hour
    }
    if (minutes.length == 1) {
      minutes = "0" + minutes
    }
    return hour + ":" + minutes
  }

}
