<nb-card>
    <nb-card-header>
        <span style="float: left; text-transform: uppercase">Bitácora</span>
        <span style="float: right">
            <button nbButton size="small" status="warning" (click)="addNewLogCompany()">
                <nb-icon icon="plus-outline"></nb-icon>Añadir entrada
            </button>
        </span>
    </nb-card-header>
    <nb-card-body>
        <div class="container">
            <div class="table-container">
                <table mat-table #table [dataSource]="logsCompany" matSort>
                    <ng-container matColumnDef="entry">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acción </th>
                        <td mat-cell *matCellDef="let row"> {{row.text}} </td>
                    </ng-container>
                    <ng-container matColumnDef="comments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentarios </th>
                        <td mat-cell *matCellDef="let row"> {{row.comments}} </td>
                    </ng-container>
                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha</th>
                        <td mat-cell *matCellDef="let log"> {{ log.date | date: "dd" }} de
                            {{ log.date | date: "MMMM" }} de
                            {{ log.date | date: "yyyy" }} a las
                            {{ log.date | date: "hh:mm a" }} </td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total de acciones </th>
                        <td mat-cell *matCellDef="let row"> {{row.totalActions}} </td>
                    </ng-container>
                    <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiempo realizado </th>
                        <td mat-cell *matCellDef="let log"> {{log.timeTask!}} mins. </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Acciones </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-button (click)="editLog(row)" class="success"
                                *ngIf="userInfo?.tipo == 'administrador'"><mat-icon>edit</mat-icon></button>
                            <button mat-button (click)="deleteLog(row.id)" class="danger"
                                *ngIf="userInfo?.tipo == 'administrador'"><mat-icon>delete</mat-icon></button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
        <!-- <div *ngIf="logsCompany.length === 0" class="text-center">
            <span>No hay registros por ahora</span>
        </div> -->
    </nb-card-body>
</nb-card>