import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { asBlob } from 'html-docx-js-typescript';
import { CompanyFocusService } from 'src/app/services/companyOnFocus/company-focus.service';
import { QuotationService } from 'src/app/services/cotizaciones/quotation.service';
import Swal from 'sweetalert2';
import { AddInfoComponent } from '../add-info/add-info.component';
import { SendMailComponent } from '../send-mail/send-mail.component';
import { saveAs } from "file-saver";
import { DiaryComponent } from '../diary.component';

@Component({
  selector: 'app-info-client',
  templateUrl: './info-client.component.html',
  styleUrls: ['./info-client.component.scss']
})
export class InfoClientComponent implements OnInit {
  public disclaimer: boolean;
  public cotizaciones: any = [];
  public name_company: any;
  public term = '';
  public filterDocuments = []
  @ViewChild("htmlDoc") html;

  constructor(
    private dialogService: NbDialogService,
    private focusService: CompanyFocusService,
    private cotizacion_service: QuotationService
  ) { }

  async ngOnInit() {
    this.getAllData()
  }

  async getAllData(){
    this.cotizaciones = await this.getAllFiles()
  }

  async getAllFiles(){
    try {
      var response = await this.cotizacion_service.getRecentImages(DiaryComponent.companyIdFocus);
      return response
    } catch (error) {
      return Swal.fire({
        title: 'Ocurrió un error al consultar los documentos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

  async generateWord() {
    const htmlString = this.html.nativeElement.innerHTML;
    const data = await asBlob(htmlString, {
      orientation: "portrait",
      margins: { top: 300, bottom: 300 },
    });
    saveAs(data, `clientes_de_${this.name_company}.docx`);
  }

  addNewInfoCompany() {
    this.dialogService.open(AddInfoComponent).onClose.subscribe(async (data) => {
      if (data != undefined) {
        try {
          await this.cotizacion_service.addNewQuo(data, DiaryComponent.companyIdFocus)
          await Swal.fire({
            position: "center",
            icon: "success",
            title: "Información guardada",
            showConfirmButton: false,
            timer: 1000,
          });
          this.getAllData();
        } catch (error) {
          await Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocurrió un error al añadir el documento",
            text: "Si el problema persiste, favor de reportar el error",
            showConfirmButton: true,
          });
        }
      }
    });
  }

  openImageCotizacion(url) {
    try {
      window.open(url);
    } catch (error) {
      return Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error al tratar de abrir el documento",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true,
      });
    }
  }

  sendInfoMail(url) {
    var companyID = this.focusService.getCompanyOnFocus()
    this.dialogService.open(SendMailComponent, { context: { url: url, company: companyID } })
  }

  async deleteDoc(data) {
    var result = await Swal.fire({
      title: "¿Desea eliminar este elemento?",
      text: "Esta acción no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    })
    if (result.isConfirmed) {
      try {
        await this.cotizacion_service.deleteCotizacion(data, DiaryComponent.companyIdFocus)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Datos eliminados",
          showConfirmButton: false,
          timer: 1000,
        });
        this.getAllData();
      } catch (error) {
        await Swal.fire({
          position: "center",
          icon: "error",
          title: "Ocurrió un error al borrar el documento",
          text: "Si el problema persiste, favor de reportar el error",
          showConfirmButton: true,
        });
      }
    }
  }

  async findIntoArray(word: string) {
    try {
      var completeSearch = [];
      for await (const documents of this.cotizaciones) {
        if (documents.tags != undefined) {
          var expresion = new RegExp(`${word}.*`, "i")
          var filterDocs = documents.tags.filter(keyword => expresion.test(keyword))
          if (filterDocs.length >= 1) {
            completeSearch.push(documents)
          }
        }
      }
      this.filterDocuments = completeSearch

      if (this.filterDocuments.length == 0) {
        await Swal.fire({
          position: "center",
          icon: "error",
          title: "No se encontraron archivos",
          text: "Intente usar otras palabras, o verifique que están bien escritas",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      await Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error al buscar un documento",
        text: "Si el problema persiste, favor de reportar el error",
        showConfirmButton: true,
      });
    }
  }

}
