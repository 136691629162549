import { Component, OnInit } from '@angular/core';
import { Prospect } from 'src/app/models/prospects';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-drive-manager',
  templateUrl: './drive-manager.component.html',
  styleUrls: ['./drive-manager.component.scss']
})
export class DriveManagerComponent implements OnInit {

  public prospectData: Prospect[]
  public imgFile = "https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/OneDrive_Folder_Icon.svg%20(1).png?alt=media&token=2b0d9db3-a129-479b-b1c5-9e70bf865a9e"
  public loader: boolean;
  public term = '';

  constructor(
    private prospectService: ProspectsService
  ) { }

  async ngOnInit() {
    this.loader = true
    this.prospectData = await this.getAllCompany()
    this.loader = false
  }

  async getAllCompany() {
    try {
      var response = await this.prospectService.getActiveProspects()
      return response.data
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al consultar la información de los prospectos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error'
      })
    }
  }

}
