import { Component, OnInit } from '@angular/core';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { Prospect } from 'src/app/models/prospects';
import Swal from 'sweetalert2';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { PlanService } from 'src/app/services/planService/plan.service';
import { Plan } from 'src/app/models/plan';
import { UserService } from 'src/app/services/users/user.service';
import { UserData } from 'src/app/models/user';
import html2canvas from 'html2canvas';
import { ExcelService } from 'src/app/services/excel/excel.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  public countComplete = 0
  public countCancel = 0
  public countProspect = 0
  public prospectData: Prospect[]
  public prospectCancel: Prospect[]
  public prospectComplete: Prospect[]
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public pluginBar = {
    legend: {
      display: true,
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
    },
  }
  public barChartLabels: string[] = []
  public barComercialLabels: string[] = ["Profesional", "Comercial", "Medico"]
  public barOriginLabels: string[] = ["Facebook", "Google", "BNI", "Recomendado", "Otro"]
  public barStateLabels: string[] = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas",
  ]
  public barPromotionLabels: string[] = ["N/A", "Otra"]
  public planLabels = []

  public barChartType: string = 'pie';
  public barChartLegend: boolean = false;
  public showData: boolean = false
  public barChartData: ChartDataSets[] = [];
  public agentGraphData = []
  public comercialGraphData: ChartDataSets[] = []
  public originGraphData: ChartDataSets[] = []
  public stateGraphData: ChartDataSets[] = []
  public planGraphData: ChartDataSets[] = []
  public promotionGraphData: ChartDataSets[] = []
  public plans: Plan[];
  public agentsList: UserData[]
  public selectType = 1;

  constructor(
    private prospectService: ProspectsService,
    private planService: PlanService,
    private userService: UserService,
    private excelService: ExcelService
  ) { }

  async ngOnInit() {
    await this.initGetData()
    await this.buildAllGraphs()
  }

  async initGetData() {
    this.prospectData = await this.getAllProspects()
    this.prospectCancel = await this.getAllCancelProspects();
    this.prospectComplete = await this.getAllCompleteProspects();
    this.plans = await this.getPlans()
    this.agentsList = await this.getAgents();
    this.countProspect = this.prospectData.length;
    this.countCancel = this.prospectCancel.length;
    this.countComplete = this.prospectComplete.length;
  }

  async buildAllGraphs() {
    this.buildAgentGraph()
    this.buildComercialGraph()
    this.buildOriginGraph()
    this.buildPlanGraph()
    this.buildPromotionGraph()
    this.buildStateGraph()
  }

  async downloadExcel() {
    try {
      var chartsImages = await this.firthDiagramChartURL("firstGraph")
      var secondCharts = await this.firthDiagramChartURL("secondGraphs")

      var dataComercial = []
      var dataAgent = []
      var dataCity = []
      var dataOrigin = []
      var dataPlans = []
      var dataPromotion = []

      for (let i = 0; i < this.barComercialLabels.length; i++) {
        dataComercial.push({ "label": this.barComercialLabels[i], "value": this.comercialGraphData[0].data[i], "color": this.comercialGraphData[0].backgroundColor[i] })
      }
      for (let i = 0; i < this.planLabels.length; i++) {
        dataPlans.push({ "label": this.planLabels[i], "value": this.planGraphData[0].data[i], "color": this.planGraphData[0].backgroundColor[i] })
      }
      for (let i = 0; i < this.barStateLabels.length; i++) {
        dataCity.push({ "label": this.barStateLabels[i], "value": this.stateGraphData[0].data[i], "color": this.stateGraphData[0].backgroundColor[i] })
      }
      for (let i = 0; i < this.barOriginLabels.length; i++) {
        dataOrigin.push({ "label": this.barOriginLabels[i], "value": this.originGraphData[0].data[i], "color": this.originGraphData[0].backgroundColor[i] })
      }
      for (let i = 0; i < this.barPromotionLabels.length; i++) {
        dataPromotion.push({ "label": this.barPromotionLabels[i], "value": this.promotionGraphData[0].data[i], "color": this.promotionGraphData[0].backgroundColor[i] })
      }
      for (let i = 0; i < this.barChartLabels.length; i++) {
        dataAgent.push({ "label": this.barChartLabels[i], "value": this.barChartData[0].data[i], "color": this.barChartData[0].backgroundColor[i] })
      }

      var dataToPrint = {
        "image1": chartsImages,
        "image2": secondCharts,
        "dataRowOne":
          [dataAgent,
            dataComercial,
            dataCity
          ],
        "dataRowTwo":
          [dataOrigin,
            dataPlans,
            dataPromotion
          ]
      }
      var reportName = "Reporte grafico de prospectos"
      switch (this.selectType) {
        case 1:
          reportName = reportName + " nuevos"
          break;
        case 2:
          reportName = reportName + " completados"
          break;
        case 3:
          reportName = reportName + " inactivos"
          break;

        default:
          break;
      }
      await this.excelService.graphReport(dataToPrint, reportName, "Metricas de prospectos")

    } catch (error) {
      console.log(error);

    }
  }

  async firthDiagramChartURL(charId: string) {
    const img: any = document.getElementById(charId);
    const canvas = await html2canvas(img);
    return canvas.toDataURL();
  }

  async getPlans() {
    try {
      var response = await this.planService.getPlans();
      return response.data
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los planes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getAgents() {
    try {
      var response = await this.userService.getActiveUsers()
      return response
    } catch (error) {
      await Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los planes',
        icon: 'error',
        text: "Si el problema persiste, favor de reportar el error",
        showCancelButton: false
      })
    }
  }

  async getAllProspects() {
    try {
      var response = await this.prospectService.getAllProspects();
      return response.data
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al consultar los datos",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async getAllCompleteProspects() {
    try {
      var response = await this.prospectService.getAllCompleteProspects();
      return response.data
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al consultar los datos",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async getAllCancelProspects() {
    try {
      var response = await this.prospectService.getAllCancelProspects();
      return response.data
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al consultar los datos",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async changeFilter(type: number) {
    this.barChartData = [];
    this.agentGraphData = []
    this.comercialGraphData = []
    this.originGraphData = []
    this.stateGraphData = []
    this.planGraphData = []
    this.promotionGraphData = []
    switch (type) {
      case 1:
        this.prospectData = await this.getAllProspects()
        this.selectType = 1
        this.buildAllGraphs()
        break;
      case 2:
        this.prospectData = this.prospectComplete
        this.selectType = 2
        this.buildAllGraphs()
        break;
      case 3:
        this.prospectData = this.prospectCancel
        this.selectType = 3
        this.buildAllGraphs()
        break;

      default:
        break;
    }
  }

  buildComercialGraph() {
    var data = []
    this.barComercialLabels.forEach(element => {
      data.push(0)
    });
    this.prospectData.forEach((prospectInfo => {
      var index = this.barComercialLabels.findIndex((agent) => agent == prospectInfo.giroComercial)
      data[index]++;
    }))

    this.comercialGraphData.push({
      label: "Giro comercial",
      data: data,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)'
      ]
    })
    this.showData = true;
  }

  buildStateGraph() {
    var data = []
    this.barStateLabels.forEach(element => {
      data.push(0)
    });
    this.prospectData.forEach((prospectInfo => {
      var index = this.barStateLabels.findIndex((agent) => agent == prospectInfo.city)
      data[index]++;
    }))

    this.stateGraphData.push({
      label: "Ejecutivos",
      data: data,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
        'rgba(12, 91, 25)',
      ],
      borderColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
        'rgba(12, 91, 25)',
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)',
        'rgba(0, 131, 28,0.5 )',
        'rgba(192, 57, 43,0.5 )',
        'rgba(185, 119, 14,0.5 )',
        'rgba(163, 228, 215,0.5 )',
        'rgba(72, 168, 72,0.5 )',
        'rgba(218, 112, 214,0.5 )',
        'rgba(32, 64, 128,0.5 )',
        'rgba(255, 165, 0,0.5 )',
        'rgba(184, 41, 95,0.5 )',
        'rgba(87, 109, 99,0.5 )',
        'rgba(145, 35, 47,0.5 )',
        'rgba(70, 130, 180,0.5 )',
        'rgba(220, 20, 60,0.5 )',
        'rgba(143, 188, 139,0.5 )',
        'rgba(123, 104, 238,0.5 )',
        'rgba(210, 105, 30,0.5 )',
        'rgba(119, 136, 153,0.5 )',
        'rgba(95, 158, 160,0.5 )',
        'rgba(106, 90, 205,0.5 )',
        'rgba(25, 25, 112,0.5 )',
        'rgba(72, 61, 139,0.5 )',
        'rgba(100, 149, 237,0.5 )',
        'rgba(205, 133, 63,0.5 )',
        'rgba(0, 191, 255,0.5 )',
        'rgba(12, 91, 25,0.5)',
      ]
    })
    this.showData = true;
  }

  buildOriginGraph() {
    var data = []
    this.barOriginLabels.forEach(element => {
      data.push(0)
    });
    this.prospectData.forEach((prospectInfo => {
      var index = this.barOriginLabels.findIndex((agent) => agent == prospectInfo.origin)
      data[index]++;
    }))

    this.originGraphData.push({
      label: "Origen",
      data: data,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)'
      ]
    })
    this.showData = true;
  }

  async buildAgentGraph() {
    this.barChartLabels = []
    for await (const agentInfo of this.agentsList) {
      this.barChartLabels.push(agentInfo.nombre);
      this.agentGraphData.push(0)
    }
    this.prospectData.forEach((prospectInfo => {
      var index = this.barChartLabels.findIndex((agent) => prospectInfo.ejecutivo.includes(agent))
      this.agentGraphData[index]++;
    }))

    this.barChartData.push({
      label: "Ejecutivos",
      data: this.agentGraphData,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
        'rgba(12, 91, 25)',
      ],
      borderColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
        'rgba(12, 91, 25)',
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)',
        'rgba(0, 131, 28,0.5 )',
        'rgba(192, 57, 43,0.5 )',
        'rgba(185, 119, 14,0.5 )',
        'rgba(163, 228, 215,0.5 )',
        'rgba(72, 168, 72,0.5 )',
        'rgba(218, 112, 214,0.5 )',
        'rgba(32, 64, 128,0.5 )',
        'rgba(255, 165, 0,0.5 )',
        'rgba(184, 41, 95,0.5 )',
        'rgba(87, 109, 99,0.5 )',
        'rgba(145, 35, 47,0.5 )',
        'rgba(70, 130, 180,0.5 )',
        'rgba(220, 20, 60,0.5 )',
        'rgba(143, 188, 139,0.5 )',
        'rgba(123, 104, 238,0.5 )',
        'rgba(210, 105, 30,0.5 )',
        'rgba(119, 136, 153,0.5 )',
        'rgba(95, 158, 160,0.5 )',
        'rgba(106, 90, 205,0.5 )',
        'rgba(25, 25, 112,0.5 )',
        'rgba(72, 61, 139,0.5 )',
        'rgba(100, 149, 237,0.5 )',
        'rgba(205, 133, 63,0.5 )',
        'rgba(0, 191, 255,0.5 )',
        'rgba(12, 91, 25,0.5)',
      ]
    })
    this.showData = true;
  }

  buildPlanGraph() {
    var data = []
    this.planLabels = []
    this.plans.forEach(plan => {
      this.planLabels.push(plan.title)
      data.push(0)
    })

    this.prospectData.forEach((prospectInfo => {
      var index = this.planLabels.findIndex((agent) => agent == prospectInfo.plan)
      data[index]++;
    }))

    this.planGraphData.push({
      label: "Plan",
      data: data,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
      ],
      borderColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)',
        'rgba(0, 131, 28)',
        'rgba(192, 57, 43)',
        'rgba(185, 119, 14)',
        'rgba(163, 228, 215)',
        'rgba(72, 168, 72)',
        'rgba(218, 112, 214)',
        'rgba(32, 64, 128)',
        'rgba(255, 165, 0)',
        'rgba(184, 41, 95)',
        'rgba(87, 109, 99)',
        'rgba(145, 35, 47)',
        'rgba(70, 130, 180)',
        'rgba(220, 20, 60)',
        'rgba(143, 188, 139)',
        'rgba(123, 104, 238)',
        'rgba(210, 105, 30)',
        'rgba(119, 136, 153)',
        'rgba(95, 158, 160)',
        'rgba(106, 90, 205)',
        'rgba(25, 25, 112)',
        'rgba(72, 61, 139)',
        'rgba(100, 149, 237)',
        'rgba(205, 133, 63)',
        'rgba(0, 191, 255)',
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)',
        'rgba(0, 131, 28,0.5 )',
        'rgba(192, 57, 43,0.5 )',
        'rgba(185, 119, 14,0.5 )',
        'rgba(163, 228, 215,0.5 )',
        'rgba(72, 168, 72,0.5 )',
        'rgba(218, 112, 214,0.5 )',
        'rgba(32, 64, 128,0.5 )',
        'rgba(255, 165, 0,0.5 )',
        'rgba(184, 41, 95,0.5 )',
        'rgba(87, 109, 99,0.5 )',
        'rgba(145, 35, 47,0.5 )',
        'rgba(70, 130, 180,0.5 )',
        'rgba(220, 20, 60,0.5 )',
        'rgba(143, 188, 139,0.5 )',
        'rgba(123, 104, 238,0.5 )',
        'rgba(210, 105, 30,0.5 )',
        'rgba(119, 136, 153,0.5 )',
        'rgba(95, 158, 160,0.5 )',
        'rgba(106, 90, 205,0.5 )',
        'rgba(25, 25, 112,0.5 )',
        'rgba(72, 61, 139,0.5 )',
        'rgba(100, 149, 237,0.5 )',
        'rgba(205, 133, 63,0.5 )',
        'rgba(0, 191, 255,0.5 )',
      ]
    })
    this.showData = true;
  }

  buildPromotionGraph() {
    var data = []
    this.barPromotionLabels.forEach(element => {
      data.push(0)
    });
    this.prospectData.forEach((prospectInfo => {
      var index = this.barPromotionLabels.findIndex((agent) => agent == prospectInfo.promotion)
      data[index]++;
    }))

    this.promotionGraphData.push({
      label: "Ejecutivos",
      data: data,
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(255, 159, 64)',
        'rgba(255, 205, 86)',
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(153, 102, 255)',
        'rgba(201, 203, 207)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      hoverBackgroundColor: [
        'rgb(255, 99, 132, 0.5)',
        'rgb(255, 159, 64, 0.5)',
        'rgb(255, 205, 86, 0.5)',
        'rgb(75, 192, 192, 0.5)',
        'rgb(54, 162, 235, 0.5)',
        'rgb(153, 102, 255, 0.5)',
        'rgb(201, 203, 207, 0.5)'
      ]
    })
    this.showData = true;
  }

}
