import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from 'src/app/services/chat/chat.service';
import { io } from 'socket.io-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatMessage } from 'src/app/models/chat';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/company';
import Swal from 'sweetalert2';

// const SOCKET_ENDPOINT = 'http://localhost:8080';
const SOCKET_ENDPOINT = 'https://miasistente.herokuapp.com';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
    socket;
    public currentMessages: Array<ChatMessage> = []
    public infoCompany: Company;
    public chat: FormGroup;
    private scrollContainer: any;
    private items = [];
    @ViewChildren('item') itemElements: QueryList<any>;
    @ViewChild('scrollframe', { static: false }) scrollFrame: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private chat_service: ChatService,
        private fb: FormBuilder,
        private companyService: CompanyService

    ) {}

    ngOnDestroy() {
        this.socket.disconnect();
    }

    ngAfterViewInit() {
        this.scrollContainer = this.scrollFrame.nativeElement;
        this.itemElements.changes.subscribe(_ => this.onItemElementsChanged());
        // Add a new item every 2 seconds
        setInterval(() => {
            this.items.push({});
        }, 3000);
    }

    private onItemElementsChanged(): void {
        this.scrollToBottom();
    }

    private scrollToBottom(): void {
        this.scrollContainer.scroll({
            top: this.scrollContainer.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    async ngOnInit() {
        var id = this.route.snapshot.params['id'];
        this.chat = this.fb.group({
            sendMessage: ["", Validators.required],
        })
        this.infoCompany = await this.getCompanyData(id)
        this.setupSocketConnection();

        this.socket.emit('join', id);
        this.socket.on('join', (data: any) => {
            let msm: ChatMessage
            data.messages.forEach((currentMsm: any) => {
                msm = {
                    message: currentMsm.text,
                    type: currentMsm.type
                }
                this.currentMessages.push(msm)
            });
        })

        this.socket.on('message', (data: any) => {
            let msm: ChatMessage
            msm = {
                message: data.text,
                type: data.type
            }
            this.currentMessages.push(msm)
        })

    }

    async getCompanyData(id: string): Promise<Company> {
        try {
            return await this.companyService.getCompanyByUserUid(id)
        } catch (error) {
            Swal.fire({
                title: 'Ocurrió un error al consultar la informacion del cliente',
                icon: 'error',
                text: "Si el problema persiste, favor de reportar el error"
            })
        }
    }

    setupSocketConnection() {
        this.socket = io(SOCKET_ENDPOINT);
        this.socket.on('disconnect', () => {
            console.log('Socket disconnected')
        })
    }

    async SendMessage() {
        try {
            let form = this.chat.value;
            let send = form.sendMessage.trim();
            if (send == '') {
                return
            }

            let sendMessage = {
                "clientId": this.infoCompany.id,
                "message": {
                    'type': 2,
                    'text': send
                }
            }

            await this.chat_service.sendMessage(sendMessage);
            this.chat.reset();
            this.sentNotification(send);
        } catch (error) {
            Swal.fire({
                title: 'Ocurrió un error al enviar el mensaje',
                icon: 'error',
                text: "Si el problema persiste, favor de reportar el error"
            })
        }
    }

    async sentNotification(send: string) {
        try {
            var dataToSend = {
                title: 'Nuevo mensaje de tu asistente',
                message: send,
                clientId: this.infoCompany.id,
            }
            await this.chat_service.newMessageNotification(dataToSend)
        } catch (error) {
            Swal.fire({
                title: 'Ocurrió un error al crear la notificación',
                icon: 'error',
                text: "Si el problema persiste, favor de reportar el error"
            })
        }
    }

}
