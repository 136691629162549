import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Task } from '../../models/task';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceMinutesService {

  private urlConnect = environment.URL_API

  constructor(
    private http: HttpClient
  ) { }

  async addtask(form: Task) {
    try {
      var response = await this.http.post<{ code: number, message: string, data: Task }>(this.urlConnect + "/tasks/add", { task: form }).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async getTask() {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Task[] }>(this.urlConnect + "/tasks/get").toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async deleteTask(id: string) {
    try {
      var response = await this.http.delete<{ code: number, message: string }>(this.urlConnect + "/tasks/delete/" + id).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async updateTask(form: Task) {
    try {
      var response = await this.http.put<{ code: number, message: string }>(this.urlConnect + "/tasks/update", { task: form }).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async getTaskByCompany(id: string) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Task[] }>(this.urlConnect + "/clients/get-tasks/" + id).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }
}
