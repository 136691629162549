<nb-card *ngIf="!isLoading">
    <nb-card-header>
        Añadir nuevo pendiente
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="addForm">
            <div class=" form-group row">
                <div class="col-md-6" *ngIf="userType == 'administrador'">
                    <label for="">Título</label>
                    <input nbInput fullWidth type="text" placeholder="Ingrese el título" formControlName="title">
                </div>
                <div class="col-md-12" *ngIf="userType != 'administrador'">
                    <label for="">Título</label>
                    <input nbInput fullWidth type="text" placeholder="Ingrese el título" formControlName="title">
                </div>
                <div class="col-md-6" *ngIf="userType == 'administrador'">
                    <label for="">Asistente</label>
                    <nb-select formControlName="userId" placeholder="Seleccione una opción" fullWidth>
                        <nb-option value="Todos">Todos</nb-option>
                        <nb-option *ngFor="let user of users" [value]="user.id">{{user.nombre}} </nb-option>
                    </nb-select>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Fecha</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="startDate" formControlName="date">
                    <nb-datepicker #startDate (dateChange)="onSelectedStartDate($event)"></nb-datepicker>
                </div>
            </div>
            <div class=" form-group row" >
                <div class="col-md-6">
                    <nb-checkbox formControlName="checked" class="basic" (checkedChange)="change($event)">
                        Repetitiva
                    </nb-checkbox>
                </div>
                <div class="col-md-6" *ngIf="hide == true">
                    <nb-select formControlName="repeat" placeholder="Opción a repetir" fullWidth
                        (selectedChange)="showLimit($event)">
                        <nb-option value="diaria">Diaria</nb-option>
                        <nb-option value="semanal">Semanal</nb-option>
                        <nb-option value="mensual">Mensual</nb-option>
                        <nb-option value="anual">Anual</nb-option>
                    </nb-select>
                </div>
            </div>
            <div class=" form-group row" *ngIf="showRepeat == 'diaria'">
                <div class="col-md-12">
                    <label for="">Fecha limite</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="finalDate" formControlName="dateFinal" >
                    <nb-datepicker #finalDate [min]="minDate"></nb-datepicker>
                </div>
            </div>
            <div class=" form-group row" *ngIf="showRepeat == 'semanal' || showRepeat == 'mensual' || showRepeat == 'anual'">
                <div class="col-md-6">
                    <label for="">{{labelRepeat}} a repetir</label>
                </div>
                <div class="col-md-6">
                    <input type="number" nbInput fullWidth formControlName="loops">
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Hora limite</label>
                    <ngb-timepicker formControlName="time" [hourStep]="1" [minuteStep]="30"></ngb-timepicker>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Comentarios</label>
                    <textarea nbInput fullWidth fieldSize="medium" rows="4" formControlName="comments"
                        placeholder="Ingrese el mensaje"></textarea>
                </div>
            </div>
            
        </form>
    </nb-card-body>
    <nb-card-footer style="text-align: center;">
        <button class="mr-5" nbButton status="danger" (click)="ref.close()">Cancelar</button>
        <button nbButton status="success" (click)="submit()">Guardar</button>
    </nb-card-footer>
</nb-card>
<nb-card *ngIf="isLoading">
    <nb-card-header>
        <h2>Creando pendientes..</h2>
    </nb-card-header>
    <nb-card-footer class="text-center">
        <small>Por favor espere, esto tomara algo de tiempo. No cierre la ventana.</small>
        <div class="loader loader--style1" title="0">
            <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px"
                viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                <path opacity="0.2" fill="#000"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
              s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
              c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
                <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
              C22.32,8.481,24.301,9.057,26.013,10.047z">
                    <animateTransform attributeType="xml" attributeName="transform" type="rotate"
                        from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" />
                </path>
            </svg>
        </div>
    </nb-card-footer>
</nb-card>
