import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserData } from "../../models/user"

@Injectable({
  providedIn: 'root'
})

export class UserService {

  public URIconnect = environment.URL_API + "/users/"

  constructor(
    private http: HttpClient
  ) { }

  async getAllUsers() {
    try {
      var response = await this.http.get<{ code: number, data: UserData[] }>(this.URIconnect).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async getActiveUsers() {
    try {
      var response = await this.http.get<{ code: number, data: UserData[] }>(this.URIconnect + "getActive").toPromise()
      return response.data
    } catch (error) {
      console.log(error);
      throw error
    }
  }

  async getAdmins() {
    try {
      var response = await this.http.get<{ code: number, data: UserData[] }>(this.URIconnect + "admins").toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getUserById(uid: string) {
    try {
      var clients = await this.http.get<{ code: number, data: UserData }>(this.URIconnect + uid).toPromise();
      return clients.data
    } catch (error) {
      throw error
    }
  }

  async getUserByEmail(email: string) {
    try {
      var clients = await this.http.get<{ code: number, data: UserData }>(this.URIconnect + email).toPromise();
      return clients.data
    } catch (error) {
      throw error
    }
  }

  async addNewUser(user) {
    try {
      await this.http.post<{ code: number, data: any }>(this.URIconnect, { data: user }).toPromise()
      return
    } catch (error) {
      throw error
    }
  }

  async updateUser(_id: string, user: UserData) {
    try {
      await this.http.put<any>(this.URIconnect + _id, { data: user }).toPromise()
      return
    } catch (error) {
      throw error
    }
  }

  async deleteUser(uid: string) {
    try {
      await this.http.delete<any>(this.URIconnect + uid).toPromise()
      return "User delete"
    } catch (error) {
      throw error
    }
  }

}
