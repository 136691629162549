import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import * as firebase from 'firebase'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FCMService {

  private messaging = firebase.messaging();
  private messageSource = new Subject();
  public currentMessage = this.messageSource
  private uriConnect = environment.URL_API + "/users/token/"
  constructor(
    private http: HttpClient
  ) { }

  async getPermission() {
    try {
      return await this.messaging.getToken();
    } catch (error) {
      throw error
    }
  }


  monitorRefresh(user) {
    this.messaging.onTokenRefresh(() => {
      this.messaging.getToken()
        .then(refreshedToken => this.saveToken(user, refreshedToken, true))
        .catch(err => console.log(err))
    })
  }

  receiveMessage() {
    this.messaging.onMessage(payload => {
      console.log('Message received', payload);
      this.messageSource.next(payload);
    });
  }


  async saveToken(user: User, token: string, flag: boolean): Promise<boolean> {
    try {
      if (flag) {
        await this.http.post(this.uriConnect + user.uid, { token }).toPromise()
        return true
      } else {
        await this.http.put(this.uriConnect + user.uid, { token }).toPromise()
        return true
      }
    } catch (error) {
      throw error
    }
  }

}
