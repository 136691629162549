<div class="dialog-template">
    <div class="header-template">
        <h3>{{isEdit ? 'Editar tarea': 'Nueva tarea'}} </h3>
        <hr>
    </div>
    <form [formGroup]="addTask">
        <div class="form-group row">
            <div class="col-md-12">
                <label for="">Nombre de la Tarea</label>
                <input type="text" nbInput fullWidth fieldSize="medium" placeholder="Ingrese el nombre"
                    formControlName="title">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-12">
                <label for="">Tiempo en minutos</label>
                <input type="number" nbInput fullWidth fieldSize="medium" placeholder="Ingrese los minutos"
                    formControlName="minutes">
            </div>
        </div>
    </form>
    <div class="buttons-footer">
        <button mat-button class="danger" (click)="ref.close()" fullWidth><mat-icon>close</mat-icon>
            Cancelar</button>
        <button mat-button class="success" (click)="submit()" fullWidth
            [disabled]="!addTask.valid"><mat-icon>save</mat-icon> {{isEdit ? 'Actualizar': 'Crear'}} </button>
    </div>
</div>