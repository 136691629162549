<div class="search">
    <mat-form-field appearance="outline">
        <mat-label>Buscar por nombre...</mat-label>
        <input type="text" matInput (keyup)="applyFilter($event)" placeholder="Ej. MIA ghost" #input>
    </mat-form-field>
</div>
<div class="table-container">
    <table mat-table #table [dataSource]="data_source">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre comercial </th>
            <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef> Nombre del cliente </th>
            <td mat-cell *matCellDef="let row"> {{row.nombreCliente}} </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef> Fecha de completado </th>
            <td mat-cell *matCellDef="let row"> {{row.completeData | date}} </td>
        </ng-container>
        <ng-container matColumnDef="agent">
            <th mat-header-cell *matHeaderCellDef> Ejecutivo asignado </th>
            <td mat-cell *matCellDef="let row"> {{row.ejecutivo}} </td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Numero telefonico </th>
            <td mat-cell *matCellDef="let row"> {{row.telefono}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Correo </th>
            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>
        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef> Plan a contratar </th>
            <td mat-cell *matCellDef="let row"> {{row.plan}} </td>
        </ng-container>
        <ng-container matColumnDef="contratoDate">
            <th mat-header-cell *matHeaderCellDef> Fecha de inicio de contrato </th>
            <td mat-cell *matCellDef="let row"> {{row.contratoDate | date}} </td>
        </ng-container>
        <ng-container matColumnDef="factura">
            <th mat-header-cell *matHeaderCellDef> Requiere factura </th>
            <td mat-cell *matCellDef="let row"> {{row.invoice}} </td>
        </ng-container>
        <ng-container matColumnDef="tag">
            <th mat-header-cell *matHeaderCellDef> Etiqueta </th>
            <td mat-cell *matCellDef="let row"> {{row.tag}} </td>
        </ng-container>
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef> Telefono asignado </th>
            <td mat-cell *matCellDef="let row"> {{row.telefono}} </td>
        </ng-container>
        <ng-container matColumnDef="prefix">
            <th mat-header-cell *matHeaderCellDef> Prefijo </th>
            <td mat-cell *matCellDef="let row"> {{row.prefix}} </td>
        </ng-container>
        <ng-container matColumnDef="datosFacturacion">
            <th mat-header-cell *matHeaderCellDef> Datos de facturación </th>
            <td mat-cell *matCellDef="let row"> {{row.fiscalAddress}} </td>
        </ng-container>
        <ng-container matColumnDef="encargado">
            <th mat-header-cell *matHeaderCellDef> Encargado </th>
            <td mat-cell *matCellDef="let row"> {{row.encargado}} </td>
        </ng-container>
        <ng-container matColumnDef="direccion">
            <th mat-header-cell *matHeaderCellDef> Dirección </th>
            <td mat-cell *matCellDef="let row"> {{row.direccion}} </td>
        </ng-container>
        <ng-container matColumnDef="drive">
            <th mat-header-cell *matHeaderCellDef> Link a drive </th>
            <td mat-cell *matCellDef="let row"> {{row.direccion}} </td>
        </ng-container>
        <ng-container matColumnDef="ejecutivo">
            <th mat-header-cell *matHeaderCellDef> Ejecutivo asignado</th>
            <td mat-cell *matCellDef="let row"> {{row.ejecutivo}} </td>
        </ng-container>
        <ng-container matColumnDef="hourWork">
            <th mat-header-cell *matHeaderCellDef> Horario de atención</th>
            <td mat-cell *matCellDef="let row"> {{row.startHourWork.hour}} - {{row.endHourWork.hour}} </td>
        </ng-container>
        <ng-container matColumnDef="giroComercial">
            <th mat-header-cell *matHeaderCellDef> Giro comercial </th>
            <td mat-cell *matCellDef="let row"> {{row.giroComercial}} </td>
        </ng-container>
        <ng-container matColumnDef="planPrice">
            <th mat-header-cell *matHeaderCellDef> Precio a facturar </th>
            <td mat-cell *matCellDef="let row"> {{row.invoicedAmount | currency:'MXN'}} </td>
        </ng-container>
        <ng-container matColumnDef="profession">
            <th mat-header-cell *matHeaderCellDef> Profesión </th>
            <td mat-cell *matCellDef="let row"> {{row.speciality}} </td>
        </ng-container>
        <ng-container matColumnDef="referencias">
            <th mat-header-cell *matHeaderCellDef> Referencias </th>
            <td mat-cell *matCellDef="let row"> {{row.referencias}} </td>
        </ng-container>
        <ng-container matColumnDef="serviciosAdicionales">
            <th mat-header-cell *matHeaderCellDef> Servicios adicionales </th>
            <td mat-cell *matCellDef="let row"> {{row.referencias}} </td>
        </ng-container>
        <ng-container matColumnDef="tareasRecurrentes">
            <th mat-header-cell *matHeaderCellDef> Tareas recurrentes </th>
            <td mat-cell *matCellDef="let row">
                <ng-container *ngFor="let task of row.tareasRecurrentes">
                    <p>{{task.title}}</p>
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let row">
                <button mat-button (click)="showDetails(row)" matTooltip="Ver Detalles" class="info-icon-m">
                    <mat-icon>insert_drive_file</mat-icon>
                </button>
                <button mat-button matTooltip="Agregar información de capacitación" class="success-icon"
                    (click)="addInfo(row)"><mat-icon>note_add</mat-icon> </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef> <button nbButton (click)="editRows()" size="tiny"
                    nbTooltip="Editar columnas"><mat-icon>edit</mat-icon></button> </th>
            <td mat-cell *matCellDef="let row"> </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayColumns"></tr>
    </table>
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
    </mat-paginator>
</div>