import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Prospect } from 'src/app/models/prospects';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class ProspectsService {


  public URIconnect = environment.URL_API;

  constructor(
    private http: HttpClient,
    private afStorage: AngularFireStorage
  ) { }

  async createProspects(data: any) {
    try {
      let response = await this.http.post<{ code: number, message: string, data: any }>(this.URIconnect + "/prospect/add", { data: data }).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getAllProspects() {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Prospect[] }>(this.URIconnect + "/prospect/get-all").toPromise()
      return response;
    } catch (error) {
      throw error
    }
  }

  async getAllCompleteProspects() {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Prospect[] }>(this.URIconnect + "/prospect/get-all-complete").toPromise()
      return response;
    } catch (error) {
      throw error
    }
  }

  async getActiveProspects() {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Prospect[] }>(this.URIconnect + "/prospect/get-all-active").toPromise()
      return response;
    } catch (error) {
      throw error
    }
  }

  async getAllCancelProspects() {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Prospect[] }>(this.URIconnect + "/prospect/get-all-cancel").toPromise()
      return response;
    } catch (error) {
      throw error
    }
  }

  async getProspectById(prospectId: string) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/get/" + prospectId).toPromise()
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async getProspectByUid(prospectId: string) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/" + prospectId).toPromise()
      return response.data;
    } catch (error) {
      throw error
    }
  }

  async completeProspect(prospectId: string, prospectForm: any) {
    try {
      await this.http.put<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/complete/" + prospectId, { prospectForm }).toPromise()
      return true;
    } catch (error) {
      throw error
    }
  }

  async updateProspect(prospectId: string, prospectForm: any) {
    try {
      await this.http.put<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/update/" + prospectId, { prospectForm }).toPromise()
      return true;
    } catch (error) {
      throw error
    }
  }

  async deleteProspect(prospectId: string) {
    try {
      await this.http.delete<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/delete/" + prospectId).toPromise()
    } catch (error) {
      throw error
    }
  }

  async updateStatusProspect(prospectId: string, status: boolean) {
    try {
      await this.http.put<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/update-status/" + prospectId, { status }).toPromise()
    } catch (error) {
      throw error
    }
  }

  async deleteFileOfProspect(prospectId: string, fileUrl: string, fileType: string) {
    try {
      await this.http.put<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/delete-info/" + prospectId, { fileType }).toPromise()
      await this.afStorage.storage.refFromURL(fileUrl).delete()
    } catch (error) {
      throw error
    }
  }

  async addFileInProspect(prospectId: string, formData: any) {
    try {
      await this.http.put<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/add-info/" + prospectId, { formData }).toPromise()
    } catch (error) {
      throw error
    }
  }

  async updateFileInProspect(prospectId: string, formData: any) {
    try {
      await this.http.put<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/update-info/" + prospectId, { formData }).toPromise()
    } catch (error) {
      throw error
    }
  }

  async addTrainingInfoInProspect(prospectId: string, formValue: any) {
    try {
      await this.http.put<{ code: number, message: string, data: Prospect }>(this.URIconnect + "/prospect/" + prospectId + "/training-info", { formValue }).toPromise()
    } catch (error) {
      throw error
    }
  }

  async getNewsProspects() {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Prospect[] }>(this.URIconnect + "/prospect/new-prospects").toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getProspectByUsername(nombre: string) {
    try {
      var response = await this.http.get<{ code: number, message: string, data: Prospect[] }>(this.URIconnect + "/prospect/by-username/" + nombre).toPromise()
      return response.data
    } catch (error) {
      throw error
    }
  }
}
