<div class="dialog-template">
    <h3>Editar zoom</h3>
    <form [formGroup]="zoomForm">
        <div class="form-group">
            <label for="">Cliente</label>
            <input type="text" placeholder="Seleccione una opción o escriba un nombre" matInput formControlName="client"
              [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.nombre.toUpperCase()}}
              </mat-option>
            </mat-autocomplete>
        </div>
        <div class="form-group">
            <label for="">Enlace</label>
            <input matInput formControlName="url">
        </div>
        <div class="form-group">
            <label for="">Fecha</label>
            <input matInput [matDatepicker]="picker" formControlName="meetDate" (click)="picker.open()"
            placeholder="dd/mm/yyyy" [min]="minDate">
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <div class="form-group">
            <label for="">Horario</label>
            <ngb-timepicker formControlName="meetHour" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
        </div>
    </form>
    <div class="buttons-footer">
        <button mat-button class="danger" (click)="closeModal()"><mat-icon>close</mat-icon> Cerrar</button>
        <button mat-button class="success" (click)="submit()"><mat-icon>edit</mat-icon> Editar</button>
    </div>
</div>