import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from "@angular/router";
import { AddFileProspectComponent } from 'src/app/components/marketing/add-file-prospect/add-file-prospect.component';
import { EditRowsProspectTableComponent } from 'src/app/components/marketing/edit-rows-prospect-table/edit-rows-prospect-table.component';
import { Prospect } from 'src/app/models/prospects';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-marketing-dashboard',
  templateUrl: './marketing-dashboard.component.html',
  styleUrls: ['./marketing-dashboard.component.scss']
})
export class MarketingDashboardComponent implements OnInit {

  public displayColumns: string[] = ['name', 'companyName', 'phone', 'email', 'plan', 'createdAt', 'vendor', 'paid', 'actions', 'options'];
  public data_source: MatTableDataSource<Prospect>;
  public data_source_cancel: Prospect[];

  constructor(
    private router: Router,
    private prospectService: ProspectsService,
    public dialogService: MatDialog
  ) { }

  async ngOnInit() {
    await this.intitGetData()
    var columnsOptions = JSON.parse(localStorage.getItem("newTColumns"))
    if (columnsOptions) {
      this.displayColumns = []
      for await (const newRow of columnsOptions) {
        if (newRow.value) {
          this.displayColumns.push(newRow.controlName)
        }
      }
      this.displayColumns.push("paid")
      this.displayColumns.push("actions")
      this.displayColumns.push("options")
    }
  }

  async intitGetData() {
    this.data_source = new MatTableDataSource(await this.getAllProspects())
    this.data_source_cancel = await this.getAllCancelProspects();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data_source.filter = filterValue.trim().toLowerCase();
  }

  addFileinProscpect(prospectId: Prospect) {
    this.dialogService.open(AddFileProspectComponent, { data: prospectId, width: "750px", height: '50vh' }).beforeClosed().subscribe((response) => {
      if (response) {
        this.ngOnInit()
      }
    })
  }

  async getAllProspects() {
    try {
      var response = await this.prospectService.getAllProspects();
      return response.data
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al consultar los datos",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async getAllCancelProspects() {
    try {
      var response = await this.prospectService.getAllCancelProspects();
      return response.data
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al consultar los datos",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  createNew() {
    this.router.navigate(['clients/new-prospects'])
  }

  openUpdateCompany(data) {
    this.router.navigate(['prospects/complete/' + data._id])
  }

  async deleteProspect(data) {
    try {
      var result = await Swal.fire({
        title: "¿Seguro que quiere eliminar este prospecto?",
        text: "El documento del prospecto sera eliminado permanentemente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
      })
      if (result.isConfirmed) {
        await this.prospectService.deleteProspect(data._id)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Prospecto eliminado",
          showConfirmButton: false,
          timer: 1000,
        });
        await this.intitGetData();
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al eliminar el prospecto",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  editRows() {
    this.dialogService.open(EditRowsProspectTableComponent, { data: this.displayColumns, width: "550px", height: 'auto' }).beforeClosed().subscribe((response) => {
      if (response) {
        this.ngOnInit()
      }
    })
  }

  async hideCompany(data) {
    try {
      var result = await Swal.fire({
        title: "¿Seguro que quiere ocultar este prospecto?",
        text: "El documento pasara a la seccion de cancelados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, desactivar",
        cancelButtonText: "Cancelar",
      })
      if (result.isConfirmed) {
        await this.prospectService.updateStatusProspect(data._id, true)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Cliente desactivado",
          showConfirmButton: false,
          timer: 1000,
        });
        await this.intitGetData();
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

}
