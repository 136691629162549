<nb-card>
    <nb-card-header>
        Datos de la cita
    </nb-card-header>
    <nb-card-body>
        <div class="col-md-12">
            <label for=""><strong>Cliente: </strong> {{data[0].title}}</label>
        </div>
        <div class="col-md-12">
            <label for=""><strong>Fecha: </strong> {{data[0].start | date: 'EEEE d MMMM, y'}}</label>
        </div>
        <div class="col-md-12">
            <label for=""><strong>Horario: </strong> De {{data[0].start | date: 'hh:mm a'}} a
                {{data[0].end | date: 'hh:mm a'}}</label>
        </div>
        <div class="col-md-12">
            <label for=""><strong>Comentarios:</strong> {{data[0].extendedProps.comment }}</label>
        </div>
    </nb-card-body>
    <nb-card-footer style="text-align: center;">
        <button nbButton status="default" (click)="ref.close()" class="mr-5">Cerrar</button>
        <button nbButton status="danger" (click)="cancelarCita()" *ngIf="data[0].extendedProps.available == true" >Cancelar cita</button>
    </nb-card-footer>
</nb-card>