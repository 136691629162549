<nb-card>
    <nb-card-header>
        Añadir nuevo pendiente
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="addForm">
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Fecha</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="startDate" formControlName="date" >
                    <nb-datepicker #startDate (dateChange)="onSelectedStartDate($event)"></nb-datepicker>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <nb-checkbox formControlName="checked" class="basic" (checkedChange)="show = change($event)">
                        Repetitiva
                    </nb-checkbox>
                </div>
            </div>
            <div class=" form-group row" *ngIf="hide == true">
                <div class="col-md-12">
                    <nb-select formControlName="repeat" id="" placeholder="Seleccione una opción"
                        (selectedChange)="final = true">
                        <nb-option value="diaria">Diaria</nb-option>
                        <nb-option value="semanal">Semanal</nb-option>
                        <nb-option value="mensual">Mensual</nb-option>
                    </nb-select>
                </div>
            </div>
            <div class=" form-group row" *ngIf="hide == true">
                <div class="col-md-12">
                    <label for="">Fecha limite</label>
                    <input nbInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha"
                        [nbDatepicker]="finalDate" formControlName="dateFinal" >
                    <nb-datepicker #finalDate [min]="minDate"></nb-datepicker>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Hora limite</label>
                    <ngb-timepicker formControlName="time" [hourStep]="1" [minuteStep]="30"></ngb-timepicker>
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Título</label>
                    <input nbInput fullWidth type="text" placeholder="Ingrese el título" formControlName="title">
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Comentarios</label>
                    <textarea nbInput fullWidth fieldSize="medium" rows="7" formControlName="comments"
                        placeholder="Ingrese el mensaje"></textarea>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer style="text-align: center;">
        <button class="mr-5" nbButton status="danger" (click)="ref.close()">Cancelar</button>
        <button nbButton status="success" (click)="submit()">Guardar</button>
    </nb-card-footer>
</nb-card>