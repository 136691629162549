import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserData } from 'src/app/models/user';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { UserService } from 'src/app/services/users/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss']
})
export class ProfileUserComponent implements OnInit {

  public user!: UserData;
  public defaultImg = "https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/profileDefault.png?alt=media&token=11dfec0b-5b69-448d-801b-6adefa90690f"
  private file: any = []
  public userForm: FormGroup
  public loader: boolean = false;
  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private service: AuthService,
    private formbuilder: FormBuilder,
    private storage: AngularFireStorage,
  ) {
    this.userForm = this.formbuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      extension: ['', Validators.required],
      profileImg: ['']
    })
  }

  ngOnInit() {
    this.afAuth.authState.subscribe(async (session) => {
      if (session) {
        let currentUserId = this.service.currentuser().uid;
        this.user = await this.userService.getUserById(currentUserId);
        this.userForm.patchValue({
          email: this.user?.email,
          password: this.user?.password,
          extension: this.user?.extension,
          profileImg: this.user?.profileImg ?? ''
        })
      } else {

      }
    });
  }

  async updateData() {
    try {
      if (this.userForm.invalid) {
        return Swal.fire({
          title: 'Campos faltantes',
          text: 'Favor de llenar todos los campos disponibles',
          icon: 'error'
        })
      }
      this.loader = true
      const form = this.userForm.value
      if (this.file.length != 0) {
        form.profileImg = await this.uploadFiles(this.file[0])
      }
      await this.userService.updateUser(this.user._id, form)
      await Swal.fire({
        title: 'Datos actualizados con exito',
        icon: 'success',
        timer: 2500
      })
      this.loader = false
      this.ngOnInit()
    } catch (error) {
      this.loader = false
      if (error.error.code == "auth/invalid-password") {
        return Swal.fire({
          title: "Ocurrió un error al actualizar el usuario",
          text: error.error.message,
          icon: 'error'
        })
      }
      if (error.error.code == "auth/email-already-exists") {
        return Swal.fire({
          title: "Ocurrió un error al actualizar el usuario",
          text: error.error.message,
          icon: 'error'
        })
      }
      Swal.fire({
        title: 'Ups... Ocurió un error',
        text: 'Ocurrio un error al actualizar la información',
        icon: 'error'
      })
      console.log(error);
    }
  }

  saveFile(event: any) {
    this.file.push(event.target.files[0])
    const reader = new FileReader();
    reader.onload = (e) => (this.user.profileImg = reader.result as string);
    reader.readAsDataURL(this.file[0]);
  }

  private async uploadFiles(file: any) {
    try {
      let name = Math.random().toString(36).substring(2);
      let refUrl = "users/ProfilePhotos/" + name;
      let ref = this.storage.ref(refUrl);
      await this.storage.upload(refUrl, file).snapshotChanges().toPromise();
      var downloadURL = await ref.getDownloadURL().toPromise();
      return downloadURL
    } catch (error) {
      console.log(error);

    }
  }

}
