import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment.prod';
import { PushNotification } from 'src/app/models/push-notification.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    public urlConnect = environment.URL_API + "/notifications/"
    constructor(
        private afs: AngularFirestore,
        private http: HttpClient
    ) { }

    getAll() {
        var companiesRef = this.afs.collection('general-notifications', ref => ref.orderBy('date'));
        var obs$ = companiesRef.snapshotChanges().map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data() as any;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
        });
        return obs$;
    }

    delete(uid: string) {
        return new Promise<any>((resolve, reject) => {
            this.afs.collection('general-notifications').doc(uid).delete().then(res => {
                resolve('done');
            }).catch(err => {
                reject('error');
            })
        });
    }

    create(form: any) {
        return new Promise<any>(async (resolve, reject) => {
            this.afs.collection('general-notifications').add(form).then(() => resolve(true))
        })
    }

    getCompanyIds() {
        return new Promise<any>((resolve, reject) => {
            var companiesRef = this.afs.collection('empresas', ref => ref.where('active', '==', true).orderBy('nombre'));
            var company$ = companiesRef.snapshotChanges().map(actions => {
                return actions.map(action => {
                    const data = action.payload.doc.data() as any;
                    const id = action.payload.doc.id;
                    return { id, ...data };
                });
            });
            var companyIds = [];

            let sub = company$.subscribe(async data => {
                await Promise.all(data.map(element => {
                    companyIds.push(element.id)
                }))
                resolve(companyIds);
                sub.unsubscribe();
            })
        });
    }

    getCompanyTokens(id: string) {
        return new Promise<any>(async (resolve, reject) => {
            var tokens = [];
            var ref = this.afs.collection('empresas').doc(id).collection('dispositivos')
            var obs$ = ref.snapshotChanges().map(actions => {
                return actions.map(action => {
                    const data = action.payload.doc.data() as any;
                    const id = action.payload.doc.id;
                    return { id, ...data };
                });
            });
            let sub = obs$.subscribe(async data => {
                await Promise.all(data.map(element => {
                    tokens.push(element.id)
                }))
                resolve(tokens);
                sub.unsubscribe();
            })
        })
    }

    async deleteNotification(id: string) {
        try {
            const response = await this.http.delete<any>(this.urlConnect + id).toPromise()
            return response
        } catch (error) {
            throw error
        }
    }

    async sendReminder(data: PushNotification) {
        try {
            await this.http.post<any>(this.urlConnect + "send-push", { data }).toPromise()
        } catch (error) {
            throw error
        }
    }

}
