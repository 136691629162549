import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
// import { from } from 'rxjs';
import * as fs from 'file-saver'
import { ReportProfitability } from 'src/app/models/report-profitability';
import { ReportAgents } from '../../models/report-agent';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  private _workbook!: Workbook
  private infoExel: any
  constructor() { }

  async dowLoadExcel(dataExcel: any, totales: any[]) {
    this.infoExel = dataExcel
    this._workbook = new Workbook()
    this._workbook.creator = "MIA";
    await this._createReportTable(dataExcel, totales)
    let buffer = await this._workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data])
      fs.saveAs(blob, `${dataExcel.report}` + '.xlsx')
    });
  }

  private async _createReportTable(dataExcel: ReportAgents, totales: any[]) {
    //SE CREA LA HOJA
    const sheet = this._workbook.addWorksheet(dataExcel.agente, { views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }] });
    //AGREGAR TITULO o lo que se guste estar de cabezar
    // const titleExcel = sheet.getCell("A1")
    // titleExcel.value = "Reporte por agente"
    // titleExcel.style.font = { bold: true, size: 24, }
    //CREAMOS LOS TITULOS PARA LAS CELDAS
    const headerRow = sheet.getRow(1);
    headerRow.values = []
    headerRow.font = { bold: true, size: 18 }
    //ESTABLECER EL ANCHO DE COLUMNAS
    sheet.getColumn("A").width = 40;
    sheet.getColumn("B").width = 23;
    sheet.getColumn("C").width = 40;
    sheet.getColumn("D").width = 31;
    sheet.getColumn("E").width = 31;
    sheet.getColumn("F").width = 30;
    sheet.getColumn("G").width = 30;
    sheet.getColumn("H").width = 28;
    sheet.getColumn("I").width = 31;
    sheet.getColumn("J").width = 15;
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: "middle" }
    });
    for (let i = 0; i < headerRow.values.length - 1; i++) {
      var rowLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
      var section = sheet.getCell(rowLetter[i] + "1")
      section.style = {
        font: { size: 15, bold: true },
        fill: {
          type: 'pattern',
          pattern: "solid",
          fgColor: { argb: "2c3e50" }
        }
      }
    }
    var odd = true
    for (let index = 0; index < dataExcel.clients.length; index++) {
      // var color = (Math.random().toString(16).substring(2)).slice(0, 6);
      const item = dataExcel.clients[index];
      if (odd) {
        await this._applyStyleTitle(sheet, [{ value: item.nombre_empresa, cell: "A" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: item.total_Llamadas, cell: "B" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: item.tiempo_llamadas, cell: "C" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: (item.seconds_llamadas / 3600).toFixed(2), cell: "D" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: item.total_tareas, cell: "E" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: item.tiempo_tareas, cell: "F" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: (item.seconds_tareas / 3600).toFixed(2), cell: "G" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: item.total_trabajo, cell: "H" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: (item.seconds_trabajo / 3600).toFixed(2), cell: "I" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: item.plan, cell: "J" + (index + 2) }], "ffffff")
        odd = false
      }
      else {
        await this._applyStyleTitle(sheet, [{ value: item.nombre_empresa, cell: "A" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: item.total_Llamadas, cell: "B" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: item.tiempo_llamadas, cell: "C" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: (item.seconds_llamadas / 3600).toFixed(2), cell: "D" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: item.total_tareas, cell: "E" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: item.tiempo_tareas, cell: "F" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: (item.seconds_tareas / 3600).toFixed(2), cell: "G" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: item.total_trabajo, cell: "H" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: (item.seconds_trabajo / 3600).toFixed(2), cell: "I" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: item.plan, cell: "J" + (index + 2) }], "77C66F")
        odd = true
      }
    }
    const totalTitle = sheet.getCell("A" + (dataExcel.clients.length + 2).toString())
    totalTitle.value = "Totales";
    totalTitle.style.font = { bold: true, size: 14, }
    for (let index = 0; index < totales.length; index++) {
      const element = totales[index];
      const totalRow = sheet.getCell(rowLetter[index + 1] + (dataExcel.clients.length + 2).toString())
      totalRow.value = element
      totalRow.style.font = { bold: true, size: 14, }
    }
  }

  async reportProfitabilityExcel(dataExcel: ReportProfitability, totales: any[]) {
    this.infoExel = dataExcel
    this._workbook = new Workbook()
    this._workbook.creator = "MIA";
    await this._createReportProfitabilityTable(dataExcel, totales)
    let buffer = await this._workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data])
      fs.saveAs(blob, `${dataExcel.report}` + '.xlsx')
    });
  }

  private async _createReportProfitabilityTable(dataExcel: ReportProfitability, totales: any[]) {
    //SE CREA LA HOJA
    const sheet = this._workbook.addWorksheet("Reporte de Rentabilidad", { views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }] });
    //AGREGAR TITULO o lo que se guste estar de cabezar


    //CREAMOS LOS TITULOS PARA LAS CELDAS
    const headerRow = sheet.getRow(1);
    headerRow.values = [
      'Nombre del asistente', //Columna A
      'Tiempo trabajado', //Columna B
      'Total horas trabajadas', //Columna B
      "No. Clientes asignados",
      "Clientes equivalentes Plata",
      "Total facturado"
    ]
    headerRow.font = { bold: true, size: 18 }
    //ESTABLECER EL ANCHO DE COLUMNAS
    sheet.getColumn("A").width = 35;
    sheet.getColumn("B").width = 30;
    sheet.getColumn("C").width = 28;
    sheet.getColumn("D").width = 30;
    sheet.getColumn("E").width = 25;
    sheet.getColumn("F").width = 25;

    var rowLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    for (let i = 0; i < headerRow.values.length - 1; i++) {
      var section = sheet.getCell(rowLetter[i] + "1")
      section.style = {
        font: { size: 15, bold: true },
        fill: {
          type: 'pattern',
          pattern: "solid",
          fgColor: { argb: "2c3e50" }
        }
      }
    }
    var odd = true
    for (let index = 0; index < dataExcel.agents.length; index++) {
      // var color = (Math.random().toString(16).substring(2)).slice(0, 6);
      const item = dataExcel.agents[index];
      if (odd) {
        await this._applyStyleTitle(sheet, [{ value: item.name, cell: "A" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: item.timeWork, cell: "B" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: (item.seconds_work / 3600).toFixed(2), cell: "C" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: item.numbers_clients, cell: "D" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: Math.round(item.equivalentes), cell: "E" + (index + 2) }], "ffffff")
        await this._applyStyleTitle(sheet, [{ value: this.formatCurrency(item.total_invoiced), cell: "F" + (index + 2) }], "ffffff")
        odd = false
      }
      else {
        await this._applyStyleTitle(sheet, [{ value: item.name, cell: "A" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: item.timeWork, cell: "B" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: (item.seconds_work / 3600).toFixed(2), cell: "C" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: item.numbers_clients, cell: "D" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: Math.round(item.equivalentes), cell: "E" + (index + 2) }], "77C66F")
        await this._applyStyleTitle(sheet, [{ value: this.formatCurrency(item.total_invoiced), cell: "F" + (index + 2) }], "77C66F")
        odd = true
      }
    }
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: "middle" }
    });
    const totalTitle = sheet.getCell("A" + (dataExcel.agents.length + 2).toString())
    totalTitle.value = "Totales";
    totalTitle.style.font = { bold: true, size: 14, }
    for (let index = 0; index < totales.length; index++) {
      const element = totales[index];
      const totalRow = sheet.getCell(rowLetter[index + 1] + (dataExcel.agents.length + 2).toString())
      totalRow.value = element
      if (index != 0) {
        totalRow.value = (element)
      }
      totalRow.style.font = { bold: true, size: 14, }
    }
  }

  async _aplyStyleDataSection(sheet: Worksheet, dataSection: any[]) {
    dataSection.forEach(item => {
      let rowNumber = 7
      item.values.forEach(value => {
        const cellValue = sheet.getCell(`${item.keyColumnValueWeeks}${rowNumber}`)
        cellValue.value = value.total as number
        rowNumber++
      })
    })
  }


  private async _applyStyleTitle(sheet: Worksheet, cells: { value: string | number, cell: string }[], color: string) {
    for await (const models of cells) {
      const section = sheet.getCell(models.cell)
      section.value = models.value
      section.style = {
        font: { size: 14, bold: true },
        fill: {
          type: 'pattern',
          pattern: "solid",
          fgColor: { argb: color }
        },
        alignment: { wrapText: true },
        border: { bottom: { style: 'thin' } }
      }
    }
  }

  formatCurrency(price: any): string {
    var lng = price.toLocaleString('en-US', { style: 'currency', currency: 'MXN' });
    return lng.slice(2)
  }

  async downloadExcel(filename: string) {
    await this._workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data])
      fs.saveAs(blob, filename + '.xlsx')
    });
  }

  async _createReport(dataExcel: any, headers: string[], filename: string, worksheetName: string, totales?: any[], rowNumberFormat?: number[], rowCurrencyFormat?: number[]) {
    //SE CREA LA HOJA
    this.infoExel = dataExcel
    this._workbook = new Workbook()
    this._workbook.creator = "MIA";
    const sheet = this._workbook.addWorksheet(worksheetName, { views: [{ state: 'frozen', xSplit: 1, ySplit: 1 }] });

    //CREAMOS LOS TITULOS PARA LAS CELDAS
    var rowLetter = this.setLenghtOfRow(headers.length)
    // var rowCurrencyFormat = ["D", "G", "I"]
    const headerRow = sheet.getRow(1);
    headerRow.values = headers
    headerRow.font = { bold: true, size: 18 }
    sheet.getColumn("A").width = 50;

    for (let h = 1; h < headers.length; h++) {
      //ESTABLECER EL ANCHO DE COLUMNAS
      const element = headers[h];
      var numberOfwords = element.length
      sheet.getColumn(rowLetter[h]).width = numberOfwords + 10;
    }
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: "middle" }
    });

    for (let i = 0; i < headerRow.values.length - 1; i++) {
      var section = sheet.getCell(rowLetter[i] + "1")
      section.style = {
        font: { size: 15, bold: true, color: { argb: "ffffff" } },
        fill: {
          type: 'pattern',
          pattern: "solid",
          fgColor: { argb: "003300" }
        }
      }
    }
    var odd = true
    for (let index = 0; index < dataExcel.length; index++) {
      // var color = (Math.random().toString(16).substring(2)).slice(0, 6);
      const item = dataExcel[index];
      var keys = Object.keys(item);
      var values: any = Object.values(item);
      for (let i = 0; i < keys.length; i++) {
        const element = values[i];
        let cell = rowLetter[i] + (index + 2)
        if (odd) {
          await this._applyStyleTitle(sheet, [{ value: element, cell: cell }], "FDE9D9")
          odd = false
        }
        else {
          await this._applyStyleTitle(sheet, [{ value: element, cell: cell }], "FABF8F")
          odd = true
        }

        if (rowLetter[i] == "R") {
          if (parseFloat(element.split('%')[0]) <= 30) {
            await this._applyStyleTitle(sheet, [{ value: element, cell: cell }], "53e553")
          }
          if (parseFloat(element.split('%')[0]) > 30) {
            await this._applyStyleTitle(sheet, [{ value: element, cell: cell }], "a0e553")
          }
          if (parseFloat(element.split('%')[0]) > 80) {
            await this._applyStyleTitle(sheet, [{ value: element, cell: cell }], "e5e553")
          }
          if (parseFloat(element.split('%')[0]) > 100) {
            await this._applyStyleTitle(sheet, [{ value: element, cell: cell }], "FF3939")
          }
        }
      }
      odd = true
    }
    if (totales != null) {
      const totalTitle = sheet.getCell("A" + (dataExcel.length + 2).toString())
      totalTitle.value = "Totales";
      totalTitle.style.font = { bold: true, size: 14, }
      for (let index = 0; index < totales.length; index++) {
        const element = totales[index];
        const totalRow = sheet.getCell(rowLetter[index + 1] + (dataExcel.length + 2).toString())
        totalRow.value = element
        totalRow.style.font = { bold: true, size: 14, }
      }
    }
    if (rowNumberFormat) {
      for (let index = 0; index < rowNumberFormat.length; index++) {
        for (let rowIdx = 1; rowIdx <= (dataExcel.length + 1); rowIdx++) {
          const element = rowLetter[rowNumberFormat[index] - 1];
          sheet.getCell(sheet.getRow(rowIdx)?.getCell(element).address!).numFmt = '#,##0.00';
        }
      }
    }
    if (rowCurrencyFormat) {
      for (let index = 0; index < rowCurrencyFormat.length; index++) {
        for (let rowIdx = 1; rowIdx <= (dataExcel.length + 1); rowIdx++) {
          const element = rowLetter[rowCurrencyFormat[index] - 1];
          sheet.getCell(sheet.getRow(rowIdx)?.getCell(element).address!).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
        }
      }
    }
    await this.downloadExcel(filename);
  }

  async graphReport(dataExcel: any, filename: string, worksheetName: string) {
    //SE CREA LA HOJA
    this.infoExel = dataExcel
    this._workbook = new Workbook()
    this._workbook.creator = "MIA";
    const sheet = this._workbook.addWorksheet(worksheetName);
    var rowLetter = this.setLenghtOfRow(25)
    //CREAMOS LOS TITULOS PARA LAS CELDAS
    sheet.getRow(1).height = 35;
    var titleRow = sheet.getCell("B1")
    titleRow.value = filename
    titleRow.style = {
      font: { size: 24, bold: true },
      fill: {
        type: 'pattern',
        pattern: "solid",
        fgColor: { argb: "FDE9D9" }
      },
      border: { bottom: { style: 'thin' }, left: { style: 'thin' }, top: { style: 'thin' }, right: { style: 'thin' } }
    }
    sheet.mergeCells("B1:P1")
    sheet.getColumn("B").width = 30;
    sheet.getColumn("G").width = 30;
    sheet.getColumn("L").width = 30;

    const chartOne = await this._getIdImage(dataExcel.image1);

    sheet.addImage(chartOne, {
      tl: { col: 1, row: 1 },
      ext: { width: 1400, height: 350 },
    });

    var col = 0;
    for await (const graphInfo of dataExcel.dataRowOne) {
      var letterForData = rowLetter[col + 1]
      for (let index = 0; index < graphInfo.length; index++) {
        const element = graphInfo[index];
        await this._applyStyleTitle(sheet, [{ value: element.label, cell: letterForData + [index + 20] }], this.rgbToHex(element.color));
        await this._applyStyleTitle(sheet, [{ value: element.value, cell: (rowLetter[col + 2]) + [index + 20] }], "FABF8F");
      }
      col = col + 5
    }

    const chartTwo = await this._getIdImage(dataExcel.image2);

    sheet.addImage(chartTwo, {
      tl: { col: 1, row: 51 },
      ext: { width: 1400, height: 350 },
    });
    col = 0
    for await (const graphInfo of dataExcel.dataRowTwo) {
      var letterForData = rowLetter[col + 1]
      for (let index = 0; index < graphInfo.length; index++) {
        const element = graphInfo[index];
        await this._applyStyleTitle(sheet, [{ value: element.label, cell: letterForData + [index + 70] }], this.rgbToHex(element.color));
        await this._applyStyleTitle(sheet, [{ value: element.value, cell: (rowLetter[col + 2]) + [index + 70] }], "FABF8F");
      }
      col = col + 5
    }

    await this.downloadExcel(filename);
  }

  private setLenghtOfRow(headersLenght: number) {
    const rowLetter: string[] = [];
    for (let i = 1; i <= headersLenght; i++) {
      const letraExcel = this.letterExcel(i);
      rowLetter.push(letraExcel);
    }
    return rowLetter
  }

  private letterExcel(n: number) {
    let result = "";
    while (n > 0) {
      const remainder = (n - 1) % 26;
      const letter = String.fromCharCode(65 + remainder);
      result = letter + result;
      n = Math.floor((n - 1) / 26);
    }
    return result;
  }

  private async _getIdImage(url: string): Promise<number> {
    const response = await fetch(url);
    const img = this._workbook.addImage({
      buffer: await response.arrayBuffer(),
      extension: 'jpeg',
    });
    return img;
  }


  rgbToHex(color: String): string {
    var rgbaString = color.split("(");
    var r = parseInt(rgbaString[1].split(",")[0])
    var g = parseInt(rgbaString[1].split(",")[1])
    var b = parseInt(rgbaString[1].split(",")[2])
    return this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  private componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

}
