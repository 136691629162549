import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FCMService } from '../FCM/fcm.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public afAuth: AngularFireAuth,
    private fcmService: FCMService
  ) { }

  loginUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(newEmail, newPassword);
  }

  signupUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.auth.createUserWithEmailAndPassword(newEmail, newPassword);
  }

  async logoutUser(): Promise<any> {
    try {
      const token = await this.fcmService.getPermission()
      await this.fcmService.saveToken(this.afAuth.auth.currentUser, token, false)
      return this.afAuth.auth.signOut()
    } catch (error) {
      throw error
    }
  }

  currentuser() {
    return this.afAuth.auth.currentUser;

  }

  async getuser() {
    return await new Promise((resolve, reject) => {
      var res = this.afAuth.auth.currentUser;
      this.afAuth.auth.onAuthStateChanged(user => {
        resolve(user);
      });
    })
  }


  updateUser(user: any) {
    this.afAuth.auth.updateCurrentUser(user);
  }


  logIn(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then(userData => resolve(userData),
          err => reject(err));
    })
  }

  logOut() {
    return this.afAuth.auth.signOut();
  }
}
