<div class="dialog-template">
    <div class="header-template">
        <h3>Añadir nueva entrada</h3>
    </div>
    <form [formGroup]="addForm">
        <div class=" form-group row">
            <div class="col-md-12">
                <label for="">Fecha</label>
                <input matInput fieldSize="medium" placeholder="Seleccione una fecha" [nbDatepicker]="startDate"
                    formControlName="date">
                <nb-datepicker #startDate></nb-datepicker>
            </div>
        </div>
        <div class=" form-group row">
            <div class="col-md-12">
                <label for="">Acción</label>
                <mat-select formControlName="text" (selectionChange)="showTimeOfTask($event.value)">
                    <mat-option [value]="entOpt.title" *ngFor="let entOpt of taskList">
                        {{entOpt.title}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class=" form-group row">
            <div class="col-md-12">
                <label for="">Tiempo realizado en minutos</label>
                <input formControlName="timeTask" matInput type="number">
            </div>
        </div>
        <div class=" form-group row">
            <div class="col-md-12">
                <label for="">Cantidad de entradas de esta acción</label>
                <input matInput type="number" placeholder="Ingrese una cantidad" formControlName="cuota" min="0"
                    numbersOnly>
            </div>
        </div>
        <div class=" form-group row">
            <div class="col-md-12">
                <label for="">Comentarios</label>
                <textarea matInput fieldSize="medium" formControlName="comments" rows="5" style="resize: none;"
                    placeholder="Ingrese el mensaje"></textarea>
            </div>
        </div>
    </form>
    <div class="buttons-footer">
        <button class="mr-5" mat-button class="danger" (click)="ref.close()"><mat-icon>close</mat-icon>
            Cancelar</button>
        <button mat-button class="success" (click)="submit()"><mat-icon>save</mat-icon> Guardar</button>
    </div>
</div>