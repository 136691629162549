import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  private uriConnect = environment.URL_API + "/contracts/"
  constructor(
    private http: HttpClient
  ) { }

  async get() {
    try {
      const response = await this.http.get<any>(this.uriConnect).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async find(id: string) {
    try {
      const response = await this.http.get<any>(this.uriConnect + "find/" + id).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }

  async update(contractId: string, newContract: any) {
    try {
      await this.http.put(this.uriConnect + contractId, { newContract }).toPromise()
    } catch (error) {
      throw error
    }
  }
}
