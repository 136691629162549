import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { CompanyService } from 'src/app/services/company/company.service';
import Swal from 'sweetalert2';
import { AddLogComponent } from '../add-log/add-log.component';
import { DiaryComponent } from '../diary.component';
import { UserService } from '../../../services/users/user.service';
import { logClient } from '../../../models/logs';
import { ServiceMinutesService } from 'src/app/services/service-minutes/service-minutes.service';
import { Task } from 'src/app/models/task';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { LogsService } from 'src/app/services/logs/logs.service';
import { UserData } from 'src/app/models/user';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.scss']
})
export class BitacoraComponent implements OnInit {
  public currentAsistente: any;
  public cotizaciones$: any;
  public disclaimer: boolean;
  public logsCompany: MatTableDataSource<logClient>;
  public taskList: Task[]
  public userInfo: any;
  public displayColumns = ["entry", "comments", "user", "total", "time", "actions"]
  public usersList: UserData[] = []
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private companyService: CompanyService,
    private service: AuthService,
    private userService: UserService,
    private taskService: ServiceMinutesService,
    private dialog: MatDialog,
    private logsService: LogsService
  ) { }

  async ngOnInit() {
    await this.getInitialData()
  }

  async getInitialData() {
    this.usersList = await this.getAgents()
    this.taskList = await this.getTaskList(DiaryComponent.companyIdFocus)
    this.logsCompany = new MatTableDataSource(await this.getLogsOfCompany(this.taskList));
    this.logsCompany.sort = this.sort
    this.logsCompany.paginator = this.paginator
    this.userInfo = await this.getCurrentUser()
  }

  async getAgents() {
    try {
      var response = await this.userService.getActiveUsers()
      return response
    } catch (error) {
      console.log(error);
    }
  }

  async getLogsOfCompany(taskOfCompany: Task[]) {
    try {
      var response = await this.companyService.getLogsCompany(DiaryComponent.companyIdFocus);
      response.map((logInfo: any) => {
        if (logInfo.date == null) {
          logInfo.date = new Date();
        } else {
          logInfo.date = new Date(logInfo.date._seconds * 1000);
        }
        if (logInfo.timeTask == undefined) {
          var result = taskOfCompany.filter((taskInfo) => logInfo.text.startsWith(taskInfo.title))
          if (result.length != 0) {
            logInfo.timeTask = result[0].minutes
          }
        }
        if (logInfo.idUser) {
          var findRealUser = this.usersList.filter((user) => user.uid == logInfo.idUser)[0]
          if (findRealUser) {
            logInfo.text = logInfo.text.split(' por')[0] + " " + findRealUser.nombre
          }
        }
      })
      return response
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al obtener la bitacora ',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  async getTaskList(id: string) {
    try {
      var response = await this.taskService.getTaskByCompany(id)
      return response
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener las tareas',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  async getCurrentUser() {
    try {
      let uid = this.service.currentuser();
      return await this.userService.getUserById(uid.uid);
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener los datos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      })
    }
  }

  async addNewLogCompany() {
    const dialogResponse = await this.dialog.open(AddLogComponent, { height: "80vh" }).beforeClosed().toPromise()
    // .onClose.subscribe(async (data) => {
    if (dialogResponse) {
      var userInfo = await this.getCurrentUser()
      var logInfo = {
        date: dialogResponse.date,
        text: dialogResponse.text + " por " + userInfo.nombre,
        comments: dialogResponse.comments,
        totalActions: dialogResponse.cuota,
        idUser: userInfo.uid,
        timeTask: dialogResponse.timeTask
      }
      await this.logsService.addManualLog(DiaryComponent.companyIdFocus, logInfo)
      await Swal.fire({
        position: "center",
        icon: "success",
        title: "Entrada guardada",
        showConfirmButton: false,
        timer: 1000,
      });
      this.getInitialData()
    }
    // });
  }

  async editLog(log: logClient) {
    var dialogResponse = await this.dialog.open(AddLogComponent, { data: log, height: "80vh" }).beforeClosed().toPromise()
    if (dialogResponse) {
      await Swal.fire({
        title: 'Registro actualizado con exito',
        icon: 'success',
        timer: 1000,
        showConfirmButton: false
      })
      this.getInitialData()
    }

  }

  async deleteLog(logId: string) {
    try {
      var result = await Swal.fire({
        title: '¿Desea elminar esta entrada en el cliente?',
        text: 'Se eliminara el registro',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, eliminar"
      })
      if (result.isConfirmed) {
        await this.logsService.deleteLog(DiaryComponent.companyIdFocus, logId)
        await Swal.fire({
          title: 'Registro eliminado con exito',
          icon: 'success',
          timer: 1000,
          showConfirmButton: false
        })
        this.getInitialData()
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al obtener los datos',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar'
      })
    }
  }

}
