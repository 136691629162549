import { Component, TemplateRef, ViewChild } from "@angular/core";
import { NbMenuService } from "@nebular/theme";
import { AuthService } from "./services/authFirebase/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore, } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { CompanyFocusService } from "./services/companyOnFocus/company-focus.service";
import { Subscription, interval } from "rxjs";
import { InComingCallService } from "./services/inComingCall/in-coming-call.service";
import { CompanyService } from "./services/company/company.service";
import { ContactService } from "./services/contact/contact.service";
import { formatDate } from "@angular/common";
import { CallsReportsService } from '../app/services/calls-reports/calls-reports.service'
import { first } from 'rxjs/operators';
import { DateTime } from 'luxon';
import Swal from "sweetalert2";
import { UserService } from "./services/users/user.service";
import { UserData } from "./models/user";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {

  adminMenu = [
    { title: "Mi perfil", icon: "person", data: { id: "profile" } },
    { title: "Pendientes", icon: "notifications", data: { id: "pendings" } },
    { title: "Agenda", icon: "calendar_today", data: { id: "user-schedule" } },
  ];
  menuMovilAdmin = [
    { title: "Dashboard", data: { id: "dashboard" } },
    { title: "Clientes", data: { id: "clientes" } },
    { title: 'Ejecutivos', data: { id: 'ejecutivos' } },
    { title: 'Reportes', data: { id: 'reportes' } },
    { title: 'Reportes rentabilidad', data: { id: 'reportes' } },
    { title: 'Reportes de agentes', data: { id: 'reportes' } },
  ];
  menuMovil = [
    { title: "Dashboard", data: { id: "dashboard" } },
    { title: "Clientes", data: { id: "clientes" } },
    { title: 'Llamadas', data: { id: 'llamadas' } },
  ];
  flag: String;
  idCall: any;
  imgUrl: any;
  nombreCompany: any;
  idCompany: any;
  currentCaller: any = {};
  data: any;
  callBackSubscription: Subscription;
  public wpClose: boolean = true;
  public missingCalls = []
  public newMissingCall = false
  public loader = false;
  public modalCall = false;
  public closeModalCall = false;
  public modalICCalls: TemplateRef<any>;
  public callerCompany: any;
  public currentCustomerCaller: any;
  public currrentIncomingCall = [];
  public session: boolean;
  public clientLoader: boolean;
  public callerIdentified: boolean;
  public user: UserData;
  public callSubscription: Subscription;
  public defaultImg = "https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/profileDefault.png?alt=media&token=11dfec0b-5b69-448d-801b-6adefa90690f"

  constructor(
    private menuService: NbMenuService,
    private service: AuthService,
    public router: Router,
    private afs: AngularFirestore,
    private focusCompanyService: CompanyFocusService,
    private iCCService: InComingCallService,
    private companyService: CompanyService,
    private contactService: ContactService,
    private afAuth: AngularFireAuth,
    private callsService: CallsReportsService,
    private userService: UserService,
  ) {
    this.menuService.onItemClick().subscribe(async (event) => {
      switch (event.item.title) {
        case "Dashboard":
          this.router.navigate(["dashboard"]);
          break;
        case "Clientes":
          this.router.navigate(["clients"]);
          break;
        case "Ejecutivos":
          this.router.navigate(["users"]);
          break;
        case "Reportes":
          this.router.navigate(["reportes"]);
          break;
        case "Reportes rentabilidad":
          this.router.navigate(["reporte-rentabilidad"]);
          break;
        case "Reportes de agentes":
          this.router.navigate(["reporte-agentes"]);
          break;
        case "Llamadas":
          this.router.navigate(["reportes-llamadas"]);
          break;
        case "Pendientes":
          this.router.navigate(["pendings"]);
          break;
        case "Agenda":
          this.router.navigate(["user-schedule"]);
          break;
        case "Panel Ventas":
          this.router.navigate(["marketing"]);
          break;
      }
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.afAuth.authState.subscribe(async (session) => {
      if (session) {
        this.incomingCallsListener()
        this.session = true;
        let currentUserId = this.service.currentuser().uid;
        this.user = await this.userService.getUserById(currentUserId);
        localStorage.setItem("currentUser", this.user.tipo);
        this.clientLoader = true;
      } else {
        this.session = false;
        if (this.callSubscription) {
          this.callSubscription.unsubscribe()
        }
      }
    });

    var response = this.callsService.observerMissingCalls()
    response.subscribe(result => {
      if (result) {
        this.newMissingCall = true;
      }
    })
    setTimeout(() => {
      this.newMissingCall = false;
    }, 2000);
  }

  ngOnInit() {
  }

  async logOut() {
    await this.service.logoutUser();
    location.reload();
  }

  toggle() {
    this.wpClose = !this.wpClose;
    if (!this.wpClose) {
      try {
        this.loader = true
        this.newMissingCall = false;
        this.missingCalls = []
        this.afAuth.authState.subscribe(async (session) => {
          if (session) {
            let currentUserId = this.service.currentuser().uid;
            var userInfo = await this.userService.getUserById(currentUserId)
            if (userInfo.tipo == "administrador") {
              var clientsPhone = []
              var allCalls = await this.callsService.getMissingCall()
              for await (const call of allCalls) {
                if (!clientsPhone.includes(call.did)) {
                  clientsPhone.push(call.did)
                }
              }
              for await (const client of clientsPhone) {
                var clientInfo = await this.companyService.getCompanyByPhoneNumber(parseInt(client))
                if (clientInfo != undefined) {
                  var calls = await this.callsService.getMissingCallsByPhoneClient(clientInfo.telefono)
                  if (calls.length != 0) {
                    this.missingCalls.push({ client: clientInfo.nombre, calls: calls.length })
                  }
                }
              }
              this.loader = false;
            }
            else {
              var allClients = await this.companyService.getCompaniesByUserName(userInfo.nombre)
              for await (const client of allClients) {
                var calls = await this.callsService.getMissingCallsByPhoneClient(client.telefono)
                console.log(calls);
                if (calls.length != 0) {
                  this.missingCalls.push({ client: client.nombre, calls: calls.length })
                }
              }
              this.loader = false;
            }
          }
        })
      } catch (error) {
        Swal.fire({
          title: 'Ocurrió un error',
          text: "Si el problema persiste, favor de reportar el error"
        })
      }
    }
  }

  async takePhoneCall(data) {
    let iCC = data;
    let ref = this.afs.collection("incomingCalls").doc(data.id);
    this.focusCompanyService.setFocusCall();
    this.focusCompanyService.setContact(data);
    var companyDoc = await this.companyService.getCompanyByPhoneNumber(iCC.numero_destino);
    if (companyDoc == null) return;

    this.focusCompanyService.setFocusCompany(companyDoc.id);
    var companyContact = await this.contactService.getContact(iCC.numero_origen, companyDoc.id);
    if (companyContact == null) {
      this.modalCall = false;
      await this.contactService.addContact(iCC, companyDoc.id);
      await ref.delete();
      this.iCCService.removeIncomingCall(iCC.id);
      localStorage.setItem("initTimeCall", DateTime.fromISO(new Date().toDateString()).toString())
      this.router.navigate(["diary"], companyDoc);
      return;
    }
    this.modalCall = false;
    await ref.delete();
    if (this.router.url == "/incomming-call") {
      window.close()
    }
    this.iCCService.removeIncomingCall(iCC.id);
    this.closeModal();
    localStorage.setItem("initTimeCall", new Date().toString())
    this.router.navigate(["diary"], companyDoc);
  }

  closeModal() {
    this.closeModalCall = !this.closeModalCall
  }

  async cancelCall(currentCall) {
    try {
      var companyDoc = await this.companyService.getCompanyByPhoneNumber(currentCall.numero_destino);
      this.modalCall = false;
      this.closeModalCall = false;
      this.iCCService.removeIncomingCall(currentCall.id);
      localStorage.setItem("isCallOnFocus", "false")
      this.callBackSubscription.unsubscribe();
    } catch (error) {
      console.log("error... cancelling callback subscription")
    }
    if (companyDoc == null) return;
    try {
      var companyDoc = await this.companyService.getCompanyByPhoneNumber(currentCall.numero_destino);
      this.iCCService.removeIncomingCall(currentCall.id);
      const indexCall = this.currrentIncomingCall.findIndex(call => call.id == currentCall.id)
      if (indexCall != -1) {
        this.currrentIncomingCall.splice(indexCall, 1);
      }
      if (companyDoc == null) return;
      this.iCCService.lostICC(currentCall, companyDoc);
      if (this.router.url == "/incomming-call") {
        window.close()
      }
    } catch (error) {
      console.log(error);
    }
  }

  async incomingCallsListener() {
    var session = await this.afAuth.authState.pipe(first()).toPromise();
    if (session == null) return;
    let currentUserInfo = (await this.afs.collection("usuarios").doc(session.uid).ref.get()).data();

    var inComingCall$ = this.iCCService.getInComingCalls(currentUserInfo.extension);
    this.callSubscription = inComingCall$.subscribe(async calls => {
      if (calls.length == 0) return;
      var individualCall = calls[0];
      var endTime = new Date();
      var difference = endTime.getTime() - individualCall.date.seconds * 1000; // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes > 5) {
        try {
          this.modalCall = false;
          this.closeModalCall = false;
        } catch (error) { }
        return;
      }
      this.currrentIncomingCall = [];

      this.idCall = individualCall.id;
      var callerPhoneNumber = individualCall.numero_origen;
      var companyPhoneNumber = individualCall.numero_destino;
      localStorage.setItem("iCCId", individualCall.id);
      var companyDoc = await this.companyService.getCompanyByPhoneNumber(companyPhoneNumber);
      if (companyDoc == null) {
        var secondDoc = await this.companyService.findCompanyWhitDifferentNumber(companyPhoneNumber);
        if (secondDoc.length == 0) {
          console.log("de plano no existe el num");
          this.iCCService.removeIncomingCall(individualCall.id);
          this.modalCall = false;
          this.closeModalCall = false;
          return;
        }
        else {
          companyDoc = secondDoc[0]
        }
      };

      this.callerCompany = companyDoc;
      try {
        var customer = await this.contactService.getContact(callerPhoneNumber, this.callerCompany.id);
        if (customer.length != 0) {
          this.clientLoader = false;
          this.callerIdentified = true;
          this.currentCustomerCaller = customer;
          localStorage.removeItem("iCCId");
        }
      } catch (error) {
        console.log(error);
        this.clientLoader = false;
        this.callerIdentified = false;
        this.currentCustomerCaller = { telefono: callerPhoneNumber };
        localStorage.removeItem("iCCId");
      }
      // this.modal.dismissAll();
      this.currrentIncomingCall.push(individualCall);
      this.modalCall = true;
      this.closeModalCall = true;
      if (localStorage.getItem("isCallOnFocus") != "true") {
        this.openPopup()
      }
      localStorage.setItem("isCallOnFocus", "true")
      this.callBackSubscription = interval(30000).subscribe((x) => {
        if (localStorage.getItem("isCallOnFocus") == "false") {
          this.callBackSubscription.unsubscribe();
          this.iCCService.removeIncomingCall(individualCall.id);
          this.modalCall = false;
          this.closeModalCall = false;
        } else {
          this.callBackSubscription.unsubscribe();
          this.iCCService.removeIncomingCall(individualCall.id);
          localStorage.setItem("isCallOnFocus", "false")
        }
      });
    })
  }

  async openPopup() {
    try {
      if (window.document.hasFocus() || window.document.hidden) {
        window.open("/incomming-call", "_blank", 'width=400,height=550')
      }
    } catch (error) {
      console.log(error);

    }
  }
}
