import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-rows-table',
  templateUrl: './edit-rows-table.component.html',
  styleUrls: ['./edit-rows-table.component.scss']
})
export class EditRowsTableComponent implements OnInit {
  public displayColumns: any[] = [
    { controlName: "factura", value: false, diplayLabel: "Factura" },
    { controlName: "tag", value: false, diplayLabel: "Etiqueta" },
    { controlName: "contratoDate", value: false, diplayLabel: "Fecha de contrato" },
    { controlName: "datosFacturacion", value: false, diplayLabel: "Datos de Facturación" },
    { controlName: "direccion", value: false, diplayLabel: "Dirección" },
    { controlName: "drive", value: false, diplayLabel: "Link a drive" },
    { controlName: "ejecutivo", value: true, diplayLabel: "Ejecutivo asignado" },
    { controlName: "email", value: true, diplayLabel: "Correo" },
    { controlName: "encargado", value: false, diplayLabel: "Encargado" },
    { controlName: "hourWork", value: false, diplayLabel: "Horio de atencion" },
    { controlName: "giroComercial", value: false, diplayLabel: "Giro comercial" },
    { controlName: "name", value: true, diplayLabel: "Nombre" },
    { controlName: "plan", value: true, diplayLabel: "Plan" },
    { controlName: "companyName", value: true, diplayLabel: "Nombre comercial" },
    { controlName: "createdDate", value: true, diplayLabel: "Fecha de completado" },
    { controlName: "planPrice", value: false, diplayLabel: "Precio a facturar" },
    { controlName: "profession", value: false, diplayLabel: "Profesión" },
    { controlName: "referencias", value: false, diplayLabel: "Referencias" },
    { controlName: "serviciosAdicionales", value: false, diplayLabel: "Servicios adicionales" },
    { controlName: "tareasRecurrentes", value: false, diplayLabel: "Tareas recurrentes" },
    { controlName: "telefono", value: true, diplayLabel: "Telefono" },
    { controlName: "prefix", value: false, diplayLabel: "Prefijo" },
  ];
  public rowViewForm: FormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditRowsTableComponent>,
    private formBuilder: FormBuilder
  ) {
    var columnsOptions = JSON.parse(localStorage.getItem("completeTColumns"))
    if (columnsOptions) {
      this.displayColumns = columnsOptions
    }
    this.displayColumns = this.displayColumns.sort((a, b) => a.value == b.value ? 0 : a.value ? -1 : 1)
  }

  async ngOnInit() {
    this.rowViewForm = this.formBuilder.group({
      factura: [false],
      tag: [false],
      contratoDate: [false],
      datosFacturacion: [false],
      direccion: [false],
      drive: [false],
      ejecutivo: [false],
      email: [false],
      encargado: [false],
      hourWork: [false],
      giroComercial: [false],
      name: [false],
      plan: [false],
      planPrice: [false],
      prefix: [false],
      profession: [false],
      referencias: [false],
      serviciosAdicionales: [false],
      tareasRecurrentes: [false],
      telefono: [false],
      companyName: [false],
      createdDate: [false],
    })
    for await (const rowValue of this.displayColumns) {
      this.rowViewForm.patchValue({
        [rowValue.controlName]: rowValue.value
      })
    }
  }

  async submit() {
    const formValue = this.rowViewForm.value
    var keyForm = Object.keys(formValue);
    var valuesForm = Object.values(formValue);
    var existOne = valuesForm.filter((value) => value == true)
    if (existOne.length == 0) {
      return Swal.fire({
        title: 'Sin columnas seleccionadas',
        text: 'Debe haber al menos una columna que mostrar en la tabla',
        icon: 'error',
        confirmButtonText: 'Entendido'
      })
    }
    for (let i = 0; i < keyForm.length; i++) {
      const control = keyForm[i];
      this.displayColumns.map((columnRow) => {
        var findElement = this.displayColumns.filter((columaArray) => columaArray.controlName == control)[0]
        findElement.value = formValue[control]
        columnRow = findElement
      })
    }
    localStorage.setItem("completeTColumns", JSON.stringify(this.displayColumns))
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-left',
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        popup: 'colored-toast',
      },
      timerProgressBar: true
    });
    this.dialogRef.close({ change: true })

    await Toast.fire({
      icon: 'success',
      title: 'Configuracion guardada con exito',
    });
  }

}
