import { Component, OnInit } from '@angular/core';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import { ContactService } from 'src/app/services/contact/contact.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-import-contacts',
  templateUrl: './import-contacts.component.html',
  styleUrls: ['./import-contacts.component.scss']
})
export class ImportContactsComponent implements OnInit {

  public config1: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
  };
  public loader = false;
  constructor(
    private contactService: ContactService,
    private modal: NbDialogRef<ImportContactsComponent>
  ) { }

  ngOnInit() {
  }

  onUploadInfo(event) {
    this.loader = true;
    setTimeout(() => {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (event: any) => {
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, { type: 'binary' });
      workbook.SheetNames.map(async sheet => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        try {
          const uploaded = await this.contactService.importContacts(data);
          this.loader = false;
          if (uploaded.data != 0) {
            this.loader = false;
            Swal.fire({
              title: '¡Excelente!',
              icon: 'success',
              text: `Se han añadido ${uploaded.data} contactos satisfactoriamente.`,
              confirmButtonText: "Aceptar",
              allowEscapeKey: false,
              allowOutsideClick: false,
              customClass: {
                container: "iosAlert",
                confirmButton: "red",
                cancelButton: "blue"
              }
            })
            this.modal.close()
          }
          else {
            Swal.fire({
              title: 'Error!',
              icon: 'error',
              text: `No se registraron contactos, favor de verificar el archivo, o que no esten registrados anteriormente`,
              confirmButtonText: "Aceptar",
              allowEscapeKey: false,
              allowOutsideClick: false,
              customClass: {
                container: "iosAlert",
                confirmButton: "red",
                cancelButton: "blue"
              }
            })
            this.modal.close()
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: 'Ha ocurrido un error',
            icon: 'error',
            text: 'No se han podido subir los contactos.',
            confirmButtonText: "Aceptar",
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: {
              container: "iosAlert",
              confirmButton: "red",
              cancelButton: "blue"
            }
          })
          this.modal.close()
          this.loader = false;
        }

      })
    }
  }, 2500);

  }

}
