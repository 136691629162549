<section>
    <div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
    <div class="headerAll">
        <input type="text" placeholder="Buscar un cliente por nombre" [(ngModel)]="term">
    </div>
    <div class="row">
        <div class="col-md-2" *ngFor="let item of prospectData | filterClients:term">
            <div class="card-file" [routerLink]="['/drive/archives/'+item._id]" routerLinkActive="router-link-active">
                <div class="name-folder">
                    <label>{{item.nombre}}</label>
                </div>
                <div class="img-preview">
                    <img [src]="imgFile" alt="">
                </div>
            </div>
        </div>
    </div>
</section>