import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/services/authFirebase/auth.service';
import { CallsReportsService } from 'src/app/services/calls-reports/calls-reports.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { UserService } from 'src/app/services/users/user.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-call-reports',
    templateUrl: './call-reports.component.html',
    styleUrls: ['./call-reports.component.scss']
})
export class CallReportsComponent implements OnInit {


    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator;
    dataSource: MatTableDataSource<any>;
    public displayedColumns: string[] = ['calldate', 'phone', 'caller', 'duration', 'horario', 'state', 'actions'];
    settings = {
        actions: {
            columnTitle: 'Acciones',
            add: false,
            edit: true,
            delete: false,
            position: 'right'
        },
        pager: {
            display: true,
            perPage: 20,
        },
        edit: {
            editButtonContent: "<span>Atender</span>",
        },
        columns: {
            calldate: {
                title: 'Fecha',
            },
            did: {
                title: 'Destino - Cliente',
            },
            origen: {
                title: 'Quién marco - Usuario',
            },
            minutes: {
                title: 'Duración',
            },
            horario: {
                title: 'Horario',
            },
            evento: {
                title: 'Motivo',
            },
        },
        mode: 'external',
        noDataMessage: 'No hay reportes por ahora'
    };
    public data_source = [];
    public filterCallsMap = new Map();
    public noAnswerCalls = [];
    public allCompanies = [];
    constructor(
        private callsService: CallsReportsService,
        private service: AuthService,
        private companyService: CompanyService,
        private userService: UserService
    ) { }

    async ngOnInit() {
        let res = await this.callsService.getCallsReports();
        this.allCompanies = await this.companyService.getAllCompanies();
        let calls = JSON.parse(res.calls);
        //let filterCalls = calls.filter(call => call.state == "NO ANSWER");
        calls.map((call: any) => {
            call.minutes = this.secondsToHms(call.duration)
            call.status = false;
            let hour = parseInt(call.calldate.split(' ')[1].split(':')[0]);
            if (hour >= 9 && hour <= 18) {
                call.horario = 'Llamada dentro de horario';
            } else {
                call.horario = 'Llamada fuera de horario';
            }
            if (call.duration > 15) {
                // if (call.did != "") {
                if (this.filterCallsMap.size == 0) {
                    this.filterCallsMap.set(call.uniqueid, { information: call })
                } else {
                    if (!this.filterCallsMap.has(call.uniqueid)) {
                        this.filterCallsMap.set(call.uniqueid, { information: call })
                    }
                }
                // }else{
                //     console.log('did empty');
                // }
            }
        });

        var array = Array.from(this.filterCallsMap, ([id, value]) => ({ id, value }));

        await Promise.all(this.allCompanies.map(async company => {
            await Promise.all(array.map(call => {
                let callerPhone = call.value.information.did;
                if (callerPhone.includes(company.telefono)) {
                    call.value.information.did = `${callerPhone} - ${company.nombre}`
                }
            }))
        }))

        var sort = array.sort(function (a, b) {
            if (a.value.information.calldate > b.value.information.calldate) {
                return -1;
            }
            if (a.value.information.calldate < b.value.information.calldate) {
                return 1;
            }
            return 0;
        });

        sort.forEach(element => {
            switch (element.value.information.evento) {
                case "ABANDON":
                    element.value.information.evento = "El cliente colgó la llamada durante la espera"
                    break;
                case "EXITWITHKEY":
                    element.value.information.evento = "Durante la espera en fila el cliente solicito que le devolvieran la llamada"
                    break;
                case "EXITWITHTIMEOUT":
                    element.value.information.evento = "La llamada permaneció en espera el tiempo máximo"
                    break;
                default:
                    break;
            }
            this.noAnswerCalls.push(element.value.information)
        });
        this.data_source = this.noAnswerCalls;
        this.dataSource = new MatTableDataSource(this.noAnswerCalls)
        this.dataSource.paginator = this.paginator;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    async changeStatusTrue(row) {
        Swal.fire({
            title: "Creando entrada...",
            icon: "info",
        });
        Swal.showLoading()
        var num = row.data.did.split('52')
        var companyPhone = parseInt(num[1]);
        var callId = row.data.uniqueid
        try {
            let companyId = await this.companyService.getCompanyByPhoneNumber(companyPhone)
            let currentUserId = this.service.currentuser().uid;
            let currentUserInfo = await this.userService.getUserById(currentUserId)
            let log = {
                comments: "Se atendio una llamada",
                date: new Date(),
                idUser: currentUserInfo.uid,
                text: "llamada atendida por " + currentUserInfo.nombre,
                totalActions: 1
            }
            await this.callsService.addLog(companyId.id, log);
            await this.callsService.attendMissingCall(callId);
            row.data.status = !row.data.status
            Swal.close()
            await Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Se creo el registro de la llamada atendida',
                showConfirmButton: true,
            })
        } catch (error) {
            Swal.close()
            await Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'No hay ninguna empresa con este numero telefonico',
                showConfirmButton: true,
            })
        }
    }

    secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr. " : " hrs. ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min. " : " mins. ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " s." : " s.") : "";
        return hDisplay + mDisplay + sDisplay;
    }

}
