<div class="table-container">
    <table mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Titulo </th>
            <td mat-cell *matCellDef="let row"> {{row.action}} </td>
        </ng-container>
        <ng-container matColumnDef="endTime">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell *matCellDef="let row"> {{row.date| date:"dd/MMMM/yyyy hh:mm a"}} </td>
        </ng-container>
        <ng-container matColumnDef="pendingFor">
            <th mat-header-cell *matHeaderCellDef> Usuario / Cliente </th>
            <td mat-cell *matCellDef="let row"> {{row.pendingFor}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Acciones </th>
            <td mat-cell *matCellDef="let row">
                <button mat-button (click)="sendNotification(row)"><mat-icon class="status-btn">send</mat-icon></button>
                <button mat-button (click)="deleteNotification(row.id)"><mat-icon
                        class="delete-btn">delete</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No hay pendientes de hoy </td>
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
</div>