import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DiaryService } from "src/app/services/diary/diary.service";
import { EventsService } from "src/app/services/events/events.service";
import Swal from "sweetalert2";
import { DateTime } from "luxon";
@Component({
  selector: "app-add-spaces",
  templateUrl: "./add-spaces.component.html",
  styleUrls: ["./add-spaces.component.scss"],
})
export class AddSpacesComponent implements OnInit {
  public eventForm: FormGroup;
  public minDate: Date;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { companyID: any, initDay: any, endDay: any },
    public dialogRef: MatDialogRef<AddSpacesComponent>,
    public formBuilderService: FormBuilder,
    private eventService: EventsService,
    public diaryService: DiaryService
  ) { }

  ngOnInit() {
    this.eventForm = this.formBuilderService.group({
      onlyOneDate: [false],
      startDate: [this.data.initDay || "", Validators.required],
      endDate: ["", Validators.required],
      subject: ["", Validators.required],
      startTime: [{ hour: 9, minute: 0 }, Validators.required],
      endTime: [{ hour: 10, minute: 0 }, Validators.required],
      duration: ["", Validators.required],
      clientId: [this.data.companyID, Validators.required],
    });
  }

  checkedChange(e) {
    this.eventForm.controls.endDate.setValue("");
    if (e) {
      this.eventForm.get("endDate").setValidators([Validators.required]);
      this.eventForm.get("endDate").updateValueAndValidity();
    } else {
      this.eventForm.get("endDate").clearValidators();
      this.eventForm.get("endDate").updateValueAndValidity();
    }
  }

  onSelectedStartDate(event) {
    var date = new Date(event.value);
    date.setDate(date.getDate() + 1);
    this.minDate = date;
  }

  getCombineDateTime(date: Date, time) {
    var day = new Date(new Date(date).setHours(0, 0, 0, 0))
    var hour = parseInt(time.hour);
    var min = parseInt(time.minute);
    var newDate = DateTime.fromISO(day.toISOString().split("T")[0])
      .set({ hour: hour })
      .set({ minute: min })
      .set({ second: 0 })
      .toJSDate();
    return newDate;
  }

  async save() {
    var form = this.eventForm.value;

    if (form.startDate == "") {
      Swal.fire({
        title: "Lo sentimos",
        text: "Debe insertar una fecha inicial para continuar",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
      return;
    }

    if (form.endDate == "" && !form.onlyOneDate) {
      Swal.fire({
        title: "Lo sentimos",
        text: "Debe insertar una fecha final para continuar",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
      return;
    }

    if (form.duration == "") {
      Swal.fire({
        title: "Lo sentimos",
        text: "Debe insertar un intervalo de tiempo para continuar",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });
      return;
    }

    if (form.onlyOneDate) {

      if (form.startTime.hour == form.endTime.hour) {

        if (form.endTime.minute < form.startTime.minute) {
          Swal.fire({
            title: "Lo sentimos",
            text: "La hora final no puede ser menor a la inicial",
            icon: "error",
            showCancelButton: false,
            showConfirmButton: true,
          });
          return;
        }

        if ((form.endTime.minute - form.startTime.minute) < form.duration) {
          Swal.fire({
            title: "Lo sentimos",
            text: "No hay tiempo suficiente para generar espacios",
            icon: "error",
            showCancelButton: false,
            showConfirmButton: true,
          });
          return;
        }
      }
    }
    var startDateTime = this.getCombineDateTime(form.startDate, form.startTime);
    var endDateTimer = this.getCombineDateTime(form.startDate, form.endTime);

    var newEndDate: any;
    if (form.endDate != "") {
      newEndDate = new Date(form.endDate);
      newEndDate.setDate(newEndDate.getDate() + 1);
    } else {
      newEndDate = form.startDate;
    }
    var buildInfo = {
      startDate: form.startDate.toString(),
      endDate: newEndDate.toString(),
      startTime: startDateTime.toString(),
      endTime: endDateTimer.toString(),
      interval: form.duration,
      clientId: form.clientId,
    };
    Swal.fire({
      title: "Creando espacios...",
      icon: "info",
    });
    Swal.showLoading();
    try {
      const response = await this.eventService.addSpaces(buildInfo);
      this.dialogRef.close();
      Swal.close();
      await Swal.fire({
        title: "Espacios creados correctamente",
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      Swal.fire({
        title: "Lo sentimos",
        text: "Ha ocurrido un error al crear espacios, verifique que no haya espacios creados en las fechas selccionadas e intente de nuevo",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
      });

      this.eventForm.reset();
    }
  }

  public cancel() {
    this.dialogRef.close();
  }
}
