<nb-card>
    <nb-card-header>
        Nueva información
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="addForm">
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Título</label>
                    <input type="text" nbInput fullWidth fieldSize="medium" formControlName="title"
                        placeholder="Título">
                </div>
            </div>
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Archivo</label>
                    <div class="dropzone-custom">
                        <div class="dropzone dropzone-primary" [dropzone]="config1" (success)="onUploadInfo($event)">
                            <div class="dz-message needsclick">
                                <nb-icon icon="cloud-upload-outline" style="font-size: 50px; color: #ffaa00;"></nb-icon>
                                <h4 class="mb-0 f-w-600" style="text-transform: unset;font-size: 18px;">Arrasta tu
                                    archivo aqui
                                    o da click para subirlo.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer style="text-align: center;">
        <button class="mr-5" nbButton status="danger" (click)="ref.close()">Cancelar</button>
        <button nbButton status="success" (click)="submit()" [disabled]="!addForm.valid">Guardar</button>
    </nb-card-footer>
</nb-card>