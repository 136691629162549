import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { AuthGuardService } from './services/authFirebase/auth-guard.service';
import { UsersComponent } from './pages/users/users.component';
import { LoginComponent } from './pages/login/login.component';
import { DiaryComponent } from './pages/diary/diary.component';
import { ReportesComponent } from './pages/reportes/reportes.component';
import { ReporteInternoComponent } from './pages/reporte-interno/reporte-interno.component';
import { ReporteInternoDetailsComponent } from './pages/reporte-interno/details/details.component';
import { ChatComponent } from './pages/chat/chat.component';
import { DatesLogsComponent } from './pages/companies/dates-logs/dates-logs.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { GeneralReportsComponent } from './pages/general-reports/general-reports/general-reports.component';
import { GeneralReportPageComponent } from './pages/general-reports/general-report-page/general-report-page.component';
import { CallReportsComponent } from './pages/call-reports/call-reports/call-reports.component';
import { ReporteRentabilidadComponent } from './pages/reporte-rentabilidad/reporte-rentabilidad.component';
import { ReporteRentabilidadDetailsComponent } from './pages/reporte-rentabilidad/reporte-rentabilidad-details/reporte-rentabilidad-details.component';
import { ReporteAgentesComponent } from './pages/reporte-agentes/reporte-agentes.component';
import { ReporteAgentesDetailsComponent } from './pages/reporte-agentes/reporte-agentes-details/reporte-agentes-details.component';
import { PendingsUsersComponent } from './pages/pendings-users/pendings-users.component';
import { SignInGuardService } from './services/sign-in-guard/sign-in-guard.service';
import { CompanyFormComponent } from './components/companies/company-form/edit.component';
import { AddProspectsComponent } from './components/companies/add-prospects/add-prospects.component';
import { CompleteProspectsComponent } from './components/companies/complete-prospects/complete-prospects.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DriveManagerComponent } from './pages/drive-manager/drive-manager.component';
import { DriveViewComponent } from './pages/drive-manager/drive-view/drive-view.component';
import { UserScheduleComponent } from './pages/user-schedule/user-schedule.component';
import { ProfibilityReportComponent } from './pages/profibility-report/profibility-report.component';
import { ReportViewComponent } from './pages/profibility-report/report-view/report-view.component';
import { AdminMarketingComponent } from './pages/admin-marketing/admin-marketing.component';
import { AnalyticsClientsComponent } from './pages/analytics-clients/analytics-clients.component';
import { CallModalComponent } from './components/call-modal/call-modal.component';
import { GenerateZoomComponent } from './components/generate-zoom/generate-zoom.component';
import { ProfileUserComponent } from './pages/profile-user/profile-user.component';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [SignInGuardService] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'marketing', component: AdminMarketingComponent, canActivate: [AuthGuardService] },
  { path: 'clients', component: CompaniesComponent, canActivate: [AuthGuardService] },
  { path: 'clients/new', component: CompanyFormComponent, canActivate: [AuthGuardService] },
  { path: 'clients/edit/:id', component: CompanyFormComponent, canActivate: [AuthGuardService] },
  { path: 'clients/new-prospects', component: AddProspectsComponent, canActivate: [AuthGuardService] },
  { path: 'clients/new-prospects/:id', component: AddProspectsComponent, canActivate: [AuthGuardService] },
  { path: 'prospects/complete/:id', component: CompleteProspectsComponent, canActivate: [AuthGuardService] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuardService] },
  { path: 'diary/:id', component: DiaryComponent, canActivate: [AuthGuardService] },
  { path: 'chat/:id', component: ChatComponent, canActivate: [AuthGuardService] },
  { path: 'reportes', component: ReportesComponent, canActivate: [AuthGuardService] },
  { path: 'reportes-generales', component: GeneralReportsComponent, canActivate: [AuthGuardService] },
  { path: 'reportes-llamadas', component: CallReportsComponent, canActivate: [AuthGuardService] },
  { path: 'reporte-general/:id', component: GeneralReportPageComponent, canActivate: [AuthGuardService] },
  { path: 'notificaciones', component: NotificationsComponent, canActivate: [AuthGuardService] },
  { path: 'reporte_interno', component: ReporteInternoComponent, canActivate: [AuthGuardService] },
  { path: 'reporte_interno_details', component: ReporteInternoDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'reporte-rentabilidad', component: ReporteRentabilidadComponent, canActivate: [AuthGuardService] },
  { path: 'reporte-rentabilidad-details/:id', component: ReporteRentabilidadDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'report-profibility', component: ProfibilityReportComponent, canActivate: [AuthGuardService] },
  { path: 'report-profibility-details/:id', component: ReportViewComponent, canActivate: [AuthGuardService] },
  { path: 'reporte-agentes', component: ReporteAgentesComponent, canActivate: [AuthGuardService] },
  { path: 'reporte-agentes-details/:id', component: ReporteAgentesDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'dates-logs', component: DatesLogsComponent, canActivate: [AuthGuardService] },
  { path: 'pendings', component: PendingsUsersComponent, canActivate: [AuthGuardService] },
  { path: 'user-schedule', component: UserScheduleComponent, canActivate: [AuthGuardService] },
  { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuardService] },
  { path: 'analytics-clients', component: AnalyticsClientsComponent, canActivate: [AuthGuardService] },
  { path: 'drive', component: DriveManagerComponent, canActivate: [AuthGuardService] },
  { path: 'drive/archives/:id', component: DriveViewComponent, canActivate: [AuthGuardService] },
  { path: 'incomming-call', component: CallModalComponent, canActivate: [AuthGuardService] },
  { path: 'create-zoom', component: GenerateZoomComponent, canActivate: [AuthGuardService] },
  { path: 'profile', component: ProfileUserComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
