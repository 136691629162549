import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { DetailsReportsComponent } from '../details/details.component';
import { Company } from 'src/app/models/company';

@Component({
    selector: 'app-generate',
    templateUrl: './generate.component.html',
    styleUrls: ['./generate.component.scss']
})
export class GenerateReporteComponent implements OnInit {

    public addForm: FormGroup;
    public minDate: Date;
    public clients: Company[] = [];

    socialMediaEntries: Array<any>;
    constructor(
        private companyService: CompanyService,
        private afs: AngularFirestore,
        public ref: NbDialogRef<GenerateReporteComponent>,
        public formBuilder: FormBuilder,
        private dialogService: NbDialogService
    ) { }

    async ngOnInit() {

        this.socialMediaEntries = [];

        this.addForm = this.formBuilder.group({
            start_date: [""],
            end_date: [""],
            client: [""],
            commentsFacebook: [false],
            campaignFacebook: [false],
            postsFacebook: [false],
            postsInsta: [false],
            inboxFacebook: [false],
            commentsInsta: [false],
            infoMasiva: [false],
            responseInsta: [false],
            confirmDates: [false],
            remiders: [false],
            congrats: [false],
            infoLinkedIn: [false],
            whatsappMessages: [false],
            surveys: [false],
            administrativeActivities: [false],
            zoomConections: [false],
            emailFollowUps: [false],
            socialNetworkPost: [false],
            externalAgenda: [false],
        });
        this.clients = await this.getCompanies();
    }

    async getCompanies() {
        try {
            var companies = await this.companyService.getAllCompanies();
            return companies.filter((company) => company.activeInDashboard == true)
        } catch (error) {
            await Swal.fire({
                icon: 'error',
                title: 'Ocurrió un error al obtener los clientes',
                text: "Si el problema persiste, favor de reportar el error"
            })
        }
    }

    onSelectedStartDate(event) {
        var date = new Date(event);
        date.setDate(date.getDate() + 1);
        this.minDate = date;
        this.addForm.controls.start_date.setValue(event);

    }

    onSelectedEndDate(event) {
        this.addForm.controls.end_date.setValue(event);
    }

    validateForm() {
        var form = this.addForm.value;
        if (form.client != '' || form.start_date != '' || form.end_date != '') {
            this.action(form);
        } else {
            Swal.fire({
                position: 'center',
                icon: 'info',
                title: 'Todos los campos son obligatorios',
                showConfirmButton: true,
            });
        }
    }

    action(form) {

        var companyID = form.client.split(',')[0];
        var companyName = form.client.split(',')[1];
        var time_start_date = form.start_date.getTime();
        var time_end_date = form.end_date.getTime();

        var day_start = formatDate(new Date(time_start_date), 'dd', 'en-US');
        var month_start = formatDate(new Date(time_start_date), 'MMMM', 'es-MX');
        var year_start = formatDate(new Date(time_start_date), 'yyy', 'en-US');
        var start_date_txt = day_start + ' de ' + month_start + " de " + year_start;

        var day_end = formatDate(new Date(time_end_date), 'dd', 'en-US');
        var month_end = formatDate(new Date(time_end_date), 'MMMM', 'es-MX');
        var year_end = formatDate(new Date(time_end_date), 'yyy', 'en-US');
        var end_date_txt = day_end + ' de ' + month_end + " de " + year_end;

        var total_mails_arr = [];
        var total_citas_arr = [];
        var total_calls_arr = [];

        var infoToShow = [
            form.commentsFacebook,
            form.campaignFacebook,
            form.postsFacebook,
            form.postsInsta,
            form.inboxFacebook,
            form.commentsInsta,
            form.infoMasiva,
            form.responseInsta,
            form.confirmDates,
            form.remiders,
            form.congrats,
            form.infoLinkedIn,
            form.whatsappMessages,
            form.surveys,
            form.administrativeActivities,
            form.zoomConections,
            form.emailFollowUps,
            form.socialNetworkPost,
            form.externalAgenda,
        ]

        this.fillArray(infoToShow);

        this.companyService.getLogsByRange(form.start_date, form.end_date, companyID).then(res => {

            //COUNT SOCIAL MEDIA ENTRIES
            this.countSMEntries(res).then(socialMediaEntries => {

                //COUNT DATES AND CALLS
                res.forEach(element => {
                    var text = element.text;
                    if (text.startsWith("Se envió")) {
                        total_mails_arr.push(element)
                    }
                    else if (text.startsWith("Llamada atendida")) {
                        total_calls_arr.push(element)
                    }
                    else if (text.startsWith("Cita agendada")) {
                        total_citas_arr.push(element)
                    }
                });

                //ADD REPORT
                this.afs.collection('client-reports').add({
                    activities: res,
                    total_mails: total_mails_arr.length,
                    total_calls: total_calls_arr.length,
                    total_citas: total_citas_arr.length,
                    socialMediaEntries: socialMediaEntries,
                    report: 'Reporte del ' + start_date_txt + ' al ' + end_date_txt,
                    date: new Date(),
                    clientID: companyID,
                    clientName: companyName,
                    infoToShow: {
                        commentsFacebook: form.commentsFacebook,
                        campaignFacebook: form.campaignFacebook,
                        postsFacebook: form.postsFacebook,
                        postsInsta: form.postsInsta,
                        inboxFacebook: form.inboxFacebook,
                        commentsInsta: form.commentsInsta,
                        infoMasiva: form.infoMasiva,
                        responseInsta: form.responseInsta,
                        confirmDates: form.confirmDates,
                        remiders: form.remiders,
                        congrats: form.congrats,
                        infoLinkedIn: form.infoLinkedIn,
                        whatsappMessages: form.whatsappMessages,
                        surveys: form.surveys,
                        administrativeActivities: form.administrativeActivities,
                        zoomConections: form.zoomConections,
                        emailFollowUps: form.emailFollowUps,
                        socialNetworkPost: form.socialNetworkPost,
                        externalAgenda: form.externalAgenda,
                    },
                    visible: false
                }).then((resAux) => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Se ha generado el reporte, recuerde enviarlo al cliente, después de verificar la información',
                        showConfirmButton: true,
                    });
                    var reportAux = {
                        data: {
                            id: resAux.id,
                            activities: res,
                            total_mails: total_mails_arr.length,
                            total_calls: total_calls_arr.length,
                            total_citas: total_citas_arr.length,
                            socialMediaEntries: socialMediaEntries,
                            report: 'Reporte del ' + start_date_txt + ' al ' + end_date_txt,
                            date: new Date(),
                            clientID: companyID,
                            clientName: companyName,
                            infoToShow: {
                                commentsFacebook: form.commentsFacebook,
                                campaignFacebook: form.campaignFacebook,
                                postsFacebook: form.postsFacebook,
                                postsInsta: form.postsInsta,
                                inboxFacebook: form.inboxFacebook,
                                commentsInsta: form.commentsInsta,
                                infoMasiva: form.infoMasiva,
                                responseInsta: form.responseInsta,
                                confirmDates: form.confirmDates,
                                remiders: form.remiders,
                                congrats: form.congrats,
                                infoLinkedIn: form.infoLinkedIn,
                                whatsappMessages: form.whatsappMessages,
                                surveys: form.surveys,
                                administrativeActivities: form.administrativeActivities,
                                zoomConections: form.zoomConections,
                                emailFollowUps: form.emailFollowUps,
                                socialNetworkPost: form.socialNetworkPost,
                                externalAgenda: form.externalAgenda,
                            },
                            visible: false
                        }
                    }

                    this.ref.close();
                    this.dialogService.open(DetailsReportsComponent, { context: reportAux });
                    this.addForm.reset();
                })
            })


        }).catch(error => {
            console.log(error);
            Swal.fire({
                position: 'center',
                icon: 'info',
                title: 'Ocurrio un error al consultar datos',
                text: 'No hay registro de actividad dentro de las fechas seleccionadas',
                showConfirmButton: true,
            })
        })


    }

    countSMEntries(entries: Array<any>) {
        return new Promise<any>(async (resolve, reject) => {

            this.socialMediaEntries.forEach(socialMediaEntry => {
                entries.forEach((entry) => {
                    if (entry.text.startsWith(socialMediaEntry.title)) {
                        socialMediaEntry.quantity++;
                        socialMediaEntry.totalAll = socialMediaEntry.totalAll + entry.totalActions
                    }
                })
            })
            resolve(this.socialMediaEntries)
        })
    }

    fillArray(infoToShow) {
        var index = 0;
        infoToShow.forEach(element => {
            if (element) {
                switch (index) {
                    case 0:
                        this.socialMediaEntries.push({
                            title: "Comentarios respondidos en Facebook",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 1:
                        this.socialMediaEntries.push({
                            title: "Campañas de Facebook",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 2:
                        this.socialMediaEntries.push({
                            title: "Post de Facebook",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 3:
                        this.socialMediaEntries.push({
                            title: "Post de Instagram",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 4:
                        this.socialMediaEntries.push({
                            title: "Inbox enviados con información por Facebook",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 5:
                        this.socialMediaEntries.push({
                            title: "Comentarios respondidos por Instagram",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 6:
                        this.socialMediaEntries.push({
                            title: "Envíos de información masiva",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 7:
                        this.socialMediaEntries.push({
                            title: "Mensajes respondidos por Instagram",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 8:
                        this.socialMediaEntries.push({
                            title: "Confirmaciones de citas",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 9:
                        this.socialMediaEntries.push({
                            title: "Envíos de recordatorios",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 10:
                        this.socialMediaEntries.push({
                            title: "Envíos de felicitaciones",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 11:
                        this.socialMediaEntries.push({
                            title: "Envío de información por LinkedIn",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 12:
                        this.socialMediaEntries.push({
                            title: "Mensaje de WhatsApp",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 13:
                        this.socialMediaEntries.push({
                            title: "Envíos de encuesta",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 14:
                        this.socialMediaEntries.push({
                            title: "Actividades administrativas",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 15:
                        this.socialMediaEntries.push({
                            title: "Conexiones por Zoom",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 16:
                        this.socialMediaEntries.push({
                            title: "Seguimientos correo electrónico",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 17:
                        this.socialMediaEntries.push({
                            title: "Post en otra red social",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    case 18:
                        this.socialMediaEntries.push({
                            title: "Citas agenda externa",
                            quantity: 0,
                            totalAll: 0,
                        })
                        break;
                    default:
                        break;
                }
            }
            index++;
        });

    }

}
