import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-change-time',
  templateUrl: './change-time.component.html',
  styleUrls: ['./change-time.component.scss']
})
export class ChangeTimeComponent implements OnInit {

  public contextForm: FormGroup
  public invalidFlag: string;
  public hasError: boolean;

  constructor(
    public ref: NbDialogRef<ChangeTimeComponent>,
    private formBuilder: FormBuilder
  ) {
    this.contextForm = this.formBuilder.group({
      reason: ["", Validators.required]
    });
  }

  ngOnInit() {
  }

  validForm() {
    const formData = this.contextForm;
    if (formData.controls["reason"].errors) {
      this.invalidFlag = 'reason';
      this.hasError = true
    }
    if (formData.invalid) {
      this.invalidFlag = 'invalid-all';
      return
    }
    this.ref.close(formData.value)
  }

  resetValidations(flag?: string) {
    this.hasError = false
    this.invalidFlag = ''
  }

}
