import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NbDialogService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { CompanyFocusService } from 'src/app/services/companyOnFocus/company-focus.service';
import { MessagesService } from 'src/app/services/recados/messages.service';
import Swal from 'sweetalert2';
import { AddRecadoComponent } from '../add-recado/add-recado.component';

@Component({
  selector: 'app-recados',
  templateUrl: './recados.component.html',
  styleUrls: ['./recados.component.scss']
})
export class RecadosComponent implements OnInit {
  public currentAsistente: any;
  public unreadMsg$: Observable<any>;
  public readMsg$: Observable<any>;
  constructor(
    private focusService: CompanyFocusService,
    private recados_service: MessagesService,
    private dialogService: NbDialogService,
    public afs: AngularFirestore,
  ) { 
    this.unreadMsg$ = this.recados_service.getUnreadMessages();
    this.unreadMsg$.subscribe((data) => { });
    this.readMsg$ = this.recados_service.getreadMessages();
    this.readMsg$.subscribe((data) => { });
  }

  ngOnInit() {
  }

  createRecado() {
    this.dialogService.open(AddRecadoComponent).onClose.subscribe((data) => {
      if (data != undefined) {
        var companyID = this.focusService.getCompanyOnFocus();
        this.recados_service.addNewMessage(data).then(() => {
          this.afs
            .collection("empresas")
            .doc(companyID)
            .collection("log")
            .add({
              date: new Date(),
              text: "Se creó un recado por " + this.currentAsistente.nombre,
              comments: "n/a",
              idUser: this.currentAsistente.id,
            });
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Recado creado",
            showConfirmButton: false,
            timer: 1000,
          });
        });
      }
    });
  }

}
