<div class="dialog-template">
  <div class="header-template">
    <h3>Nuevos espacios</h3>
  </div>
  <form [formGroup]="eventForm" class="formContainer">
    <div class=" form-group row">
      <div class="col-md-12">
        <mat-slide-toggle formControlName="onlyOneDate">Solo con una fecha</mat-slide-toggle>
      </div>
    </div>
    <div class=" form-group row">
      <div [ngClass]="eventForm.controls['onlyOneDate'].value ? 'col-md-12': 'col-md-6'">
        <label for="">Fecha inicial</label>
        <input matInput [matDatepicker]="picker" formControlName="startDate" (click)="picker.open()"
          placeholder="dd/mm/yyyy" (dateChange)="onSelectedStartDate($event)">
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <div class="col-md-6" *ngIf="!eventForm.value.onlyOneDate">
        <label for="">Fecha final</label>
        <input matInput [matDatepicker]="pickerEnd" formControlName="endDate" (click)="pickerEnd.open()"
          placeholder="dd/mm/yyyy" [min]="minDate">
        <mat-datepicker #pickerEnd></mat-datepicker>
      </div>
    </div>
    <div class=" form-group row">
      <div class="col-md-12">
        <label for="">Intervalo</label>
        <mat-select placeholder="Selecciona un intervalo" formControlName="duration">
          <mat-option [value]="15">15 minutos</mat-option>
          <mat-option [value]="20">20 minutos</mat-option>
          <mat-option [value]="25">25 minutos</mat-option>
          <mat-option [value]="30">30 minutos</mat-option>
          <mat-option [value]="35">35 minutos</mat-option>
          <mat-option [value]="40">40 minutos</mat-option>
          <mat-option [value]="45">45 minutos</mat-option>
          <mat-option [value]="50">50 minutos</mat-option>
          <mat-option [value]="60">60 minutos</mat-option>
        </mat-select>
      </div>
    </div>
    <div class=" form-group row">
      <div class="col-md-6">
        <label for="">Hora inicial</label>
        <ngb-timepicker formControlName="startTime" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
      </div>
      <div class="col-md-6">
        <label for="">Hora final</label>
        <ngb-timepicker formControlName="endTime" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
      </div>
    </div>
  </form>
  <div class="buttons-footer">
    <button mat-button class="danger" (click)="cancel()">
      <mat-icon>close</mat-icon> Cancelar
    </button>
    <button mat-button class="success" [disabled]="!eventForm.value" (click)="save()">
      <mat-icon>save</mat-icon> Guardar
    </button>
  </div>
</div>
