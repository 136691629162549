<section>
    <div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
    <div class="card-count">
        <nb-card [ngClass]="{'active-graphs': selectType == 1}" (click)="changeFilter(1)">
            <nb-card-body>
                <div class="card-content">
                    <div>
                        <h4>Cientes Activos</h4>
                        <h4><strong>Total: </strong> {{countClients}} </h4>
                    </div>
                    <mat-icon>check_circle</mat-icon>
                </div>
            </nb-card-body>
        </nb-card>
        <nb-card [ngClass]="{'active-graphs': selectType == 2}" (click)="changeFilter(2)">
            <nb-card-body>
                <div class="card-content">
                    <div>
                        <h4>Clientes inactivos</h4>
                        <h4><strong>Total: </strong> {{countInactiveClients}} </h4>
                    </div>
                    <mat-icon>visibility_off</mat-icon>
                </div>
            </nb-card-body>
        </nb-card>
        <nb-card [ngClass]="{'active-graphs': selectType == 3}" (click)="changeFilter(3)">
            <nb-card-body>
                <div class="card-content">
                    <div>
                        <h4>Clientes registrados</h4>
                        <h4><strong>Total: </strong> {{clientList.length}} </h4>
                    </div>
                    <mat-icon>assignment</mat-icon>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="row">
        <div class="col-md-4">
            <nb-card>
                <nb-card-header>
                    <h5>Ejecutivos</h5>
                </nb-card-header>
                <div *ngIf="showData">
                    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                        [legend]="barChartLegend" [chartType]="barChartType" [plugins]="pluginBar">
                    </canvas>
                </div>
            </nb-card>
        </div>
        <div class="col-md-4">
            <nb-card>
                <nb-card-header>
                    <h5>Plan </h5>
                </nb-card-header>
                <div *ngIf="showData">
                    <canvas baseChart [datasets]="planGraphData" [labels]="planLabels" [options]="barChartOptions"
                        [legend]="barChartLegend" [chartType]="barChartType">
                    </canvas>
                </div>
            </nb-card>
        </div>
        <div class="col-md-4">
            <nb-card>
                <nb-card-header>
                    <h5>Tareas de clientes</h5>
                </nb-card-header>
                <div *ngIf="showData">
                    <canvas baseChart [datasets]="taskGraphData" [labels]="taskLabels" [options]="barChartOptions"
                        [legend]="barChartLegend" [chartType]="barChartType">
                    </canvas>
                </div>
            </nb-card>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-card>
                <h5>Nuevos Clientes en el mes</h5>
                <div class="table-container">
                    <table mat-table #table [dataSource]="newClients">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Cliente </th>
                            <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                        </ng-container>
                        <ng-container matColumnDef="plan">
                            <th mat-header-cell *matHeaderCellDef> Plan contratado </th>
                            <td mat-cell *matCellDef="let row"> {{row.plan}} </td>
                        </ng-container>
                        <ng-container matColumnDef="contract">
                            <th mat-header-cell *matHeaderCellDef> Fecha de contrato </th>
                            <td mat-cell *matCellDef="let row"> {{row.contratoDate | date}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['name','plan','contract']"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['name','plan','contract'];"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="3">No hay nuevos clientes</td>
                        </tr>
                    </table>
                </div>
            </mat-card>
        </div>
        <div class="col-md-6">
            <mat-card>
                <h5>Registro de llamadas</h5>
                <div class="table-container">
                    <table mat-table #table [dataSource]="todayCalls">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Cliente - Numero </th>
                            <td mat-cell *matCellDef="let row"> {{row.number}} </td>
                        </ng-container>
                        <ng-container matColumnDef="plan">
                            <th mat-header-cell *matHeaderCellDef> Tipo de llamada </th>
                            <td mat-cell *matCellDef="let row"> <span [ngClass]="row.state">{{row.state}}
                                    <mat-icon>{{row.type == 'incoming' ? 'call_received':'call_made' }}
                                    </mat-icon></span> </td>
                        </ng-container>
                        <ng-container matColumnDef="contract">
                            <th mat-header-cell *matHeaderCellDef> Tiempo de llamada </th>
                            <td mat-cell *matCellDef="let row"> {{row.duration}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['name','plan','contract']"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['name','plan','contract'];"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="3">No hay nuevos clientes</td>
                        </tr>
                    </table>
                </div>
            </mat-card>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            <nb-card>
                <nb-card-header>
                    <h5>Citas en el dia</h5>
                </nb-card-header>
                <nb-card-body>
                    <div class="table-container">
                        <table mat-table #table [dataSource]="eventsList">
                            <ng-container matColumnDef="subject">
                                <th mat-header-cell *matHeaderCellDef>Asunto </th>
                                <td mat-cell *matCellDef="let row"> {{row.subject}} </td>
                            </ng-container>
                            <ng-container matColumnDef="client">
                                <th mat-header-cell *matHeaderCellDef> Nombre del cliente </th>
                                <td mat-cell *matCellDef="let row"> {{row.clientId}} </td>
                            </ng-container>
                            <ng-container matColumnDef="time">
                                <th mat-header-cell *matHeaderCellDef> Fecha limite </th>
                                <td mat-cell *matCellDef="let row"> {{row.endDate | date:'medium'}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="eventsColumns"></tr>
                            <tr mat-row *matRowDef="let row;columns: eventsColumns"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="3">No hay pendientes por hoy</td>
                            </tr>
                        </table>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="col-md-6">
            <nb-card>
                <nb-card-header>
                    <h5>Pendientes en el dia</h5>
                </nb-card-header>
                <nb-card-body>
                    <div class="table-container">
                        <table mat-table #table [dataSource]="pendingList">
                            <ng-container matColumnDef="pending">
                                <th mat-header-cell *matHeaderCellDef>Pendiente</th>
                                <td mat-cell *matCellDef="let row"> {{row.title}} </td>
                            </ng-container>
                            <ng-container matColumnDef="client">
                                <th mat-header-cell *matHeaderCellDef> Nombre del cliente </th>
                                <td mat-cell *matCellDef="let row"> {{row.companyName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="time">
                                <th mat-header-cell *matHeaderCellDef> Fecha limite </th>
                                <td mat-cell *matCellDef="let row"> {{row.limitTime | date:'medium'}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                            <tr mat-row *matRowDef="let row;columns: displayColumns"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">No hay pendientes por hoy</td>
                            </tr>
                        </table>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</section>