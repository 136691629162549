<section>
    <div class="header-container">
        <h3>Lista de tareas</h3>
        <div class="button-wrap">
            <button mat-button (click)="createTask()"><mat-icon>add</mat-icon>Nueva tarea</button>
        </div>
    </div>
    <div class="card">
        <ng2-smart-table [settings]="settingsActivities" [source]="data_source_4" (edit)="updateTask($event.data)"
            (delete)="deleteTask($event.data)">
        </ng2-smart-table>
    </div>
</section>