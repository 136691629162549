import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  userId: any;
  constructor(
    private router: Router,
    private authService: AuthService,
    public afAuth: AngularFireAuth
  ) {
  }

  getUserId() {
    var user = this.authService.currentuser();

    if (user) {
      this.userId = user.uid;
      return this.userId;
    } else {
      return false;
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.afAuth.authState.
      pipe(take(1),
        map(authstate => !!authstate),
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['']);
          }
        }));
  }
}
