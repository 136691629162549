<div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>
                Reportes internos
            </nb-card-header>

            <nb-card-body>
                <ng2-smart-table [settings]="settings" [source]="data_source" (edit)="openDetails($event)"
                    (create)="createNew()">
                </ng2-smart-table>
            </nb-card-body>
        </nb-card>
    </div>
</div>