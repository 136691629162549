<section>
    <div class="header-container">
        <h3>Lista de prospectos</h3>
        <div class="button-wrap">
            <button mat-button class="success-btn" (click)="createNew()"><mat-icon>add</mat-icon> Nuevo
                prospecto</button>
        </div>
    </div>
    <div class="card-container">
        <nb-tabset>
            <nb-tab tabTitle="Nuevos prospectos">
                <div class="search">
                    <mat-form-field appearance="outline">
                        <mat-label>Buscar por nombre...</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" placeholder="Ej. MIA ghost" #input>
                    </mat-form-field>
                </div>
                <div class="table-container">
                    <table mat-table #table [dataSource]="data_source">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Nombre comercial </th>
                            <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                        </ng-container>
                        <ng-container matColumnDef="companyName">
                            <th mat-header-cell *matHeaderCellDef> Nombre del cliente </th>
                            <td mat-cell *matCellDef="let row"> {{row.nombreCliente}} </td>
                        </ng-container>
                        <ng-container matColumnDef="gender">
                            <th mat-header-cell *matHeaderCellDef> Sexo </th>
                            <td mat-cell *matCellDef="let row"> {{row.gender == 'male' ? 'Masculino': 'Femenino'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="age">
                            <th mat-header-cell *matHeaderCellDef> Edad </th>
                            <td mat-cell *matCellDef="let row"> {{row.age}} </td>
                        </ng-container>
                        <ng-container matColumnDef="giroComercial">
                            <th mat-header-cell *matHeaderCellDef> Giro comercial </th>
                            <td mat-cell *matCellDef="let row"> {{row.giroComercial}} </td>
                        </ng-container>
                        <ng-container matColumnDef="direccion">
                            <th mat-header-cell *matHeaderCellDef> Dirección comercial </th>
                            <td mat-cell *matCellDef="let row"> {{row.direccion}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fiscalAddress">
                            <th mat-header-cell *matHeaderCellDef> Dirección fiscal </th>
                            <td mat-cell *matCellDef="let row"> {{row.fiscalAddress}} </td>
                        </ng-container>
                        <ng-container matColumnDef="city">
                            <th mat-header-cell *matHeaderCellDef> Ciudad </th>
                            <td mat-cell *matCellDef="let row"> {{row.city}} </td>
                        </ng-container>
                        <ng-container matColumnDef="origin">
                            <th mat-header-cell *matHeaderCellDef> Origen </th>
                            <td mat-cell *matCellDef="let row"> {{row.origin}} </td>
                        </ng-container>
                        <ng-container matColumnDef="ejecutivo">
                            <th mat-header-cell *matHeaderCellDef> Ejecutivo asignado </th>
                            <td mat-cell *matCellDef="let row"> {{row.ejecutivo}} </td>
                        </ng-container>
                        <ng-container matColumnDef="trainingDate">
                            <th mat-header-cell *matHeaderCellDef> Fecha de capacitación </th>
                            <td mat-cell *matCellDef="let row"> {{row.trainingDate | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="trainingHour">
                            <th mat-header-cell *matHeaderCellDef> Hora de capacitación </th>
                            <td mat-cell *matCellDef="let row"> {{row.trainingHour.hour}} -
                                {{row.trainingHour.minute}} </td>
                        </ng-container>
                        <ng-container matColumnDef="zoomLink">
                            <th mat-header-cell *matHeaderCellDef> Link de Zoom </th>
                            <td mat-cell *matCellDef="let row"> <a [href]="row.zoomLink">{{row.zoomLink}}</a> </td>
                        </ng-container>
                        <ng-container matColumnDef="needHelp">
                            <th mat-header-cell *matHeaderCellDef> En que requiere ayuda </th>
                            <td mat-cell *matCellDef="let row"> {{row.needHelp}} </td>
                        </ng-container>
                        <ng-container matColumnDef="campaign">
                            <th mat-header-cell *matHeaderCellDef> Campaña </th>
                            <td mat-cell *matCellDef="let row"> {{row.campaign}} </td>
                        </ng-container>
                        <ng-container matColumnDef="promotion">
                            <th mat-header-cell *matHeaderCellDef> Promoción aplicada </th>
                            <td mat-cell *matCellDef="let row"> {{row.promotion}} </td>
                        </ng-container>
                        <ng-container matColumnDef="invoice">
                            <th mat-header-cell *matHeaderCellDef>Requiere Factura </th>
                            <td mat-cell *matCellDef="let row"> {{row.invoice}} </td>
                        </ng-container>
                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef> Fecha de creacion </th>
                            <td mat-cell *matCellDef="let row"> {{row.createdAt | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="vendor">
                            <th mat-header-cell *matHeaderCellDef> Vendedor </th>
                            <td mat-cell *matCellDef="let row"> {{row.vendor}} </td>
                        </ng-container>
                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef> Numero telefonico </th>
                            <td mat-cell *matCellDef="let row"> {{row.telefono}} </td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Correo </th>
                            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                        </ng-container>
                        <ng-container matColumnDef="plan">
                            <th mat-header-cell *matHeaderCellDef> Plan a contratar </th>
                            <td mat-cell *matCellDef="let row"> {{row.plan}} </td>
                        </ng-container>
                        <ng-container matColumnDef="paid">
                            <th mat-header-cell *matHeaderCellDef> Pagado </th>
                            <td mat-cell *matCellDef="let row"> {{row.isPaid== 'Si' ? 'Si' : 'No'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="options">
                            <th mat-header-cell *matHeaderCellDef> <button nbButton (click)="editRows()" size="tiny"
                                    nbTooltip="Editar columnas"><mat-icon>edit</mat-icon></button></th>
                            <td mat-cell *matCellDef="let row"></td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-button (click)="openUpdateCompany(row)" matTooltip="Completar Prospecto"
                                    class="success-icon" *ngIf="row.isPaid == 'Si'"><mat-icon
                                        class="success-icon">check_circle</mat-icon></button>
                                <button mat-button (click)="addFileinProscpect(row)"
                                    matTooltip="Agregar archivos"><mat-icon>attach_file</mat-icon></button>
                                <button mat-button matTooltip="Editar informacion del Prospecto"
                                    class="warning-icon"><mat-icon class="info-icon"
                                        [routerLink]="['/clients/new-prospects/'+row._id]"
                                        routerLinkActive="router-link-active">edit</mat-icon></button>
                                <button mat-button (click)="deleteProspect(row)" matTooltip="Eliminar Prospecto"
                                    class="danger-icon"><mat-icon class="danger-icon">delete</mat-icon></button>
                                <button mat-button (click)="hideCompany(row)" matTooltip="Ocultar Prospecto"
                                    class="info-icon"><mat-icon class="warning-icon">visibility_off</mat-icon></button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                        <tr mat-row *matRowDef="let row;columns: displayColumns"></tr>
                    </table>
                </div>
            </nb-tab>
            <nb-tab tabTitle="Prospectos completados">
                <app-complete-prospect-table (eventInit)="intitGetData()"></app-complete-prospect-table>
            </nb-tab>
            <nb-tab tabTitle="Prospectos cancelados">
                <app-cancel-prospect-table [data]="data_source_cancel"
                    (eventInit)="intitGetData()"></app-cancel-prospect-table>
            </nb-tab>
        </nb-tabset>
    </div>
</section>