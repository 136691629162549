<div class="dialog-template">
    <div class="header-dialog">
        <h3>{{isEdit ? "Editar plan" : "Nuevo Plan"}} </h3>
    </div>
    <form [formGroup]="priceForm" action="">
        <div class="form-group">
            <label for="">Nombre del plan</label>
            <input type="text" matInput fieldSize="medium" placeholder="Ingrese el nombre" formControlName="title">
        </div>
        <div class="form-group">
            <label for="">Precio del plan</label>
            <input type="text" matInput fieldSize="medium" placeholder="Ingrese un nuevo valor" formControlName="precio"
                (input)="onlyNums($event)">
        </div>
        <div class="form-group">
            <label for="">Horas del plan</label>
            <input type="text" matInput fieldSize="medium" placeholder="Ingrese un nuevo valor" formControlName="time"
                (input)="onlyNums($event)">
        </div>
    </form>
    <div class="buttons-footer">
        <button class="mr-5" mat-button class="danger" (click)="ref.close()"><mat-icon>close</mat-icon>
            Cancelar</button>
        <button mat-button class="success" (click)="submit()"><mat-icon>save</mat-icon> Guardar</button>
    </div>
</div>