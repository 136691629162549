<nb-card *ngIf="!isLoading">
    <nb-card-header>
        Enviar información
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="addForm">
            <div class=" form-group row">
                <div class="col-md-12">
                    <label for="">Correo electrónico</label>
                    <input type="text" nbInput fullWidth fieldSize="medium" placeholder="Correo electrónico"
                        formControlName="email">
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer style="text-align: center;">
        <button class="mr-5" nbButton status="danger" (click)="ref.close()">Cancelar</button>
        <button nbButton status="success" (click)="submit()" [disabled]="!addForm.valid"><mat-icon>
                mail_outline</mat-icon> Enviar</button>
    </nb-card-footer>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerStatus="basic" *ngIf="isLoading">
    <nb-card-body>Enviando correo... </nb-card-body>
</nb-card>