<div class=" form-group row">
    <div class="col-md-6">
        <label for="">Adjuntar contrato</label>
        <ng-container *ngIf="contractFile == undefined && !loadFile">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,1)" class="fileContract"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo de contrato está vacio</span>
        </ng-container>
        <ng-container *ngIf="contractFile && contractFile != undefined">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(1)"> <mat-icon>delete</mat-icon> Borrar archivo
                    cargado
                    previamente</button>
            </div>
        </ng-container>
        <ng-container *ngIf="loadFile && contractFile == undefined">
            <div class="dropzone-custom">
                <div [nbSpinner]="true" nbSpinnerStatus="basic" class="loader-file"></div>
            </div>
        </ng-container>

        <label for="">Adjuntar factura</label>
        <ng-container *ngIf="fileInvoice == undefined && !loadFile">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,3)" class="dropFile"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo de la factura está vacio</span>
        </ng-container>
        <ng-container *ngIf="fileInvoice != undefined ">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(3)"> <mat-icon>delete</mat-icon> Borrar archivo
                    cargado
                    previamente</button>
            </div>
        </ng-container>
        <ng-container *ngIf="loadFile && fileInvoice == undefined ">
            <div class="dropzone-custom">
                <div [nbSpinner]="true" nbSpinnerStatus="basic" class="loader-file">
                </div>
            </div>
        </ng-container>


        <label for="">Adjuntar Comprobante de pago</label>
        <ng-container *ngIf="fileFiscal == undefined && !loadFile">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,4)" class="dropFile"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo de Comprobante de pago está vacio</span>
        </ng-container>
        <ng-container *ngIf="fileFiscal != undefined ">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(4)"> <mat-icon>delete</mat-icon> Borrar archivo
                    cargado
                    previamente</button>
            </div>
        </ng-container>
        <ng-container *ngIf="loadFile">
            <div class="dropzone-custom">
                <div [nbSpinner]="true" nbSpinnerStatus="basic" class="loader-file">
                </div>
            </div>
        </ng-container>
    </div>
    <div class="col-md-6">
        <label for="">Adjuntar convenio de confidencialidad</label>
        <ng-container *ngIf="fileAgreement == undefined && !loadFile">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,2)" class="dropFile"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo del convenio está vacio</span>
        </ng-container>
        <ng-container *ngIf="fileAgreement != undefined ">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(2)"> <mat-icon>delete</mat-icon> Borrar archivo
                    cargado
                    previamente</button>
            </div>
        </ng-container>
        <ng-container *ngIf="loadFile">
            <div class="dropzone-custom">
                <div [nbSpinner]="true" nbSpinnerStatus="basic" class="loader-file">
                </div>
            </div>
        </ng-container>

        <label for="">Cedula fiscal de pago</label>
        <ng-container *ngIf="filePay == undefined && !loadFile">
            <div class="dropzone-custom">
                <input type="file" name="" (change)="saveContract($event,5)" class="dropFile"
                    accept=".pdf, .png, .jpeg, .docx, .xls">
            </div>
            <span *ngIf="this.showError" class="error-field">*El archivo de Cedula fisca está vacio</span>
        </ng-container>
        <ng-container *ngIf="filePay != undefined ">
            <div class="dropzone-custom">
                <button mat-button class="input-btn" (click)="deleteFile(5)"> <mat-icon>delete</mat-icon> Borrar archivo
                    cargado
                    previamente</button>
            </div>
        </ng-container>
        <ng-container *ngIf="loadFile">
            <div class="dropzone-custom">
                <div [nbSpinner]="true" nbSpinnerStatus="basic" class="loader-file">
                </div>
            </div>
        </ng-container>
    </div>
</div>