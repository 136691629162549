import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { PushNotification } from 'src/app/models/push-notification.model';
import { CompanyService } from 'src/app/services/company/company.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { PendingsService } from 'src/app/services/pendings/pendings.service';
import { UserService } from 'src/app/services/users/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pending-concluded-table',
  templateUrl: './pending-concluded-table.component.html',
  styleUrls: ['./pending-concluded-table.component.scss']
})
export class PendingConcludedTableComponent implements OnInit {

  public dataSource: any = []
  public displayColumns = ["title", "endTime", "pendingFor", "actions"]
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private pendingService: PendingsService,
    private userService: UserService,
    private notificationService: NotificationsService,
    private companyService: CompanyService
  ) {
    var logs = []
    this.pendingService.getConcludedLogs$().subscribe(async (notifications) => {
      var userList = await this.userService.getActiveUsers()
      notifications.map(async (notificationInfo: any) => {
        var userInfo = userList.filter(user => user.uid == notificationInfo.who)
        if (userInfo.length != 0) {
          notificationInfo['pendingFor'] = userInfo[0].nombre
        }
        notificationInfo.date = notificationInfo.date.toDate().toISOString()
        notificationInfo.action = notificationInfo.action.split("|")[1]
        logs.push(notificationInfo)
      })
      this.dataSource = new MatTableDataSource(logs)
      this.dataSource.paginator = this.paginator
      logs = []
    })
  }

  ngOnInit() {

  }

  async deleteNotification(id: string) {
    try {
      const result = await Swal.fire({
        title: '¿Desea eliminar esta notificación?',
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar",
        icon: 'warning'
      })
      if (result.isConfirmed) {
        const result = await this.notificationService.deleteNotification(id)
        if (result) {
          await Swal.fire({
            title: 'Notificación removida con exito!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        }
      }
    } catch (error) {
      return Swal.fire({
        title: 'Ocurrió un error al eliminar la notificación',
        icon: 'error',
      })
    }
  }

  async sendNotification(data: any) {
    try {
      var pending = data.action.split('ha')[0]
      if (pending == data.action) {
        //Si el pendiente es de un cliente se recorta otra vez el titulo
        pending = data.action.split('a finalizado')[0]
      }
      var result = await Swal.fire({
        title: '¿Desea enviar una notificación de recordatorio?',
        text: 'El agente o agentes encargados recibiran una notificacion',
        showCancelButton: true,
        icon: 'info',
        confirmButtonText: 'Si, enviar'
      })
      if (result.isConfirmed) {
        var dataToSend = {
          'notification': {
            'title': 'Recordatorio de pendiente',
            'body': 'Tienes un pendiente sin realizar: ' + pending,
            'sound': "default",
            'icon': 'https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/logo_circle.png?alt=media&token=c9ea2ac7-0db4-4913-a111-9290e4696bf7'
          },
        }

        var findUser = await this.userService.getUserById(data.who)
        if (findUser) {
          dataToSend['tokens'] = findUser.token
        }
        else {
          var findClient = await this.companyService.getTokensOfAgents(data.who)
          dataToSend['tokens'] = findClient
        }
        await this.notificationService.sendReminder(dataToSend as PushNotification)
        await Swal.fire({
          title: 'Notificación enviada con exito',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      }
    } catch (error) {
      return Swal.fire({
        title: 'Ocurrió un error al enviar la notificación',
        icon: 'error',
      })
    }
  }

}
