import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

  value = 0;
  hexValue = this.value.toString(16).toUpperCase().padStart(2, '0');
  colorR = new FormControl(0, [Validators.min(0), Validators.max(255)])
  colorG = new FormControl(0, [Validators.min(0), Validators.max(255)])
  colorB = new FormControl(0, [Validators.min(0), Validators.max(255)])
  colorHex = new FormControl('000000')
  colorPicker = "rgb(0,0,0)";
  preview1 = "rgb(229,83,195)";
  preview2 = "rgb(113,199,225)";
  preview3 = "rgb(225,113,113)";
  redRange = new Array(255)
  reverRange = []
  opacityColor = "rgb(0,0,0)";
  constructor(
    private dialog: MatDialogRef<ColorPickerComponent>
  ) { }

  ngOnInit() {
    for (let index = 255; index >= 0; index--) {
      this.reverRange.push(index)
    }
  }

  addPointColor(matiz: number) {

    switch (matiz) {
      case 1:
        if (this.colorR.value < 255) {
          this.colorR.setValue(parseInt(this.colorR.value) + 1);
          this.colorHex.setValue(this.rgbToHex(this.colorPicker))
        }
        break;
      case 2:
        this.colorG.setValue(parseInt(this.colorG.value) + 1)
        this.colorHex.setValue(this.rgbToHex(this.colorPicker))
        break;
      case 3:
        this.colorB.setValue(parseInt(this.colorB.value) + 1)
        this.colorHex.setValue(this.rgbToHex(this.colorPicker))
        break;
    }
  }

  removePointColor(matiz: number) {
    switch (matiz) {
      case 1:
        this.colorR.setValue(parseInt(this.colorR.value) - 1)
        this.colorHex.setValue(this.rgbToHex(this.colorPicker))
        break;
      case 2:
        this.colorG.setValue(parseInt(this.colorG.value) - 1)
        this.colorHex.setValue(this.rgbToHex(this.colorPicker))
        break;
      case 3:
        this.colorB.setValue(parseInt(this.colorB.value) - 1)
        this.colorHex.setValue(this.rgbToHex(this.colorPicker))
        break;
      default:
        break;
    }
  }

  onSelectPreview(select: string) {
    var rgbaString = select.split("(");
    var r = parseInt(rgbaString[1].split(",")[0])
    var g = parseInt(rgbaString[1].split(",")[1])
    var b = parseInt(rgbaString[1].split(",")[2])

    this.colorR.setValue(r)
    this.colorG.setValue(g)
    this.colorB.setValue(b)
    this.colorHex.setValue(this.rgbToHex(this.getColor()))
  }

  onlyNums(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/\D/g, '')
  }

  getColor() {
    this.colorPicker = "rgb(" + this.colorR.value + "," + this.colorG.value + "," + this.colorB.value + ")"
    return this.colorPicker
  }

  changeHex(hex: any) {
    hex = hex.target.value;
    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }
    // Convertir el valor hexadecimal a decimal
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    this.colorR.setValue(r)
    this.colorG.setValue(g)
    this.colorB.setValue(b)
  }

  rgbToHex(color: String): string {
    var rgbaString = color.split("(");
    var r = parseInt(rgbaString[1].split(",")[0])
    var g = parseInt(rgbaString[1].split(",")[1])
    var b = parseInt(rgbaString[1].split(",")[2])
    return this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  private componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  close() {
    this.dialog.close()
  }

  submitColor() {
    this.dialog.close(this.rgbToHex(this.colorPicker))
  }

}
