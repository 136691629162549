<section>
  <div class="header-container">
    <h3>
      <mat-icon (click)="close()" class="back-btn" matTooltip="Volver atras">arrow_back</mat-icon>
      {{isEdit ? 'Editar cliente': 'Agregar nuevo cliente'}}
    </h3>
    <div class="button-wrap">
      <button mat-button class="warning-btn" *ngIf="userType == 'administrador' && isEdit"
        [routerLink]="['/drive/archives/'+companyData?._id]"><mat-icon>perm_media</mat-icon>Ver
        documentos</button>
      <button mat-button class="success-btn" (click)="submit()"><mat-icon>save</mat-icon> {{isEdit ? 'Actualizar':
        'Crear'}}</button>
    </div>
  </div>
  <div class="loadspinner" *ngIf="isLoading">Cargando&#8230;</div>
  <form [formGroup]="addForm" *ngIf="userType == 'administrador' && !isLoading">
    <div class="section">
      <div class="header-section">
        <h4>Información de contacto</h4>
        <hr>
      </div>
      <div class="form-group row">
        <input hidden type="file" #imgFileInput multiple placeholder="Upload file" accept=".png,.jpg,.jpeg" name="file"
          (change)="saveFile($event)" required />
        <div class="col-md-6" style="text-align: center;">
          <img class="imgPreview" [src]="urlPreviweImg || urlDefaultImg" alt="log image" />
          <button type="button" mat-button class="filebutton" style="color: #222b45;" (click)="imgFileInput.click()">
            <mat-icon>camera_alt</mat-icon> Cambiar logo
          </button>
        </div>
        <div class="col-md-6">
          <label>Nombre comercial <span class="requiered">(*)</span></label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="nombre"
            placeholder="Nombre comercial" class="mb-3">
          <div class="alert alert-danger" *ngIf="fieldRequired('nombre')">
            <span>El campo nombre comercial es requerido</span>
          </div>
          <div class="alert alert-danger" *ngIf="fieldPattern('nombre')">
            <span>No se aceptan caracteres y números</span>
          </div>

          <label>Nombre del cliente <span class="requiered">(*)</span></label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="nombreCliente"
            placeholder="Nombre del cliente">
          <p class="alert alert-danger" *ngIf="fieldRequired('nombreCliente')">
            <span>El campo nombre del cliente es requerido</span>
          </p>
          <div class="alert alert-danger" *ngIf="fieldPattern('nombreCliente')">
            <span>No se aceptan caracteres y números</span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3">
          <label>Sexo</label>
          <mat-select placeholder="Selecciona una opción" formControlName="gender">
            <mat-option value="male">Masculino</mat-option>
            <mat-option value="female">Femenino</mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('gender')">
            <span>El campo genero es requerido</span>
          </div>
        </div>

        <div class="col-md-3">
          <label>Edad</label>
          <input type="number" (input)="onlyNums($event)" matInput fullWidth fieldSize="medium" formControlName="age"
            placeholder="Edad">
          <div class="alert alert-danger" *ngIf="fieldMin('age')">
            <span>Ingrese un número mayor a 0</span>
          </div>
          <div class="alert alert-danger" *ngIf="fieldRequired('age')">
            <span>El campo edad es requerido</span>
          </div>
        </div>

        <div class="col-md-6">
          <label for="">Número de teléfono principal<span class="requiered">(*)</span></label>
          <div class="row">
            <div class="col-md-6">
              <input type="tel" matInput fullWidth fieldSize="medium" formControlName="telefono"
                (input)="onlyNums($event)" placeholder="Número de teléfono" maxlength="10">
              <div class="alert alert-danger" *ngIf="fieldRequired('telefono')">
                <span>El campo número de teléfono es requerido</span>
              </div>
            </div>
            <div class="col-md-6">
              <button mat-button (click)="addAditionalNumbers()" class="add-button"><mat-icon>add</mat-icon> Numeros
                adicionales</button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Correo electrónico <span class="requiered">(*)</span></label>
          <input type="email" matInput fullWidth fieldSize="medium" formControlName="email"
            placeholder="Correo electrónico">
          <div class="alert alert-danger" *ngIf="fieldRequired('email')">
            <span>El campo correo electrónico es requerido</span>
          </div>
          <div class="alert alert-danger" *ngIf="fieldPattern('email')">
            <span>Ingrese un correo valido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Contraseña (min. 6 caracteres)<span class="requiered">(*)</span></label>
          <input type="email" matInput fullWidth fieldSize="medium" formControlName="password" placeholder="Contraseña">
          <div class="alert alert-danger" *ngIf="fieldRequired('password')">
            <span>Ingrese una contraseña valida</span>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="header-section">
        <h4>Información comercial</h4>
        <hr>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="">Giro comercial <span class="requiered">(*)</span></label>
          <mat-select fullWidth placeholder="Selecciona una opción" formControlName="giroComercial">
            <mat-option *ngFor="let item of commercialTurn" value="{{item.value}}">{{item.viewValue |
              titlecase}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('giroComercial')">
            <span>El campo Giro comercial es requerido</span>
          </div>
          <div class="alert alert-danger" *ngIf="errorInComercial">
            <span>Se requiere un campo para agregar un tipo</span>
          </div>
        </div>
        <div class="col-md-6 form-group">
          <label for="">Tipo</label>
          <div class="box-tags">
            <input type="text" matInput fullWidth fieldSize="medium" [formControl]="tagField" placeholder="Escribe...">
            <button mat-button (click)="addTag()"><mat-icon>add</mat-icon></button>
          </div>
        </div>
      </div>
      <div class="tags-container">
        <ng-container *ngIf="tags.length != 0">
          <span *ngFor="let tag of tags" (click)="removeTag(tag)"><mat-icon>close</mat-icon> {{tag}}</span>
        </ng-container>
        <p *ngIf="tags.length == 0">No se han agregado etiquetas</p>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Profesión u ocupación</label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="profession" placeholder="Dirección">
          <div class="alert alert-danger" *ngIf="fieldRequired('profession')">
            <span>El campo es requerido</span>
          </div>
        </div>

        <div class="col-md-6">
          <label for="">Uso de Agenda</label>
          <mat-select placeholder="Tipo de agenda" formControlName="specialSchedule" fullWidth>
            <mat-option [value]="false">Agenda comun </mat-option>
            <mat-option [value]="true">Agenda para Doctores </mat-option>
          </mat-select>
        </div>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Ciudad <span class="requiered">(*)</span></label>
          <mat-select fullWidth placeholder="Selecciona una opción" formControlName="city">
            <mat-option *ngFor="let item of cities" value="{{item.value}}">{{item.viewValue | titlecase}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('city')">
            <span>El campo ciudad es requerido</span>
          </div>
        </div>

        <div class="col-md-6">
          <label for="">Origen <span class="requiered">(*)</span></label>
          <mat-select fullWidth placeholder="Selecciona una opción" formControlName="origin">
            <mat-option *ngFor="let item of origin" value="{{item.value}}">{{item.viewValue | titlecase}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('origin')">
            <span>El campo origen es requerido</span>
          </div>
        </div>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Dirección comercial</label>
          <textarea matInput fullWidth placeholder="Direccion comercial" formControlName="direccion"
            style="resize: none;" rows="4"></textarea>
          <div class="alert alert-danger" *ngIf="fieldRequired('direccion')">
            <span>El campo Dirección comercial es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Dirección fiscal</label>
          <textarea matInput fullWidth placeholder="Direccion fiscal" formControlName="fiscalAddress"
            style="resize: none;" rows="4"></textarea>
          <div class="alert alert-danger" *ngIf="fieldRequired('fiscalAddress')">
            <span>El campo Dirección fiscal es requerido</span>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="header-section">
        <h4>Información de actividades</h4>
        <hr>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Ejecutivo asignado <span class="requiered">(*)</span></label>
          <mat-select multiple fullWidth placeholder="Selecciona una opción" formControlName="ejecutivo">
            <mat-option *ngFor="let item of users" [value]="item.nombre">{{item.nombre}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('ejecutivo')">
            <span>El campo ejecutivo es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Ejecutivos backup </label>
          <mat-select multiple fullWidth placeholder="Selecciona una opción" formControlName="assistant">
            <mat-option *ngFor="let item of users" [value]="item.nombre">{{item.nombre}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('assistant')">
            <span>El campo es requerido</span>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Tareas recurrentes</label>
          <mat-select multiple fullWidth placeholder="Selecciona una opción" formControlName="tareasRecurrentes"
            class="mb-3">
            <mat-option *ngFor="let item of tasks" [value]="item._id">{{item.title}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('tareasRecurrentes')">
            <span>Seleccione almenos una tarea</span>
          </div>
          <label for="">Prefijo <span class="requiered">(*)</span></label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="prefix" placeholder="0000">
          <div class="alert alert-danger" *ngIf="fieldRequired('prefix')">
            <span>El campo es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Horario de antención</label>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="" style="border-radius: 0px;">De las</label>
              <ngb-timepicker formControlName="startHourWork" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
            </div>
            <div class="col-md-6">
              <label for="">A las</label>
              <ngb-timepicker formControlName="endHourWork" [hourStep]="1" [minuteStep]="15"></ngb-timepicker>
            </div>
          </div>
          <p class="alert alert-danger" *ngIf="fieldRequired('startHourWork')">
            <span>El Horario es requerido</span>
          </p>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Etiqueta</label>
          <input type="text" matInput fullWidth fieldSize="medium" placeholder="Hola muy buenos dias..."
            formControlName="tag">
          <div class="alert alert-danger" *ngIf="fieldRequired('tag')">
            <span>El campo es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Link a drive</label>
          <input type="text" matInput fullWidth fieldSize="medium" formControlName="drive"
            placeholder="https://docs.google.com...">
          <div class="alert alert-danger" *ngIf="fieldRequired('drive')">
            <span>El campo es requerido</span>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="header-section">
        <h4>Información del contrato</h4>
        <hr>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Plan contratado <span class="requiered">(*)</span></label>
          <mat-select fullWidth placeholder="Selecciona una opción" formControlName="plan">
            <mat-option *ngFor="let item of actualPlans" [value]="item.title">{{item.title | titlecase}}
            </mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('plan')">
            <span>El campo correo plan es requerido</span>
          </div>
        </div>
        <div class="col-md-6 row">
          <div class="col-md-5">
            <mat-checkbox (change)="toggle($event.checked)">Usar otro precio para factura</mat-checkbox>
          </div>
          <div class="col-md-7" [hidden]="!showNewPrice">
            <label for="">Precio a facturar</label>
            <input type="text" matInput fullWidth fieldSize="medium" formControlName="planPrice"
              placeholder="Precio del plan a facturar">
          </div>
        </div>
      </div>
      <div class=" form-group row">
        <div class="col-md-6">
          <label for="">Fecha de incio de contrato <span class="requiered">(*)</span></label>
          <input matInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha" [matDatepicker]="contratoDate"
            formControlName="contratoDate" (click)="contratoDate.open()">
          <mat-datepicker #contratoDate></mat-datepicker>
          <!-- <nb-datepicker #fechacontrato></nb-datepicker> -->
          <div class="alert alert-danger" *ngIf="fieldRequired('contratoDate')">
            <span>El campo fecha de contrato es requerido</span>
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Fecha de registro (Antigüedad)<span class="requiered">(*)</span></label>
          <input matInput fullWidth fieldSize="medium" placeholder="dd/mm/yyyy" [matDatepicker]="completeData"
            formControlName="completeData" (click)="completeData.open()">
          <mat-datepicker #completeData></mat-datepicker>
          <!-- <nb-datepicker #fechacontrato></nb-datepicker> -->
          <div class="alert alert-danger" *ngIf="fieldRequired('completeData')">
            <span>El campo fecha de contrato es requerido</span>
          </div>
        </div>

      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="">Requiere Factura</label>
          <mat-select fullWidth placeholder="Selecciona una opción" formControlName="invoice">
            <mat-option value="Si">Si</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <div class="alert alert-danger" *ngIf="fieldRequired('invoice')">
            <span>El campo es requerido</span>
          </div>
        </div>
      </div>
      <app-clients-contract [contract]="contractInfo" (newItemEvent)="setFile($event)"
        *ngIf="isEdit"></app-clients-contract>
      <app-contract-form (newItemEvent)="addContractDoc($event)" *ngIf="!isEdit"></app-contract-form>
    </div>

  </form>
  <ng-container *ngIf="userType != 'administrador'">
    <div class="section">
      <div class="header-section">
        <h4>Información del contrato</h4>
        <hr>
      </div>
      <app-clients-contract [contract]="contractInfo" (newItemEvent)="setFile($event)"></app-clients-contract>
    </div>
  </ng-container>

</section>