import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from 'src/app/models/task';
import Swal from 'sweetalert2';
import { ServiceMinutesService } from 'src/app/services/service-minutes/service-minutes.service';
import { TaskFormComponent } from '../../task-form/add-task.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss']
})
export class TasksTableComponent implements OnInit {
  settingsActivities = {
    actions: {
      columnTitle: "Acciones",
      add: false,
      edit: true,
      delete: true,
      position: "right",
    },
    pager: {
      display: true,
      perPage: 20,
    },
    add: {
      addButtonContent: "<span>Nuevo Tarea</span>",
    },
    edit: {
      editButtonContent: "<span>Editar</span>",
    },
    delete: {
      deleteButtonContent: "<span>Eliminar</span>",
    },
    columns: {
      title: {
        title: "Tarea",
      },
      time: {
        title: "Minutos de elaboración base",
        filter: false,
      }
    },
    mode: "external",
    noDataMessage: "No hay registros por ahora",
  };
  @Input() data_source_4 = [];
  @Output('eventInit') fatherOnInit = new EventEmitter();


  constructor(
    private dialogService: MatDialog,
    private taskService: ServiceMinutesService
  ) { }

  ngOnInit() {
  }

  async createTask() {
    const resultDialog = await this.dialogService.open(TaskFormComponent, { height: '40vh' }).beforeClosed().toPromise()
    if (resultDialog) {
      this.fatherOnInit.emit();
    }
  }

  async updateTask(data: Task) {
    const resultDialog = await this.dialogService.open(TaskFormComponent, { data: data, height: '40vh' }).beforeClosed().toPromise()
    if (resultDialog) {
      this.fatherOnInit.emit();
    }
  }

  async deleteTask(data: Task) {
    try {
      var result = await Swal.fire({
        title: "¿Seguro que quiere eliminar esta tarea?",
        text: "Esta tarea ya no podrá ser asignada a los clientes.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
      })
      if (result.isConfirmed) {
        await this.taskService.deleteTask(data._id)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Tarea eliminada",
          showConfirmButton: false,
          timer: 1000,
        });
        this.fatherOnInit.emit();
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

}
