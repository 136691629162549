<section>
    <div class="header-container" style="top:0">
        <h3>Planes</h3>
        <div class="button-wrap">
            <button mat-button (click)="createPlan()"><mat-icon>add</mat-icon>Nuevo Plan</button>
        </div>
    </div>
    <div class="card">
        <ng2-smart-table [settings]="settingsPlans" [source]="data_source_3" (edit)="editPrice($event)"
            (delete)="deletePlan($event.data)">
        </ng2-smart-table>
    </div>
</section>