<div class="dialog-wrapper">
  <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>

  <div class="dialog-heading">
    <h1 class="dialog-title">Información del prospecto</h1>
  </div>

  <div class="dialog-content" mat-dialog-content>

    <div class="row">
      <div class="zoom">
        <mat-form-field appearance="outline">
          <mat-label>Liga de zoom</mat-label>
          <input matInput [value]="dataInject.prospectInfo.zoomLink || 'Sin información'" readonly>
        </mat-form-field>
        <button (click)="copyZoomLink(dataInject.prospectInfo.zoomLink)">Copiar</button>
      </div>
    </div>

    <div class="row">
      <div class="item">
        <h4>Nombre del cliente</h4>
        <p>{{dataInject.prospectInfo.nombreCliente || 'Sin información'}}</p>
      </div>

      <div class="item">
        <h4>Nombre comercial</h4>
        <p>{{dataInject.prospectInfo.nombre || 'Sin información'}}</p>
      </div>
    </div>

    <div class="row">
      <div class="item">
        <h4>{{isProspect? 'Fecha de capacitación': 'Fecha de conferencia'}}</h4>
        <p><mat-icon>date_range</mat-icon>{{(dataInject.prospectInfo.trainingDate | date) || 'Sin información'}}</p>
      </div>

      <div class="item">
        <h4>{{isProspect? 'Hora de capacitación': 'Hora de conferencia'}} </h4>
        <p><mat-icon>access_time</mat-icon>{{formattedHour(dataInject.prospectInfo.trainingHour) || 'Sin información' }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="item">
        <h4>Giro economico</h4>
        <p>{{dataInject.prospectInfo.giroComercial || 'Sin información'}}</p>
      </div>
    </div>

    <div class="row" *ngIf="isProspect">
      <div class="item help-wrapper">
        <h4>¿En que necesita ayuda?</h4>
        <p class="help-text"><mat-icon>info</mat-icon>{{dataInject.prospectInfo.needHelp || 'Sin información'}}</p>
      </div>
    </div>
  </div>
</div>
