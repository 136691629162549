import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NbDialogRef } from '@nebular/theme';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsReportsComponent implements OnInit {
  [x: string]: any;
  data: any;
  bitacora = [];
  constructor(
    public ref: NbDialogRef<DetailsReportsComponent>,
    private afs: AngularFirestore,
  ) { }

  ngOnInit() {
    this.data = this.ref.componentRef.instance.data;
    
    this.bitacora = this.data.activities.sort((a, b) => b.date - a.date); 
  }

  sendToClient(id){
    this.afs.collection('client-reports').doc(id).update({
      visible: true,
    }).then(()=>{
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Se ha enviado al cliente, ¿Desea cerrar esta pestaña?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText:"Si, cerrar",
        cancelButtonText:"No, mantener",
      }).then((result) => {
        if (result.isConfirmed) {
          this.ref.close();
        }
      });
    });
  }

}
