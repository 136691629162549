<div class="loadspinner" *ngIf="loader">Cargando&#8230;</div>
<section>
    <div class="header-container">
        <h3><mat-icon [routerLink]="['/drive']" class="back-btn"
                routerLinkActive="router-link-active">keyboard_arrow_left</mat-icon> {{companyData?.nombre}} / Archivos
        </h3>
        <div class="button-wrap">
            <button mat-button class="success" (click)="addFiles()"><mat-icon>add</mat-icon> Agregar archivo</button>
        </div>
    </div>
    <div class="files-grid">
        <div class="card" *ngFor="let urlFile of prospectFile">
            <div class="icon-file">
                <ng-container *ngIf="urlFile.url != ''">
                    <a [href]="urlFile.url" target="_blank">
                        <ng-container *ngIf="urlFile.extension == 'pdf'">
                            <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/pdf.png?alt=media&token=289b2cbb-f248-42f0-a3ba-3c705de0e1f9"
                                alt="pdfFile">
                        </ng-container>
                        <ng-container *ngIf="urlFile.extension == 'docx'">
                            <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/doc.png?alt=media&token=efd97789-42d9-4ac9-aaf7-977144808075"
                                alt="docxFile">
                        </ng-container>
                        <ng-container *ngIf="urlFile.extension == 'xlsx'">
                            <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/xls.png?alt=media&token=8e8c2b57-9368-4acf-9e61-aa85b6da7025"
                                alt="xlsFile">
                        </ng-container>
                        <ng-container
                            *ngIf="urlFile.extension == 'png' || urlFile.extension == 'jpeg' || urlFile.extension == 'jpg' || urlFile.extension == 'webp'">
                            <img class="img-responsive" [src]="urlFile.url" alt="imgFile">
                        </ng-container>
                        <!-- <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/default-information.png?alt=media&token=9ac8c179-4e15-477b-b316-eb9748c99f2b"
                        alt="pdfFile"> -->
                    </a>
                </ng-container>
                <ng-container *ngIf="urlFile.url == ''">
                    <div class="addFile-icon" (click)="addFiles(urlFile.fileName)" nbTooltip="Agregar archivo faltante">
                        <img src="https://firebasestorage.googleapis.com/v0/b/miasistema.appspot.com/o/addFile.png?alt=media&token=dc9a174f-7f5e-4716-9fce-c48bc99215e9"
                            alt="">
                    </div>
                </ng-container>
            </div>
            <div class="footer-file">
                {{urlFile.fileName}}
                <div class="btn-options">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <ng-container *ngIf="urlFile.url != ''">
                            <button mat-menu-item (click)="viewFile(urlFile.url)" *ngIf="urlFile.extension != 'docx'">
                                <mat-icon>remove_red_eye</mat-icon>
                                <span>Ver </span>
                            </button>
                            <button mat-menu-item (click)="download(urlFile)">
                                <mat-icon>cloud_download</mat-icon>
                                <span>Descargar archivo</span>
                            </button>
                            <button mat-menu-item (click)="deleteFile(urlFile)">
                                <mat-icon>delete</mat-icon>
                                <span>Eliminar</span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="urlFile.url == ''">
                            <button mat-menu-item (click)="addFiles(urlFile.fileName)">
                                <mat-icon>cloud_upload</mat-icon>
                                <span>Cargar archivo</span>
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</section>