<ng-container>
    <ngb-toast *ngFor="let pending of clientsNotifications" class="bg-danger text-light">
        <div>
            <strong>
                <h6>{{pending.title}}</h6>
            </strong>
            <p>El cliente <strong>{{pending.pendingFor}}</strong> {{pending.action}} </p>
        </div>
    </ngb-toast>
</ng-container>
<ng-container *ngIf="userType != 'administrador'">
    <ngb-toast *ngFor="let pending of currentPendingsUser">
        <div>
            <strong>
                <h6>{{pending.title}}</h6>
            </strong>
            Pendiente por hacer : <strong>{{pending.action}}</strong>
        </div>
    </ngb-toast>
</ng-container>

<ng-container *ngIf="userType != 'administrador'">
    <ngb-toast *ngFor="let pending of pendingForUsers" class="bg-warning text-light">
        <div>
            <strong>
                <h6>{{pending.title}}</h6>
            </strong>
            {{pending.action}}
        </div>
    </ngb-toast>
</ng-container>

<ng-container *ngIf="userType == 'administrador'">
    <ngb-toast *ngFor="let pending of notificationList" class="bg-success text-light">
        <div>
            <strong>
                <h6>{{pending.title}}</h6>
            </strong>
            <ng-container *ngIf="pending.title != 'Pendiente a punto de concluir'">
                <ng-container *ngIf="pending.forWho != undefined">
                    <p>El usuario {{pending.pendingFor}} {{pending.action}} </p>
                </ng-container>
                <ng-container *ngIf="pending.forWho == undefined">
                    <p>El cliente <strong>{{pending.pendingFor}}</strong> {{pending.action}} </p>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="pending.title == 'Pendiente a punto de concluir'">
                <p>El cliente <strong>{{pending.pendingFor}}</strong> {{pending.action}} </p>
            </ng-container>
        </div>
    </ngb-toast>
</ng-container>