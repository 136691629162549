import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-aditional-info',
  templateUrl: './add-aditional-info.component.html',
  styleUrls: ['./add-aditional-info.component.scss']
})
export class AddAditionalInfoComponent implements OnInit {

  public trainingForm: FormGroup
  public showError: boolean = false;
  public invalidFlag: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddAditionalInfoComponent>,
    private prospectService: ProspectsService
  ) { }

  ngOnInit() {
    this.trainingForm = this.formbuilder.group({
      speach: ["", Validators.required],
      recording: ["", Validators.required],
      tools: ["", Validators.required],
      tasksDo: ["", Validators.required],
      fiscalData: ["", Validators.required],
      serviceReview: ["", Validators.required],
      appUse: ["", Validators.required],
      monthlyReports: ["", Validators.required],
      agreementFile: ["", Validators.required],
      contractFile: ["", Validators.required],
      hasCRM: ["", Validators.required],
      hasSchedule: ["", Validators.required],
      requestNumber: ["", Validators.required],
      requestSocialMedia: ["", Validators.required],
      requestInvoice: ["", Validators.required],
      sendFiel: ["", Validators.required],
      sendPassword: ["", Validators.required],
      adminAccess: ["", Validators.required]
    })
    if (this.data.trainingInfo) {
      this.trainingForm.patchValue(this.data.trainingInfo)
    }
  }

  validateForm() {
    if (this.trainingForm.controls['speach'].errors) {
      this.invalidFlag = 'invalid-speach';
      this.showError = true
    }
    if (this.trainingForm.invalid) {
      this.invalidFlag = 'invalid-all';
      this.showError = true
      return Swal.fire({
        title: 'Campos requerido faltantes',
        text: 'Favor de completar los campos sin seleccionar',
        icon: 'error',
        confirmButtonText: 'Entendido'
      })
    }
    this.submit()
  }

  async submit() {
    try {
      const formValue = this.trainingForm.value;
      await this.prospectService.addTrainingInfoInProspect(this.data._id, formValue)
      await Swal.fire({
        title: 'Información agregada con exito !!!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000
      })
      this.trainingForm.reset()
      this.dialogRef.close({ change: true })
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error al agregar información del prospecto',
        text: "Si el problema persiste, favor de reportar el error",
        icon: 'error',
      })
    }
  }

  fieldRequired(field: string) {
    return this.trainingForm.get(field)?.hasError('required') && this.trainingForm.get(field)?.touched || (this.showError && this.trainingForm.get(field)?.untouched)
  }

}
