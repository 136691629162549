import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PlanService } from 'src/app/services/planService/plan.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.scss']
})
export class AddPlanComponent implements OnInit {

  public priceForm: FormGroup
  public isEdit = false;

  constructor(
    public ref: MatDialogRef<AddPlanComponent>,
    public formBuilder: FormBuilder,
    private planService: PlanService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.priceForm = this.formBuilder.group({
      title: ['', Validators.required],
      precio: ['', Validators.required],
      time: ['', Validators.required]
    })
    if (this.data != undefined) {
      this.isEdit = true
      var dataPrice = this.data.data
      var precio = dataPrice.precio.split(" ");
      this.priceForm = this.formBuilder.group({
        title: [dataPrice.title, Validators.required],
        precio: [precio[1], Validators.required],
        time: [dataPrice.time, Validators.required]
      })
    }
  }

  async submit() {
    try {
      var form = this.priceForm.value
      form.title = form.title.toUpperCase()
      Swal.fire({
        title: "Guardando",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      if (form.title == "" || form.precio == "") {
        return Swal.fire({
          title: "Todos los campos son obligatorios",
          icon: "error",
        });
      }
      Swal.showLoading();
      if (this.isEdit) {
        await this.planService.updatePrice(this.data.data._id, form)
        Swal.close();
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Datos actualizados",
          showConfirmButton: false,
          timer: 1000,
        });
        this.ref.close(true);
      } else {
        await this.planService.addPlan(form)
        Swal.close();
        await Swal.fire({
          title: "Nuevo plan creado!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        this.ref.close(true);
      }
    } catch (error) {
      Swal.fire({
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error",
        icon: "error",
        showConfirmButton: true
      });
    }
  }

  onlyNums(event: any) {
    const input = event.target.value;
    event.target.value = input.replace(/\D/g, '')
  }
}
