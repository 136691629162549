<form [formGroup]="fileForm">
  <div class="form-group row">
    <div class="col-md-6 " *ngIf="this.isAdmin">
      <label for="">Estatus</label>
      <mat-select placeholder="Seleccione una opcion" formControlName="status" (valueChange)="changeStatus($event)">
        <mat-option [value]="1">Pagado</mat-option>
        <mat-option [value]="2">Pendiente por pagar</mat-option>
        <mat-option [value]="3">Pago atrasado</mat-option>
        <mat-option [value]="4">Plan consumido</mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 status-tag" *ngIf="!isAdmin">
      <label for="">Estatus</label>
      <span [ngClass]="contract?.status == 1 ?'payed':contract?.status == 2 ? 'in-time': 'overdue'">
        {{contract?.status
        == 1 ?'Pagado':contract?.status == 2 ? "Pendiente por pagar": contract?.status == 3 ? "Pago atrasado": "Plan consumido" }}
      </span>
    </div>
    <div class="col-md-6">
      <label>Fecha de Renovacion de contrato</label>
      <p *ngIf="!isAdmin">{{contract?.nextRenovationDate | date}} </p>
      <input matInput fullWidth fieldSize="medium" placeholder="Seleccione una fecha" [matDatepicker]="fechacontrato"
        formControlName="nextRenovationDate" *ngIf="isAdmin" (click)="fechacontrato.open()" (dateChange)="changeDate($event.value)">
      <mat-datepicker #fechacontrato></mat-datepicker>
    </div>
  </div>
</form>
<div class="form-group row" *ngIf="contract?.status != 1">
  <div class="col-md-6">
    <h4>Anexar Comprobante de Pago del mes</h4>
    <div class="dropzone-custom">
      <div class="dropzone dropzone-primary" [dropzone]="config1" (success)="onUploadInfo($event)">
        <div class="dz-message needsclick">
          <nb-icon icon="cloud-upload-outline" style="font-size: 50px; color: #ffaa00;"></nb-icon>
          <h4 class="mb-0 f-w-600" style="text-transform: unset;font-size: 18px;">Arrasta tu
            archivo aqui
            o da click para subirlo.</h4>
        </div>
      </div>
    </div>
  </div>
</div>
