import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cancel-prospect-table',
  templateUrl: './cancel-prospect-table.component.html',
  styleUrls: ['./cancel-prospect-table.component.scss']
})
export class CancelProspectTableComponent implements OnInit {

  @Input() data;
  @Output('eventInit') fatherOnInit = new EventEmitter();
  public displayColumns: string[] = ['name', 'companyName', 'phone', 'email', 'plan', 'createdDate', 'agent', 'actions'];
  constructor(
    private prospectService: ProspectsService
  ) { }

  ngOnInit() {
  }

  async deleteProspect(data) {
    try {
      var result = await Swal.fire({
        title: "¿Seguro que quiere eliminar este prospecto?",
        text: "El documento del prospecto sera eliminado permanentemente",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
      })
      if (result.isConfirmed) {
        await this.prospectService.deleteProspect(data._id)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Prospecto eliminado",
          showConfirmButton: false,
          timer: 1000,
        });
        this.fatherOnInit.emit()
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado al eliminar el prospecto",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

  async hideCompany(data) {
    try {
      var result = await Swal.fire({
        title: "¿Seguro que quiere mostrar este prospecto?",
        text: "El documento pasara a la seccion de prospectos en lista",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, activar",
        cancelButtonText: "Cancelar",
      })
      if (result.isConfirmed) {
        await this.prospectService.updateStatusProspect(data._id, false)
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Cliente activado",
          showConfirmButton: false,
          timer: 1000,
        });
        this.fatherOnInit.emit()
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Ocurrió un error inesperado",
        text: "Si el problema persiste, favor de reportar el error"
      });
    }
  }

}
