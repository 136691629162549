import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Company } from 'src/app/models/company';
import { Contact } from 'src/app/models/contact';

@Injectable({
  providedIn: 'root'
})
export class CompanyFocusService {
  idCompany: any;
  idCall: any;
  public companyInfo: Company
  public contactsOfCompany: Contact[]
  constructor(private afs: AngularFirestore) { }

  public getInfoForReminder(companyId: string) {
    return new Promise<any>(async (resolve, reject) => {
      let reminderHour: string;
      let tokens = [];
      let count = 0;
      this.afs.collection('empresas').doc(companyId).ref.get().then(res => {
        reminderHour = res.data().reminderHour;
        var ref = this.afs.collection('empresas').doc(companyId).collection('dispositivos');
        var obs$ = ref.snapshotChanges().map(actions => {
          return actions.map(action => {
            const data = action.payload.doc.data() as any;
            const id = action.payload.doc.id;
            return { id, ...data };
          });
        });
        let sub = obs$.subscribe(data => {
          data.forEach(element => {
            count = count + 1;
            tokens.push(element.token)
          });
          if (count == data.length) {
            resolve({ reminderHour: reminderHour, tokens: tokens });
            sub.unsubscribe();

          }
        })
      })
    })
  }
  async setCompanyInfo(companyData: Company) {
    this.companyInfo = companyData
  }

  async setContactsOfCompany(contacts:Contact[]){
    this.contactsOfCompany = contacts
  }

  setFocusCall() {
    localStorage.setItem('isCallOnFocus', 'true');
  }

  setFocusCompany(idCompany) {
    localStorage.setItem('companyOnFocus', idCompany);
    localStorage.setItem('isCompanyOnFocus', 'true');
    this.idCompany = idCompany;
  }

  setContact(data) {
    localStorage.setItem("telefonoContact", "")
    localStorage.setItem("telefonoContact", data.numero_origen)
  }

  getContact() {
    return {
      name: localStorage.getItem("nameContact"),
      email: localStorage.getItem("emailContact"),
      phone: localStorage.getItem("telefonoContact")
    }
  }

  getCompanyFocus() {
    return this.companyInfo;
  }

  getAllContacts(): Contact[] {
    return this.contactsOfCompany;
  }

  dissmissFocus() {
    localStorage.setItem('isCompanyOnFocus', 'false');
  }

  getCompanyOnFocus() {
    let id = localStorage.getItem('companyOnFocus');
    return id;
  }

  getIsCompanyOnFocus() {
    let flag = localStorage.getItem('isCompanyOnFocus')
    return flag;
  }

  // Llamadas
  dissmissFocusCall() {
    localStorage.setItem('isCompanyOnFocus', 'false');
  }

  getCallyOnFocus() {
    let id = localStorage.getItem('callOnFocus');
    return id;
  }

  getIsCallOnFocus() {
    let flag = localStorage.getItem('isCallOnFocus')
    return flag;
  }
}
