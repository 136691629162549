<nb-card style="max-height: 95vh; margin-bottom: 0;">
    <nb-card-header>
        Detalles del reporte
    </nb-card-header>
    <nb-card-body>
        <div id="printsection">
            <div class="col-md-12 text-center" style="margin-bottom: 5px;">
                <h3 class="form-font" style="color: black;text-transform: uppercase;">Entradas de redes sociales</h3>
            </div>
            <ng-container *ngFor="let sMEntry of data.socialMediaEntries">
                <ng-container [ngSwitch]="sMEntry.title">                    
                    <ng-container *ngSwitchCase="'Comentarios respondidos en Facebook'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.campaignFacebook && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Inbox enviados con información por Facebook'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.inboxFacebook && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Comentarios respondidos por Instagram'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.commentsInsta  && sMEntry.totalAll > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.quantity}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Comentarios respondidos por Facebook'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.commentsFacebook && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Post de Facebook'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.postsFacebook && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Campañas de Facebook'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.campaignFacebook && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Post de Instagram'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.postsInsta && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Envíos de información masiva'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.infoMasiva && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Mensajes respondidos por Instagram'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.responseInsta && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Confirmaciones de citas'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.confirmDates && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Envíos de recordatorios'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.confirmDates && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Envíos de felicitaciones'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.congrats && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Envío de información por LinkedIn'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.infoLinkedIn && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Mensaje de WhatsApp'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.whatsappMessages && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Envíos de encuesta'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.surveys && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Actividades administrativas'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.administrativeActivities && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Citas agenda externa'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.externalAgenda && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Conexiones por Zoom'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.zoomConections && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Seguimientos correo electrónico'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.emailFollowUps && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Post en otra red social'">
                        <div class="row" style="align-items: center; justify-content: center;"
                            *ngIf="data.infoToShow.socialNetworkPost && sMEntry.quantity > 0">
                            <div class="col-md-6">
                                <div style="margin-bottom: 9px;">
                                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                                        <nb-card-body class="text-center">
                                            <span class="number">{{sMEntry.totalAll}}</span><br>
                                            <span class="form-font">{{sMEntry.title}}</span>

                                        </nb-card-body>
                                    </nb-card>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </ng-container>
            </ng-container>
            <div class="col-md-12 text-center">
                <h3 class="form-font" style="color: black;text-transform: uppercase; margin-top: 30px;">Citas</h3>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                        <nb-card-body class="text-center">
                            <span class="number">{{data.total_mails}}</span><br>
                            <span class="form-font">Info. enviada por correo</span>
                        </nb-card-body>
                    </nb-card>
                </div>
                <div class="col-md-4">
                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                        <nb-card-body class="text-center">
                            <span class="number">{{data.total_citas}}</span><br>
                            <span class="form-font">Citas agendadas</span>
                        </nb-card-body>
                    </nb-card>
                </div>
                <div class="col-md-4">
                    <nb-card style="height: 101px;border: 1px solid #ffc94d;">
                        <nb-card-body class="text-center">
                            <span class="number">{{data.total_calls}}</span><br>
                            <span class="form-font">Llamadas recibidas</span>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div>
            <!-- <div class="row" style="margin-top: 30px;">
                <div class="col-md-12 text-center">
                    <h3 class="form-font" style="color: black;text-transform: uppercase;">Bitácora</h3>
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-md-12" scrollY="true" style="height: 15rem">
                    <nb-card *ngFor="let act of bitacora">
                        <nb-card-header style="padding: 6px 12px 0px;">
                            <div class="timestamp-container">
                                <div>
                                    <p class="timestamp"><span>{{act.date.toDate() | date:'dd MMMM yyyy'}}</span> a las
                                        <span>{{act.date.toDate() | date:'hh:mm a'}}</span>
                                    </p>
                                </div>
                            </div>
                        </nb-card-header>
                        <nb-card-body>
                            <p>{{act.text}}</p>
                            <p>Comentarios: {{act.comments}}</p>
                        </nb-card-body>
                    </nb-card>
                </div>
            </div> -->
        </div>
    </nb-card-body>
    <nb-card-footer class="text-center" style="display: flex; justify-content: space-evenly;">
        <button nbButton status="danger" (click)="ref.close()">Cerrar</button>
        <button nbButton status="success" (click)="sendToClient(data.id)">Enviar a cliente</button>
        <button nbButton status="info" printSectionId="printsection" ngxPrint
            [useExistingCss]="true">Imprimir</button>
    </nb-card-footer>
</nb-card>