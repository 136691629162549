import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { logClient } from 'src/app/models/logs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  private urlConnect = environment.URL_API + "/"
  constructor(
    private http: HttpClient
  ) { }

  async editLog(companyId: string, log: logClient) {
    try {
      var response = await this.http.put<any>(this.urlConnect + "log/" + companyId, log).toPromise()
      if (response.data) {
        return true
      }
      else {
        return false
      }
    } catch (error) {
      throw error
    }
  }

  async deleteLog(compamyId: string, logId: string) {
    try {
      await this.http.delete<any>(this.urlConnect + "clients/" + compamyId + "/delete-log/" + logId).toPromise()
      return
    } catch (error) {
      throw error
    }
  }

  async addManualLog(companyId: string, logInfo: logClient) {
    try {
      var response = await this.http.post<{ code: number, message: string }>(this.urlConnect + "clients/add-manual-log", { companyId: companyId, log: logInfo }).toPromise()
      return response
    } catch (error) {
      throw error
    }
  }
}
