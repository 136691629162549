import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-call-modal',
  templateUrl: './call-modal.component.html',
  styleUrls: ['./call-modal.component.scss']
})
export class CallModalComponent implements OnInit {

  constructor(private route: Router) {
    if (localStorage.getItem('isCallOnFocus') == "false") {
      this.route.navigate(["/dashboard"])
    }
    setTimeout(() => {
      window.close();
    }, 30000);
  }

  ngOnInit() { }
}
