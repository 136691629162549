import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Prospect } from 'src/app/models/prospects';
import { ProspectsService } from 'src/app/services/prospects/prospects.service';
import { ZoomService } from 'src/app/services/zoom/zoom.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-zoom',
  templateUrl: './edit-zoom.component.html',
  styleUrls: ['./edit-zoom.component.scss']
})
export class EditZoomComponent implements OnInit {

  public filteredOptions: Observable<any>
  public zoomForm: FormGroup
  public minDate = new Date()
  private prospectList = []
  constructor(
    private dialogRef: MatDialogRef<EditZoomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formbuilder: FormBuilder,
    private prospectService: ProspectsService,
    private zoomService: ZoomService
  ) { }

  async ngOnInit() {
    this.zoomForm = this.formbuilder.group({
      client: ['', Validators.required],
      // agents: [[], Validators.required],
      meetDate: ['', Validators.required],
      meetHour: [{ hour: 1, minute: 0 }, Validators.required],
      url: ['', Validators.required],
    })
    this.prospectList = await this.getClients()
    this.filteredField()
    this.zoomForm.patchValue({
      client: this.prospectList.filter((user) => user._id == this.data.clientId)[0],
      meetDate: this.data.meetDate,
      url: this.data.url,
      meetHour: this.extractTime(this.data.meetDate)
    })

  }

  async getClients() {
    try {
      const response = await this.prospectService.getActiveProspects()
      return response.data
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error inesperado al consultar los clientes',
        icon: 'error',
        text: 'Si el problema persiste favor de comunicarlo con un administrador'
      })
    }
  }

  filteredField() {
    this.filteredOptions = this.zoomForm.controls['client'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string) {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();
      return this.prospectList.filter((client) => client.nombre.toLowerCase().includes(filterValue));
    }
  }

  async submit() {
    try {
      const formValue = this.zoomForm.value
      var data = {
        clientId: formValue.client._id,
        meetDate: this.getCombineDateTime(formValue.meetDate, formValue.meetHour),
        url: formValue.url,
      }
      await this.zoomService.update(this.data._id, data)

      await Swal.fire({
        icon: 'success',
        title: 'Información actualizada con exito',
        showConfirmButton: false,
        timer: 2000
      })
      this.closeModal(true)
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Ocurrió un error al actualizar la información',
        icon: 'error'
      })
    }
  }

  closeModal(action?: boolean) {
    if (action) {
      this.dialogRef.close(true)
    } else {
      this.dialogRef.close()
    }
  }

  private getCombineDateTime(date: Date, time: any) {
    var day = new Date(new Date(date).setHours(0, 0, 0, 0))
    var hour = parseInt(time.hour);
    var min = parseInt(time.minute);
    var newDate = DateTime.fromISO(day.toISOString().split("T")[0])
      .set({ hour: hour })
      .set({ minute: min })
      .set({ second: 0 })
      .toJSDate();
    return newDate;
  }

  extractTime(date: string) {
    var timeFormat = new Date(date)
    return { hour: timeFormat.getHours(), minute: timeFormat.getMinutes(), second: 0 }
  }

  displayFn(client: Prospect) {
    return client.nombre
  }


}
